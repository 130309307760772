import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../../Styles/AdminDashboard";
import axios from "axios";

export default function InnerDashboard(props) {
  const [userId,setUserId] = useState(props.innerId?props.innerId:"");
  const [allTickets,setAlltickets] = useState([]);
  const [innerNav,setInnerNav] = useState(false);
  const [userData,setuserData] = useState("");
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserdata`,
          {
            id: localStorage.getItem("user_id"),
          }
        );
        // console.log(response.data);

        if (response.data.status === 1) {
          // console.log(response.data.data);
          setuserData(response.data.data[0]);
        }
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const [data,setData] = useState("");
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserdata`,
          {
            id: userId,
          }
        );
        // console.log(response.data);

        if (response.data.status === 1) {
          // console.log(response.data.data);
          setData(response.data.data[0]);
        }
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [props]);

  const [innerData,setinnerData] = useState("");
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserdata`,
          {
            id: userId,
          }
        );
        // console.log(response.data);

        if (response.data.status === 1) {
          // console.log(response.data.data);
          setinnerData(response.data.data[0]);
        }
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const getsystemdata = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserspecifictickets`,
          { "user_id": userId, "organisation":  localStorage.getItem("orgn_id") ,"role": props.innerRole?props.innerRole:localStorage.getItem("role")}
        );
        // console.log(response.data.data,"project api inner dash  ");
        if (response.data.status === 1) {
          setAlltickets(response.data.data);
        }
        getteam()
      } catch (e) {
        // console.log(e);
      }
    };
    getsystemdata();
  }, [userId]);
const [getteams,setgetteams]=useState([])
  const getteam = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallreportinguser`,
        { "user_id": userId, "raised_organisation_id":  localStorage.getItem("orgn_id") }
      );
      // console.log(response.data.data,"team api");
      if (response.data.status === 1) {
        setgetteams(response.data.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // const navigateInnerDash = (id)=>{
  //   setUserId(id);
  // };

  const navigateInnerDash = (id) => {
    // alert("Please",id)
    setInnerNav(true);
    // console.log("navigateInnerDash",id)
    setUserId((prevUserId) => {
      // Use the previous state value to ensure the latest state
      if (prevUserId !== id) {
        // Add your logic to handle state updates or API calls
        // console.log(`New userId: ${id}`);
      }
      return id; // Set the new state value
    });
  };

  const ViewTicketPage = (status,length)=>{
    props.setActive(8);
    props.setTicketSortings(status);
    props.setTicketLength(length)
  }
  const handleBackDash = ()=>{
    props.setActive(1);
  };

  const handleBack = ()=>{
    setUserId(props.innerId);
    setInnerNav(false);
  };


  const resolvedTickets = allTickets?.filter(ticket => ticket.status === 'Resolved');
  const closedTickets = allTickets?.filter(ticket => ticket.status === 'Closed');
  const openedTickets = allTickets?.filter(ticket => ticket.status === 'Opened');
  const inprogressTickets = allTickets?.filter(ticket => ticket.status === 'In Progress');
  const assignedTickets = allTickets?.filter(ticket => ticket.status === "Assigned");
  const reopenedTickets = allTickets?.filter(ticket => ticket.status === "Reopened");
  const pendingTickets = allTickets?.filter(ticket => ticket.status === "Pending");
  return (
    <Box style={Styles.mainBox}>
      <Box style={Styles.upperBox}>
        <Typography style={{...Styles.name}}><span style={{cursor:"pointer"}} onClick={()=>{handleBackDash()}}>{userData.name}</span> / <span style={{cursor:"pointer"}} onClick={()=>{handleBack()}}>{data.name}</span> / {innerNav?innerData.name:""}</Typography>
        <Box onClick={()=>{props.setActive(12)}}style={Styles.reqButton}>
          <Typography style={Styles.text3}>New ticket</Typography>
          <img src={require("../../Images/plus.png")} />
        </Box>
      </Box>

      <Box style={Styles.mainMiddleBox}> 
        <Box onClick={()=>ViewTicketPage("",allTickets?.length)} style={{ ...Styles.card }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={Styles.innerTicket}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Total tickets</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{allTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Resolved",resolvedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#FEB7B1" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Resolved</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{resolvedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Closed",closedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#E96973" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Closed</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{closedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Opened",openedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#F9C29A" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Opened</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{openedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("In Progress",inprogressTickets?.length)} style={{ ...Styles.card }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#BCDCA4" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>In Progress</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{inprogressTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Assigned",assignedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#BEC9F3" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Assigned</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{assignedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Reopened",reopenedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#FFE4AC" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Reopened</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{reopenedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Pending",pendingTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#86D8FF" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Pending</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{pendingTickets?.length}</Typography>
        </Box>
      </Box>

      <Box style={{...Styles.upperBox}}>
        <Typography
          style={{ ...Styles.name, fontSize: "22px", marginTop: "1%" }}
        >
          Team performance
        </Typography>
      </Box>

      <Box style={Styles.mainBottomBox}>
      <Box style={Styles.tableBox2}>
  <TableContainer component={Paper} elevation={0} style={Styles.tableContainer}>
    <Table style={Styles.table} aria-label="simple table">
      <TableHead style={Styles.tableHead}>
        <TableRow>
          <TableCell style={Styles.tableCell} align="left">
            Name
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Assigned
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Pending
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {getteams?.map((row) => (
          <TableRow  onClick={()=>{navigateInnerDash(row.id)}} style={{ height: 60 }} key={row.id}>
            <TableCell style={Styles.tableText} align="left">
              {row?.name}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.assigned_count}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.pending_count}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</Box>

      </Box>
    </Box>
  );
}
