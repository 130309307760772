import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import AdminDashboard from "./AdminDashboard";
import Sidebar from "../Components/Sidebar";
import Navbar from "../Components/Navbar";
import ViewTicket from "./ViewTicket";
import TicketDetail from "./TicketDetail";
import MyTeam from "./MyTeam";
import Projects from "./Projects";
import Profile from "./Profile";
import NewProject from "../Components/NewProject";
import Organisation from "./Organisation/Organisation";
import CreateOrganisation from "./Organisation/CreateOrganisation";
import RaiseNewTicket from "../Components/RaiseNewTicket";
import RaiseNewTask from "../Components/RaiseNewTask";
import CheckList from "./CheckList";
import InnerDashboard from "../Components/Dashboard/InnerDashboard";
import CreateProjects from "./Projects/CreateProjects";
import Styles from "../Styles/AdminDashboard";
import EditDetails from "./Organisation/EditDetails";
import TaskDetail from "./Projects/TaskDetail";
import Connect from "./Projects/Connect";

export default function Dashboard(props) {
  const [active, setActive] = useState(1);
  const [JdDetail,setJdDetail] = useState([]);
  const [ticketDetails,setTicketDetails] = useState([]);
  const [ticketSortings,setTicketSortings] = useState("");
  const [ticLength,setTicketLength] = useState("");
  const [innerId,setinnerId] = useState("");
  const [ticketId,setTicketId] = useState("");
  const [innerRole,setinnerRole] = useState("");
  const [row,setRow] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [taskSelected,settaskSelected] = useState({});
  // console.log("task selected data",taskSelected);
  const [checkLists,setcheckLists] = useState([]);
  const [checkListsamc,setcheckListsamc] = useState([]);
  const [taskData,setTaskData] = useState(null);
  const [taskNavigate,setTasknavigate] = useState(false);
  const [taskDatastatus,setTaskDatastatus] = useState("");
  // console.log("jd detail",JdDetail)
  const handleClick = (key) => {
    setActive(key);
  };

  let content = null;
  if (active === 1) {
    content = <AdminDashboard taskNavigate={taskNavigate} setTaskDatastatus={setTaskDatastatus} setTaskData={setTaskData} setTicketLength={setTicketLength} setTicketSortings={setTicketSortings} setinnerRole={setinnerRole}  setTicketId={setTicketId} setinnerId={setinnerId} setJdDetail={setJdDetail} setActive={setActive} />;
  } else if (active === 2) {
    content = <MyTeam />;
  } else if (active === 3) {
    content = <Projects setTasknavigate={setTasknavigate} setTaskData={setTaskData} taskDatastatus={taskDatastatus} taskData={taskData} setActive={setActive} setRow={setRow} setSelectedProject={setSelectedProject}  selectedProject={selectedProject} settaskSelected={settaskSelected} setcheckLists={setcheckLists} setcheckListsamc={setcheckListsamc}/>;
  } else if (active === 4) {
    content = <Organisation setActive={setActive} setRow={setRow} setSelectedProject={setSelectedProject}/>;
  } else if (active === 5) {
    content = <Profile  />;
  } else if (active === 6) {
    content = <AdminDashboard JdDetail={JdDetail} setActive={setActive} />;
  } else if (active === 7) {
    content = <AdminDashboard JdDetail={JdDetail} />;
  }
  else if (active === 8) {
    content = <ViewTicket ticLength={ticLength} ticketSortings={ticketSortings} ticketId={ticketId} innerRole={innerRole} setActive={setActive} setTicketDetails={setTicketDetails}/>;
  }
  else if (active === 9) {
    content = <TicketDetail ticketDetails={ticketDetails} setActive={setActive} />;
  }
  else if (active === 11) {
    // console.log('Create project is been called');
    content = <CreateProjects setActive={setActive} row={row}/>;
  }
  else if (active === 10) {
    content = <CreateOrganisation ticketDetails={ticketDetails} setActive={setActive} />;
  } else if (active === 12) {
    content = <RaiseNewTicket ticketDetails={ticketDetails} setActive={setActive} />;
  } else if (active === 13) {
    content = <Connect />;
  }
  else if (active === 14) {
    content = <InnerDashboard setTicketLength={setTicketLength} setTicketSortings={setTicketSortings} innerId={innerId} innerRole={innerRole}  ticketDetails={ticketDetails} setActive={setActive} />;
  }
  else if (active === 15) {
    content = <RaiseNewTask ticketDetails={ticketDetails} setActive={setActive} />;
  }
  else if (active === 16) {
    content = <TaskDetail setActive={setActive} taskSelected={taskSelected} selectedProject={selectedProject} checkLists={checkLists} checkListsamc={checkListsamc}/>;
  }

   else {
    content = <AdminDashboard />;
  }
  return (
    <>
      <Box sx={styles.mainContainer}>
        {/* ..............navbar */}
        <Box styles={Styles.navbar}>
          <Navbar settaskSelected={settaskSelected} setTaskData={setTaskData} setActive={setActive} setTicketDetails={setTicketDetails} active={active} handleClick={handleClick}/>
        </Box>
        {/* ..................navbar ends here */}

        <Box sx={styles.bottomContainer}>
          {/* ....................sidebar starts here */}
          <Box sx={styles.sidebar}>
            <Sidebar
              active={active}
              setActive={setActive}
              handleClick={handleClick}
              setTasknavigate={setTasknavigate}
            />
          </Box>
          {/* ...................sidebar ends here */}

          {/* .................center  start here */}
          <Box sx={styles.centerPart}>{content}</Box>
          {/* ...................center ends here */}
        </Box>
      </Box>
    </>
  );
}

const styles = {
  mainContainer: {
    width: "100%",
    overflowY: "hidden",
    height: "100%",
    // display:"flex",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    height: "89vh",

    "@media (max-width: 768px)": {
      flexWrap: "wrap",
    },
  },
  sidebar: {
    width: "15%",
    height:"100%",
    backgroundColor: "#A3CEF1",
  },
  centerPart: {
    width: "85%",
    overflowX: "hidden",
  },
  navbar: {
    position: "sticky",
    top: 0,
  },
};
