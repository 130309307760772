import {
  Box,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../../Styles/AdminDashboard";
import axios from "axios";

export default function AdminEditPopup(props) {
  const [edittargetdate, setedittargetdate] = useState(false);
  const [targetDate, setTargetDate] = useState(null);
  console.log("checking ids in edut: " ,props);
  const [data, setData] = useState({});

  const [assignTo, setAssignto] = useState([]);

  useEffect(() => {
    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getprojectteam`,
          {
            organisation: localStorage.getItem("orgn_id"),
            project_id: props.project_id,
          }
        );
        // console.log(response.data.data,"project api oooooooo");
        if (response.data.status === 1) {
          setAssignto(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    allMembers();
  }, []);

  //   const [edittargetdate,setedittargetdate] = useState(false)
  const handleInputChange = (key, value) => {
    // console.log("all data: ", key, value);
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const inputDateString1 = props.sendData?.performing_date;
  const formattedDate1 = new Date(inputDateString1).toLocaleDateString("en-GB");
  const handleTargetDateChange = (event) => {
    const selectedDateString = event.target.value;
    const selectedDateObject = new Date(selectedDateString);
    setTargetDate(selectedDateObject);
    console.log(selectedDateObject.toLocaleDateString("en-GB")); // Log in dd/mm/yy format
  };
  const handleClose = async () => {
    props.setOpen(false);
  };

  const handleSave = async () => {
    
    try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/changechecklisttasktouser`,
          {
            assigned_user_id: data.assignto,
            checklist_id: props.chkid,
            performing_date:targetDate
          }
        );
        if (response.data.status === 1) {
            // console.log(response.data.data,"checklist edited successfully");
            props.setOpen(false);
        //   setAssignto(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
  }
  return (
    <Box>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          style={{
            ...Styles.statusnotify_changeassign,
            width: "25%",
            height: "25%",
          }}
        >
          <Box
            style={{
              ...Styles.statusnotifyTitlebutton_changestat,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignitem: "center",
            }}
          >
            <h2
              id="parent-modal-title"
              style={{ ...Styles.statusTitle_changestat, fontSize: "20px" }}
            >
              Edit checklist
            </h2>
            <Typography
              onClick={handleClose}
              style={{
                ...Styles.statusnotifyXbutton_changestat,
                display: "flex",
                justifyContent: "right",
                marginLeft: "auto",
                marginTop: "1%",
              }}
            >
              X
            </Typography>
          </Box>

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap:"wrap"
            }}
          >
            <Box>
              <Typography
                style={{ fontFamily: "Montserrat", color: "#082A49" }}
              >
                Targeted date
              </Typography>
              <FormControl
                size="small"
                style={{
                  ...Styles.FormControl,
                  height: "40px",
                  marginTop: "1.5%",
                  alignItems: "center",
                  justifyContent: "center",
                  //   marginLeft: "2.4%",
                }}
                fullWidth
              >
                  <TextField
                    type="date"
                    // value={formattedDateString || oldDateObject} // Use formatted string or Date object
                    onChange={handleTargetDateChange}
                    InputLabelProps={{ style: { display: "none" } }}
                    InputProps={{
                      style: {
                        border: "none",
                        height: "100%",
                        // width: "100%",
                        color: "#A9A9A9",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        border: "none",
                      },
                      ...Styles.inputBox,
                      width: "100%",
                      height: "100%",
                      marginTop: "0%",
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      margin: "auto",
                    }}
                  />
                
              </FormControl>
            </Box>
            <Box>
              <Typography
                style={{ fontFamily: "Montserrat", color: "#082A49" }}
              >
                Change asignee
              </Typography>
              <FormControl
                size="small"
                style={{
                  ...Styles.FormControl,
                  height: "40px",
                  marginTop: "1.5%",
                  //   marginLeft: "2.4%",
                }}
                fullWidth
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={sort}
                  // label="Select company"
                  style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                  size="small"
                  onChange={(e) =>
                    handleInputChange("assignto", e.target.value)
                  }
                  // onChange={(event) => setsort(event.target.value)}
                >
                  {/* ... (other Select menu items) */}
                  {assignTo?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
            <Box onClick={handleSave} style={{ ...Styles.reqButton,width: "120px",marginTop:"5%"}}>
            <Typography style={Styles.text3}  >
              Save changes
            </Typography>
           
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
