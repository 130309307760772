import {
    Box,
    FormControl,
    MenuItem,
    Modal,
    Radio,
    Select,
    TextField,
    Typography,
    createTheme,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import dayjs from "dayjs";
  import Styles from "../../../Styles/AdminDashboard";
  import axios from "axios";
//   import imageDemo from "../../../Images/docPlaceholder.png";
  import LogsAttachment from "../../../Components/TicketDetails/LogsAttachment";
  
  export default function Checklistpopupamc(props) {
    console.log("props popup: ", props);
    // console.log("props popup date: ", props.sendData.id);
    const [data, setData] = useState({
      progress: props.sendData.completed_status?props.sendData.completed_status:"",
    });
    const arrName = [
      {
        id: props.sendData?.created_user_id,
        name: props.sendData?.createdname,
      },
      {
        id: props.sendData?.assigned_user_id,
        name: props.sendData?.username,
      },
    ];
    const [edittargetdate,setedittargetdate] = useState(false)
    const handleInputChange = (key, value) => {
      // console.log("all data: ", key, value);
      setData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    };
    
    let createdName = props.sendData?.createdname;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedValue, setSelectedValue] = useState("a");
    console.log("first selected value", selectedFiles);
  
    const handleChangeRadio = async(event) => {
      setSelectedValue(event.target.value);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/completeatskchecklist`,
          {
            "id":props.sendData.checklist_id,
            "user_id":localStorage.getItem("user_id"),
            "description":data.notes
          }
        );
        // console.log(response.data.data,"checklist completed",response.data.responseassignee);
        if (response.data.status === 1) {
          props.setsubCheckLists(response.data.responseassignee);

          // setAssignto(response.data.data);
          // alert("Task completed");
          handleClose();
        }
      } catch (e) {
        // console.log(e);
      }
    };

    const [attachments, setAttachments] = useState([]);
    const [newattachments, setnewAttachments] = useState([]);
    
    useEffect(() => {
      const getAttachments = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/gettaskdoclist`,
            { checklist_id: props.sendData.checklist_id }
          );
          // console.log(response.data.data, "project api");
          if (response.data.status === 1) {
            setAttachments(response.data.data);
            setSelectedFiles(response.data.data);
          }
        } catch (e) {
          // console.log(e);
        }
      };
      getAttachments();
    }, []);
    useEffect(() => {
      const getAttachments = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/gettaskspecificdoclist`,
            { task_id: props.sendData?.taskid }
          );
          // console.log(response.data.data, "project api");
          if (response.data.status === 1) {
            setAttachments(response.data.data);
            setSelectedFiles(response.data.data);
          }
        } catch (e) {
          // console.log(e);
        }
      };
      getAttachments();
    }, []);
    
    useEffect(() => {
      const getAttachments = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/getchecklistlogs`,
            { checklist_id: props.sendData.taskid }
          );
          if (response.data.status === 1) {
            console.log(response.data.data, "fetch task logs api");
          }
        } catch (e) {
          // console.log(e);
        }
      };
      getAttachments();
    }, []);

    const controlProps = (item) => ({
      checked: selectedValue === item,
      onChange: handleChangeRadio,
      value: item,
      name: "color-radio-button-demo",
      inputProps: { "aria-label": item },
    });
  
    useEffect(()=>{
      if(props.sendData.completed_status==="completed"){
        setSelectedValue("true")
      }
    },[])
    const inputDateString = props.sendData?.created_date;
    const formattedDate = new Date(inputDateString).toLocaleDateString("en-GB");
  
    const inputDateString1 = props.sendData?.performing_date;
    const formattedDate1 = new Date(inputDateString1).toLocaleDateString("en-GB");
  
    const inputDateString3 = props.sendData?.start_date;
    console.log("this is formatted", inputDateString3)
    const formattedDate3 = inputDateString3 ? new Date(inputDateString3).toLocaleDateString("en-GB") : "none";
  
    const letterColors = {
      a: "#81b29a",
      b: "#81b29a",
      c: "#81b29a",
      d: "#f2cc8f",
      e: "#f2cc8f",
      f: "#f2cc8f",
      g: "#3d405b",
      h: "#3d405b",
      i: "#3d405b",
      j: "#e07a5f",
      k: "#e07a5f",
      l: "#e07a5f",
      m: "#eaac8b",
      n: "#eaac8b",
      o: "#eaac8b",
      p: "#fe7f2d",
      q: "#e4c1f9",
      r: "#e4c1f9",
      s: "#a1c181",
      t: "#a1c181",
      u: "#a1c181",
      v: "#fe7f2d",
      w: "#fe7f2d",
      x: "#fe7f2d",
      y: "#bf0603",
      z: "#bf0603",
    };
  
    const getRandomColor = (name) => {
      const firstLetter = name?.charAt(0).toLowerCase();
      return letterColors[firstLetter] || "#808080"; // Default color is gray (#808080)
    };
  
    const isDarkColor = (color) => {
      const hex = color.substring(1); // Remove the '#' character
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
  
      // Use the relative luminance formula to determine if the color is dark
      const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
      return luminance < 128;
    };
  
    const [selectedDate, setSelectedDate] = useState(null);
    // console.log("selceted date: ", selectedDate);
    const handleDateChange = (event) => {
      const selectedDateString = event.target.value;
      const selectedDateObject = new Date(selectedDateString);
      setSelectedDate(selectedDateObject);
      console.log(selectedDateObject.toLocaleDateString("en-GB")); // Log in dd/mm/yy format
    };
    const [targetDate, setTargetDate] = useState(null);
    console.log("target date: " + targetDate);
    const handleTargetDateChange = (event) => {
      const selectedDateString = event.target.value;
      const selectedDateObject = new Date(selectedDateString);
      setTargetDate(selectedDateObject);
      console.log(selectedDateObject.toLocaleDateString("en-GB")); // Log in dd/mm/yy format
    };
  
    // useEffect(()=>{
    //   setTargetDate(formattedDate1);
    // },[])
  
    const [assignTo, setAssignto] = useState([]);
  
    useEffect(() => {
      const allMembers = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/getprojectteam`,
            {
              organisation: localStorage.getItem("orgn_id"),
              project_id: props.project_id,
            }
          );
          // console.log(response.data.data,"project api oooooooo");
          if (response.data.status === 1) {
            setAssignto(response.data.data);
          }
        } catch (e) {
          // console.log(e);
        }
      };
      allMembers();
    }, []);
    const [numofFiles, setNumOfFiles] = useState("");
    // console.log("first file` num of files", numofFiles)
    const handleFileChange = (e) => {
      const files = Array.from(e.target.files);
      console.log("selecetde base file new num of files",files)
      setNumOfFiles(files.length);
      // setAttachments(files);
      // setSelectedFiles(files);
      // setnewAttachments(files);
      uploadCategoryImgFile(files.length,files)
    };
    
    const uploadCategoryImgFile = async (len,allfiles) => {
      console.log("Hello : uploadCategoryImgFile " );
      try {
        for (let i = 0; i < len; i++) {
          const formdata = new FormData();
          const file = allfiles[i];
          console.log("file name : ", file,"ith value",allfiles[i]);
          formdata.append("tasks_documents", file);
          // formdata.append("task_id", id);
          // formdata.append("name", file?.name);
          console.log(formdata,"uploaded formdata");
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/uploadtask?name=${file.name}`,
            formdata,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data.status === 1) {
            console.log("uploaded successfully in second attempt");

            try {
              const file = allfiles[i];
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/addtaskdocpath`,
                {
                  "checklist_id": props.sendData?.checklist_id,
                  "file_name": file.name,
                  "user_id": localStorage.getItem("user_id"),
                  "task_id":props.sendData?.taskid
                }
              );
              if (response.data.status === 1) {
                console.log("uploaded successfully", response.data.data);
                // setAttachments()
                setAttachments((prevLogs) => [...prevLogs, ...response.data.data]);
                setSelectedFiles((prevLogs) => [...prevLogs, ...response.data.data]);
              }
              console.log(response.data);
            } catch (e) {
              console.log(e);
            }
          }
          console.log(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    const handleSave = async() => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/updatechecklisttask`,
          {
            "id":props.sendData.taskid,
            "turnkey_id":data.bucket?data.bucket:props.sendData.turnkey_id,
            "assigned_user_id":data.assignto?data.assignto:props.sendData.assigned_user_id,
            "completed_status":data.progress?data.progress:props.sendData.progress,
            "performing_date":targetDate?targetDate:formattedDate1,
            "description": data.notes,
            "user_id":localStorage.getItem("user_id"),
          }
        );
        console.log(response.data.data,"cheklist completed",response.data.responseassignee);
        if (response.data.status) {
          // setAssignto(response.data.data);
          // props.setsubCheckLists(response.data.responseassignee);
          props.setsubCheckLists((prevLogs) => [...prevLogs, ...response.data.responseassignee]);

          // alert("Task Updated");
          handleClose();
        }
      } catch (e) {
        console.log(e);
      }
      props.setOpen(false);
    }
  
    const handleClose = async() => {
      props.setOpen(false);
    };


    const getFileExtension = (filename) => {
      return filename?.split(".")?.pop()?.toLowerCase();
    };
    const renderUser = (user) => {
      const backgroundColor = getRandomColor(user);
      const textColor = isDarkColor(backgroundColor) ? "white" : "black";
  
      return (
        <Box
          key={user.id}
          style={{
            marginLeft: "2%",
            width: "33px",
            height: "33px",
            borderRadius: "50px",
            backgroundColor,
            color: textColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography style={{ fontStyle: "bolder" }}>
            {createdName.charAt(0).toUpperCase()}
          </Typography>
          {/* <Typography>{createdName}</Typography> */}
        </Box>
      );
    };
  
    return (
      <Box>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box
            style={{
              ...Styles.statusnotify_changestat,
              height: "75%",
              backgroundColor: "#EDEDED",
              border: "1px solid #F8F8F8",
            }}
          >
            <Box style={Styles.statusnotifyTitlebutton_changestat}>
              <Radio
                {...controlProps("true")}
                sx={{
                  color: "#082A49",
                  "&.Mui-checked": {
                    color: "#082A49",
                  },
                }}
              />
  
              <h2
                id="parent-modal-title"
                style={{
                  ...Styles.statusTitle_changestat,
                  color: "#082A49",
                  fontSize: "24px",
                  textAlign: "flex-start",
                  width: "80%",
                }}
              >
                {props?.sendData?.taskname}
              </h2>
              <Box
                style={{
                  width: "70%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  onClick={()=>{handleSave()}}
                  style={{ ...Styles.statusnotifyXbutton_changestat }}
                >
                  X
                </p>
              </Box>
            </Box>
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                marginTop: "5%",
                flexDirection: "column",
              }}
            >
              <Box style={{ width: "100%", height: "12%", display: "flex" }}>
                <img
                  style={{ width: "29px", height: "25px", cursor: "pointer" }}
                  src={require("../../../Images/team.png")}
                />
                {arrName.map(({ id, name }) => {
                  const backgroundColor = getRandomColor(name);
                  const textColor = isDarkColor(backgroundColor)
                    ? "white"
                    : "black";
  
                  return (
                    <React.Fragment key={id}>
                      <Box
                        style={{
                          marginLeft: "2%",
                          width: "33px",
                          height: "33px",
                          borderRadius: "50px",
                          backgroundColor,
                          color: textColor,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Typography style={{ fontStyle: "bolder" }}>
                          {name.charAt(0).toUpperCase()}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Box>
  
              <Box
                style={{
                  width: "90%",
                  height: "25%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  // backgroundColor:"red",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    style={{ fontFamily: "Montserrat", color: "#082A49" }}
                  >
                    Progress
                  </Typography>
                  <FormControl
                    size="small"
                    style={{
                      ...Styles.FormControl,
                      height: "40px",
                      marginTop: "1.5%",
                      //   marginLeft: "2.4%",
                    }}
                    fullWidth
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={sort}
                      // label="Select company"
                      style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                      size="small"
                      value={data.progress}
                      onChange={(e) =>
                        handleInputChange("progress", e.target.value)
                      }
                      // onChange={(event) => setsort(event.target.value)}
                    >
                      <MenuItem value={"notstarted"}>Not started</MenuItem>
                      <MenuItem value={"inprogress"}>In Progress</MenuItem>
                      <MenuItem value={"completed"}>Completed</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Typography
                    style={{ fontFamily: "Montserrat", color: "#082A49" }}
                  >
                    Start date
                  </Typography>
                  <FormControl
                    size="small"
                    style={{
                      ...Styles.FormControl,
                      height: "40px",
                      marginTop: "1.5%",
                      // alignItems: "center",
                      justifyContent: "center",
                      display:"flex",
                      //   marginLeft: "2.4%",
                    }}
                    fullWidth
                  >
                    <Typography
                      sx={{
                        // width: "90%",
                        fontSize: "14px",
                        cursor: "pointer", 
                        fontWeight: "bold",
                        color: "#1282A2 ",
                        fontFamily: "Montserrat",
                        display: "flex",
                        marginLeft:"2%"
                        // justifyContent: "space-between",
                        // alignItems: "center",
                      }}
                    >Anytime</Typography>
                  </FormControl>
                </Box>
                <Box>
                    <Typography
                      style={{ fontFamily: "Montserrat", color: "#082A49" }}
                    >
                      Change asignee
                    </Typography>
                    <FormControl
                      size="small"
                      style={{
                        ...Styles.FormControl,
                        height: "40px",
                        marginTop: "1.5%",
                        //   marginLeft: "2.4%",
                      }}
                      fullWidth
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={sort}
                        // label="Select company"
                        style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                        size="small"
                        onChange={(e) =>
                          handleInputChange("assignto", e.target.value)
                        }
                        // onChange={(event) => setsort(event.target.value)}
                      >
                        {/* ... (other Select menu items) */}
                        {assignTo?.map((item, index) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                <Box>
                  <Typography
                    style={{ fontFamily: "Montserrat", color: "#082A49" }}
                  >
                    Targeted date
                  </Typography>
                  <FormControl
                    size="small"
                    style={{
                      ...Styles.FormControl,
                      height: "40px",
                      marginTop: "1.5%",
                      alignItems: "center",
                      justifyContent: "center",
                      //   marginLeft: "2.4%",
                    }}
                    fullWidth
                  >
                    {!edittargetdate ? (
                      <Typography
                      onClick={()=>{setedittargetdate(true)}}
                      style={{
                        width: "90%",
                        fontSize: "14px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#1282A2 ",
                        fontFamily: "Montserrat",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {formattedDate1}
                      <img
                        style={{ width: "20px", height: "12px" }}
                        src={require("../../../Images/downArrow.png")}
                      />
                    </Typography>
                    ) : (
                    <TextField
                      type="date"
                      // value={formattedDateString || oldDateObject} // Use formatted string or Date object
                      onChange={handleTargetDateChange}
                      InputLabelProps={{ style: { display: "none" } }}
                      InputProps={{
                        style: {
                          border: "none",
                          height: "100%",
                          // width: "100%",
                          color: "#1282A2 ",
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        ...Styles.inputBox,
                        width: "100%",
                        height: "100%",
                        marginTop: "0%",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        margin: "auto",
                      }}
                    />
                    )}
                    
  
                    
                  </FormControl>
                  
                </Box>
  
                <Box
                  style={{
                    width: "100%",
                    // backgroundColor: "red",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* {props.projecttype === "AMC" && (
                    <Box>
                      <Typography
                        style={{ fontFamily: "Montserrat", color: "#082A49" }}
                      >
                        Frequency
                      </Typography>
                      <FormControl
                        size="small"
                        style={{
                          ...Styles.FormControl,
                          height: "40px",
                          marginTop: "1.5%",
                          //   marginLeft: "2.4%",
                        }}
                        fullWidth
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={sort}
                          // label="Select company"
                          style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                          size="small"
                          value={data.frequency}
                          onChange={(e) =>
                            handleInputChange("frequency", e.target.value)
                          }
                        >
                          <MenuItem value={"daily"}>Daily</MenuItem>
                          <MenuItem value={"weekly"}>Weekly</MenuItem>
                          <MenuItem value={"monthly"}>Monthly</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )} */}
                 
  
                  {/* <Box>
                    <Typography
                      style={{ fontFamily: "Montserrat", color: "#082A49" }}
                    >
                      Change bucket
                    </Typography>
                    <FormControl
                      size="small"
                      style={{
                        ...Styles.FormControl,
                        height: "40px",
                        marginTop: "1.5%",
                        //   marginLeft: "2.4%",
                      }}
                      fullWidth
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                        size="small"
                        onChange={(e) =>
                          handleInputChange("bucket", e.target.value)
                        }
                      >
                        {props.checkLists?.map((item, index) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box> */}
                </Box>
              </Box>
              <Box
                style={{
                  width: "90%",
                  height: "35%",
                  // backgroundColor: "red",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "4%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  flexDirection: "row",
                }}
              >
                <Box style={{ width: "50%" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      color: "#082A49",
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                    Notes
                  </Typography>
                  <TextField
                    id="standard-multiline-static"
                    multiline
                    style={{
                      backgroundColor: "#F8F8F8",
                      width: "80%",
                      marginTop: "2%",
                    }}
                    rows={5}
                    variant="standard"
                    value={data.notes}
                    onChange={(e) => handleInputChange("notes", e.target.value)}
                  />
                </Box>
                <Box style={{ width: "50%" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      color: "#082A49",
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                   {attachments.length} Attachments
                  </Typography>
                  <Box
                    style={{
                      width: "90%",
                      marginTop: "2%",
                      height: "55%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        width: "130%",
                        height: "95%",
                        display: "flex",
                        flexDirection: "row",
                        overflowY: "auto",
                        justifyContent: "space-between",
                        paddingTop:"5%"
                      }}
                    >
                      {selectedFiles?.map((file, index) => (
                        <Box
                          style={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "#F8F8F8",
                            // border: "1px solid #1282A2",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LogsAttachment
                            checklist={true}
                            ticket_id={file.checklist_id}
                            id={file.id}
                            name={file?.file_name}
                          />
                        </Box>
                      ))}
                    </Box>
                    <input
                      type="file"
                      multiple
                      onChange={(e) => handleFileChange(e)}
                      style={{ display: "none" }}
                      id="fileInput"
                    />
                    <label
                      htmlFor="fileInput"
                      style={{ cursor: "pointer", marginBottom: "10px" }}
                    >
                      <img
                        style={{ width: "25px", cursor: "pointer" }}
                        src={require("../../../Images/Iconplusblue.png")}
                      />
                    </label>
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  width: "95%",
                  height: "10%",
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {renderUser(createdName)}
                <Typography
                  style={{ marginLeft: "2%", fontFamily: "Montserrat" }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {createdName}
                  </span>{" "}
                  created task on {formattedDate}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }
  