import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
//   import ChecklistInnerCard from "./ChecklistInnerCard";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
//   import ChecklistPopup from "../../Pages/Projects/ChecklistPopup";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Styles from "../../Styles/AdminDashboard";
//   import ExpandMoreIcon from "../Images/addbutton.png";
import axios from "axios";
import Checklistpopupamc from "../../Pages/Projects/AMC/Checklistpopupamc";
import ChecklistInneramc from "./ChecklistInneramc";
import AdminEditPopup from "./AdminEditPopup";
import moment from "moment";

export default function ChecklistNameCardamc(props) {
  // console.log("props in name card amc card", props);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [subCheckLists, setsubCheckLists] = useState([]);
  const [notCompleted,setNotCompleted] = useState([]);
  const [showNotCompleted,setShowNotCompleted] = useState(false);
  // console.log("first not completed",notCompleted)
  const [subCheckListsRefresh,setsubCheckListsRefresh] = useState([]);
  const projectEndDate = new Date(props.end_date);

  useEffect(()=>{
    if(subCheckListsRefresh){
      setsubCheckLists((prevLogs) => [...prevLogs, ...subCheckListsRefresh]);
      getsystemdata();

    }
  },[subCheckListsRefresh])

  useEffect(() => {
    if(localStorage.getItem("role")==="admin")
    {
      if(props.taskDatastatus){
        if(props.taskDatastatus === "today"){
          getsystemdata();
        }
        else if(props.taskDatastatus === "all"){
          getsystemdataadmin();
        }
        else{
          getsystemdataadmintask(props.taskDatastatus)
        }
      }
      else{
        getsystemdataadmin();
      }
    }
    else{
      getsystemdata();
    }
  }, []);

  useEffect(()=>{
    getsystemdatanotcompleted();
  },[])

  const getsystemdatanotcompleted = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getalltasknotcompleted`,
        {
            "chktskid": props.item.chkid,
        }
      );
      // console.log(response.data.data, "turnkeys  data newwww");
      if (response.data.status === 1) {
        setNotCompleted(response.data.data);
    
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getsystemdata = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getalltaskforchecklisttsk`,
        {
            "chktskid": props.item.chkid,
        }
      );
      // console.log(response.data.data, "turnkeys  data newwww");
      if (response.data.status === 1) {
        setsubCheckLists(response.data.data);
      //   const currentDate = new Date(); // Get the current date

      // // Filter out tasks with completed_status not equal to 'completed' and performing_date not greater than today
      // const filteredTasks = response.data.data.filter(task => 
      //   task.completed_status !== 'completed' &&
      //   new Date(task.performing_date) <= currentDate
      // );
      // setNotCompleted(filteredTasks);
      }
    } catch (e) {
      console.log(e);
    }
  };
  
  const getsystemdataadmin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getalltaskforchecklisttskadmin`,
        {
            "chktskid": props.item.chkid,
        }
      );
      // console.log(response.data.data, "turnkeys  data newwww");
      if (response.data.status === 1) {
        setsubCheckLists(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getsystemdataadmintask = async (taskDatastatus) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getalltaskforchecklisttskadminbystatus`,
        {
            "chktskid": props.item.chkid,
            "status":taskDatastatus
        }
      );
      // console.log(response.data.data, "turnkeys  data newwww");
      if (response.data.status === 1) {
        setsubCheckLists(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [assignTo, setAssignto] = useState([]);

  useEffect(() => {
    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getprojectteam`,
          {
            organisation: localStorage.getItem("orgn_id"),
            project_id: props.item.project_id,
          }
        );
        // console.log(response.data.data,"project api oooooooo");
        if (response.data.status === 1) {
          setAssignto(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    allMembers();
  }, []);

  const handleDatePickerOpen = () => {
    setDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setDatePickerOpen(false);
  };

  const [targetDate, setTargetDate] = useState(null);

  // console.log("target date: " + targetDate);
  const handleTargetDateChange = (event) => {
    const selectedDateString = event.target.value;
    const selectedDateObject = new Date(selectedDateString);
    setTargetDate(selectedDateObject);
    // console.log(selectedDateObject.toLocaleDateString("en-GB")); // Log in dd/mm/yy format
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // console.log("selected date for create task: " + date);
    handleDatePickerClose();
  };
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [isAssigneeDropdownOpen, setAssigneeDropdownOpen] = useState(false);

  const handleAssigneeDropdownOpen = () => {
    setAssigneeDropdownOpen(true);
  };

  const handleAssigneeDropdownClose = () => {
    setAssigneeDropdownOpen(false);
  };
  const [AssignedId, setAssignId] = useState("");
  const handleAssigneeChange = (event) => {
    // console.log("events dispatched", event.target.value);
    setAssignId(event.target.value[0]);
    setSelectedAssignee(event.target.value[1]);
    handleAssigneeDropdownClose();
  };

  const [showCompleted, setShowCompleted] = useState(true);
  const [completeStatus, setCompleteStatus] = useState("");
  const [newItemText, setNewItemText] = useState("");
  const [newItemDes, setNewItemDes] = useState("");
  const [showPopup, setshowpopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = ()=>{
    setOpen1(true);
  }
  const [sendData, setSendData] = useState([]);
  const handleToggleCompleted = () => {
    setShowCompleted(!showCompleted);
  };

  const handleSave = async () => {
    handleInsertQuery();
    // const datas = {
    //   "created_user_id": localStorage.getItem("user_id"),
    //   "type": props.projecttype,
    //   "organisation": localStorage.getItem("orgn_id"),
    //   "performing_date": targetDate,
    //   "name": newItemText,
    //   "project_id": props.item.project_id,
    //   "chktaskid": props.item.chkid,
    //   "assigned_user_id": AssignedId,
    // }
    // try {
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_API_URL}/createtaskchecklist`,
    //     datas
    //   );
    //   // console.log(response.data.data)
    //   if (response.data.status === 1) {
    //     // console.log("inserteed task: ", response.data)
    //     setsubCheckLists((prevLogs) => [...prevLogs, ...response.data.responseassignee]);
    //     setshowpopup(false);
    //     // uploadCategoryImgFile(response.data?.data[0]?.id)
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  }

  const handleInsertQuery = async () => {
    var current_date = new Date();
    var formatted_current_date = current_date.toISOString().split('T')[0];
    
    // const startDate = new Date(formData["startDate"]); // Convert startDate to Date object
    
    // const targetDate = new Date(formData["targetDate"]); // Convert targetDate to Date object
    console.log("handleInsertQuery is called",formatted_current_date,targetDate);
  
    const calculateDateDifference = () => {
      if (formatted_current_date && targetDate) {
        const start = moment(formatted_current_date);
        const end = moment(targetDate);
        const duration = moment.duration(end.diff(start));
        console.log("Duration for frequnecy",duration);
  
        if (props.item.frequency === "Daily") {
          console.log("Daily frequency",duration.asDays());
  
          return duration.asDays();
        } else if (props.item.frequency === "Weekly") {
          console.log("Weekly frequency");
  
          return duration.asWeeks();
        } else if (props.item.frequency === "Monthly") {
          console.log("Monthly frequency");
  
          return duration.asMonths();
        } else {
          console.log("Invalid frequency");
          return 0;
        }
      }
    };
  
    const dateDifference = calculateDateDifference();
  
    // console.log("datedifference", dateDifference);
  
    for (let i = 0; i <= dateDifference; i++) {
      console.log("value of i ", i);
  
      const currentDate = new Date(formatted_current_date);
  
      console.log("current date inside loop date", currentDate);
      console.log("End date nside loop date", targetDate);
  
  
      if (props.item.frequency === "Daily") {
        currentDate.setDate(currentDate.getDate() + i);
  
        // console.log("freq", props.item.frequency);
      } else if (props.item.frequency === "Weekly") {
        currentDate.setDate(currentDate.getDate() + i * 7);
  
        console.log("freq", props.item.frequency);
  
        console.log("currrent date", currentDate);
      } else if (props.item.frequency === "Monthly") {
        currentDate.setMonth(currentDate.getMonth() + i);
  
        console.log("freq", props.item.frequency);
  
        console.log("currrent date", currentDate);
      } 
      else if (props.item.frequency === "Yearly") {
        currentDate.setFullYear(currentDate.getFullYear() + i);
  
        console.log("freq", props.item.frequency);
  
        console.log("currrent date", currentDate);
      } 
      else {
        break;
      }
  
      if (currentDate > targetDate) {
        break; // Exit the loop if currentDate exceeds targetDate
      }
  
      handleamcinsert(currentDate);
  
      console.log("insertd rec event header",currentDate,"date difference" , dateDifference);
    }
  };
  const handleamcinsert = async(currentDate)=>{

      const performing_date = currentDate.toISOString().split('T')[0];
      console.log("performing date direct inserting",performing_date)
    const datas={
      // "raised_organisation_id":localStorage.getItem("orgn_id"),
      "created_user_id":localStorage.getItem("user_id"),
      "type":"AMC",
      "organisation":localStorage.getItem("orgn_id"),
      "performing_date":performing_date?performing_date:props.end_date,
      "project_id":props.item.project_id,
      "schedule":props.item.frequency,
      "assigned_user_id":AssignedId,
      "name":newItemText,
      "chktaskid":props.item.chkid,
      "description":newItemDes
      // "raisedto_organisation":data.assignTo
      
    }
    console.log("all datas are saved for task","---",datas)
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/createtaskchecklist`,
          datas
        );
        console.log(response.data.data)
        if (response.data.status===1) {
        console.log("inserteed ticket: ", response.data?.responseassignee)
        setsubCheckLists((prevLogs) => [...prevLogs, ...response.data.responseassignee]);
        setshowpopup(false);
        setNewItemText("");
        setNewItemDes("");
        }
      } catch (e) {
        console.log(e);
      }
  }

  const handleOpen = (item, completed) => {
    //setOpen(true);
    props.settaskSelected(item);
    props.setcheckListsamc(props.checkLists);
    props.setActive(16);
    setSendData(item);
    setCompleteStatus(completed);
  };

  const handleClose =()=>{
    setshowpopup(false);
    setNewItemText("");
    setAssignId("");
    setAssigneeDropdownOpen(false);
    setSelectedAssignee("")
    setDatePickerOpen(false);
  }
  return (
    <Box style={{ width: "28%", minWidth: "28%", height: "55vh" }}>
      <Box style={{width:"90%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
      <Typography
        style={{
          marginTop: "2%",
          fontFamily: "Montserrat",
          fontWeight: "bold",
          color: "#082A49",
          fontSize: "20px",
        }}
        >
        {props.item.name}
      </Typography>
      {localStorage.getItem("role") === "admin" &&
      <img onClick={handleOpen1} style={{height:"20px",cursor:"pointer"}} src={require('../../Images/threeDots.png')} />
      }
      </Box>
      <Box
        style={{
          width: "90%",
          borderRadius: "10px",
          height: "5vh",
          backgroundColor: "#F8F8F8",
          marginTop: "8%",
          boxShadow: "5px 5px 4px 0px rgba(0, 0, 0, 0.2)",
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
        }}
      >
        <Box
          onClick={() => {
            setshowpopup(!showPopup);
          }}
          style={{
            width: "90%",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#082A49",
              fontSize: "16px",
              fontWeight: "bold",
              // gap: "25px",
              fontFamily: "Montserrat",
              cursor: "pointer",
              // height: "10%",
            }}
          >
            Create new{" "} 
          </Typography>
          <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
            +
          </Typography>
        </Box>
      </Box>
      {showPopup && (
        <Box
          style={{
            width: "90%",
            height: "30vh",
            backgroundColor: "#EEEEEE",
            marginTop: "5%",
            marginBottom: "5%",
            overflowY: "auto",
          }}
        >
          <img
            style={{ width: "20px", marginLeft: "5%", marginTop: "12%" }}
            src={require("../../Images/BlankTick.png")}
          />
          <TextField
            label="Enter task name"
            value={newItemText}
            variant="standard"
            InputProps={{
              style: {
                gap: "25px",
                color: "#082A49",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                // borderBottom: "2px dashed #A9A9A9", // Use dashed line style
                outline: "none", // Remove the blue outline on focus
              },
            }}
            style={{
              color: "#082A49",
              fontSize: "22px",
              fontWeight: "bold",
              gap: "25px",
              width: "60%",
              marginLeft: "5%",
              marginTop: "2%",
              fontFamily: "Montserrat",
              cursor: "pointer",
              height: "10%",
            }}
            onChange={(e) => setNewItemText(e.target.value)}
          />
<Box style={{ display: "flex", flexDirection: "row" }}>
            <img
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "5%",
                marginTop: "10%",
              }}
              src={require("../../Images/BlankTick.png")}
              />
            <TextField
            label="Description"
            value={newItemDes}
            variant="standard"
            multiline
            InputProps={{
              style: {
                gap: "25px",
                color: "#082A49",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                // borderBottom: "2px dashed #A9A9A9", // Use dashed line style
                outline: "none", // Remove the blue outline on focus
              },
            }}
            style={{
              color: "#082A49",
              fontSize: "22px",
              fontWeight: "bold",
              gap: "25px",
              width: "60%",
              marginLeft: "5%",
              marginTop: "2%",
              fontFamily: "Montserrat",
              cursor: "pointer",
              height: "10%",
            }}
            onChange={(e) => setNewItemDes(e.target.value)}
          />
          </Box>
          

          <Box style={{ display: "flex", flexDirection: "row" }}>
            <img
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "5%",
                marginTop: "10%",
              }}
              src={require("../../Images/DateIcon.png")}
            />
            <>
              {isDatePickerOpen ? (
                <TextField
                  type="date"
                  // value={formattedDateString || oldDateObject} // Use formatted string or Date object
                  onChange={handleTargetDateChange}
                  InputLabelProps={{ style: { display: "none" } }}
                  InputProps={{
                    style: {
                      border: "none",
                      height: "50px",
                      // width: "100%",
                      color: "#1282A2 ",
                    },
                  }}
                  sx={{
                    "& fieldset": {
                      border: "none",
                      // height: "60px",
                    },
                    ...Styles.inputBox,
                    width: "70%",
                    height: "60%",
                    marginTop: "0%",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    marginTop: "6%",
                    marginLeft: "4%",
                  }}
                  inputProps={{ max: projectEndDate?.toISOString()?.split('T')[0] }}

                />
              ) : (
                <Typography
                  style={{
                    gap: "25px",
                    marginTop: "10%",
                    color: "#082A49",
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    marginLeft: "5%",
                    cursor: "pointer",
                  }}
                  onClick={handleDatePickerOpen}
                >
                  Set target date
                </Typography>
              )}
            </>
          </Box>

          <Box style={{ display: "flex", flexDirection: "row" }}>
            <img
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "5%",
                marginTop: "10%",
              }}
              src={require("../../Images/team.png")}
            />
            <>
              {isAssigneeDropdownOpen ? (
                <FormControl style={{width:"70%",marginLeft:"4%",marginTop:"3%",marginBottom:"3%"}}>
                  {/* <InputLabel id="assignee-label">Assignee</InputLabel> */}
                  <Select
                    labelId="assignee-label"
                    id="assignee-select"
                    // value={selectedAssignee}
                    onChange={handleAssigneeChange}
                  >
                    {assignTo?.map((item, index) => (
                      <MenuItem key={item.id} value={[item.id, item.name]}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Typography
                  style={{
                    gap: "25px",
                    marginTop: "10%",
                    color: "#082A49",
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    marginLeft: "5%",
                    cursor: "pointer",
                  }}
                  onClick={handleAssigneeDropdownOpen}
                >
                  {selectedAssignee ? selectedAssignee : "Select assignee"}
                </Typography>
              )}
            </>
          </Box>
          <Box
            style={{
              width: "80%",
              marginTop: "4%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              style={{
                width: "22%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                onClick={() => {
                  handleSave();
                }}
                style={{ cursor: "pointer" }}
                src={require("../../Images/Iconcheck.png")}
              />
              <img
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                src={require("../../Images/Iconcancel.png")}
              />
            </Box>
          </Box>
        </Box>
      )}
      
      {subCheckLists.map((item) => (
        <Box key={item.id}>
          {item.completed_status === "completed" && showCompleted ? null : (
            <Box onClick={() => handleOpen(item)} style={{ cursor: "pointer" }}>
              <ChecklistInneramc item={item} />
            </Box>
          )}
        </Box>
      ))}

<Box>
        {/* Collapsible dropdown for completed cards */}
        {!showCompleted && (
          <Accordion
            onClick={handleToggleCompleted}
            style={{
              cursor: "pointer",
              width: "90%",
              backgroundColor: "#F8F8F8",
              boxShadow: "5px 5px 4px 0px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <AccordionSummary
              // expandIcon={require("../Images/addbutton.png")}
              onClick={handleToggleCompleted}
            >
              <span style={{ fontFamily: "Montserrat", fontWeight: "bold" }}>
                Completed{" "}
              </span>
              <img
                style={{
                  width: "22px",
                  height: "10px",
                  marginLeft: "auto",
                  marginTop: "auto",
                }}
                src={require("../../Images/upArrow.png")}
              />
            </AccordionSummary>
          </Accordion>
        )}
        <Accordion
onClick={()=>{setShowNotCompleted(true)}}            style={{
              cursor: "pointer",
              width: "90%",
              backgroundColor: "#F8F8F8",
              boxShadow: "5px 5px 4px 0px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <AccordionSummary
              // expandIcon={require("../Images/addbutton.png")}

              style={{ width: "100%", display: "flex" }}
            >
              {/* <Box> */}

                <span style={{width:"90%", fontFamily: "Montserrat", fontWeight: "bold" }}>
                 Not Completed
                </span>
              {/* </Box> */}
              {/* <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Typography style={{ fontFamily: "Montserrat" }}>
                  {subCheckLists.length}
                </Typography>
              </Box> */}

              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "60%",
                }}
              >
                <img
                  style={{
                    width: "22px",
                    height: "10px",
                    marginLeft: "auto",
                    marginTop: "auto",
                  }}
                  src={require("../../Images/downArrow.png")}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails  style={{width:"90%", cursor: "pointer" }}>
              <Box style={{width:"90%", cursor: "pointer" }}>
              {/* {showNotCompleted && ( */}
          <>
         {notCompleted.map((item) => (
           <Box key={item.id}>
            {/* {item.completed_status === "completed" && showCompleted ? null : ( */}
              <Box onClick={() => handleOpen(item)} style={{width:"150%", cursor: "pointer" }}>
                <ChecklistInneramc item={item} />
              </Box>
            {/* )} */}
          </Box>
        ))}
        </>
        {/* )} */}
              </Box>
            </AccordionDetails>
          </Accordion>
        {/* <Typography onClick={()=>{setShowNotCompleted(true)}}>Not completed Shjow</Typography> */}
        
        {showCompleted && (
          <Accordion
            onClick={handleToggleCompleted}
            style={{
              cursor: "pointer",
              width: "90%",
              backgroundColor: "#F8F8F8",
              boxShadow: "5px 5px 4px 0px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <AccordionSummary
              // expandIcon={require("../Images/addbutton.png")}

              style={{ width: "100%", display: "flex" }}
            >
              <Box>

                <span style={{ fontFamily: "Montserrat", fontWeight: "bold" }}>
                  Completed
                </span>
              </Box>
              <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Typography style={{ fontFamily: "Montserrat" }}>
                  {subCheckLists.filter((item) => item.completed_status === "completed").length}
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    width: "22px",
                    height: "10px",
                    marginLeft: "auto",
                    marginTop: "auto",
                  }}
                  src={require("../../Images/downArrow.png")}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {subCheckLists
                  .filter((item) => item.completed_status === "completed")
                  .map((item) => (
                    <Box
                      key={item.id}
                      onClick={() => handleOpen(item, "completed")}
                      style={{ cursor: "pointer" }}
                    >
                      <ChecklistInneramc item={item} />
                    </Box>
                  ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
     
      {open && (
        <Checklistpopupamc
          projecttype={props.projecttype}
          project_id={props.item.project_id}
          checkLists={props.checkLists}
          completeStatus={completeStatus}
          setsubCheckLists={setsubCheckListsRefresh}
          sendData={sendData}
          open={open}
          setOpen={setOpen}
        />
      )}
      {open1 && (
      <AdminEditPopup chkid={props.item.id} project_id={props.item.project_id} open={open1} setOpen={setOpen1} />
    )}
    </Box>
  );
}