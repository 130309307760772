import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  PaginationItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Styles from "../Styles/AdminDashboard";
import ViewTicketCard from "../Components/ViewTicketCard";
import axios from "axios";

const CardsPerPage = 4;

export default function ViewTicket(props) { 
  const [currentPage, setCurrentPage] = useState(1);
  const [allTickets,setAlltickets] = useState([]);
  const [prioritySort, setPrioritySort] = useState("");  
  const [sort, setsort] = React.useState("");
  const [ticketssort, setticketssort] = useState(props.ticketSortings);
  const [filteredCards, setFilteredCards] = useState([]);
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * CardsPerPage;
  const endIndex = startIndex + CardsPerPage;
  const visibleCards = filteredCards.slice(startIndex, endIndex);
  
  const handleSortChange = (event) => {
    setticketssort("")
      setPrioritySort(event.target.value);
  };
  
  const handleticketChange = (event) => {
    setPrioritySort("")
    setticketssort(event.target.value);
  };
  
  useEffect(() => {
    const newFilteredCards = allTickets
      .filter((card) => !sort || card.department.toLowerCase() === sort.toLowerCase())
      .filter((card) => !ticketssort || card.status === ticketssort);
    setFilteredCards(newFilteredCards);
  }, [sort, ticketssort, allTickets]);

  useEffect(() => {
    if (prioritySort !== "") {
      const newFilteredCards = allTickets
        .filter((card) => !sort || card.status.toLowerCase() === sort.toLowerCase())
        .filter((card) => !prioritySort || card.priority === prioritySort);
      setFilteredCards(newFilteredCards);
    }
  }, [sort, prioritySort, allTickets]);
  
  
  

  const handleDetail = (card)=>{
    console.log("naviagtion card",card);
      props.setTicketDetails(card);
      props.setActive(9);
  }
const handleBack = () => {
    props.setActive(1);
  }
  useEffect(() => {
    const getsystemdata = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserspecifictickets`,
          {  "user_id": props.ticketId? props.ticketId: localStorage.getItem("user_id") ,
        "raisedto_organisation": localStorage.getItem("user_id"), "organisation":  localStorage.getItem("orgn_id"),"role":props.innerRole?props.innerRole:localStorage.getItem("role") 
        }
        );
        // console.log(response.data.data,"project api");
        if (response.data.status === 1) {
          setAlltickets(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getsystemdata();
  }, []);


  let ticketvalue1 = "In Progress"
  let ticketvalue2 = "Resolved"
  let ticketvalue3 = "Opened"
  let ticketvalue4 = "Pending"
  let ticketvalue5 = "Assigned"
  let ticketvalue6 = "Reopened"
  let ticketvalue7 = "Closed"
  let priority1 = "Low"
  let priority2 = "Medium"
  let priority3 = "High"
  return (
    <Box>
      <Box style={Styles.upperBox}>
        <Box style={{display: 'flex',width: "300px", alignItems: "center"}}>
        <img src={require("../Images/Iconbackarrow.png")} alt="plus-icon" style={{ width: "30px", height: "20px", cursor: "pointer", marginLeft: "20px" }} onClick={() => { handleBack();}}
            />
        <Typography style={Styles.name}>{ticketssort?ticketssort:"Total"} tickets</Typography>
        </Box>
        <Box style={{...Styles.FormControlBox, marginRight: '2%'}}>
          <FormControl
            size="small"
            style={Styles.FormControl}
            fullWidth
          >
            <InputLabel
              style={{ fontFamily: "Montserrat", color: "#1282A2" }}
              id="demo-simple-select-label"
            >
              {ticketssort ? "" : "All tickets"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ticketssort}
              label={ticketssort ? "" : "All tickets"}
              style={Styles.selectBox}
              size="small"
              onChange={handleticketChange}
            >
              <MenuItem style={{ fontFamily: "Montserrat" }} value={ticketvalue1}>
                {ticketvalue1}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={ticketvalue2}>
                {ticketvalue2}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={ticketvalue3}>
                {ticketvalue3}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={ticketvalue4}>
                {ticketvalue4}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={ticketvalue5}>
                {ticketvalue5}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={ticketvalue6}>
                {ticketvalue6}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={ticketvalue7}>
                {ticketvalue7}
              </MenuItem>
            </Select>
          </FormControl>


          <FormControl size="small" style={Styles.FormControl} fullWidth>
  <InputLabel style={{ fontFamily: "Montserrat", color: "#1282A2" }} id="demo-simple-select-label-1">
    {prioritySort ? "" : "Sort by Priority"}
  </InputLabel>
  <Select
    labelId="demo-simple-select-label-1"
    id="demo-simple-select-1"
    value={prioritySort}
    label={prioritySort ? "" : "Sort by Priority"}
    style={Styles.selectBox}
    size="small"
    onChange={handleSortChange}
  >
    <MenuItem style={{ fontFamily: "Montserrat" }} value={priority1}>
      {priority1}
    </MenuItem>
    <MenuItem style={{ fontFamily: "Montserrat" }} value={priority2}>
      {priority2}
    </MenuItem>
    <MenuItem style={{ fontFamily: "Montserrat" }} value={priority3}>
      {priority3}
    </MenuItem>
  </Select>
</FormControl>

        </Box>
      </Box>

      {visibleCards?.map((card) => (
        <Box key={card.id} onClick={()=>{handleDetail(card)}} style={{width:"95%",margin:"auto"}}>
          <ViewTicketCard card={card}/>
         </Box>
      ))}

      <Stack  spacing={2} justifyContent="center" alignItems="center">
        <Pagination
         color="primary"
         style={{color:"#1282A2"}} 
          count={Math.ceil(props.ticLength / CardsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              style={
                item.type === 'page' && item.page === currentPage
                  ? { backgroundColor: '#1282A2', color: 'white' }
                  : {}
              }
            />
          )}
        />
      </Stack>
    </Box>
  );
}
