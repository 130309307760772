
import {
    Box,
    Typography,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Styles from "../../Styles/AdminDashboard";
import axios from "axios";
  
  export default function ViewEmploye(props) {
    const [open, setOpen] = React.useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [data, setData] = useState([]);
    const [tmp,settmp] = useState(false);
    const handleChanges = (key, value) => {
      setData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    };
    // console.log(props.data.id)
    useEffect(()=>{
        handleupdateUserData(props.data.id)
        // console.log("users called")
    },[props?.data?.id])
const handleupdateUserData = async (id) => {
  let data={
    "organisation":id
  }
    try {
      // const updatedData = {
      //   id: "10", 
      //   name: data.name,
      //   email: data.email,
      //   phone: data.phone,
      // };
  // let datas={
  //   ...data,
  //   "id":21
  // }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getorganisationproject`,
         data
      
      );
  // console.log(response,'shshshshshh')
      if (response.data.status === 1) {
        setData(response.data.data);
    //  alert("Organisation updated successfully");
      
       
      } else {
        // console.error("Failed to update user data:",response.data.data);
      }
    } catch (error) {
      // console.error("Error updating user data:", error);
    }
  };
  
    // const handleOpen = () => {
    //   setOpen(true);
    // };
  
    const handleClose = () => {
      props.setOpen(false);
    };
   
    const [status, setStatus] = React.useState("");
  
    const handleChange = (event) => {
      setStatus(event.target.value);
    };
    // console.log(data,"employees")
    const handleEditProject = (item) => {
      // Use the handleEditProject directly without destructuring
      console.log(item, "Item shared");
      props.handleEditProject(item);
    };
    const handleOpenProject = (item) =>{
      props.handleOpenProject(item);
    }
    const handleDelete = async(row) => {
      //console.log("clicked row: " , row)
      console.log("Row shared",row);
      if(settmp){
        const confirmation = window.confirm(
          `Are you sure you want to Delete the ${row.name}`
        );
        if (confirmation) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/deactivateproject`,
              {
                "id":row.id
              }
              
            );
            if (response.data.status === 1) {
              console.log("Response is ",response.data);
              handleupdateUserData(props.data.id)
            // console.log("organisations", id);
            }
          } catch (e) { 
            console.log(e);
          }
        }
      }
  
    };
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box style={{...Styles.statusnotify_changestat,width:502,height: 300}}>
            <Box style={Styles.statusnotifyTitlebutton_changestat}>
              <h2
                id="parent-modal-title"
                style={{
                  color: '#1282A2',
                  marginBottom: '10px',
                  fontWeight: 700,
                  fontSize: '28px',
                  lineHeight: '34.13px',
                  alignItems: 'center',
                  alignSelf: "center",
                }}
              >
                {props?.data?.name} Projects
              </h2>
              <p
                onClick={handleClose}
                style={{...Styles.statusnotifyXbutton_changestat,display:'flex',justifyContent: 'right',marginLeft:"auto",marginTop:"7.5%"}}
              >
                X
              </p>
            </Box>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'center',
                // alignItems: 'center',
                height: '160px',
                overflowY: 'auto',
                marginTop:'2%'
              }}
            >
            {
                data?.map((item)=>(
                    <Box sx={{display:'flex',flexDirection:'row',width:'AUTO',alignItems:'center',justifyContent: 'space-between'}}>
                      <Box sx={{display:'flex'}}>
                <Box sx={{height:10,width:10,backgroundColor:'#1282A2',borderRadius:10,marginRight:5}}></Box>

                  <Typography style={{fontWeight: 400, fontSize: '18px', lineHeight: '34.13px', cursor: "pointer"}} onClick={ () => handleOpenProject(item)}>{item?.name}</Typography>
                  </Box>
                  <Box>
                  <img
                        style={{ cursor: "pointer", marginLeft: "30PX" }}
                        onClick={() => handleEditProject(item)}
                        src={require("../../Images/Iconedit.png")}
                      />
                     <img
                          onClick={()=> handleDelete(item)}
                            style={{ cursor: "pointer", marginLeft: "20PX" }}
                            src={require("../../Images/Icondelete.png")}
                          />
                      </Box>
</Box>         ))
            }            
            </Box>
  
           
          </Box>
        </Modal>
      </Box>
    );
  }
  