import axios from "axios";
import React, { useEffect, useState } from "react";

export default function ViewAttachments(props) {
  const [attachments, setAttachments] = useState("");
  console.log("props in view attachments", props);
  useEffect(() => {
    if (props?.checklist) {
      getTaskAttachments();
    }
    else if (props?.projDoc){
      getProjAttachments();
      console.log("proj doc called")
    }
     else {
      getAttachments();
    }
  }, [props]);

  const getAttachments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getdocumentcontent`,
        { ticket_id: props.item.ticket_id, name: props.item.file_name },
        { responseType: "arraybuffer" } // Ensure binary response
      );

      if (response.data) {
        const contentType = response.headers["content-type"];
        const arrayBuffer = response.data;

        // Convert binary data to base64
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        const dataURI = `data:${contentType};base64,${base64}`;
        setAttachments(dataURI);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const getTaskAttachments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/gettaskdocument`,
        { checklist_id: props.id, name: props.FileName },
        { responseType: "arraybuffer" } // Ensure binary response
      );

      if (response.data) {
        const contentType = response.headers["content-type"];
        const arrayBuffer = response.data;

        // Convert binary data to base64
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        const dataURI = `data:${contentType};base64,${base64}`;
        setAttachments(dataURI);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const getProjAttachments = async () => {
    console.log("data in attachments projdoc",props)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getdocumentcontentproject`,
    { project_id: props.id, name: props.FileName, docid: props.docId},
        { responseType: "arraybuffer" } // Ensure binary response
      );

      if (response.data) {
        const contentType = response.headers["content-type"];
        const arrayBuffer = response.data;

        // Convert binary data to base64
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        const dataURI = `data:${contentType};base64,${base64}`;
        setAttachments(dataURI);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <iframe
        title="attachments-iframe"
        src={attachments}
        style={{
          width: "100%",
          height: "100%",
          margin: "auto",
          border: "none",
        }}
      />
    </div>
  );
}
