import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import axios from "axios";

export default function ViewTicketCard(props) {
    // console.log("therse are card",props.card)
  const ticketStatus = props.card.status;
  // const orgName = props.card.org;
  const [orgName,setOrgName] = useState("");
  // const orgName = props.card.name;
  const header = props.card.ticket_name;
  const description = props.card.description;

  const status = props.card.priority;
  let textColor = "#85A1EE"; // Default color for "Medium"
  if (status === "High") {
    textColor = "red";
  } else if (status === "Low") {
    textColor = "#FD9F13";
  }

  let bgColor = "#FEB7B1;"; //resolved color
  if (ticketStatus === "In Progress") {
    bgColor = "#BCDCA4";
  } else if (ticketStatus === "Opened") {
    bgColor = "#F9C29A";
  } else if (ticketStatus === "Resolved") {
    bgColor = "#FEB7B1";
  } else if (ticketStatus === "Closed") {
    bgColor = "#E96973";
  } else if (ticketStatus === "Assigned") {
    bgColor = "#BEC9F3";
  } else if (ticketStatus === "Reopened") {
    bgColor = "#FFE4AC";
  } else if (ticketStatus === "Pending") {
    bgColor = "#86D8FF";
  }
  const [isHovered, setIsHovered] = useState(false);

  const handlesortChange=async(event)=>{
    event.preventDefault();
    event.stopPropagation(); 
  }

  const [data,setData] = useState("");

  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserdata`,
          {
            id: props.card.assigned_to,
          }
        );
        // console.log(response.data);

        if (response.data.status === 1) {
          // console.log(response.data.data);
          setData(response.data.data[0]);
        }
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getprojectdetails`,
          {
            "project_id": props.card.project_id,
          }
        );
        // console.log(response.data);

        if (response.data.status === 1) {
          // console.log(response.data.data,"project name");
          setOrgName(response.data.data[0].name);
        }
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const inputDateString = props.card?.created_date;
  const formattedDate = new Date(inputDateString).toLocaleDateString("en-GB");
  
  return (
    <Box  onMouseOver={() => setIsHovered(true)}
    onMouseOut={() => setIsHovered(false)} style={{...Styles.ViewTicketCardBox,...(isHovered && Styles.HoveredViewTicketCardBox)}}>
      <Box style={{ width: "15%", display: "flex" }}>
        <Box style={{ ...Styles.dottedLine, width: "10%", marginLeft: "0%" }} />
        <Typography
          style={{
            position: "absolute",
            marginTop: "3%",
            marginLeft: "2%",
            color: "#082A49",
            fontFamily: "Montserrat",
            fontSize: "14px",
            
          }}
        >
         #{props?.card?.id}
        </Typography>

        <Box
          style={{
            ...Styles.innerTicket,
            width: "10vw",
            marginLeft: "-3.7%",
            marginBottom: "3%",
            backgroundColor: bgColor,
          }}
        >
          <Box style={Styles.circle1}></Box>

          <Typography style={{ ...Styles.text4, fontSize: "12px",width:"80px" }}>
            {ticketStatus}
          </Typography>
          <Box
            style={{
              ...Styles.dottedLine,
              width: "10%",
              marginLeft: "10%",
              height: "14vh",
              marginTop: "4%",
              borderRight: "2px dashed #A9A9A9",
            }}
          />

          <Box style={{ ...Styles.circleMainBox, marginLeft: "-3%" }}>
            <Box style={Styles.circle2}></Box>
            <Box style={Styles.circle2}></Box>
            <Box style={Styles.circle2}></Box>
          </Box>
        </Box>

        <Box style={{ ...Styles.circle3, marginTop: "5%" }}></Box>
      </Box>

      <Box
        style={{
          width: "50%",
          display: "flex",
          marginLeft: "-16%",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            textAlign: "justify",
            fontFamily: "Montserrat",
            color: "#A9A9A9",
          }}
        >
          {orgName}
        </Typography>
        <Typography
          style={{
            textAlign: "justify",
            color: "#082A49",
            fontFamily: "Montserrat",
            fontWeight: "bold",
            fontSize: "18px",
           height:"30px",
           overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1, // Limit to one line
    width: "100%",
       
          }}
        > 
        {(header ?? '').substring(0, 50)}
        {header && header.length > 50 && (
          <>
            {" "}
            <span style={{ fontSize: "13px", color: "#1282A2", cursor: "pointer" }}>
              ....
            </span>
          </>
        )}
      </Typography>
      
      

       
        <Typography
        style={{
          height: "40px",
          width: "100%",
          textAlign: "justify",
          fontFamily: "Montserrat",
         
        }}
      >
      {(description ?? '').substring(0, 50)}
      {description && description.length > 50 && (
        <>
          {" "}
          <span style={{ color: "#1282A2", cursor: "pointer",fontSize:"13px" }}>...See more</span>
        </>
      )}
    </Typography>
    </Box>
        {/* <Typography
          style={{
            textAlign: "justify",
            color: "#1282A2",
            fontFamily: "Montserrat",
          }}
        >
          {" "}
          <img src={require("../Images/attachment.png")} />2 screen recordings
        </Typography> */}
     

      <Box
        style={{
          width: "28%",
          marginLeft: "-20%",
          height: "100%",
          borderLeft: "2px dashed #A9A9A9",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "100%",
            borderRight: "2px dashed #A9A9A9",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "50%",
              borderBottom: "2px dashed #A9A9A9",
              overflow: "hidden"
            }}
          >
            <Typography
              style={{
                color: "#A9A9A9",
                fontFamily: "Montserrat",
                fontSize: "14px",
                margin: "6px 0px 0px 6px",
              }}
            >
              Start date
            </Typography>
            <Typography
              style={{
                color: "#082A49",
                fontWeight: "bolder",
                fontFamily: "Montserrat",
                fontSize: "14px",
                margin: "6px 0px 0px 6px",
              }}
            >
              {formattedDate}
            </Typography>
          </Box>
          <Box style={{ width: "100%", height: "50%" }}>
            <Typography
              style={{
                color: "#A9A9A9",
                fontFamily: "Montserrat",
                fontSize: "14px",
                margin: "6px 0px 0px 6px",
              }}
            >
              Asignee
            </Typography>
            <Typography
              style={{
                color: "#082A49",
                fontWeight: "bolder",
                fontFamily: "Montserrat",
                fontSize: "14px",
                margin: "6px 0px 0px 6px",
              }}
            >
              {data?data?.name:"null"}
            </Typography>
            
          </Box>
        </Box>

        <Box
          style={{
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "50%",
              borderBottom: "2px dashed #A9A9A9",
            }}
          >
            <Typography
              style={{
                color: "#A9A9A9",
                fontFamily: "Montserrat",
                fontSize: "14px",
                margin: "6px 0px 0px 6px",
              }}
            >
              Raised By
            </Typography>
            <Typography
              style={{
                color: "#082A49",
                fontWeight: "bolder",
                fontFamily: "Montserrat",
                fontSize: "14px",
                margin: "6px 0px 0px 6px",
              }}
            >
              {props?.card?.raised_name}
            </Typography>
          </Box>
          <Box style={{ width: "100%", height: "50%" }}>
            <Typography
              style={{
                color: "#A9A9A9",
                fontFamily: "Montserrat",
                fontSize: "14px",
                margin: "6px 0px 0px 6px",
              }}
            >
              Priority
            </Typography>
            <Typography
              style={{
                color: textColor,
                fontWeight: "bolder",
                fontFamily: "Montserrat",
                fontSize: "14px",
                margin: "6px 0px 0px 6px",
              }}
            >
              {status}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
