import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";  
export default function RaiseNewTicket(props) {
  const MediaQueryTickit = useMediaQuery("(max-width: 900px)");
  const [data, setData] = useState({
    frequency: "Once",
  });
  const [datas, setDatas] = useState([]);
  const handleChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachment, setAttachments] = useState([]);
  const [projectid, setProjectId] = useState("");
  const [project, setproject] = useState([]);
  const [checklists, setchecklists] = useState([]);
  const [assignTo, setAssignto] = useState([]);
  const [ptype, setptype] = useState("");
  const [turnkeysValue, setturnkeysValue] = useState([]);
  const [endDate , setEnddate]= useState([]);
  useEffect(() => {
    const getsystemdata = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallprojects`,
          {
            organisation: localStorage.getItem("orgn_id"),
            user_id: localStorage.getItem("user_id"),
          }
        );
        console.log(response.data.data, "project data");
        if (response.data.status === 1) {
          setproject(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
      getprganisationdata();
    };
    getsystemdata();
  }, []);

  useEffect(() => {
    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getprojectteam`,
          { organisation: localStorage.getItem("orgn_id"),
        "project_id":projectid }
        );
        console.log(response.data.data, "project team api ");
        if (response.data.status === 1) {
          setAssignto(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
      getprganisationdata();
    };
    allMembers();
  }, [projectid]);

  useEffect(() => {
    const getTurnkeys = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallturnkeys`,
          {
            role: localStorage.getItem("role"),
            user_id: localStorage.getItem("user_id"),
            project_id: projectid,
          }
        );
        console.log(response.data.data, "turnkeys data");
        if (response.data.status === 1) {
          setturnkeysValue(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
      getprganisationdata();
    };
    getTurnkeys();
  }, [projectid]);

  const getprganisationdata = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getorganisationdetails`,
        {
          id: localStorage.getItem("orgn_id"),
          created_organisation_id: localStorage.getItem("orgn_id"),
        }
      );
      console.log(response.data.data, "organisation api");
      setDatas(response?.data?.data);

      if (response.data.status === 1) {
        setData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // getorganisationdetails
  useEffect(() => {
    console.log("project id is", projectid);
    const getsystemdata = async () => {
      if (ptype === "AMC") {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/getallchecklist`,
            {
              "project_id": projectid,
            }
          );
          // console.log(response.data);
          if (response.data.status === 1) {
            // setRole(response?.data?.responserole);
            // setDesignation(response?.data?.responsedesignation);
            console.log("response data checklist in uuu: ", response?.data?.data);
            setchecklists(response?.data?.data);
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    getsystemdata();
  }, [ptype === "AMC" || ptype === "turnkey"]);
  
  const [frequncy , setfrequency] =useState("")
  const [freqId, setfreqId] = useState("");
  const [bucket, setbucket] = useState("");
  //const [errorproject, setErrorProject] = useState(false);
  const [errortaskname, setErrorTaskName] = useState(false);
  const [errorchktaskid, setErrorchktaskid] = useState(false);
  //const [errorpriority, setErrorPriority] = useState(false);
  const [errorassignto, setErrorAssignTo] = useState(false);
  //const [startvalidation, setStartValidation] = useState(false);
  const [errorturntargetdate, setErrorTurnTargetDate] = useState(false);
  const [daydifferenece , setdaydifference]=useState('')
  const [targetDate, setTargetDate] = useState(null);

  const handleTargetDateChange = (event) => {
    const selectedDateString = event.target.value;
    const selectedDateObject = new Date(selectedDateString);
    setTargetDate(selectedDateObject.toLocaleDateString("en-GB"));
    console.log(selectedDateObject.toLocaleDateString("en-GB")); // Log in dd/mm/yy format
  };

  // useEffect(() => {
  //   if(startvalidation){
  //     setErrorchktaskid(!data.chktaskid);
  //     setErrorAssignTo(!data.assigned_user_id);}
  // }, [startvalidation,data]);

  // const validateForm = async() => {
  //   console.log("checking validation",startvalidation);
  //   if (!startvalidation) {
  //     return false;
  //   }
  //   else{
  //     if (
  //     //errorproject ||
  //     //errorticketname ||
  //    errorchktaskid ||
  //     //errorpriority ||
  //     errorassignto
  //   ) {
  //     console.log("Assigned To", errorassignto);
  //     //console.log("Priority", errorpriority);
  //     console.log("Dep",errorchktaskid);
  //    // console.log("name", errorticketname);
  //     //console.log("Project", errorproject);
  //     console.log("I have restricted him");
  //     return false;
  //   }
  //   else{
  //     handleSubmit(ptype);
  //   }
  // }
  // };

  const handletype=(type,id,end_date)=>{
    var current_date = new Date();
  var formatted_current_date = current_date.toISOString().split('T')[0];
 
  var end_date_obj = new Date(end_date);
  var formatted_date_end = end_date_obj?.toISOString().split('T')[0];
  
  // Calculate the difference in days
  var time_difference = end_date_obj.getTime() - current_date.getTime();
  var day_difference = Math.ceil(time_difference / (1000 * 3600 * 24));
  
  // Output the result
  setdaydifference(day_difference)
  // console.log('Difference in days:', day_difference);
  
  // console.log('type of project', formatted_date);
  
  setptype(type)
  setProjectId(id)
  setEnddate(formatted_date_end)
}
const handleInsertQuery = async () => {
  var current_date = new Date();
  var formatted_current_date = current_date.toISOString().split('T')[0];
  
  // const startDate = new Date(formData["startDate"]); // Convert startDate to Date object
  
  // const endDate = new Date(formData["endDate"]); // Convert endDate to Date object
  console.log("handleInsertQuery is called",formatted_current_date,endDate);

  const calculateDateDifference = () => {
    if (formatted_current_date && endDate) {
      const start = moment(formatted_current_date);
      const end = moment(endDate);
      const duration = moment.duration(end.diff(start));
      console.log("Duration for frequnecy",duration);

      if (frequncy === "Daily") {
        console.log("Daily frequency",duration.asDays());

        return duration.asDays();
      } else if (frequncy === "Weekly") {
        console.log("Weekly frequency");

        return duration.asWeeks();
      } else if (frequncy === "Monthly") {
        console.log("Monthly frequency");

        return duration.asMonths();
      } else {
        console.log("Invalid frequency");
        return 0;
      }
    }
  };

  const dateDifference = calculateDateDifference();

  // console.log("datedifference", dateDifference);

  for (let i = 0; i <= dateDifference; i++) {
    console.log("value of i ", i);

    const currentDate = new Date(formatted_current_date);

    console.log("current date inside loop date", currentDate);
    console.log("End date nside loop date", endDate);


    if (frequncy === "Daily") {
      currentDate.setDate(currentDate.getDate() + i);

      // console.log("freq", frequncy);
    } else if (frequncy === "Weekly") {
      currentDate.setDate(currentDate.getDate() + i * 7);

      console.log("freq", frequncy);

      console.log("currrent date", currentDate);
    } else if (frequncy === "Monthly") {
      currentDate.setMonth(currentDate.getMonth() + i);

      console.log("freq", frequncy);

      console.log("currrent date", currentDate);
    } 
    else if (frequncy === "Yearly") {
      currentDate.setFullYear(currentDate.getFullYear() + i);

      console.log("freq", frequncy);

      console.log("currrent date", currentDate);
    } 
    else {
      break;
    }

    if (currentDate > endDate) {
      break; // Exit the loop if currentDate exceeds endDate
    }

    handleamcinsert(currentDate);

    console.log("insertd rec event header",currentDate,"date difference" , dateDifference);
  }
};
const handleamcinsert = async(currentDate)=>{
   
    
    const performing_date = currentDate.toISOString().split('T')[0];
    console.log("performing date direct inserting",performing_date)
  const datas={
    ...data,
    // "raised_organisation_id":localStorage.getItem("orgn_id"),
    "created_user_id":localStorage.getItem("user_id"),
    "type":ptype,
    "organisation":localStorage.getItem("orgn_id"),
    "performing_date":performing_date,
    "project_id":projectid,
    "schedule":frequncy
    // "raisedto_organisation":data.assignTo
    
  }
  console.log("all datas are saved for task",data,"---",datas)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/createtaskchecklist`,
        datas
      );
      console.log(response.data.data)
      if (response.data.status===1) {
      console.log("inserteed ticket: ", response.data?.data[0]?.id)
      // if(response.data?.data[0]?.id){
      //   uploadCategoryImgFile(response.data?.data[0]?.id)
      // }
      // uploadCategoryImgFile(response.data?.data[0]?.id)
      }
    } catch (e) {
      console.log(e);
    }
}
console.log("ptype in outer cosole",ptype)
const handleSubmit = async(ptype)=>{
  console.log("day differe",daydifferenece,ptype)
  if (!projectid) {
        alert("Please select Project Name");
      } else {
  if(!data.chktaskid || !data.assigned_user_id || !data.name|| (ptype=== 'Turnkey' && !targetDate) ){
    setErrorchktaskid(!data.chktaskid);
    setErrorAssignTo(!data.assigned_user_id);
    setErrorTaskName(!data.name);
    setErrorTurnTargetDate(!targetDate);
  }
  else{
  // let projectoftype = ptype;
  var current_date = new Date();
  var formatted_current_date = current_date.toISOString().split('T')[0];
  if (ptype === "AMC"){
  handleInsertQuery()
  uploadCategoryImgFile()

  }
  else{
    console.log("project type is not supported",ptype,"dates",targetDate);
    const datas={
      ...data,
      // "raised_organisation_id":localStorage.getItem("orgn_id"),
      "created_user_id":localStorage.getItem("user_id"),
      "type":ptype,
      "organisation":localStorage.getItem("orgn_id"),
      "performing_date":targetDate
      // "raisedto_organisation":data.assignTo
      
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/createtaskchecklist`,
        datas
      );
      console.log(response.data.data,"inserteed ticket: ")
      if (response.data) {
      console.log("inserteed ticket: ", response.data.data)
        // uploadCategoryImgFile(response.data?.data[0]?.id)
        uploadCategoryTurnkeyImgFile(response.data?.data[0]?.id)
      }
    } catch (e) {
      console.log(e);
    }
  }
    alert("Task raised")
        props.setActive(1);
}
      }
    }

  const [numofFiles, setNumOfFiles] = useState("");
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setNumOfFiles(files.length);
    setAttachments(files);
    setSelectedFiles(files);
  };
  const uploadCategoryImgFile = async () => {
    // console.log("Hello : " + id);
    try {
      for (let i = 0; i < numofFiles; i++) {
        const formdata = new FormData();
        const file = attachment[i];
        formdata.append("tasks_documents", file);
        // formdata.append("task_id", id);
        // formdata.append("name", file.name);
        console.log(formdata,"uploaded formdata");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/uploadtask?name=${file.name}`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.status === 1) {
          try {
            const file = attachment[i];
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/addtaskdocpath`,
              {
                checklist_id: freqId,
                file_name: file.name,
                user_id: localStorage.getItem("user_id"),
              }
            );
            if (response.data.status === 1) {
              console.log("uploaded successfully", response.data.data);
            }
            console.log(response.data);
          } catch (e) {
            console.log(e);
          }
        }
        console.log(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const uploadCategoryTurnkeyImgFile = async () => {
    // console.log("Hello : " + id);
    try {
      for (let i = 0; i < numofFiles; i++) {
        const formdata = new FormData();
        const file = attachment[i];
        formdata.append("tasks_documents", file);
        // formdata.append("task_id", id);
        // formdata.append("name", file.name);
        console.log(formdata,"uploaded formdata");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/uploadtask?name=${file.name}`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.status === 1) {
          try {
            const file = attachment[i];
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/addtaskturneydocpath`,
              {
                "turnkey_id": bucket,
                "file_name": file.name,
                "user_id": localStorage.getItem("user_id"),
              }
            );
            if (response.data.status === 1) {
              console.log("uploaded successfully", response.data.data);
            }
            console.log(response.data);
          } catch (e) {
            console.log(e);
          }
        }
        console.log(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // console.log(datas[0]?.name)
  const addimagepath = async (id) => {
    let datas = {
      id: id,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addimagetotickets`,
        datas
      );
      console.log(response.data.data);
      if (response.data.status === 1) {
        console.log("inserteed ticket: ", response.data?.data[0]?.id);
        // uploadCategoryImgFile(response.data?.data[0]?.id);
        alert("Ticket raised");
        props.setActive(1);
      }
    } catch (e) {
      console.log(e);
    }
  };

const handletaskfrequency = (item)=>{
  console.log("frequncy",item)
  setfrequency(item.frequency)
  setfreqId(item.chkid)
}

const handletaskbucket = (item)=>{
  // console.log("frequncy",item.chkid)
  // setfrequency(item.frequency)
  setbucket(item.id)
}
// const handlesetValidation = async () =>{
//   setStartValidation(true);
// }

// const handleButtonClick = async () => {
//  await handlesetValidation();

//   if (!projectid) {
//     alert("Please select Project Name");
//   } else {
//     validateForm();
//   }
// };

  return (
    <Box style={Styles.mainBox}>
      <Box
        style={{
          ...Styles.upperBox,
          justifyContent: "left",
          height: MediaQueryTickit ? "10vh" : "15vh",
        }}
      >
        <Typography style={Styles.name}>Raise New Task</Typography>
      </Box>
      <Box
        style={{
          width: "90%",
          margin: "auto",
          display: "flex",
          // border:"2px solid black",
          flexDirection: MediaQueryTickit ? "column" : "Row",
        }}
      >
        <Box style={{ width: MediaQueryTickit ? "70%" : "25%" }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "1%",
              color: "#082A49",
              fontSize: "18px",
            }}
          >
            Project name
          </Typography>
          <FormControl
            size="small"
            style={{
              ...Styles.tdBox1InnerRight,
              border: "2px solid #1282A2",
              boxShadow: "none",
              backgroundColor: "transparent",
              width: "90%",
              height: "60%",
              marginTop:"1%",
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                border: "1px solid #1282A2",
                borderColor: "#1282A2",
              },
            }}
            fullWidth
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.project_id}
              //   label={ticketssort ? "" : "Change status"}
              style={{
                ...Styles.selectBox,
                height: "100%",
                
         
                "& .css-1d3z3hw-MuiOutlinedInput-project_idnotchedOutline": {
                  border: "1px solid white",
                  borderColor: "white",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              size="small"
              onChange={(e) => {
                handleChange("project_id", e.target.value);
              }}
            >
              {project?.map((item, index) => (
                <MenuItem key={item.id} value={item.id} onClick={()=>{handletype(item.type , item.id,item.end_date )}} >
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* {errorproject && (
            <Typography sx={{ color: "red" }}>Please Select Project Name</Typography>
          )} */}
        </Box>
        <Box style={{ width: MediaQueryTickit ? "70%" : "25%" }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "1%",
              color: "#082A49",
              fontSize: "18px",
            }}
          >
            Project Type
          </Typography>
          <TextField
            value={ptype}
            disabled
            InputLabelProps={{ style: { display: "none" } }}
            InputProps={{
              style: {
                border: "none",
                height: "100%",
                width: "100%",
                color: "#082A49 ",
                broderRadius:"10px"
              },
            }}
            sx={{
              "& fieldset": {
                border: "none",
                broderRadius:"10px"
              },
              ...Styles.inputBox,
              width: "90%",
              
              height: "60%",
              marginTop: "1%",
              borderColor: "#1282A2",
              borderRadius:"10px"
            }}
          />
        </Box>

        <Box style={{ width: MediaQueryTickit ? "70%" : "25%" }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "1%",
              color: "#082A49",
              fontSize: "18px",
            }}
          >
            Assign to
          </Typography>
          <FormControl
            size="small"
            style={{
              ...Styles.tdBox1InnerRight,
              border: "2px solid #1282A2",
              boxShadow: "none",
              backgroundColor: "transparent",
              width: "90%",
              height: "60%",
              marginTop: "1%",
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                border: "1px solid #1282A2",
                borderColor: "#1282A2",
              },
            }}
            fullWidth
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.assigned_user_id}
              //   label={ticketssort ? "" : "Change status"}
              style={{
                ...Styles.selectBox,
                height: "100%",
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "1px solid white",
                  borderColor: "white",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              size="small"
              onChange={(e) => {
                handleChange("assigned_user_id", e.target.value);
              }}
            >
              {assignTo?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorassignto && (
            <Typography sx={{ color: "red" }}>
              Please Select Assigned To
            </Typography>
          )}
        </Box>
        {/* {
  ptype ==="AMC" &&
          <Box style={{ width:MediaQueryTickit?"70%":"25%"  }}>
            <Typography
              style={{
                ...Styles.profileText1,
                marginTop: "0%",
                color: "#082A49",
                fontSize: "18px",
              }}
            >
           Frequency
            </Typography>
            <FormControl
              size="small"
              style={{
                ...Styles.tdBox1InnerRight,
                backgroundColor: "transparent",
                width: "90%",
                height: "70%",
                border: "2px solid #1282A2",
                boxShadow: "none",
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              fullWidth
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.frequency}
                //   label={ticketssort ? "" : "Change status"}
                style={{
                  ...Styles.selectBox,
                  height: "100%",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid white",
                    borderColor: "white",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                }}
                size="small"
                onChange={(e) => {
                  handleChange("frequency", e.target.value);
                }}
                // onChange={handlePriority}
              >
                {["Once" , "Daily" , "Weekly" , "Monthly"].map((item)=>(

               

                <MenuItem style={{ fontFamily: "Montserrat" }} value={item}>
              {item}
                </MenuItem>
                 ))
                }
               
              </Select>
            </FormControl>
            {
              errorpriority &&
              <Typography sx={{color:'red'}} >Please Select Frequency</Typography>
            }
          </Box>
} */}
{ptype === "Turnkey" &&
        <Box style={{ width: MediaQueryTickit ? "70%" : "25%" }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "1%",
              color: "#082A49",
              fontSize: "18px",
              borderRadius:"10px",
            }}
          >
            Targeted Date
          </Typography>
          <FormControl
            size="small"
            style={{
              ...Styles.tdBox1InnerRight,
              backgroundColor: "transparent",
              width: "110%",
              height: "70%",
              borderRadius:"10px",

              boxShadow: "none",
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            fullWidth
          >
            <TextField
              type="date"
              onChange={handleTargetDateChange}
              InputLabelProps={{ style: { display: "none" } }}
              InputProps={{
                style: {
                  border: "none",
                  height: "100%",
                  width: "100%",
                  color: "#082A49 ",
                },
              }}
              sx={{
                "& fieldset": {
                  border: "none",
                },
                ...Styles.inputBox,

                marginTop: "0.5%",
                borderColor: "#1282A2",
              }}
            />
          </FormControl>
          {errorturntargetdate && (
            <Typography sx={{ color: "red" }}>
              Please Set Target Date
            </Typography>
          )}
        </Box>
}
      </Box>
      <Box
        style={{
          width: "90%",
          margin: "auto",
          display: "flex",
          marginTop: "1.5%",
          // border:"2px solid yellow",
          flexDirection: MediaQueryTickit ? "column" : "row",
        }}
      >
        <Box style={{ width: MediaQueryTickit ? "100%" : "45%" }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "0%",
              color: "#082A49",
              fontSize: "18px",
            }}
          >
            Task Name
          </Typography>
          <TextField
            value={data.name}
            onChange={(e) => {
              handleChange("name", e.target.value);
            }}
            InputLabelProps={{ style: { display: "none" } }}
            InputProps={{
              style: {
                border: "none",
                height: "10%",
                width: "90%",
                color: "#082A49 ",
              },
            }}
            sx={{
              "& fieldset": {
                border: "none",
         
              },
              ...Styles.inputBox,
              width: "90%",
              marginTop: "0.5%",
              borderColor: "#1282A2",
              borderRadius:"10px",
            }}
          />
          {errortaskname && (
            <Typography sx={{ color: "red" }}>
              Please Enter Task Name
            </Typography>
          )}
        </Box>
          
        {ptype === "AMC" ? (
          <Box style={{ width: MediaQueryTickit ? "70%" : "25%",marginLeft:"5%" }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "0%",

              color: "#082A49",
              fontSize: "18px",
            }}
          >
            Checklist
          </Typography>
          <FormControl
            size="small"
            style={{
              ...Styles.tdBox1InnerRight,
              border: "2px solid #1282A2",
              boxShadow: "none",
              backgroundColor: "transparent",
              width: "90%",
              height: "60%",
              marginTop: "1.5%",
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                border: "1px solid #1282A2",
                borderColor: "#1282A2",
                borderRadius:"10px",
              },
            }}
            fullWidth
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.chktaskid}
              //   label={ticketssort ? "" : "Change status"}
              style={{
                ...Styles.selectBox,
                height: "100%",
                // marginTop: "0.5%",
                borderRadius:"10px",
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "1px solid white",
                  borderColor: "white",
                },
                "& fieldset": {
                  border: "none",
                  borderRadius:"10px",
                },
              }}
              size="small"
              onChange={(e) => {
                handleChange("chktaskid", e.target.value);
              }}
            >
              {checklists?.map((item, index) => (
                <MenuItem
                  key={item.chkid}
                  value={item.chkid}
                  onClick={() => {
                    // handletype(item.type);
                    handletaskfrequency(item)
                  }}
                >
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorchktaskid && (
            <Typography sx={{ color: "red" }}>
              Please Select Checklist
            </Typography>
          )}
        </Box>
        ) : (
          <>
          
          <Box style={{ width: MediaQueryTickit ? "70%" : "25%",marginLeft:"5%"  }}>
            <Typography
              style={{
                ...Styles.profileText1,
                marginTop: "0%",

                color: "#082A49",
                fontSize: "18px",
              }}
            >
              Buckets
            </Typography>
            <FormControl
              size="small"
              style={{
                ...Styles.tdBox1InnerRight,
                border: "2px solid #1282A2",
                boxShadow: "none",
                backgroundColor: "transparent",
                width: "90%",
                height: "60%",
                marginTop: "1.5%",
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #1282A2",
                  borderColor: "#1282A2",
                },
              }}
              fullWidth
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.chktaskid}
                //   label={ticketssort ? "" : "Change status"}
                style={{
                  ...Styles.selectBox,
                  height: "100%",
                  // marginTop: "0.5%",
                
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid white",
                    borderColor: "white",
                  },
                  "& fieldset": {
                    border: "none",
                    
                  },
                }}
                size="small"
                onChange={(e) => {
                  handleChange("chktaskid", e.target.value);
                }}
              >
                {turnkeysValue?.map((item, index) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    onClick={() => {
                      // handletype(item.type);
                      handletaskbucket(item)
                    }}
                    // onClick={() => {
                    //   handletype(item.type);
                    // }}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorchktaskid && (
            <Typography sx={{ color: "red" }}>
              Please Select Bucket
            </Typography>
          )}
          </Box>
          </>
        )}
      </Box>
      <Box style={{ width: "90%", height: "20vh", margin: "auto" }}>
        <Typography
          style={{
            ...Styles.profileText1,
            marginTop: "1%",
            color: "#082A49",
            fontSize: "18px",
          }}
        >
          Task description
        </Typography>
        <TextField
          value={data.description}
          multiline
          maxRows={3}
          onChange={(e) => {
            handleChange("description", e.target.value);
          }}
          InputLabelProps={{ style: { display: "none" } }}
          InputProps={{
            style: {
              border: "none",
              height: "100%",
              width: "100%",
              color: "#082A49 ",
            },
          }}
          sx={{
            "& fieldset": {
              border: "none",
            },
            ...Styles.inputBox,
            width: "40%",
            marginTop: "0.5%",
            borderColor: "#1282A2",
            height: "13vh",
            borderRadius:"10px",
          }}
        />

        <Box style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                multiple
                onChange={(e) => handleFileChange(e)}
                style={{ display: "none" }}
                id="fileInput"
              />
            </Box>
            <label
              htmlFor="fileInput"
              style={{ cursor: "pointer", marginBottom: "10px" }}
            >
              <Box
                style={{ ...Styles.reqButton, justifyContent: "space-around" }}
              >
                <Typography style={Styles.text3}>Attach files</Typography>
                <img src={require("../Images/attachmentGray.png")} />
              </Box>
            </label>
            {selectedFiles.length > 0 && (
              <>
                <Typography
                  variant="subtitle1"
                  style={{
                    ...Styles.profileText1,
                    marginTop: "2%",
                    color: "#082A49",
                    fontSize: "18px",
                  }}
                >
                  Selected Files:
                </Typography>
                <Box
                  style={{
                    width: "130%",
                    height: "95%",
                    display: "flex",
                    overflowX: "auto",
                  }}
                >
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li
                        style={{
                          border: "none",
                          height: "40%",
                          // width: "100%",
                          color: "#A9A9A9",
                        }}
                        key={index}
                      >
                        {file.name}
                      </li>
                    ))}
                  </ul>
                </Box>
              </>
            )}
          </Box>

          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: MediaQueryTickit ? "left" : "flex-end",
              marginTop: MediaQueryTickit ? "17%" : "2%",
            }}
          >
            <Box
              onClick={() => {handleSubmit(ptype)}}
              style={{ ...Styles.reqButton, justifyContent: "space-around" }}
            >
              <Typography style={Styles.text3}>Save</Typography>
            </Box>
            <Box
              onClick={() => {
                props.setActive(1);
              }}
              style={{ ...Styles.cancelButton, justifyContent: "space-around" }}
            >
              <Typography
                style={{
                  ...Styles.text3,
                  color: "#144C89",
                  fontWeight: "bolder",
                }}
              >
                Cancel
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}