import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import Styles from "../../Styles/AdminDashboard";
  import ChangeStatusPopup from "../../Components/ChangeStatusPopup";
  import ChangeAsigneePopup from "../../Components/ChangeAsigneePopup";
  import img1 from "../../Images/demouser1.png";
  import img2 from "../../Images/demouser2.png";
  import img3 from "../../Images/cbs_Logo.png";
  import axios from "axios";
  import AttachmentList from "../../Components/TicketDetails/AttachmentList";
  import LogsAttachment from "../../Components/TicketDetails/LogsAttachment";
  import UserAvatar from "../../Components/TicketDetails/UserAvatar";
  import SeemoreTd from "../../Components/TicketDetails/SeemoreTd";
  import ConveneCall from "../../Components/TicketDetails/ConveneCall";
import ChangeTaskPopup from "./ChangeTaskPopup";
import ChangeTaskPopupamc from "./ChangeTaskPopupamc";
import TaskDetailAMCCard from "./TaskDetailAMCCard";
import AMCselectedchange from "./AMCselectedchange";
  
  export default function TaskDetail(props) {
    const mediaQueryMyTeam = useMediaQuery('(max-width:1302px)');
    const mediaQueryMyTeamUpper = useMediaQuery('(max-width:873px)');
    const inputDateString = props.taskSelected?.created_date;
        const formattedDate = new Date(inputDateString).toLocaleDateString("en-GB");
    let task = props;
    //  console.log("props in ticketDetails prat?ik", props);
     const [status,setstatus] = useState(props.selectedProject?.type === 'AMC' ? props.taskSelected?.completed_status : props.taskSelected?.progress)
     const [data,setData] = useState(props?.taskSelected); 
     const [projectselected,setProjectSelected] = useState(props?.selectedProject); 
    const [assigned_to,setassigned_to] = useState(props.taskSelected?.username)
    let role = localStorage.getItem("role");
    let bgColor = "#FEB7B1;"; //resolved color
    if (status === "inprogress") {
      bgColor = "#BCDCA4";
    } else if (status === "completed") {
      bgColor = "#228B22";
    } 
    else if (status === "notstarted" || status === "notcompleted") {
        bgColor = "#D9D21F";
      }
    const imgMap = {
      sameer: img1,
      kartik: img2,
    };
    const [allLogs, setAllLogs] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [AMCOpen, setAMCOpen] = React.useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [handleRefresh, setHandleRefresh] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    const [numofFiles, setNumOfFiles] = useState("");
    const [selectedFiles, setSelectedFiles] = useState("");
    // console.log("this is all logs,", allLogs);
    const [attachments, setAttachments] = useState([]);
    const [newStatusArray,setNewstatusArray] = useState([])
    const [statusClosed,setStatusClosed] = useState(true);
    const [newChecklist,setNewChecklist] = useState([])
    const [selectedChecklist,setSelectedChecklist] = useState([])
    const statusArray2 = [
      {
        id:1,
        name:"In Progress",
        value:"inprogress"
      },
      {
        id:2,
        name:"Completed",
        value:"completed"
      },
      {
        id:3,
        name:"Not Started",
        value:"notstarted"
      },
    ]
    const messagesContainerRef = useRef(null);

    useEffect(()=>{
        setNewstatusArray(statusArray2)
        getCurrentBucket()
        getCurrentBucketamc()
        getCheckList()
    },[])

    useEffect(()=>{
      if(handleRefresh){
        getsystemdata();
        setHandleRefresh(false);
      }
    },[handleRefresh])
    
    useEffect(() => {
     
      getsystemdata();
    }, []);

    const getsystemdata = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/gettasklogs`,
          { task_id: props.taskSelected.taskid}
        );
        // console.log(response.data.data, "all task logs");
        if (response.data.status === 1) {
          setAllLogs(response.data.data);
          // setticketssort(status);
        }
      } catch (e) {
        // console.log(e);
      }
    };

    const handleOpenPopup = () => {
      setOpenPopup(true);
    };
  
    const handleClosePopup = () => {
      setOpenPopup(false);
    };
    const handleOpen = (item) => {
      if(projectselected?.type === 'AMC'){
        setOpen1(true);
      }
      else{
      setOpen(true);
      }
    };
  
    const handleOpen1 = () => {
      setOpen1(true);
    };
    
    const handleOpenAMC = (item) => {
      // console.log("Handle Open AMC is hit properly");
      setSelectedChecklist(item);
      setAMCOpen(true);
    }
    const handleOpen2 = () => {
      setOpen2(true);
    };
  
    const handleOpen3 = () => {
      setOpen3(true);
    };
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [ticketssort, setticketssort] = useState(status);
    const [oldStatus, setoldStatus] = useState("");
    const [newStatus, setNewStatus] = useState("");
    const [changeStatusClickedHovered, setChangeStatusClickedHovered] = useState(false);
    const [changeAssigneeHovered, setChangeAssigneeHovered] = useState(false);
    
    useEffect(() => {
      const getAttachments = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/gettaskturnkeydoclist`,
            { "turnkey_id": data?.turnkey_id }
          );
          // console.log(response.data.data, "project api turnkey doclist");
          if (response.data.status === 1) {
            setAttachments(response.data.data);
            setSelectedFiles(response.data.data);
          }
        } catch (e) {
          console.log(e);
        }
      };
      getAttachments();
      setoldStatus(status);
    }, []);
    const [currentBucket,setCurrentBucket] = useState("");
    const [currentBucketamc,setCurrentBucketamc] = useState("");
    const getCurrentBucket = async () => {
      const turnkeyIdToMatch = data?.turnkey_id;
    
      if (props.checkLists && Array.isArray(props.checkLists)) {
        for (let n = 0; n < props.checkLists.length; n++) {
          if (turnkeyIdToMatch === props.checkLists[n].id) {
            setCurrentBucket(props.checkLists[n].name);
            return; // Stop searching after the first match
          }
        }
      }
    
      // If no match found or props.checklist is undefined or not an array, set a default value
      setCurrentBucket("Bucket");
    };

    const getCurrentBucketamc = async () => {
      const amcIdToMatch = data?.checklist_id;
      if (props.checkListsamc && Array.isArray(props?.checkListsamc)) {
        for (let n = 0; n < props.checkListsamc.length; n++) {
          if (String(amcIdToMatch) === String(props.checkListsamc[n].chkid)) {
            setCurrentBucketamc(props.checkListsamc[n].name);
            return; // Stop searching after the first match
          }
        }
      }
      // If no match found or props.checklist is undefined or not an array, set a default value
      setCurrentBucketamc("CheckList");
    };

    const getCheckList = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getamctaskmaster`,
          { "checklist_id": data?.checklist_id }
        );
        // console.log("checklist is ",response.data.data);
        if (response.data.status === 1) {
          setNewChecklist(response.data.data);
          // console.log("retrived data is ",response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    }

    const sortedArray = allLogs.sort((a, b) => {
      const dateTimeA = new Date(a.datetime);
      const dateTimeB = new Date(b.datetime);
      return dateTimeA - dateTimeB;
    });
    const handleChange = (key, value) => {
      setData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    };

    const handlemessageend = async () => {
      if (data.comment !== "" || selectedFiles) {
        let datas = {
          ...data,
          user_id: localStorage.getItem("user_id"),
          task_id: props.taskSelected?.taskid,
        };
        // console.log("updated data",datas)
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/inserttasklogs`,
            datas
          );
          // console.log(response.data.data[0]?.id);
          if (response.data.status === 1) {
            // uploadCategoryImgFile(response.data.data[0]?.id)
            setAllLogs((prevLogs) => [...prevLogs, ...response.data.data]);
            scrollToBottom();
            data.comment = "";
          }
        } catch (e) {
          // console.log(e);
        }
      }
    };
   
    const handleBack = () => {
      props.setActive(3);
    }

    const scrollToBottom = () => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setNumOfFiles(files.length);
    // setAttachments(files);
    setSelectedFiles(files);
    uploadCategoryImgFile(files.length, files);
  };


  const uploadCategoryImgFile = async (len, allfiles) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addtasklogsimage`,
        {
          task_id: props.taskSelected.taskid,
          user_id: localStorage.getItem("user_id"),
          file_extension: allfiles[0]?.type?.split("/")[1],
        }
      );
  
      if (response.data.status === 1) {
        const newId = response.data.data[0].id;
        const formData = new FormData();
        formData.append("task_logs", allfiles[0]); // Append the file to FormData
        formData.append("format", allfiles[0]?.type?.split("/")[1]);
  
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/uploadtasklog?name=${newId}&format=${allfiles[0]?.type?.split("/")[1]}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (uploadResponse.data.status === 1) {
          setAllLogs((prevLogs) => [...prevLogs, ...response.data.data]);
          scrollToBottom();
          // console.log("File successfully uploaded (Upload task log endpoint)");
        } else {
          console.error("File upload failed:", uploadResponse.data.error);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const [tdBoxHeight, setTdBoxHeight] = useState("11%"); // Initial height
  const [viewMoreClicked, setViewMoreClicked] = useState(false);

const handleViewMore = () => {
  // Set the height to 35% when "view more" is clicked
  setTdBoxHeight("35%");
  setViewMoreClicked(true);
};
    return (
      <Box style={Styles.mainBox}>
        <Box style={Styles.tdMainBox}>
          <Box style={Styles.tdBox1}>
            <Box style={{...Styles.tdBox1Inner,height:mediaQueryMyTeamUpper?"15%":"10%",flexDirection:mediaQueryMyTeamUpper?"column":"row", alignItems: "center"}}>
            <img src={require("../../Images/Iconbackarrow.png")} alt="plus-icon" style={{ width: "30px", height: "20px", cursor: "pointer" }} onClick={() => { handleBack();}}
              />
              {projectselected?.type === 'AMC' ? (
                <Box style={{...Styles.tdBox1InnerLeft,width:"90%",flexWrap:mediaQueryMyTeamUpper?"wrap":"nowrap",justifyContent:mediaQueryMyTeamUpper?"space-between":"space-around", marginRight: "25px"}}>
                <Typography style={{ ...Styles.tdText1, marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                  CheckList id :{" "}
                  <span
                    style={{
                      ...Styles.tdText1,
                      marginTop: "0px",
                      color: "#1282A2",
                      fontWeight: "light",
                      // marginTop: "25px",
                      marginLeft: "2px",
                      // fontSize: "14px",
                      fontSize:mediaQueryMyTeamUpper?"10px":"14px"
                    }}
                  >
                    #{data?.checklist_id}
                  </span>
                </Typography>

                <Typography style={{ ...Styles.tdText1, marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                  CheckList Name :{" "}
                  <span
                    style={{
                      ...Styles.tdText1,
                      marginTop: "0px",
                      color: "#1282A2",
                      fontWeight: "light",
                      // marginTop: "25px",
                      marginLeft: "2px",
                      // fontSize: "14px",
                      fontSize:mediaQueryMyTeamUpper?"10px":"14px"
                    }}
                  >
                    {currentBucketamc}
                  </span>
                </Typography>
  
                <Typography style={{ ...Styles.tdText1, display:"flex",marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                  Date :{" "}
                  <span
                    style={{
                      ...Styles.tdText1,
                      marginTop: "3px",
                      color: "#1282A2",
                      fontWeight: "light",
                      // marginTop: "25px",
                      marginLeft: "2px",
                      // fontSize: "14px",
                      fontSize:mediaQueryMyTeamUpper?"10px":"14px"
                    }}
                  > 
                    {formattedDate}
                  </span>
                </Typography>
                  
                <Typography style={{ ...Styles.tdText1, marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                  Schedule :{" "}
                  <span
                    style={{
                      ...Styles.tdText1,
                      marginTop: "0px",
                      color: "#1282A2",
                      fontWeight: "light",
                      // marginTop: "25px",
                      marginLeft: "2px",
                      // fontSize: "14px",
                      fontSize:mediaQueryMyTeamUpper?"10px":"14px"
                    }}
                  >
                    {data.schedule}
                  </span>
                </Typography>


                <Typography style={{ ...Styles.tdText1, marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                {props.selectedProject.type === 'AMC' ? (
                  <>
                    Completion status :{" "}
                    <span
                      style={{
                        ...Styles.tdText1,
                        marginTop: "0px",
                        color: "#1282A2",
                        fontWeight: "light",
                        marginLeft: "2px",
                        fontSize: mediaQueryMyTeamUpper ? "10px" : "14px"
                      }}
                    >
                      {status}
                    </span>
                  </>
                ) : (
                  <>
                    Current status :{" "}
                    <span
                      style={{
                        ...Styles.tdText1,
                        marginTop: "0px",
                        color: "#1282A2",
                        fontWeight: "light",
                        marginLeft: "2px",
                        fontSize: mediaQueryMyTeamUpper ? "10px" : "14px"
                      }}
                    >
                      {status}
                    </span>
                  </>
                )}
                </Typography>
              </Box>
              ):(<Box style={{...Styles.tdBox1InnerLeft,width:mediaQueryMyTeamUpper?"90%":"80%",flexWrap:mediaQueryMyTeamUpper?"wrap":"nowrap",justifyContent:mediaQueryMyTeamUpper?"space-between":"space-around"}}>
                <Typography style={{ ...Styles.tdText1, marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                  Bucket id :{" "}
                  <span
                    style={{
                      ...Styles.tdText1,
                      marginTop: "0px",
                      color: "#1282A2",
                      fontWeight: "light",
                      // marginTop: "25px",
                      marginLeft: "2px",
                      // fontSize: "14px",
                      fontSize:mediaQueryMyTeamUpper?"10px":"14px"
                    }}
                  >
                    #{data?.turnkey_id}
                  </span>
                </Typography>

                <Typography style={{ ...Styles.tdText1, marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                  Bucket Name :{" "}
                  <span
                    style={{
                      ...Styles.tdText1,
                      marginTop: "0px",
                      color: "#1282A2",
                      fontWeight: "light",
                      // marginTop: "25px",
                      marginLeft: "2px",
                      // fontSize: "14px",
                      fontSize:mediaQueryMyTeamUpper?"10px":"14px"
                    }}
                  >
                    {currentBucket}
                  </span>
                </Typography>
  
                <Typography style={{ ...Styles.tdText1, display:"flex",marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                  Date :{" "}
                  <span
                    style={{
                      ...Styles.tdText1,
                      marginTop: "3px",
                      color: "#1282A2",
                      fontWeight: "light",
                      // marginTop: "25px",
                      marginLeft: "2px",
                      // fontSize: "14px",
                      fontSize:mediaQueryMyTeamUpper?"10px":"14px"
                    }}
                  > 
                    {formattedDate}
                  </span>
                </Typography>

                <Typography style={{ ...Styles.tdText1, marginTop: "0px",fontSize:mediaQueryMyTeamUpper?"10px":"16px" }}>
                  Current status :{" "}
                  <span
                    style={{
                      ...Styles.tdText1,
                      marginTop: "0px",
                      color: "#1282A2",
                      fontWeight: "light",
                      // marginTop: "25px",
                      marginLeft: "2px",
                      // fontSize: "14px",
                      fontSize:mediaQueryMyTeamUpper?"10px":"14px"
                    }}
                  >
                    {status}
                  </span>
                </Typography>
              </Box>
              )}

            </Box>
            <Box style={{...Styles.tdBox1Middle, height: tdBoxHeight, overflowY: 'auto', alignItems: 'stretch' }}>
              <Box
                style={{
                  width: "3%",
                  height: "100%",
                  backgroundColor: bgColor,
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                }}
              ></Box>
              <Box
                style={{ width: "80%",
                overflow: "hidden",
                flexWrap: "nowrap",
                overflowY: 'auto',
                boxSizing: 'border-box' }}
              >
                  <Typography
  // onClick={scrollToBottom}
  style={{
    textAlign: "justify",
    color: "#082A49",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "18px",
    margin: "1%",
    display: "-webkit-box",
    alignItems: "center",
    justifyContent: "center",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: viewMoreClicked ? "unset" : 2, // Adjusted WebkitLineClamp value
    width: "97%",
  }}
>
  {viewMoreClicked
    ? data?.taskname
    : data?.taskname && data?.taskname.length > 80
    ? `${data?.taskname.substring(0, 80)}`
    : data?.taskname}
  {data?.taskname && data?.taskname.length > 80 && !viewMoreClicked && (
    <>
      {" "}
      <span
        style={{
          color: "#1282A2",
          cursor: "pointer",
          fontSize: "18px",
        }}
        onClick={handleViewMore}
      >
        ...view more
      </span>
    </>
  )}
</Typography>
                  {/* <Typography
                    style={{
                      textAlign: "justify",
                      margin: "1%",
                      fontFamily: "Montserrat",
                      maxHeight: "20px", 
                      fontSize: "14px",
                      margin: "1%",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1, // Limit to one line
                      width: "100%",
            
                    }}
                    >
                    {description && description.length > 50
                      ? `${description.substring(0, 50)}...`
                      : description}
                    {description && description.length > 50 && (
                      <>
                        {" "}
                        <span
                          style={{
                            color: "#1282A2",
                            cursor: "pointer",
                            fontSize: "13px",
                          }}
                          //onClick={handleOpenPopup}
                        >
                          See more
                        </span>
                      </>
                    )}
                  </Typography> */}
                </Box>
                
                <Box
                    style={{
                      width: "15%",
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      marginLeft: "auto",  // This will push the box to the right
                    }}
                  >
                    <Typography
                      onClick={handleOpen2}
                      style={{
                        cursor: "pointer",
                        marginLeft: "2%",
                        fontSize: "18px",
                        color: "#1282A2",
                        fontFamily: "Montserrat",
                      }}
                    >
                      <img
                        style={{ width: "20px", marginRight: "1px" }}
                        src={require("../../Images/attachment.png")}
                      />
                      {attachments.length}
                    </Typography>
                  </Box>
            </Box>
            <Box
            ref={messagesContainerRef}
            style={{
              ...Styles.chatBox,
              height: "50vh",
              width: "95%",
              display: "flex",
              //   margin:"auto",
              // borderLeft: "2px dashed #A9A9A9",

              marginLeft: "10%",
              flexDirection: "column",
            }}
          >
            {sortedArray.map((message) => (
              <Box
                key={message.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems:
                    message.user_id === localStorage.getItem("user_id")
                      ? "flex-end"
                      : "flex-start",
                  margin: "10px",
                  padding: "10px",
                  marginLeft: "4%",
                  borderLeft:/^Changed status to\s/.test(message.comment) ? "none" : "2px dashed #A9A9A9",
                
                  // border: "1px solid #1282A2",
                  borderRadius: "0px",
                  maxWidth: "88%",
                }}
              >
                {/* changed status,etc msgs */}
 
                {/* {update && (
                  <>
                    <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "1%",
                      }}
                    >
                      {message.name} {message.comment} {oldStatus} to {newStatus}
                      .
                    </Typography>
                    <img src={require("../Images/plus.png")} />
                  </>
                )} */}
 
                {/* changed status,etc msgs */}
 
               {message.user_id !== localStorage.getItem("user_id") ||
  ((/^Changed assigned user\s/.test(message.comment) || (/^Changed assigned user\s/.test(message.comment))) && (
    <Box
      style={{
        width: "30%",
        display: "flex",
        flexDirection: "row",
        marginBottom: "10px",
      }}
    >
                      {/* <UserAvatar uid={message.user_id} /> */}
                     
                      <Typography
                        style={{
                          textAlign: "justify",
                          color: "#082A49",
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          fontSize: "18px",
                          margin: "1%",
                        }}
                      >
                        {message.name}
                      </Typography>
                    </Box>
                  ))}
 
                { /^Changed status to\s/.test(message.comment) ? (
                  <Box style={{width:"100%",height:"30px",display:"flex",alignItems:"center"}} >
                    <img
                      style={{
                        alignSelf: "flex-start",
                        position: "relative",
                        top: "34%",
                        left: "-1.5%",
                      }}
                      src={require("../../Images/circleBlue.png")}
                    />
                      <UserAvatar  uid={message.user_id} />
 
                    <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "1%",
                        marginLeft: "3%",
                        alignSelf: "flex-start",
                      }}
                    >
                      {message.user_name}{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {message.comment.startsWith("Changed status to")
                          ? <>
                              Changed status to <span style={{ color: "#FEB7B1", fontWeight: "bold" }}>
                                {message.comment.substring("Changed status to".length).trim()}
                              </span>
                            </>
                          : message.comment} 
                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Montserrat",
                          color: "#BCDCA4",
                        }}
                      >
                        {message.ticket_status}
                      </span>{" "}          
                      .
                    </Typography>
                    <Typography
                      style={{
                        margin: "5px 0",
                        fontSize: "12px",
                        color: "#A9A9A9",
                        fontFamily: "Montserrat",
                      }}
                    >
                       {new Intl.DateTimeFormat("en-US", {
                          day: "numeric",
                          month: "short",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }).format(new Date(message.created_date))}
                      {/* {`${new Date(message.created_date).toLocaleDateString()} `} */}
                    </Typography>
                  </Box>
 
                ) : /^Changed assigned user\s/.test(message.comment) ? (
 
                  <Box style={{height:"30px",width:"100%",display:"flex",alignItems:"center"}}>
                    <img
                      style={{
                        alignSelf: "flex-start",
                        position: "relative",
                        top: "34%",
                        left: "-1.5%",
                      }}
                      src={require("../../Images/circleBlue.png")}
                    />
                      <UserAvatar uid={message.user_id} />
 
                    <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        // margin: "1%",
                        marginLeft: "3%",
                        alignSelf: "flex-start",
                      }}
                    >
                      {message?.user_name} <span style={{color:"#082A49",fontWeight:"bold"}}>{message.name}</span>{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          fontFamily: "Montserrat",
                        }}
                      >
                         {message.comment.startsWith("Changed assigned user")
            ? <>
                assigned task to <span style={{ color: "#BCDCA4", fontWeight: "bold" }}>
                  {message.comment.substring("Changed assigned user".length).trim()}
                </span>
              </>
            : message.comment}
                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Montserrat",
                          color: "#BCDCA4",
                        }}
                      >
                        {/* {message?.user_name} <span style={{color:"#082A49",fontWeight:"bold"}}>{message.name}</span> */}
                      </span>{" "}          
                      . {" "} {" "}
                    </Typography>
                    <Typography
                      style={{
                        margin: "5px 0",
                        fontSize: "12px",
                        color: "#A9A9A9",
                        fontFamily: "Montserrat",
                        alignSelf:"flex-end"
                      }}
                    >
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(new Date(message.created_date))}
                    </Typography>
                  </Box>
                ) : 
                /^Changed bucket\s/.test(message.comment) ? (
 
                  <Box style={{height:"30px",width:"100%",display:"flex",alignItems:"center"}}>
                    <img
                      style={{
                        alignSelf: "flex-start",
                        position: "relative",
                        top: "34%",
                        left: "-1.5%",
                      }}
                      src={require("../../Images/circleBlue.png")}
                    />
                      <UserAvatar uid={message.user_id} />
 
                    <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        // margin: "1%",
                        marginLeft: "3%",
                        alignSelf: "flex-start",
                      }}
                    >
                      {message?.user_name} <span style={{color:"#082A49",fontWeight:"bold"}}>{message.name}</span>{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          fontFamily: "Montserrat",
                        }}
                      >
                         {message.comment.startsWith("Changed assigned user") ? (
  <>
    assigned task to <span style={{ color: "#BCDCA4", fontWeight: "bold" }}>
      {message.comment.substring("Changed assigned user".length).trim()}
    </span>
  </>
) : message.comment.startsWith("Changed bucket") ? (
  <>
    Changed bucket to <span style={{ color: "#86D8FF", fontWeight: "bold" }}>
      {message.comment.substring("Changed bucket".length).trim()}
    </span>
  </>
) : message.comment}

                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Montserrat",
                          color: "#BCDCA4",
                        }}
                      >
                        {/* {message?.user_name} <span style={{color:"#082A49",fontWeight:"bold"}}>{message.name}</span> */}
                      </span>{" "}          
                      . {" "} {" "}
                    </Typography>
                    <Typography
                      style={{
                        margin: "5px 0",
                        fontSize: "12px",
                        color: "#A9A9A9",
                        fontFamily: "Montserrat",
                        alignSelf:"flex-end"
                      }}
                    >
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(new Date(message.created_date))}
                    </Typography>
                  </Box>):
                (
                  <>
                    {message.file_path ? (
                      <>
                    {message.user_id !== localStorage.getItem("user_id") && (
                      <Box style={{width:"100%",display:"flex"}}>
                      <UserAvatar  uid={message.user_id} />
 
                       <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "1%",
                      }}
                    >
                      {message.user_name}
                    </Typography>
                    </Box>
                    )}
                      <Box>
                        <LogsAttachment
                          task_id={true}
                          id={message.id}
                          name={message.file_path}
                        />
                      </Box>
                      </>
                    ) : (
                      <>
                      {message.user_id !== localStorage.getItem("user_id") && (
                      <Box style={{width:"100%",display:"flex"}}>
                      <UserAvatar  uid={message.user_id} />
 
                       <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "1%",
                      }}
                    >
                      {message.user_name}
                    </Typography>
                    </Box>
                    )}
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            margin: "0",
                            padding: "0",
                            border: message.file_path
                              ? "none"
                              : "1px solid #1282A2",
                            borderRadius: "10px",
                            padding: "1%",
                            color: "#A9A9A9",
                            ...(/^Changed status to\s/.test(message.comment) ||
                              (/^Changed assigned user\s/.test(message.comment) && {
                                alignSelf: "flex-start",
                                border: "none",
                              })),
                          }}
                        >
                          {message.comment}
                        </Typography>
                        <Typography
                          style={{
                            margin: "5px 0",
                            fontSize: "12px",
                            color: "#A9A9A9",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {" "}
                          {new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(new Date(message.created_date))}
                          {/* {`${new Date(
                            message.created_date
                          ).toLocaleDateString()} `} */}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </Box>
            ))}
          </Box>


  
            <Box
              //ref={messagesContainerRef}
              style={{
                ...Styles.chatBox,
                height:localStorage.getItem("role")==="admin" && projectselected?.type === 'AMC'?"22vh":"0vh",
                width: "87%",
                display: "flex",
                // overflowY:"hidden",
                // backgroundColor:"cyan",
                //   margin:"auto",
                // borderLeft: "2px dashed #A9A9A9",
  
                marginLeft: "10%",
                flexDirection: "column",
              }}
            >
              {localStorage.getItem("role")==="admin" && projectselected?.type === 'AMC' && <Box
              sx={{
                display: "flex",
                overflowX: "auto",
                marginLeft: "4%",
                marginTop: "auto",
              }}
            >
              {newChecklist?.map((item, index) => (
                <Box
                  key={index}
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
              <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            //borderBottom: "2px dashed #A9A9A9",
          }}
        >
          <Box 
            onClick={() => handleOpenAMC(item)}
            style={{ cursor: "pointer" , width: "350px"}}>
                <TaskDetailAMCCard item={item} />
              </Box>
        </Box>
            </Box>
          ))}
        </Box>
              }
            </Box>
  
            <Box style={Styles.textfieldSender}>
              <TextField
                // size="small"
                // InputProps={{
                //   style: { border: "none" },
                // }}
                value={data?.comment}
                onChange={(e) => {
                  handleChange("comment", e.target.value);
                }}
                style={{
                  width: "100%",
                  height: "6vh",
                  borderRadius: "10px",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                   handlemessageend();
                  }
                }}
                placeholder="Type here ..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="file"
                         onChange={(e) => handleFileChange(e)}
                          style={{ display: "none" }}
                          id="fileInput"
                        />
                      </Box>
                      <label
                        htmlFor="fileInput"
                        style={{ cursor: "pointer", marginBottom: "10px" }}
                      >
                        <img
                          style={{
                            marginRight: "20px",
                            width: "20px",
                            cursor: "pointer",
                            marginTop:"14px" 
                          }}
                          src={require("../../Images/attachment.png")}
                        />
                      </label>
                      <img
                        style={{ cursor: "pointer" }}
                        src={require("../../Images/sendIcon.png")}
                       onClick={() => handlemessageend()}
                      />{" "}
                    </InputAdornment>
                  ),
                }}
              >
                {/* <img src={require("../../Images/attachment.png")}/> */}
              </TextField>
            </Box>
          </Box>
  
          <Box style={Styles.tdBox2}>
            <Typography style={Styles.tdText1}>Client details</Typography>
            {/* <Typography style={Styles.tdText2}>{orgDetails[0]?.name}</Typography> */}
            <Typography style={Styles.tdText3}>Manager</Typography>
            {/* <Typography style={Styles.tdText3}>
              {" "}
              <img
                style={{
                  marginRight: "2%",
                  marginLeft: "3%",
                  marginTop: "3%",
                  width: "15px",
                }}
                src={require("../../Images/phone.png")}
              />
              {orgDetails[0]?.number}
            </Typography> */}
            <Typography style={Styles.tdText3}>
              {" "}
              <img
                style={{ marginRight: "2%", marginLeft: "3%", width: "15px" }}
                src={require("../../Images/email.png")}
              />
              {/* {orgDetails[0]?.email} */}
            </Typography>
            <Box
              style={{
                width: "90%",
                height: "2px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "5%",
                borderBottom: "2px dashed #A9A9A9",
              }}
            />
            <Typography style={Styles.tdText1}>Project details</Typography>
            <Typography
              style={{ ...Styles.tdText3, fontWeight: "bolder", marginTop: "2%" }}
            >
              Project name :
            </Typography>
            <Typography style={{ ...Styles.tdText3, width: "70%" }}>
              {projectselected?.project_name}
            </Typography>
            <Typography
              style={{ ...Styles.tdText3, fontWeight: "bolder", marginTop: "2%" }}
            >
              Task Created By :
            </Typography>
            <Typography style={{ ...Styles.tdText3, width: "70%" }}>
              {data?.createdname}
            </Typography>
            {role === "admin" && (
            <Box style={{ ...Styles.reqButton, margin: "3%" }} 
            //onClick={()=>{props.setActive(11)}}
            >
              <Typography style={Styles.text3}>Show details</Typography>
              </Box>
              )}
            <Box
              style={{
                borderBottom: "2px dashed #A9A9A9",
                width: "90%",
                height: "2px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <Box style={{ width: "100%", display: "flex" }}>
              <Typography style={Styles.tdText1}>Assigned to</Typography>
              {isHovered && (
                <Box
                  onMouseOver={() => setIsHovered(true)}
                  onMouseOut={() => setIsHovered(false)}
                  style={{
                    cursor: "pointer",
                    width: "50%",
                    height: "90%",
                    backgroundColor: "white",
                    display: "flex",
                    jusifyContent: "center",
                    border: "2px solid #144C89",
                    borderRadius: "10px",
                    margin: "2.5%",
                    marginBottom: "0px",
                    alignItems:"center"
                    // overflow: "hidden"
                  }}
                >
                  <Typography
                  // onClick={handleOpen3}
                    style={{
                      ...Styles.tdText1,
                      fontSize: "14px",
                      margin: "auto",
                      zIndex: 100,
                      display:"flex",
                      justifyContent:"space-evenly",
                      alignItems: "center",
                      // backgroundColor:"green",
                      width: "80%",
                    }}
                  >
                  </Typography>
                  <Box
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: "white",
                      position: "relative",
                      top: "55%",
                      right: "65%",
                      borderBottom: "2px solid #144C89",
                      borderRight: "2px solid #144C89",
                      borderBottomRightRadius: "5px",
                      transform: "rotate(45deg)", // Rotate by 90 degrees
                    }}
                  ></Box>
                </Box>
              )}
            </Box>
            <Box
              onMouseOver={() => setIsHovered(true)}
              onMouseOut={() => setIsHovered(false)}
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}
            >
              {/* <UserAvatar assigned={true} /> */}
              {/* <img
                style={{
                  width: "55px",
                  height: "50px",
                  borderRadius: "50%",
                  marginLeft: "4%",
                  marginTop: "4%",
                }}
                src={require("../../Images/profile.png")}
              /> */}
              <Box>
                <Typography style={{ ...Styles.tdText2, marginTop: "15px" }}>
                  {assigned_to}
                </Typography>
                {/* <Typography style={Styles.tdText3}> Assig Detail Desig </Typography> */}
              </Box>
            </Box>
            <Box
              style={{
                borderBottom: "2px dashed #A9A9A9",
                width: "90%",
                height: "2px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "5%",
              }}
            />
  
            {/* {!isHovered2 && ( */}
            {/* {statusClosed && */}
            <Box
              // onMouseOver={() => setIsHovered2(true)}
              // onMouseOut={() => setIsHovered2(false)}
              onClick={() => {handleOpen(props.taskSelected)}}
              style={{
                ...Styles.reqButton,
                marginTop: "5%",
                marginRight: "0%",
                marginLeft: "5%",
              }}
            >
              <Typography style={Styles.text3}>Action</Typography>
            </Box>
  {/* } */}
            {/* )} */}
            {isHovered2 && (
              <Box
                onMouseOver={() => setIsHovered2(true)}
                onMouseOut={() => setIsHovered2(false)}
                style={{
                  width: "55%",
                  //   height: "4vh",
                  backgroundColor: "white",
                  display: "flex",
                  // jusifyContent: "center",
                  border: "2px solid #144C89",
                  borderRadius: "10px",
                  margin: "auto",
                  marginBottom: "0px",
                  marginTop: "1%",
                  flexDirection: "column",
                  // alignItems: "center",
                  // textAlign: "center",
                }}
              >
                <Typography
                  style={{
                    textAlign: "end",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsHovered2(false);
                  }}
                >
                  X
                </Typography>
                
                <Typography
                  // onClick={handleOpen}
                  onMouseOver={() => setChangeStatusClickedHovered (true)}
                  onMouseLeave={() => setChangeStatusClickedHovered (false)}
                  style={{
                    fontFamily: "Montserrat",
                    color: "#082A49",
                    fontWeight: "bold",
                    marginTop: "2%",
                    marginLeft: "8px",
                    fontSize: "18px",
                    fontSize: "14px",
                    cursor: "pointer",
                    textAlign: "justify",
                    color: changeStatusClickedHovered ? "#144C89" : "#B2AFAF",
                    //   margin: "auto",
                    //   marginTop: "15px",
                  }}
                >
                  Change status
                </Typography>
                {/* {role === "admin" && */}
  
                <Typography
                  onClick={handleOpen1}
                  onMouseOver={() => setChangeAssigneeHovered(true)}
                  onMouseLeave={() => setChangeAssigneeHovered(false)}
                  style={{
                    fontFamily: "Montserrat",
                    color: changeAssigneeHovered ? " #144C89" : "#B2AFAF",
                    fontWeight: "bold",
                    marginTop: "2%",
                    marginLeft: "8px",
                    fontSize: "18px",
                    fontSize: "14px",
                    cursor: "pointer",
  
                    //   margin: "auto",
                    //   marginTop: "20px",
                  }}
                >
                  Change asignee
                </Typography>
                {/* 
                {role === "admin" && (
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      color: "#082A49",
                      fontWeight: "bold",
                      marginTop: "2%",
                      marginBottom: "18px",
                      fontSize: "18px",
                      fontSize: "14px",
                      cursor: "pointer",
                      marginLeft: "8px",
                    }}
                  >
                    Resolve ticket
                  </Typography>
                )}
              */}
              </Box>
            )}
          </Box>
        </Box>
         {open && (
          <ChangeTaskPopup
            open={open} 
            currentstatus={status}
            //project_id={project_id}
            //id={id}
            data={data}
            checkLists={props.checkLists}
            assigned_to={assigned_to}
            projectselected={projectselected}
            currentBucket={currentBucket}
            //assigned_name={assiDetail?.name}
            setstatus={setstatus}
            setassigned_to={setassigned_to}
            setData={setData}
            setCurrentBucket={setCurrentBucket}
            //fetchUserData={fetchUserData}
            setOpen={setOpen}
            setHandleRefresh={setHandleRefresh}
          />
        )}
         {open1 && (
          <ChangeTaskPopupamc
            open={open1}
            assigned_to={assigned_to}
            currentstatus={status}
            //project_id={project_id}
            //id={id}
            data={data}
            checkListsamc={props.checkListsamc}
            projectselected={projectselected}
            currentBucketamc={currentBucketamc}
            //assigned_name={assiDetail?.name}
            setstatus={setstatus}
            setassigned_to={setassigned_to}
            setData={setData}
            setCurrentBucketamc={setCurrentBucketamc}
            setOpen1={setOpen1}
            setHandleRefresh={setHandleRefresh}
            getCheckList={getCheckList}
            // allLogs={allLogs}
            // setAllLogs={setAllLogs}
  
          />
        )}
        {AMCOpen &&
          <AMCselectedchange
          selectedChecklist={selectedChecklist}
          projectselected={projectselected}
          AMCOpen={AMCOpen}
          setAMCOpen={setAMCOpen}
          getCheckList={getCheckList}
          />
        }
        {openPopup && (
          <SeemoreTd
          open={openPopup}
          setOpen={setOpenPopup}
          cardData={props.taskSelected}
          
          />
        )}
        
        {/* {open3 && <ConveneCall ticket_name={ticket_name} open={open3} assigned={assiDetail.id} setOpen={setOpen3} />} */}
  
   {open2 && <AttachmentList open={open2} id={props.taskSelected.id} setOpen={setOpen2} />}
        </Box>
  
    );
  }
  