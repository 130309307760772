import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Styles from "../../Styles/AdminDashboard";
import OrganisationCard from './OrganisationCard';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Organisation(props) {
  const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)')
  const [refetchdata,setRefetchdata] = useState(false);
  const [tmp,settmp] = useState(false);
  const [data,setData]=useState([])
  const updateRefetchdata = async(id) => {
    if(settmp){
    const confirmation = window.confirm(
      `Are you sure you want to Delete the Organisation?`
    );
    if (confirmation) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/deactivateorganisation`,
          {
            "id":id
          }
          
        );
        if (response.data.status === 1) {
        // console.log("organisations", id);
        }
      } catch (e) {
        // console.log(e);
      }
    }
  }
  setRefetchdata(true);
  };
  const updatedataforedit = async() => {
    // console.log("updatedataforedit has been called")
    setRefetchdata(true);
  };
  const getsystemdata = async()=>{
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallorganisations`,{
          "associated_organisationid":localStorage.getItem("orgn_id")
        }
      );
      // console.log(response.data.data)
      if (response.data.status===1) {
        setData(response.data.data)
        setRefetchdata(false);
        // localStorage.setItem("crtorgid", response.data.data[0].id);
        // props.setcardnumber(3)
        // history.push("/Home");
        // let token = `${response.data.token}`;
        // let user_id = `${response.data.id}`;
        // let mbl_no = `${response.data.mbl_no}`;
        // localStorage.setItem("user_id", user_id);
        // localStorage.setItem("mbl_no", mbl_no);
      }
    } catch (e) {
      // console.log(e);
    }
  }
  useEffect(()=>{
    getsystemdata()
      },[refetchdata])
    
      const handleEditProject = async(row) => {
          //console.log("clicked row: " , row)
      //setOpen(true);
      props.setRow(row);
      props.setActive(11);
      //setEdiProjectData1(row);
      }

      const handleOpenProject = async(row) =>{
        props.setSelectedProject(row);
        props.setActive(3);
      }
  return (
    <Box sx={{width:"100%",height:'99%',overflow:'hidden'}} >
      {/* button and topname */}
      <Box sx={{width:'100%',height:45,marginTop:'1.5%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
      <Typography style={{...Styles.name,marginTop:0,marginLeft:"1%",}} >Organisations</Typography>
     {localStorage.getItem('role') === "admin" &&
      <Box onClick={()=>{props.setActive(10)}} style={{...Styles.reqButton,marginTop:0,marginRight:"4%",}}>
          <Typography style={Styles.text3}>Add </Typography>
          <img src={require("../../Images/plus.png")} />
        </Box>
}
      </Box>
<Box sx={{width:'100%',height:"85%",display:'flex',marginTop:'3%',flexDirection:'column',alignItems:'center',overflowY:'auto'}} >
  {data?.map((item)=>(
<Box sx={{width:'100%',height:320,display:'flex',justifyContent:'center',marginTop:2}} >
    <OrganisationCard data={item}  updateRefetchdata={updateRefetchdata} updatedataforedit={updatedataforedit} getsystemdata={getsystemdata} handleEditProject={handleEditProject} handleOpenProject={handleOpenProject}/>
    </Box>
  ))

  }

</Box>

    </Box>
  )
}
