import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router";

export default function Sidebar(props) {
  //    const {active, props.setActive, props.handleClick}=props;
  const navigate = useNavigate();

  const handleLogout = () => {
    // localStorage.clear();
    navigate("/");
  };

  return (
    <Box sx={styles.main}>
      <>
        <Box style={styles.logoBox}>
          {/* <img style={styles.logo} src={require("../../Images/cbs_Logo.png")} /> */}
        </Box>
        <Box sx={styles.top}>
          <Box
            sx={{
              ...styles.item,
              width: props.active === 1 || props.active === 8 || props.active === 9 || props.active === 12 || props.active === 14 || props.active === 15 ? "99%" : "88%",
              backgroundColor: props.active === 1 || props.active === 8 || props.active === 9 || props.active === 12 || props.active === 14 || props.active === 15 ? "#FFFFFF" : "#A3CEF1",
              color: props.active === 1 || props.active === 8 || props.active === 9 || props.active === 12 || props.active === 14 || props.active === 15 ? "#082A49" : "#082A49",
            }}
            onClick={() => {
              props.setTasknavigate(false);
              props.setActive(1);
              props.handleClick(1);
            }}
          >
            {props.active === 1 || props.active === 8 || props.active === 9 || props.active === 12 || props.active === 14 || props.active === 15 ? (
              <img
                style={{ marginLeft: "10px" }}
                src={require("../Images/dashboardFill.png")}
                width="20px"
                
              />
            ) : (
              <img
                style={{ marginLeft: "10px" }}
                src={require("../Images/dashboard.png")}
                width="20px"
                
              />
            )}
            <Typography sx={styles.txt}>Dashboard</Typography>
          </Box>

          <Box
            sx={{
              ...styles.item,
              width: props.active === 2  ? "99%" : "88%",
              backgroundColor: props.active === 2  ? "#FFFFFF" : "#A3CEF1",
              color: props.active === 2  ? "#082A49" : "#082A49",
            }}
            onClick={() => {
              props.setActive(2);
              props.handleClick(2);
            }}
          >
            {props.active === 2 ? (
              <img
                style={{ marginLeft: "10px" }}
                src={require("../Images/usersFill.png")}
                width="20px"
                
              />
            ) : (
              <img
                style={{ marginLeft: "10px" }}
                src={require("../Images/users.png")}
                width="20px"
                
              />
            )}
            <Typography sx={styles.txt}>Team</Typography>
          </Box>
{(localStorage.getItem("role")==="admin" || localStorage.getItem("role")==="customeradmin" || localStorage.getItem("role")==="user")  &&

          <Box
            sx={{
              ...styles.item,
              width: props.active === 3 || props.active === 11 || props.active === 16  ? "99%" : "88%",
              backgroundColor: props.active === 3 || props.active === 11 || props.active === 16  ? "#FFFFFF" : "#A3CEF1",
              color: props.active === 3 || props.active === 11 || props.active === 16  ? "#082A49" : "#082A49",
            }}
            onClick={() => {
              props.setActive(3);
              props.handleClick(3);
            }}
          >
            {props.active === 3 || props.active === 11 || props.active === 16 ? (
              <img
                style={{ marginLeft: "10px", height: "20px", width: "20px" }}
                src={require("../Images/projectFill.png")}
              />
            ) : (
              <img
                style={{ marginLeft: "10px" }}
                src={require("../Images/project.png")}
                width="20px"
                
              />
            )}
            <Typography sx={styles.txt}>Projects</Typography>
          </Box>
}
{localStorage.getItem("role")==="admin" &&
          <Box
            sx={{
              ...styles.item,
              width: props.active === 4 || props.active === 10  ? "99%" : "88%",
              backgroundColor: props.active === 4 || props.active === 10  ? "#FFFFFF" : "#A3CEF1",
              color: props.active === 4 || props.active === 10 ? "#082A49" : "#082A49",
            }}
            onClick={() => {
              props.setActive(4);
              props.handleClick(4);
            }}
          >
            {props.active === 4 || props.active === 10 ? (
              <img
                style={{ marginLeft: "10px", height: "20px", width: "20px" }}
                src={require("../Images/orgFill.png")}
              />
            ) : (
              <img
                style={{ marginLeft: "10px" }}
                src={require("../Images/org.png")}
                width="20px"
                
              />
            )}
            <Typography sx={styles.txt}>Organisation</Typography>
          </Box>
}
           {/* <Box
            sx={{
              ...styles.item,
              width: props.active === 5  ? "99%" : "88%",
              backgroundColor: props.active === 5  ? "#FFFFFF" : "#A3CEF1",
              color: props.active === 5 ? "#082A49" : "#082A49",
            }}
            onClick={() => {
              props.setActive(5);
              props.handleClick(5);
            }}
          >
            {props.active === 5 ? (
              <img
                style={{ marginLeft: "10px", height: "20px", width: "20px" }}
                src={require("../Images/profileFill.png")}
              />
            ) : (
              <img
                style={{ marginLeft: "10px" }}
                src={require("../Images/profile.png")}
                width="20px"
                
              />
            )}
            <Typography sx={styles.txt}>Profile</Typography>
          </Box> */}
          <Box
            sx={{
              ...styles.item,
              width: props.active === 13  ? "99%" : "88%",
              backgroundColor: props.active === 13  ? "#FFFFFF" : "#A3CEF1",
              color: props.active === 13 ? "#082A49" : "#082A49",
            }}
            onClick={() => {
              props.setActive(13);
              props.handleClick(13);
            }}
          >
            {props.active === 13 ? (
              <img
                style={{ marginLeft: "10px", height: "20px", width: "20px" }}
                src={require("../Images/checklistfill.png")}
              />
            ) : (
              <img
                style={{ marginLeft: "10px" }}
                src={require("../Images/checklist.png")}
                width="20px"
                
              />
            )}
            <Typography sx={styles.txt}>Connect</Typography>
            </Box>

        </Box>
      </>
    </Box>
  );
}

const styles = {
  logoBox: {
    width: "90%",
    // height: '80px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1%",
    marginBottom: "1%",
  },
  logo: {
    // padding: "10%",
    width: "100px",
    margin: "auto",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "",
    width: "100%",
    "@media (max-width: 768px)": {
      width: "38px",
    },
  },
  top: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    "@media (max-width: 768px)": {
      marginLeft: "0px",
    },
  },
  item: {
    display: "flex",
    flexDirection: "row",
    margin: "auto auto auto 8px",
    alignItems: "center",
    marginY: "10px",
    width: "88%",
    height: "50px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    cursor: "pointer",
    padding: "2px",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    transition: "background-color 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "FFFFFF",
      color: "#252525",
    },
    "@media (max-width: 768px)": {
      margin: "0px",
      padding: "2px",
      marginY: "4px",
    },
  },
  txt: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "16px",
    marginLeft: "8px",
    "@media (max-width: 998px)": {
      fontSize: "14px",
    },
    "@media (max-width: 768px)": {
      color: "rgba(0, 0, 0, 0)",
    },
  },
  [`@media (max-width: 600px)`]: {
    main: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "10px",
      height: "auto",
      minWidth: "initial",
    },

    top: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "10px",
    },
    bottom: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "10px",
    },
    item: {
      width: "30%",
      height: "40px",
      margin: "10px",
    },
    txt: {
      marginLeft: "10px",
      fontSize: "14px",
    },
  },
};
