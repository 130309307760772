import { Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
export default function ContactDetails(props) {
  const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)');
    const [data, setData] = useState({});
  const handleChange = (key, value) => {
    // console.log("data is:",key, value);
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handlenext =async()=>{
    let datas={
      ...data,
      'id':localStorage.getItem("crtorgid")
    }
    // console.log("updated data",datas)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addorganisationbank`,
        datas
      );
      // console.log(response.data.data)
      if (response.data.status===1) {
        // localStorage.setItem("crtorgid", response.data.data[0].id);
        props.setcardnumber(3)
        // history.push("/Home");
        // let token = `${response.data.token}`;
        // let user_id = `${response.data.id}`;
        // let mbl_no = `${response.data.mbl_no}`;
        // localStorage.setItem("mbl_no", mbl_no);
      }
    } catch (e) {
      // console.log(e);
    }
    // props.setcardnumber(3)
  }
  return (
    <Box
    sx={{
      height: '100%',
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: MediaQueryOrgCard ? 'center' : 'flex-start',
      margin: '0 auto',
      marginTop: 8
    }}
  >
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection:MediaQueryOrgCard ? 'column' : 'row',
        justifyContent: 'left',
        alignItems: 'flex-start',
        marginBottom: '15px',
      }}
    >
      <Typography
        sx={{
          width: MediaQueryOrgCard ? '100%' : '20%',
          color: '#082A49',
          fontWeight: 500,
          fontSize: MediaQueryOrgCard ? 15 : 20,
          marginBottom: MediaQueryOrgCard ? '8px' : 0,
        }}
      >
       Bank Name
      </Typography>
      <TextField
      sx={{
        width: MediaQueryOrgCard ? '100%' : '30%',
      }}
        id="outlined-basic"
        value={data.bank_details}
        onChange={(e) => handleChange('bank_details', e.target.value)}
        variant="standard"
        fullWidth
      />
    </Box>

    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection:MediaQueryOrgCard ? 'column' : 'row',
        justifyContent: 'left',
        alignItems: 'flex-start',
        marginBottom: '15px',
      }}
    >
      <Typography
        sx={{
          width: MediaQueryOrgCard ? '100%' : '20%',
          color: '#082A49',
          fontWeight: 500,
          fontSize: MediaQueryOrgCard ? 15 : 20,
          marginBottom: MediaQueryOrgCard ? '8px' : 0,
        }}
      >
 Account No
      </Typography>
      <TextField
      sx={{
        width: MediaQueryOrgCard ? '100%' : '30%'}}
        id="outlined-basic"
        value={data.account_number}
        onChange={(e) => handleChange('account_number', e.target.value)}
        variant="standard"
        fullWidth
      />
    </Box>

    <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection:MediaQueryOrgCard ? 'column' : 'row',
      justifyContent: 'left',
      alignItems: 'flex-start',
      marginBottom: '15px',
    }}
  >
    <Typography
      sx={{
        width: MediaQueryOrgCard ? '100%' : '20%',
        color: '#082A49',
        fontWeight: 500,
        fontSize: MediaQueryOrgCard ? 15 : 20,
        marginBottom: MediaQueryOrgCard ? '8px' : 0,
      }}
    >
  IFSC Code
    </Typography>
    <TextField
    sx={{
      width: MediaQueryOrgCard ? '100%' : '30%'}}
      id="outlined-basic"
      value={data.ifsccode}
      onChange={(e) => handleChange('ifsccode', e.target.value)}
      variant="standard"
      fullWidth
    />
  </Box>

    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 100,
          height: '40px',
          backgroundColor: '#144C89',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handlenext}
      >
        <Typography sx={{ color: 'white', cursor: 'pointer' }}>Next</Typography>
      </Box>
    </Box>
  </Box>
);
}




// <Box sx={{height:'100%',width:'90%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'space-evenly'}} >
// <Box sx={{width:450,height:'10%',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center',flexWrap:'wrap' ,'@media screen and (max-width: 200px)': {
//      flexDirection: 'column',
//    },
//    '@media screen and (min-width: 201px)': {
//      flexDirection: 'row',
//    }}} >   
//    <Box sx={{width:240,height:'100%' }} >
//     <Typography sx={{color:'#082A49',marginRight:2,marginLeft:2,fontWeight:500,fontSize:20,}} >Account Holder Name </Typography>
//      </Box>    
//     <Box sx={{width:210,height:'100%' }} >
//        <TextField
     
//      id="outlined-basic"
//      value={data.bank_details}
//      onChange={(e) => {
//        handleChange("bank_details", e.target.value);
//      }}
//      // label="Name"
//      variant="standard"
//      // InputLabelProps={{ shrink: true }}
//      />
//      </Box>
//      </Box>
     
//      <Box sx={{width:450,marginTop:2,height:'10%',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center',flexWrap:'wrap' ,'@media screen and (max-width: 200px)': {
//      flexDirection: 'column',
//    },
//    '@media screen and (min-width: 201px)': {
//      flexDirection: 'row',
//    }}} >
//       {/* <Box sx={{width:200, marginBottom: 1}}> */}
//       <Box sx={{width:220,height:'100%' }} >
//        <Typography sx={{color:'#082A49',marginRight:5,fontWeight:500,fontSize:20,marginLeft:1}} >Account number </Typography>
//       </Box>
//       {/* <Box sx={{width:200, marginBottom: 1}}> */}
//       <Box sx={{width:220,height:'100%' }} >
//        <TextField
//                id="outlined-basic"
//                value={data.account_number}
//                onChange={(e) => {
//                  handleChange("account_number", e.target.value);
//                }}
//                // label="Name"
//                variant="standard"
//                // InputLabelProps={{ shrink: true }}
//              />
//              </Box>
//      </Box>
//      <Box sx={{width:450,marginTop:2,height:'10%',display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap' ,'@media screen and (max-width: 200px)': {
//      flexDirection: 'column',
//    },
//    '@media screen and (min-width: 201px)': {
//      flexDirection: 'row',
//    }}} >  
//       <Box sx={{width:220,height:'100%' }} >

//        <Typography sx={{color:'#082A49',marginRight:2,marginLeft:2,fontWeight:500,fontSize:20}} >IFSC Code </Typography>
//       </Box>
//       <Box sx={{width:220,height:'100%' }} >

//        <TextField
//                id="outlined-basic"
//                value={data.ifsccode}
//                onChange={(e) => {
//                  handleChange("ifsccode", e.target.value);
//                }}
//                // label="Name"
//                variant="standard"
//                // InputLabelProps={{ shrink: true }}
//                />
//                </Box>
//      </Box>

    
//      <Box sx={{width:'65%',height:40,display:'flex',justifyContent:'center',alignItems:'center'}} >
// <Box sx={{width:100 , height:'100%',backgroundColor:'#144C89',borderRadius:2,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>handlenext()} >
// <Typography sx={{color:'white',cursor:'pointer'}} >Next</Typography>
// </Box>
//      </Box>
     

//    </Box>
//  )
// }
