import { React, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import Styles from "../Styles/AdminDashboard";
import CheckListCard from "../Components/CheckListCard";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
import ChecklistCard2 from "../Components/Dashboard/ChecklistCard2";

export default function CheckList(props) {
  const [data, setData] = useState( [
      {
        "org_name": "sample 1",
        "industry": "Education",
        "owner": "Mr Amit shah",
        "spoc": "Tom hilston",
        "Members": [{"id": 1, "name": "Pramod"},{"id": 2, "name": "Sagar"},{"id": 3, "name": "Sammer"},{"id": 4, "name": "Vishnu"},]
      },
      {
        "org_name": "sample 2",
        "industry": "Services",
        "owner": "Mr Nirmala Shankaracharya",
        "spoc": "Wilson fisk",
        "Members": [{"id": 1, "name": "Sarang"},{"id": 2, "name": "Vaidehi"},{"id": 3, "name": "Samrudi"},{"id": 4, "name": "Vaishali"},]
      },
      {
        "org_name": "sample 2",
        "industry": "Services",
        "owner": "Mr Nirmala Shankaracharya",
        "spoc": "Wilson fisk",
        "Members": [{"id": 1, "name": "Nirmala"},{"id": 2, "name": "Adwin"}]
      },
      {
        "org_name": "sample 1",
        "industry": "Education",
        "owner": "Mr Amit shah",
        "spoc": "Tom hilston",
        "Members": [{"id": 1, "name": "Pramod"},{"id": 2, "name": "Sagar"},{"id": 3, "name": "Sammer"},{"id": 4, "name": "Vishnu"},{"id": 5, "name": "Qrr"},{"id": 6, "name": "owen"}]
      },
      {
        "org_name": "sample 2",
        "industry": "Services",
        "owner": "Mr Nirmala Shankaracharya",
        "spoc": "Wilson fisk",
        "Members": [{"id": 1, "name": "Pramod"},{"id": 2, "name": "Sagar"},{"id": 3, "name": "Sammer"},{"id": 4, "name": "Vishnu"},]
      },
    ]
  );
  
  const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)')
  const [checklists, setchecklists] = useState([]);
  // console.log("all the checklists",checklists)
  const [checkedItems, setCheckedItems] = useState(
    Array(data.length).fill(false)
  );
  const [assignValue, setAssignValue] = useState(""); // State to manage the "Assign" radio button value
  const [showSearchBar, setShowSearchBar] = useState(false); // State for managing the visibility of the search bar
  useEffect(() => {
    // console.log("project id is", projectid);
    const getchecklisttask = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallprojects`,
          {
            user_id: localStorage.getItem("user_id"),
          }
        );
        if (response.data.status === 1) {
          // console.log("response data checklist task: ", response?.data?.data);
          setchecklists(response?.data?.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getchecklisttask();
  }, []);

  //  const [assignid , setAssignId]=useState(0)
  const handlechange = async (key, value) => {
    console.log(key, value, "assignee id ");
    setData({
      ...data,
      [key]: value,
    });
  };
  const [projectid, setprojectid] = useState(0);
  const [taskid, settaskid] = useState(0);

  const handleCheckboxChange = (index, data) => {
    console.log("selected checklist data:", data);
    setprojectid(data.project_id);
    settaskid(data.taskid);
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = [...prevCheckedItems];
      newCheckedItems[index] = !newCheckedItems[index];
      return newCheckedItems;
    });
    setShowSearchBar(false); // Hide the search bar when the checkbox is unchecked
  };

  const handleRadioChange = (value) => {
    setAssignValue(value);
    setShowSearchBar(value === "assign"); // Show the search bar when "Assign" is selected
  };
  const handletaskcomplete = async () => {
    console.log("value of the aasigned task completed", assignValue);
    if (assignValue === "notAssign") {
      let userId = localStorage.getItem("user_id");
      let datas = {
        ...data,
        "id": taskid,
        "description": data.description,
        "user_id": localStorage.getItem("user_id"),
      };
      // console.log(datas , "for completing tasks")
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/completeatskchecklist`,
          datas
        );
        // console.log(response.data.data);
        if (response.data.status === 1) {
          // console.log("task completed: ", response.data?.responseassignee);
          // uploadCategoryImgFile(response.data?.data[0]?.id);
          // alert("task completed successfully !");
          setchecklists((prevData) => [
            ...prevData,
            ...response.data.responseassignee,
          ]);
          setShowSearchBar(false);
          // props.setActive(1);
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (assignValue === "assign") {
      setShowSearchBar(false);
      let userId = localStorage.getItem("user_id");
      let datas={
        ...data,
        "id":taskid,
        "assigned_user_id":data.assignto_id,
        "description":data.description,
        "user_id":userId,
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/changetaskassignee`,
          datas
        );
        // console.log(response.data.data);
        if (response.data.status === 1) {
          // console.log("task completed: ", response.data?.data[0]?.id);
          // uploadCategoryImgFile(response.data?.data[0]?.id);
          const completedTaskId = response.data?.data[0]?.id;
          // console.log("task completed: ", completedTaskId);

          // Remove the completed task from checklists
          // alert("task completed successfully !");
          setchecklists((prevData) => prevData.filter(item => item.taskid !== completedTaskId));
          // setShowSearchBar(false);
          // props.setActive(1);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const [assignto, setAssignto] = useState("");
  useEffect(() => {
    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getprojectteam`,
          {
            organisation: localStorage.getItem("orgn_id"),
            project_id: projectid,
          }
        );
        // console.log(response.data.data, "project team api ");
        if (response.data.status === 1) {
          setAssignto(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
      // getprganisationdata();
    };
    allMembers();
  }, [projectid]);

  const navigateTeams = (item) =>{
    props.setActive(3);
    props.setTaskData(item)
  }

 

  const letterColors = {
    a: "#81b29a",
    b: "#81b29a",
    c: "#81b29a",
    d: "#f2cc8f",
    e: "#f2cc8f",
    f: "#f2cc8f",
    g: "#3d405b",
    h: "#3d405b",
    i: "#3d405b",
    j: "#e07a5f",
    k: "#e07a5f",
    l: "#e07a5f",
    m: "#eaac8b",
    n: "#eaac8b",
    o: "#eaac8b",
    p: "#fe7f2d",
    q: "#e4c1f9",
    r: "#e4c1f9",
    s: "#a1c181",
    t: "#a1c181",
    u: "#a1c181",
    v: "#fe7f2d",
    w: "#fe7f2d",
    x: "#fe7f2d",
    y: "#bf0603",
    z: "#bf0603",
  };

  const getRandomColor = (name) => {
    const firstLetter = name?.charAt(0).toLowerCase();
    return letterColors[firstLetter] || "#808080"; // Default color is gray (#808080)
  };

  const isDarkColor = (color) => {
    const hex = color.substring(1); // Remove the '#' character
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Use the relative luminance formula to determine if the color is dark
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance < 128;
  };
  // return (
  //   <Box sx={{ width: "100%", height: "80%", overflow: "hidden" }}>
  //     <Box>
  //       <Box>
  //         <Typography
  //           style={{
  //             ...Styles.checklistToday,
  //             marginTop: "0%",
  //             marginLeft: "4%",
  //           }}
  //         >
  //           Today
  //         </Typography>
  //       </Box>
  //       <Box
  //         sx={{
  //           display: "flex",
  //           overflowX: "auto",
  //           marginLeft: "4%",
  //           marginTop: "2%",
  //         }}
  //       >
  //         {checklists?.map((item, index) => (
  //           <Box
  //             key={index}
  //             style={{
  //               width: "90%",
  //               display: "flex",
  //               flexDirection: "row",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               marginBottom: 2,
  //             }}
  //           >
  //             <Checkbox
  //               checked={checkedItems[index]}
  //               onChange={() => handleCheckboxChange(index, item)}
  //               style={{ marginLeft: "10px" }}
  //             />
  //             {checkedItems[index] ? (
  //               <Box
  //                 style={{
  //                   ...Styles.checklistTodaytemplate,
  //                   height: "210px",
  //                   marginLeft: "4%",
  //                   border: "3px solid black",
  //                 }}
  //               >
  //                 {/* Content of the new card when checkbox is checked */}
  //                 <RadioGroup
  //                   style={{
  //                     marginLeft: "4%",
  //                     color: "#082A49",
  //                     fontSize: "5px",
  //                     width: "100%",
  //                   }}
  //                   onChange={(e) => handleRadioChange(e.target.value)}
  //                 >
  //                   <Box
  //                     style={{
  //                       display: "flex",
  //                       flexDirection: "row",
  //                       gap: "30px",
  //                     }}
  //                   >
  //                     <FormControlLabel
  //                       value="assign"
  //                       control={<Radio style={{ color: "#082A49" }} />}
  //                       label={
  //                         <Typography
  //                           style={{
  //                             color: "#082A49",
  //                             fontSize: "14px",
  //                             fontWeight: "bold",
  //                           }}
  //                         >
  //                           Assign Next To
  //                         </Typography>
  //                       }
  //                     />
  //                     {showSearchBar && (
  //                       <Box
  //                         style={{
  //                           border: "1px solid #144C89",
  //                           display: "flex",
  //                           width: "130px",
  //                           marginTop: "2%",
  //                           borderRadius: "5px",
  //                         }}
  //                       >
  //                         <FormControl
  //                           size="small"
  //                           style={{
  //                             ...Styles.tdBox1InnerRight,
  //                             border: "2px solid #1282A2",
  //                             boxShadow: "none",
  //                             backgroundColor: "transparent",
  //                             width: "100%",
  //                             height: "100%",
  //                             // marginTop:"1%",
  //                             "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
  //                               {
  //                                 border: "1px solid #1282A2",
  //                                 borderColor: "#1282A2",
  //                               },
  //                           }}
  //                           fullWidth
  //                         >
  //                           <Select
  //                             labelId="demo-simple-select-label"
  //                             id="demo-simple-select"
  //                             value={data.assignto_id}
  //                             //   label={ticketssort ? "" : "Change status"}
  //                             style={{
  //                               ...Styles.selectBox,
  //                               height: "100%",

  //                               "& .css-1d3z3hw-MuiOutlinedInput-project_idnotchedOutline":
  //                                 {
  //                                   border: "1px solid white",
  //                                   borderColor: "white",
  //                                 },
  //                               "& fieldset": {
  //                                 border: "none",
  //                               },
  //                             }}
  //                             size="small"
  //                             onChange={(e) => {
  //                               handlechange("assignto_id", e.target.value);
  //                             }}
  //                           >
  //                             {assignto.map((item) => (
  //                               <MenuItem value={item.id}>{item.name}</MenuItem>
  //                             ))}
  //                           </Select>
  //                         </FormControl>
  //                       </Box>
  //                     )}
  //                   </Box>
  //                   <FormControlLabel
  //                     value="notAssign"
  //                     control={<Radio style={{ color: "#082A49" }} />}
  //                     label={
  //                       <Typography
  //                         style={{
  //                           color: "#082A49",
  //                           fontSize: "14px",
  //                           fontWeight: "bold",
  //                         }}
  //                       >
  //                         Complete Without Assignee
  //                       </Typography>
  //                     }
  //                   />
  //                 </RadioGroup>

  //                 <Typography
  //                   style={{
  //                     ...Styles.profileText1,
  //                     marginTop: "0.5%",
  //                     color: "#082A49",
  //                     fontSize: "18px",
  //                     marginLeft: "4%",
  //                   }}
  //                 >
  //                   Notes
  //                 </Typography>
  //                 <TextField
  //                   value={data.description}
  //                   multiline
  //                   maxRows={3}
  //                   onChange={(e) => {
  //                     handlechange("description", e.target.value);
  //                   }}
  //                   InputLabelProps={{ style: { display: "none" } }}
  //                   InputProps={{
  //                     style: {
  //                       border: "none",
  //                       height: "100%",
  //                       width: "100%",
  //                       color: "#082A49 ",
  //                       fontSize: "14px",
  //                     },
  //                   }}
  //                   sx={{
  //                     "& fieldset": {
  //                       border: "none",
  //                     },
  //                     ...Styles.inputBox,
  //                     width: "90%",
  //                     marginTop: "0.5%",
  //                     borderColor: "#1282A2",
  //                     height: "60px",
  //                     marginLeft: "4% ",
  //                   }}
  //                 />
  //                 <Box style={{ width: "90%", marginTop: "2%" }}>
  //                   <img
  //                     style={{
  //                       display: "flex",
  //                       alignItems: "flex-end",
  //                       alignSelf: "flex-end",
  //                       marginLeft: "auto",
  //                       cursor: "pointer",
  //                     }}
  //                     src={require("../Images/Send.png")}
  //                     onClick={() => handletaskcomplete()}
  //                   />
  //                 </Box>
  //               </Box>
  //             ) : (
  //               <Box
  //                 key={index}
  //                 style={{ ...Styles.checklistTodaytemplate, marginLeft: "4%" }}
  //               >
  //                 <CheckListCard data={item} />
  //               </Box>
  //             )}
  //           </Box>
  //         ))}
  //       </Box>
  //     </Box>
  //     {/* <Box style={{  width: '100%',
  //     display: 'flex',
  //     flexDirection: 'row',
  //     justifyContent: 'left',
  //     overflowX:'auto',
  //     alignItems: 'left', marginTop:'10px', marginLeft:"2%", marginRight:"2%"}}>
  //     {projectdata.map((item, index) => (
  //     <Box style={{  marginTop:'1px' , display:"flex" , minWidth: 150, height: 200,border:"2px solid #082A49", borderRadius: '25px',marginleft:'4%',overflow: 'hidden',backgroundColor:'red', marginRight:"1.5%"}}>  
  //     <Box style={{width:'100%',height:"20%",borderBottom: '3px dashed #082A49 ',overflow: 'hidden',backgroundColor:"#85A1EE"}}>
  //       <img
  //     style={{ display: 'flex', alignItems: 'flex-end', alignSelf: 'flex-end', marginLeft: 'auto', cursor: 'pointer',marginTop:"10%",justifyContent: 'center',marginRight:'2%'}}
  //     src={require("../Images/HDots.png")}></img>
      
  //     </Box>
     
  //     </Box>
  //  ) )}


  //     </Box> */}
  //   </Box>
  // );
  return (
    <Box sx={{width: "100%", height: "auto", }}>
    {checklists?.map((item)=>(
      <Box onClick={()=>{navigateTeams(item)}}>
        <ChecklistCard2 setActive={props.setActive} setTaskDatastatus={props.setTaskDatastatus} setTaskData={props.setTaskData} item={item}/> 
      </Box>
    ))}

    {/* {data?.map((item)=>(<Box sx={{width:MediaQueryOrgCard ? "99%": "90%",marginTop:'1%',backgroundColor:'#F8F8F8',borderRadius:5,boxShadow:5,display:'flex',flexDirection:'row', height:  320 , marginBottom:1, marginLeft: "100px"}}>
    
      <Box sx={{width:'70%',borderRight:'2px dashed #A9A9A9',height:'100%'}}>
        <Box sx={{width:'95%',height:'35%',display:'flex',justifyContent:'center',flexDirection:'column'}} >

    <Typography sx={{color:'#082A49',fontWeight:700,fontSize: "32px",marginLeft:'5%'}} >{item.projectname}
    </Typography>
    <Typography sx={{color:'#A9A9A9',fontWeight:500,fontSize: MediaQueryOrgCard ? 13 : 18,marginLeft:'5%'}} >{item.industry}
    </Typography>
    
        </Box>
        <Box sx={{marginTop: "10px"}}>
        <Typography sx={{color:'#1282A2',fontWeight:500,fontSize: "24px",marginLeft:'5%'}}>Owner - {item.owner}</Typography>
        <Typography sx={{color:'#1282A2',fontWeight:500,fontSize: "24px",marginLeft:'5%'}}>Spoc - {item.spoc}</Typography>
        </Box>
        <Box style={{ width: "100%", height: "12%", display: "flex", marginLeft: "30px", marginTop: "30px" }}>
              {item?.Members?.map(({ id, name }) => {
                const backgroundColor = getRandomColor(name);
                const textColor = isDarkColor(backgroundColor) ? "white" : "black";
            
                return (
                  <Box
                    key={id}
                    style={{
                      width: "53px",
                      height: "53px",
                      borderRadius: "50px",
                      backgroundColor,
                      color: textColor,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: "32px" }}>
                      {name.charAt(0).toUpperCase()}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
      </Box>
      <Box  sx={{width:'100%',height:'auto', display:'flex', flexWrap: "wrap", marginTop: "15px"}} >


      <Box style={{ ...Styles.card, width: "290px", height: "8vh", }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={Styles.innerTicket}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Total Tasks</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>12</Typography>
        </Box>

        <Box style={{ ...Styles.card, width: "290px", height: "8vh", }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{...Styles.innerTicket, backgroundColor:"#FFE4AC",}}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Today's Tasks</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>12</Typography>
        </Box>

        <Box style={{ ...Styles.card, width: "290px", height: "8vh", }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{...Styles.innerTicket, backgroundColor:"#BEC9F3",}}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Assigned Tasks</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>12</Typography>
        </Box>

        <Box style={{ ...Styles.card, width: "290px", height: "8vh", }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{...Styles.innerTicket, backgroundColor:"#BCDCA4",}}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Completed Tasks</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>12</Typography>
        </Box>

        <Box style={{ ...Styles.card, width: "290px", height: "8vh", }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{...Styles.innerTicket, backgroundColor:"#FEB7B1"}}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Pending Tasks</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>12</Typography>
        </Box>
   
      </Box>
    </Box>))} */}
    </Box>
   
  )
}
