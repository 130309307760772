const Styles = {
  mainBox:{
      width: "100%",
      height: "100%",
  },
  upperBox:{
      width: "100%",
      height: "15vh",
      display:"flex",
      justifyContent:"space-between", 
      position:"relative",
  },
  name:{
      fontFamily:"Montserrat",
      color:"#1282A2",
      fontWeight:"bold",
      fontSize:"30px",
      marginTop:"2.4%",
      marginLeft:"4%",
  },
  reqButton: {
      width: "160px",
      height: "40px",
      borderRadius: "10px",
      backgroundColor: "#144C89",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      cursor: "pointer",
      marginTop:"2.4%",
      marginRight:"4%",
    },
    cancelButton:{
      width: "160px",
      height: "40px",
      borderRadius: "10px",
      color:"#144C89",
      border: "2px solid #144C89",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      cursor: "pointer",
      marginTop:"2.4%",

    },
    text3: {
      fontSize: "15px",
      fontFamily: "Montserrat",
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    text4: {
      fontSize: "14px",
      fontFamily: "Montserrat",
      color: "#082A49",
      fontWeight: "bold",
      marginLeft:"4%"
    },
    text5: {
      fontSize: "24px",
      fontFamily: "Montserrat",
      color: "#082A49",
      fontWeight: "bold",
      marginLeft:"4%"
    },
    profileText1:{
      fontSize: "14px",
      color:"#1282A2",
      marginTop:"3%",
      fontFamily: "Montserrat",
    },
    inputBox:{
      width:"70%",
      border: "2px solid #C4C4C480", 
      borderRadius: "14px",
      color:"#C4C4C480",
      marginTop:"1.2%"
    },
    rightInnerBox:{
      width:"100%",
      height:"70%",
      border: "3px solid #E3F2F7",
      marginLeft:"4%",
      marginTop:"3%",
      borderRadius:"14px",
 

    },
    card:{
      marginLeft:"5%",
      width: "300px",
      height: "10vh",
      maxHeight:"20vh",
      marginBottom:"20px",
      backgroundColor:"#F8F8F8",
      borderRadius: "25px",
      boxShadow:"5px 5px 2px rgba(0, 0, 0, 0.2)",
      display:"flex",
      alignItems: "center",
      cursor:"pointer",
    },
    dottedLine:{
      width: "22%",
      height: "92%",
      // backgroundColor:"red",
      borderRight:"2px dashed rgba(169, 169, 169, 1)",
      zIndex: 10
    },
    innerTicket:{
      width: "60%",
      height: "5vh",
      // margin:"auto",
      marginLeft:"-43px",
      backgroundColor:"#85A1EE",
      borderRadius:"12px",
      display:"flex",
      alignItems:"center",
    },
    circle1:{
      width: "20px",
      height: "20px",
      marginLeft:"-11px",
      borderRadius: "150px",
      backgroundColor:"#F8F8F8"
    },
    circle2:{
      width: "10px",
      height: "10px",
      // marginLeft:"1px",
      margin:"auto",
      borderRadius: "150px",
      backgroundColor:"#F8F8F8"
    },
    circle3:{
      width: "20px",
      height: "20px",
      marginLeft:"-8px",
      borderRadius: "150px",
      backgroundColor:"#F8F8F8"
    },
    circleMainBox:{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      marginLeft:"15.2%",
      alignItems:"space-between",
    },
    mainMiddleBox:{
      width: "99%",
      // height:"40%",
      margin:"auto",
      marginLeft:"-1%",
      // backgroundColor:"#999999",
      display: "flex",
      flexWrap: "wrap",
    },
    mainBottomBox:{
      width: "92%",
      height:"30%",
      margin:"auto",
      // backgroundColor:"#999999",
      display: "flex",
      flexWrap: "wrap",
    },
    tableBox2: {
      width: "100%",
      height: 250,
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      // margin: "auto",
      marginBottom: "5px",
      boxShadow: "2px 2px 5px #999999",
      borderRadius: "15px",
     
   
    
    },
    tableContainer: {
      height: "100%", // Subtract the height of the header
      overflowY: "auto", // Allow scrolling for the container
      position: "relative",
     

    },
    table: {
      minWidth: 600,
      width: "100%",
      height:"100%",

    },
    tableHead: {
      height: "60px",
      backgroundColor: "#E3F2F7",
      color: "white",
      position: "sticky",
      top: 0,
      zIndex: 1,
      
    },
    tableCell: {
      padding: "5px 16px",
      height: 5,
      fontFamily: "Montserrat",
      fontSize: "22px",
      borderBottom: "none",
      borderColor:"#E3F2F7",
      color:"#1282A2",
      fontWeight: "bold",
   
    },
    tableText: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      borderColor:"#E3F2F7",
    },
    FormControlBox:{
      width: "30%",
      marginTop:"2%",
      display: "flex",
      justifyContent:"space-around",
    },
    FormControl:{
      width: "200px",
      borderRadius: "10px",
      height: "45px",
      backgroundColor: "#F8F8F8",
      borderColor: "red",
      fontFamily: "Montserrat",
      border:"none"
      // margin:"1%"
    },
    selectBox:{
      borderRadius: "10px",
      border:"none",
      // border: "1px solid #144C89",
      height: "45px",
      fontFamily: "Montserrat",
    },
    ViewTicketCardBox:{
      width: "100%", 
      height: "15vh",
      marginBottom: "20px",
      display: "flex",
      borderRadius:"20px",
      backgroundColor:"#F8F8F8",
      boxShadow:"5px 5px 8px lightgray",
      alignItems: "center",
      justifyContent:"space-between",
      overflow:"hidden",
      transition: 'background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease',
      cursor:"pointer",
    },
    HoveredViewTicketCardBox: {
      transform: 'scale(1.01)',
      // boxShadow: '0px 0px 10px lightgray',
      // backgroundColor: '#edebeb',
    },
    tdMainBox:{
      width: '100%',
      height:"100%",
      display: 'flex',
    },
    tdBox1:{
      width: '77%',
      height:"95%",
      display: 'flex',
      flexDirection:"column",
    },
    tdBox1Inner:{
      width: '100%',
      height:"10%",
      display: 'flex',
      flexDirection:"row",
      justifyContent:"space-around",
      marginTop:"3%",
    },
    tdBox1InnerLeft:{
      width: '70%',
      marginLeft:"5%",
      height:"70%",
      boxShadow:"2px 2px 15px lightgray",
      borderRadius:"10px",
      backgroundColor:"#F8F8F8",
      display:"flex",
      alignItems:"center",
      justifyContent:"space-around",
      overflow:"hidden",  

    },
    tdBox1InnerRight:{
      width: '15%',
      height:"6vh",
      boxShadow:"2px 2px 15px lightgray",
      borderRadius:"10px",
      backgroundColor:"#F8F8F8",
      overflow:"hidden",  

    },
    tdBox1Middle:{
      // marginTop:"2%",
      width: '90%',
      marginLeft:"7.5%",
      height:"11%",
      backgroundColor:"#F8F8F8",
      // margin:"auto",
      display:"flex",
      alignItems: 'center',
      borderRadius:"15px",
      boxShadow:"2px 2px 15px lightgray",
      overflow:"hidden",
    },  
    chatBox:{
      width:"90%",
      height:"55vh",
      marginLeft:"7.5%",
      marginTop:"1%",
      // backgroundColor:"gray",
      overflowY:"auto",
    },
    chatContainer:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    messageContainer:{
      width: "300px",
      overflowY: "auto",
    },
    message :{
      display: "flex",
      flexDirection: "column",
      margin: "8px",
    },
    
    sender :{
      alignSelf: "flex-end",
    },
    
    receiver :{
      alignSelf: "flex-start",
    },
    
    messageContent :{
      backgroundColor: "#e0e0e0",
      padding: "8px",
      borderRadius: "8px",
      maxWidth: "200px",
    },
    
    messageHeader :{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    
    messageName :{
      fontWeight: "bold",
    },
    
    messageTime :{
      color: "#888",
    },
    
    messageText :{
      marginTop: "4px",
    },
    textfieldSender:{
      width: '85%',
      marginTop: "2%",
      marginLeft:"11.5%",
      height:"6vh",
      // border:"1px solid #1282A2",
      borderRadius:"10px",
    },
    tdBox2:{
      width: '23%',
      height:"100%",
      display: 'flex',
      backgroundColor: "#EDEDED",
      flexDirection: 'column',
    },
    tdText1:{
      fontFamily:"Montserrat",
      color:"#082A49",
      fontWeight:"bold",
      marginTop: "4%",
      marginLeft: "25px",
      fontSize:"16px",
    },
    tdText2:{
      fontFamily:"Montserrat",
      color:"#1282A2",
      fontWeight:"bold",
      marginTop: "4%",
      marginLeft: "25px",
      fontSize:"19px",
      overflowY:"scroll",
      height:"60vh",
      scrollbarWidth: "none", /* For Firefox */
    msOverflowStyle: "none", /* For Internet Explorer and Edge */
      

    },
    tdText3:{
        color: "#A9A9A9",
        fontFamily: "Montserrat",
        fontSize: "15px",
        // margin: "6px 0px 0px 6px",
      marginLeft: "25px",
      marginTop: "0px",
    },
statusnotify_changestat:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontFamily: 'Montserrat',
      padding: 30,
      width: 703,
      height: 500,
      backgroundColor: '#FFFFFF',
      borderRadius: '20px',
      boxShadow: 'none',
      pt: 2,
      px: 4,
      pb: 3,
    },
    statusTitle_changestat: {
      color: '#1282A2',
      marginBottom: '10px',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '34.13px',
      alignItems: 'center',
      alignSelf:"center",
    },
    statusText_changestat: {
      color: '#082A49',
      marginRight: '10px',
      display: 'inline-block',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '26.82px',
    },
    statusCurrent_changestat:{
      color: '#BCDCA4',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21.94px',
    },
    statusnotifysave_changestat: {
      color: 'white',
      backgroundColor: '#144C89',
      borderColor: '#144C89',
      borderRadius: '8px',
      width: '100px',
      height: '36px',
      top: '486px',
      left: '301px',
      padding: '8px 30px',
      marginBottom: '10px',
      position: 'absolute',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.5px',
    },
    
    statusnotifycancel_changestat: {
      color: '#144C89',
      backgroundColor: 'white',
      border: '2px solid #144C89',
      borderRadius: '8px',
      width: '100px',
      height: '36px',
      top: '486px',
      left: '420px',
      padding: '8px 30px',
      marginBottom: '10px',
      position: 'absolute',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.5px',
    },
    statusnotifyreason_changestat: {
      width: '210px',
      height: '27px',
      top: '425px',
      left: '445px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '26.82px',
      color: '#082A49',
    },
    customtextfield_changestat: {
      width: '695px',
      height: '150px',
      border: '2px solid #1282A2',
      borderRadius: '20px',
      padding: '10px',
      boxSizing: 'border-box',
      color: '#1282A2',
      backgroundColor: '#FFFFFF',
    },
    statusselectlabel_changestat:{
      width: '220px',
      height: '40px',
      borderRadius: '8px',
      border: '1px solid #1282A2',
      position: 'absolute', // Position the label absolutely
      transform: 'translate(14px, 14px)', // Adjust the translation as needed
    },
    statusnotifyTitlebutton_changestat: {
      display: 'flex',
      // justifyContent: 'space-between',
      marginRight: '20px', // Adjust the marginRight as needed

    },
    statusnotifyXbutton_changestat:{
      color: '#000000',
      cursor: 'pointer',
    },
    statusCurrent_changeassign:{
      color: '#1282A2',
      fontSize: '18px'
    },
    statusnotify_changeassign:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontFamily: 'Montserrat',
      padding: 30,
      width: 691,
      height: 281,
      backgroundColor: '#FFFFFF',
      borderRadius: '20px',
      boxShadow: 'none',
      pt: 2,
      px: 4,
      pb: 3,
    },
    statusnotifysave_changeassign:{
      color: 'white',
      backgroundColor: '#144C89',
      borderColor: '#144C89',
      borderRadius: '8px',
      width: '100px',
      height: '36px',
      top: '286px',
      left: '501px',
      padding: '8px 30px',
      marginBottom: '10px',
      position: 'absolute',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.5px',
    },
    statusnotifycancel_changeassign:{
      color: '#144C89',
      backgroundColor: 'white',
      border: '2px solid #144C89',
      borderRadius: '8px',
      width: '100px',
      height: '36px',
      top: '286px',
      left: '611px',
      padding: '8px 30px',
      marginBottom: '10px',
      position: 'absolute',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.5px',
    },
    profileMain:{
      width: '90%',
      height: '100%',
      margin:"auto",
      // backgroundColor:"gray",
      display: "flex",
      flexDirection: "row",
    },
    leftProfileBox:{
      width: '60%',
      height: '100%',
    },
    rightProfileBox:{
      width: '40%',
  
    },
  //   name:{
  //     fontFamily:"Montserrat",
  //     color:"#1282A2",
  //     fontWeight:700,
  //     fontSize:"32px",
  //     marginTop:"2.4%",
  // },
  formfonts:{
      fontFamily: "Montserrat",
      color:"#082A49",
      fontWeight:500,
      fontSize:'22px',
      lineHeight:'26.82px'
    },

    // statusnotifysave_changestat: {
    //   color: 'white',
    //   backgroundColor: '#144C89',
    //   borderColor: '#144C89',
    //   borderRadius: '8px',
    //   width: '100px',
    //   height: '36px',
    //   top: '846px',
    //   left: '341px',
    //   padding: '8px 30px',
    //   marginBottom: '10px',
    //   position: 'absolute',
    //   fontFamily: 'Montserrat',
    //   fontWeight: 700,
    //   fontSize: '16px',
    //   lineHeight: '19.5px',
    // },
    
    // statusnotifycancel_changestat: {
    //   color: '#144C89',
    //   backgroundColor: 'white',
    //   border: '2px solid #144C89',
    //   borderRadius: '8px',
    //   width: '100px',
    //   height: '36px',
    //   top: '846px',
    //   left: '451px',
    //   padding: '8px 30px',
    //   marginBottom: '10px',
    //   position: 'absolute',
    //   fontFamily: 'Montserrat',
    //   fontWeight: 700,
    //   fontSize: '16px',
    //   lineHeight: '19.5px',
    // },
    formField: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    formField1: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& p, & FormControl': {
        marginRight: '10px', // Adjust this value to your desired spacing
      },
  },
    // statusselectlabel_changestat:{
    //   width: '220px',
    //   height: '40px',
    //   borderRadius: '8px',
    //   border: '1px solid #1282A2',
    // },
    personcancelcheck:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '30px',
      height: '30px',
      borderRadius: '3px',
      backgroundColor: '#E3F2F7',
      color: '#144C89',
      fontWeight: 'bold',
    },
    name1:{
      fontFamily:"Montserrat",
      color:"#1282A2",
      fontWeight:700,
      fontSize:"32px",
      marginTop:"2.4%",
  },
 formfonts:{
     fontFamily: "Montserrat",
     color:"#082A49",
     fontWeight:500,
     fontSize:'22px',
     lineHeight:'26.82px'
   },

    statusnotifysave_changestat1: {
      color: 'white',
      backgroundColor: '#144C89',
      borderColor: '#144C89',
      borderRadius: '8px',
      width: '100px',
      height: '36px',
      //top: '846px',
      //left: '341px',
      padding: '8px 30px',
      //marginBottom: '10px',
      position: 'absolute',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.5px',
    },
   
    statusnotifycancel_changestat1: {
      color: '#144C89',
      backgroundColor: 'white',
      border: '2px solid #144C89',
      borderRadius: '8px',
      width: '100px',
      height: '36px',
      //top: '846px',
      //left: '451px',
      padding: '8px 30px',
      //marginBottom: '10px',
      position: 'absolute',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.5px',
    },
   formField: {
     display: 'flex',
     flexDirection: 'row',
     alignItems: 'center',
   },
   formField1: {
     display: 'flex',
     flexDirection: 'row',
     alignItems: 'center',
     '& p, & FormControl': {
       marginRight: '10px',  //Adjust this value to your desired spacing
     },
   },
 formField2: {
   display: 'flex',
   flexDirection: 'row',
   },
    statusselectlabel_changestat1:{
      width: '220px',
      height: '40px',
      borderRadius: '8px',
      border: '1px solid #1282A2',
    },
   addcancelcheck:{
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center',
     width: '30px',
     height: '30px',
     borderRadius: '3px',
     backgroundColor: '#E3F2F7',
     color: '#144C89',
     fontWeight: 'bold',
   },
   spocText: {
     fontFamily: 'Montserrat',
     fontWeight: 500,
     fontSize: '20px',
     lineHeight: '24.38px',
     color: '#082A49',
     height: '24px',
     width:"100%",
     justifyContent:"center",
     alignItems:"center"
   },
   
   proceedText: {
     fontFamily: 'Montserrat',
     fontWeight: 500,
     fontSize: '18px',
     lineHeight: '21.94px',
     color: '#1282A2',
     height: '22px',
   },
   SPOCComplete:{
      //top: '846px',
      //left: '61%',
      position: 'relative',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.5px',
   },
   checklistToday:{
      width: '75px',
      height: '29px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '24px',
      color: '#082A49',
      lineHeight: '29.26px',
   },
   checklistTodaytemplate:{
    width: '361px',
    height: '230px',
    borderRadius: '25px',
    backgroundColor: '#F8F8F8',
    boxShadow: '5px 5px 4px 0px rgba(0, 0, 0, 0.2)', 
   },
   checklistTodaytemplatetitle:{
      width: '312px',
      height: '24px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '20px',
      color: '#082A49',
   
      display:"flex",
      alignItems: 'center',
      justifyContent: 'left',
      alignSelf:"center",
      marginTop:"4%",
   },
    checklistTodaytemplatedescription:{
    width: '312px',
    height: '100px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '16px',
    color: '#082A49',

    marginTop: '7%',
  // Add this line for bottom border

   },
   checklistViewAll:{
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '20px',
    color: '#082A49',
    lineHeight: '24.38px',
   }
}
export default Styles;