import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import EditDetails from "./EditDetails";
import useMediaQuery from '@mui/material/useMediaQuery';
import ViewEmploye from './ViewEmploye';
import ViewProjects from './ViewProjects';
import ViewBankDetails from './ViewBankDetails';
import axios from 'axios';


export default function OrganisationCard(props) {
  const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)')
  const [editDetailsData, setEditDetailsData] = React.useState(null);
  const { updateRefetchdata, updatedataforedit } = props;
  const [open, setOpen] = React.useState(false);
  const [empopen, setempOpen] = React.useState(false);
  const [projrctopen, setprojrctOpen] = React.useState(false);
  const [bnkopen, setbnkOpen] = React.useState(false);


  const handlenext =async(modified)=>{
    // console.log("updated data",datas)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addorganisationbank`,
        modified
      );
      //console.log(response.data)
      if (response.data.status===1) {
        props.getsystemdata();
        //setEdit(false);
      }
    } catch (e) {
      // console.log(e);
    }
    // props.setcardnumber(3)
  }

    const handlebankdetails=async()=>{
      setbnkOpen(true);
      setEditDetailsData(props.data);

    }
    const handleemployees=async()=>{
      setempOpen(true)
      setEditDetailsData(props.data);

    }
    const handleprojects=async()=>{
      setprojrctOpen(true)
      setEditDetailsData(props.data);
    }
    const handleDataUpdate = (updatedData) => {
      setEditDetailsData(updatedData);
    };
    
    
    const handleOpen = () => {
      setOpen(true);
      setEditDetailsData(props.data);

    };
    const handleDelete = async() => {
      updateRefetchdata(props.data.id);

    };
  return (

    <Box sx={{width:MediaQueryOrgCard ? "99%": "85%",marginTop:'1%',backgroundColor:'#F8F8F8',borderRadius:5,boxShadow:5,display:'flex',flexDirection:'row', height:  MediaQueryOrgCard ? 320: 270 , marginBottom:1}}>
    
      <Box sx={{width:'100%',borderRight:'2px dashed #A9A9A9',height:'100%'}}>
        <Box sx={{width:'95%',height:'35%',display:'flex',justifyContent:'center',flexDirection:'column'}} >

    <Typography sx={{color:'#082A49',fontWeight:700,fontSize: MediaQueryOrgCard ? 18 : 18,marginLeft:'5%'}} >{props?.data?.name}</Typography>
    <Typography sx={{color:'#A9A9A9',fontWeight:500,fontSize: MediaQueryOrgCard ? 13 : 18,marginLeft:'5%'}} >{props?.data?.type}</Typography>
    
        </Box>
        <Box sx={{width:'95%',height:'50%',marginLeft:'1%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',flexWrap:'wrap'}} >

{/* <Typography sx={{color:'#082A49',fontWeight:700,fontSize:32}} >Awake Origins</Typography> */}
<Box sx={{width:MediaQueryOrgCard ? 120: 170,height:40,border:3,borderColor:'#144C89',borderRadius:3 , display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handlebankdetails} >
<Typography sx={{ color:'#144C89',fontWeight:  900,fontSize: MediaQueryOrgCard ?10 : 16, cursor: "pointer"}}>View Bank Details</Typography>
</Box>

<Box sx={{width:MediaQueryOrgCard ? 120: 170,height:40,border:3,borderColor:'#144C89',borderRadius:3 , display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handleemployees} >
<Typography sx={{color:'#144C89',fontWeight:900,fontSize: MediaQueryOrgCard ?10 : 18, cursor: "pointer"}}>View Employees</Typography>
</Box>

<Box sx={{width:MediaQueryOrgCard ? 120: 170,height:40,border:3,borderColor:'#144C89',borderRadius:3 , display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handleprojects}>
<Typography sx={{color:'#144C89',fontWeight:900,fontSize: MediaQueryOrgCard ?10 : 18, cursor: "pointer"}}>View Project</Typography>
</Box>

    </Box>
      </Box>
      <Box  sx={{width: MediaQueryOrgCard?'100%':'40%',height:'100%', display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-evenly'}} >
      {localStorage.getItem('role') === "admin" &&
        <Box sx={{display:'flex',alignItems:'right', justifyContent:'space-evenly', width: "20%", marginRight: "4%", marginLeft: 'auto'}}>
      <img style={{position:'relative', cursor: "pointer" }} src={require("../../Images/Iconedit.png")} onClick={handleOpen} />
      <img style={{position:'relative', cursor: "pointer" }} src={require("../../Images/Icondelete.png")} onClick={handleDelete} />
      </Box>
      }
      <Box sx={{width:'80%',height:30 , display:'flex',flexDirection:'row' , alignItems:'center'}}>
    <img style={{marginLeft:10}} src={require("../../Images/phoneblue.png")} />
    <Typography sx={{ width:"100%",color:'#082A49',fontWeight:500,marginLeft:'2%',fontSize: MediaQueryOrgCard ?12 : 18}} >+{props.data.number}</Typography>
    

    </Box>

    <Box sx={{ width: '80%', height: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <img style={{ marginLeft: 10 }} src={require("../../Images/emailblue.png")} />
  <div style={{ flex: 1, overflowWrap: 'break-word', wordBreak: 'break-all' }}>
    <Typography sx={{ width: MediaQueryOrgCard ? "100%" : "100%", color: '#082A49', fontWeight: 500, marginLeft: '2%', fontSize: MediaQueryOrgCard ? 12 : 18 }}>
      {props.data.email}
    </Typography>
  </div>
</Box>

   <Box sx={{ width: '80%', height: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <img style={{ marginLeft: 10 }} src={require("../../Images/locationblue.png")} />
  <div style={{ flex: 1, overflowWrap: 'break-word', wordBreak: 'break-all' }}>
  <Typography sx={{ width: MediaQueryOrgCard ? "100%" : "100%", color: '#082A49', fontWeight: 500, marginLeft: '2%', fontSize: MediaQueryOrgCard ? 12 : 18 }}>
      {props.data.location}
    </Typography>
  </div>
</Box>
    
   
      </Box>
     
      {empopen && <ViewEmploye open={empopen} setOpen={setempOpen} data={editDetailsData}  />}
      {projrctopen && <ViewProjects open={projrctopen} setOpen={setprojrctOpen} data={editDetailsData}  handleEditProject={props.handleEditProject} handleOpenProject={props.handleOpenProject}/>}
      {bnkopen && <ViewBankDetails open={bnkopen} setOpen={setbnkOpen} data={editDetailsData}  handlenext={handlenext}/>}
     
      {open && <EditDetails open={open} setOpen={setOpen} data={editDetailsData}  updatedataforedit={updatedataforedit}/>}
    </Box>
  
   
  )
}


// <img style={{position:'relative'}} src={require("../../Images/threeDots.png")} onClick={handleOpen} />
// const styles = {
//   main: {
//     width: '85%',
//     height: 250,
//     marginTop: '1%',
//     backgroundColor: '#F8F8F8',
//     borderRadius: 5,
//     boxShadow: 5,
//     display: 'flex',
//     flexDirection: 'row'
//   },
//   main2: {
//     width: '65%',
//     borderRight: '2px dashed #A9A9A9',
//     height: '100%',
//   },
//   headingContainer: {
//     width: '95%',
//     height: '50%',
//     display: 'flex',
//     justifyContent: 'center',
//     flexDirection: 'column'
//   },
//   heading: {
//     color: '#082A49',
//     fontWeight: 700,
//     fontSize: 32,
//     marginLeft: '5%'
//   },
//   subHeading: {
//     color: '#A9A9A9',
//     fontWeight: 500,
//     fontSize: 22,
//     marginLeft: '5%'
//   },
//   actionsContainer: {
//     width: '95%',
//     height: '50%',
//     marginLeft: '2%',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-around',
//     alignItems: 'center',
//     flexWrap: 'wrap'
//   },
//   actionButton: {
//     width: 170,
//     height: 40,
//     border: 3,
//     borderColor: '#144C89',
//     borderRadius: 3,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     cursor: 'pointer'
//   },
//   actionButtonText: {
//     color: '#144C89',
//     fontWeight: 900,
//     fontSize: 18
//   },
//   contactDetailsContainer: {
//     width: '33%',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'space-evenly'
//   },
//   contactDetailsItem: {
//     width: '100%',
//     height: 30,
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center'
//   },
//   contactIcon: {
//     marginLeft: 10
//   },
//   contactText: {
//     color: '#082A49',
//     fontWeight: 500,
//     marginLeft: '2%',
//     fontSize: '100%'
//   }
 
// };

