import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Styles from "../../Styles/AdminDashboard";
import ProjectDetails from './ProjectDetails';
import ProjectTeam from './ProjectTeam';
import ProjectAttachments from './ProjectAttachments';
import axios from 'axios';
// import ContactDetails from './ContactDetails';
// import BankDetails from './BankDetails';
// import EmployeeDetails from './EmployeeDetails';

export default function CreateProjects(props) {
    let colorblue=false
    const [organizationData, setOrganizationData] = useState(null);
    const [data, setData] = useState({});
    const [save, setSave] = useState(false);
    const [cardnumber , setcardnumber]=useState(1)
    const [ usersarray,setusersarray]=useState([])
    const [radiovalue, setradiovalue] = React.useState('');
    const[difforn , setDifforn]=useState([])
    const[txtturn , setTxtTurn]=useState({})
    const[txtamc , setTxtAMC]=useState({})
    const[attachedfile, setAttachmentedFile] = useState([])
    const [insertlink, setInsertLink] = useState([{ id: 1, name: "" }]);
    const [insertitems, setInsertItems] = React.useState('');
    const [AMCData, setAMCData] = useState([]);
    const [turnkeysData, setTurnkeysData] = useState([]);
    console.log('Data got  is ', turnkeysData);
    //console.log('Data on the createproject', data)
    //console.log('link inserted are',insertlink);
    //console.log('Data on the createproject', props)
    useEffect(()=>{
      if(save){
        if(props.row){
          handleupdateProjectData();
        }
        else{
          handlesaveproject();
        }
      }
        },[save])
    const handleSave = () =>{
      props.setActive(3);
    }
    //console.log('THe data is ',data);
    const handleupdateProjectData = async () => {
      if(data.type === 'AMC'){
        let i = 1;
        for (const key in txtamc) {
          const frequencyKey = `checklist_freq_${i}`;
          const nameKey = `bucket-textfield-${i}`;
      
          const txtAWCData = {
            "user_id": localStorage.getItem("user_id"),
            "project_id": props.row.project_id,
            "frequency": data[frequencyKey],  // Get the frequency from data
            "name": txtamc[nameKey]?.textfield,
            "end_date":data.end_date  // Get the name from textdataAMC
          };
      
          try {
            const txtturnResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/createchacklist`,
              txtAWCData
            );
            //console.log(txtturnResponse);
            // Handle the response as needed
          } catch (e) {
            //console.error("Error in creating checklist", e);
          }
          i++;
        }
        for(const key in AMCData){
          const AMCDatadata = {
            "frequency": AMCData[key]?.frequency,  // Get the frequency from data
            "name": AMCData[key]?.name,
            "id":AMCData[key]?.chkid            // Get the name from textdataAMC
          };
          try {
            const AMCDataResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/updatechacklist`,
              AMCDatadata
            );
            //console.log(txtturnResponse);
            // Handle the response as needed
            if (AMCDataResponse.data.status === 1) {
              //setData(response1.data.data);
              console.log("Updated successfully",AMCDataResponse)
              //alert("updated successfully");
            } else {
              console.error("Failed to update user data:", AMCDataResponse.data.data);
            }
          } catch (e) {
            console.error("Error in creating checklist", e);
          }
        }
      }


      if(data.type === 'Turnkey'){
        for (const key in txtturn) {
          const txtturnData = {
            "name": txtturn[key], // Access the value directly
            "organisation": props.row.organisation,
            "project_id": props.row.project_id,
            "end_date":props.row.end_date
          };
         // console.log('I have this in turn', txtturn[key]);
          try {
            const txtturnResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/createturnkey`,
              txtturnData
            );
        
            //console.log(txtturnResponse);
          } catch (e) {
            //console.error("Error in inserting txtturn ", e);
          }
      }

      for(const key in turnkeysData){
        const turnkeysDatadata = {
          "name": turnkeysData[key]?.name,
          "id":turnkeysData[key]?.id            // Get the name from textdataAMC
        };
        try {
          const turnkeysDataResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/updateturnkeylist`,
            turnkeysDatadata
          );
          //console.log(txtturnResponse);
          // Handle the response as needed
          if (turnkeysDataResponse.data.status === 1) {
            //setData(response1.data.data);
            console.log("Updated successfully",turnkeysDataResponse)
            //alert("updated successfully");
          } else {
            console.error("Failed to update user data:", turnkeysDataResponse.data.data);
          }
        } catch (e) {
          console.error("Error in creating checklist", e);
        }
      }
    }
    const orgId = props.row.project_id;

    try {
      for (const fileName of attachedfile) {
        //console.log('This is file name',fileName);
        const response1 = await axios.post(
          `${process.env.REACT_APP_API_URL}/addprojectdocpath`,
          {
            "project_id": orgId,
            "file_name": fileName,
            "user_id": localStorage.getItem("user_id")
          }
        );
    
        if (response1.data.status === 1) {
          //console.log('Successful uploaded name:', fileName);
        }
      }
    } catch (e) {
      //console.log(e);
    }
      for(const key in insertlink){
        if(key < insertitems){
      let insertDatas ={
          "id": insertlink[key].id,
          "name": insertlink[key].name,
          "link": insertlink[key].link,
          "project_id" : props.row.project_id
      }
      console.log('Inserted Data is',insertDatas);
      try {
        const response1 = await axios.post(
          `${process.env.REACT_APP_API_URL}/updateprojectslink`,
          insertDatas
        );
  
        if (response1.data.status === 1) {
          //setData(response1.data.data);
          console.log("Updated successfully",response1)
          //alert("updated successfully");
        } else {
          console.error("Failed to update user data:", response1.data.data);
        }
      } catch (error) {
        // console.error("Error updating user data:", error);
      }
    }
    else{
      const insertlinkData = {
        "project_id": props.row.project_id,
        "name": insertlink[key].name,
        "link": insertlink[key].link,
      };
     // console.log('I have this in turn', txtturn[key]);
     if(insertlink[key].link===undefined){
      continue;
      }
      try {
        const insertlinkResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/addprojectslink`,
          insertlinkData
        );
        if (insertlinkResponse.data.status === 1) {
          console.log("Insertion successful");
        }
        //console.log(txtturnResponse);
      } catch (e) {
        //console.error("Error in inserting txtturn ", e);
      }
    }
  }
      let datas = {
        ...data,
        owner: props.row.owner,
        spoc: props.row.spoc,
        id: props.row.project_id,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/updateproject`,
          datas
        );
  
        if (response.data.status === 1) {
          setData(response.data.data);
            // console.log("organisations",response)
          alert("updated successfully");
          handleSave();
        } else {
          // console.error("Failed to update user data:", response.data.data);
        }
      } catch (error) {
        // console.error("Error updating user data:", error);
      }
    };
    //console.log('I am being called',cardnumber );
    const handlesaveproject = async()=>{
      let datas={
        ...data,
        // "created_oragnisation_id":localStorage.getItem("orgid")
        "created_organisation_id":localStorage.getItem("orgn_id")
    
      }
      // if (
      //   !errorprojname ||
      //   !errorprojdescription
      // ) {
      //   return;
      // }
      console.log(data.end_date , "end date of the project")
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/addproject`,
            datas
          );
      
          if (response.data.status === 1) {
            alert("New project added successfully")
            const orgId = response.data?.data[0]?.id;
      
            if (orgId) {
              // Iterate through usersarray and make requests
              for (let i = 0; i < usersarray.length; i++) {
                //console.log("users ayyai",usersarray)
    
                const userData = {
                  ...datas,
                  "username": usersarray[i].name, // Adjust the property accordingly
                  "organisation": localStorage.getItem("orgn_id"),
                  "user_id": usersarray[i].id,
                  "project_id": orgId,
                  "project_name": data.name,
                  "type": data.type
                };
      
                try {
                  const userResponse = await axios.post(
                    `${process.env.REACT_APP_API_URL}/addusertoproject`,
                    userData
                  );
      
                  //console.log("addusertoproject inserted cbs", userResponse);
      
                  if (userResponse.data.status !== 1) {
                    //console.error("Error inserting user data");
                    // Handle error if needed
                  }
                } catch (e) {
                  //console.log(e);
                }
              }
      
              // Iterate through difforn and make requests
              for (let j = 0; j < difforn.length; j++) {
                //console.log("users ayyai",difforn[j].id,difforn)
                const diffornData = {
                  ...datas,
                  "username": difforn[j], // Assuming diffornName is the username
                  "organisation": data.organisation, // Assuming diffornName is the organisation
                  "user_id": difforn[j].id, // Adjust this if needed
                  "project_id": orgId, // Adjust this if needed
                  "project_name": data.name,
                  "type": data.type
                };
      
                try {
                  const diffornResponse = await axios.post(
                    `${process.env.REACT_APP_API_URL}/addusertoproject`,
                    diffornData
                  );
      
                  //console.log("addusertoproject inserted org", diffornResponse);
                  try {
                    const diffornResponse = await axios.post(
                      `${process.env.REACT_APP_API_URL}/addspoctoproject`,
                      {
                        "id":orgId,
                        "owner":localStorage.getItem("user_id"),
                        "spoc":radiovalue
                      }
                    );
        
                    //console.log("addspoctoproject inserted", diffornResponse);
                      
                    if (diffornResponse.data.status !== 1) {
                      //console.error("Error inserting difforn data");
                      // Handle error if needed
                    }
                  } catch (e) {
                   // console.log(e);
                  }
                  if (diffornResponse.data.status !== 1) {
                    //console.error("Error inserting difforn data");
                    // Handle error if needed
                  }
                } catch (e) {
                 // console.log(e);
                }
              }
              if(data.type=== 'Turnkey'){
              //console.log('I have this in turn', txtturn);
              for (const key in txtturn) {
                const txtturnData = {
                  "name": txtturn[key], // Access the value directly
                  "organisation": data.organisation,
                  "project_id": orgId,
                  "end_date":data.end_date
                };
               // console.log('I have this in turn', txtturn[key]);
                try {
                  const txtturnResponse = await axios.post(
                    `${process.env.REACT_APP_API_URL}/createturnkey`,
                    txtturnData
                  );
              
                  //console.log(txtturnResponse);
                } catch (e) {
                  //console.error("Error in inserting txtturn ", e);
                }
              }
            }
            if(data.type === 'AMC'){
              let i = 1;
              for (const key in txtamc) {
                const frequencyKey = `checklist_freq_${i}`;
                const nameKey = `bucket-textfield-${i}`;
            
                const txtAWCData = {
                  "user_id": localStorage.getItem("user_id"),
                  "project_id": orgId,
                  "frequency": data[frequencyKey],  // Get the frequency from data
                  "name": txtamc[nameKey]?.textfield,
                  "end_date":data.end_date  // Get the name from textdataAMC
                };
            
                try {
                  const txtturnResponse = await axios.post(
                    `${process.env.REACT_APP_API_URL}/createchacklist`,
                    txtAWCData
                  );
                  //console.log(txtturnResponse);
                  // Handle the response as needed
                } catch (e) {
                  //console.error("Error in creating checklist", e);
                }
                i++;
              }
            }
            try {
              for (const fileName of attachedfile) {
                //console.log('This is file name',fileName);
                const response1 = await axios.post(
                  `${process.env.REACT_APP_API_URL}/addprojectdocpath`,
                  {
                    "project_id": orgId,
                    "file_name": fileName,
                    "user_id": localStorage.getItem("user_id")
                  }
                );
            
                if (response1.data.status === 1) {
                  //console.log('Successful uploaded name:', fileName);
                }
              }
            } catch (e) {
              //console.log(e);
            }
            for (const key in insertlink) {
              const insertlinkData = {
                "project_id": orgId,
                "name": insertlink[key].name,
                "link": insertlink[key].link,
              };
              //console.log('link is', insertlink[key].link);
             if(insertlink[key].link===undefined){
                continue;
             }

              try {
                const insertlinkResponse = await axios.post(
                  `${process.env.REACT_APP_API_URL}/addprojectslink`,
                  insertlinkData
                );
                if (insertlinkResponse.data.status === 1) {
                  console.log("Insertion successful");
                }
                //console.log(txtturnResponse);
              } catch (e) {
                //console.error("Error in inserting txtturn ", e);
              }
            }
            }
            handleSave();
          }
        } catch (e) {
          //console.log(e);
        }
      };
    
    return (
      <Box sx={{width:'100%',height:'95%'}} >
  <Box sx={{width:'100%',height:45,marginTop:'2%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
        <Typography style={{...Styles.name,marginTop:0,marginLeft:"4%",}} >{props.row ? "Edit Project" : "Add Project"}</Typography>
        
        </Box>
  
        {/* 3 dots with the line */}
        <Box sx={{width:'100%',height:90,borderBottom:'2px dashed #D9D9D9',display:'flex',justifyContent:'center',alignItems:'center'}} >
  <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'70%'}} >
  
   {
  
     cardnumber===1 ?
  //<Box sx={{height:15,width:15,border:2,borderColor:'#1282A2',borderRadius:20,backgroundColor:colorblue?'#1282A2':'white'}}>
    <img src={require('../../Images/bluefilledcircle.png')}/>
  //</Box>
  :
  <img src={require('../../Images/tickcircle.png')}/>
  }
  
  
  
  <Typography sx={{color:'#1282A2',marginTop:1}} >Project Detail</Typography>
  </Box>
  
  <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',height:'70%',width:'20%'}} >
  <Box sx={{width:'100%',border:1,borderColor:'#1282A2',backgroundColor:colorblue?'#1282A2':'white',marginTop:'4%'}}>
  </Box>
  </Box>
  
  <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'70%'}} >
  
  {
   cardnumber===1 &&
  <Box sx={{height:15,width:15,border:2,borderColor:'#1282A2',borderRadius:20,backgroundColor:colorblue?'#1282A2':'white'}}>
  </Box>
  }
  {cardnumber === 2 ? (
  <img src={require('../../Images/bluefilledcircle.png')} />
) : cardnumber !== 1 && (
  <img src={require('../../Images/tickcircle.png')} />
)}
  <Typography sx={{color:'#1282A2',marginTop:1}} >Teams&nbsp;and&nbsp;SPOC</Typography>
  </Box>
  
  <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',height:'70%',width:'20%'}} >
  <Box sx={{width:'100%',border:1,borderColor:'#1282A2',backgroundColor:colorblue?'#1282A2':'white',marginTop:'4%'}}>
  </Box>
  </Box>
  
  <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'70%'}} >
  
  {cardnumber===2 || cardnumber===1 ?
  <Box sx={{height:15,width:15,border:2,borderColor:'#1282A2',borderRadius:20,backgroundColor:colorblue?'#1282A2':'white'}}>
  </Box>
  :
  <img src={require('../../Images/bluefilledcircle.png')}/>
  }
  <Typography sx={{color:'#1282A2',marginTop:1}} >Attachments</Typography>
  </Box>
  
        </Box>
  
        
          {
            cardnumber===1 &&
            <Box sx={{width:'auto',height:'60%',display:'flex',alignItems:'flex-start'}} >
  <ProjectDetails setcardnumber={setcardnumber} setOrganizationData={setOrganizationData} setData={setData} row={props.row} setTxtTurn={setTxtTurn} setTxtAMC={setTxtAMC} setAMCData={setAMCData} setTurnkeysData={setTurnkeysData}/>
  </Box>
          }
            {cardnumber===2 &&
            <Box sx={{width:'auto',height:'60%',alignItems:'flex-start'}} >
  <ProjectTeam setcardnumber={setcardnumber} organizationData={organizationData} setusersarray={setusersarray} setradiovalue={setradiovalue} setDifforn={setDifforn} row={props.row}/>
                </Box>
          }
          {cardnumber===3 &&
            <Box sx={{width:'auto',height:'60%',alignItems:'flex-start'}} >
    <ProjectAttachments project_id={props?.row?.project_id} cardnumber={cardnumber} setcardnumber={setcardnumber} setSave={setSave} setAttachmentedFile={setAttachmentedFile} setInsertLink={setInsertLink} setInsertItems={setInsertItems}/>
            </Box>
          }
      </Box>
    )
  }