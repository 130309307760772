
import {
    Box,
    Typography,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Styles from "../../Styles/AdminDashboard";
import axios from "axios";
  
  export default function ViewEmploye(props) {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState({"bank_details": props?.data?.bank_details,
    "account_number": props?.data?.account_number,
    "ifsccode": props?.data?.ifsccode,
    "id": props?.data?.id
    }); 
    const [edit,setEdit] = useState(false);
    const handleChanges = (key, value) => {
      //console.log(key,value);
      setData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    };
 
    const openedit = () => {
      setEdit(true);
    }
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setEdit(false);
      props.setOpen(false);
    };
   
    const [status, setStatus] = React.useState("");

    const handleupdatenext =async()=>{
      props.handlenext(data);
      handleClose();
    }
  
    const handleChange = (event) => {
      setStatus(event.target.value);
    };
    // console.log(data,"employees")
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50%",
        }}
      >
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          {edit ? 
           <Box style={{...Styles.statusnotify_changestat,width:502, height: 300,}}>
           <Box style={{ ...Styles.statusnotifyTitlebutton_changestat, display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography style={{
                    color: '#1282A2',
                    marginBottom: '10px',
                    fontWeight: 700,
                    fontSize: '28px',
                    lineHeight: '34.13px',
                  }}>
                    Edit Bank Details
                  </Typography>
                </Box>
                <Typography
                  onClick={handleClose}
                  style={{ ...Styles.statusnotifyXbutton_changestat, cursor: 'pointer' }}
                >
                  X
                </Typography>
              </Box>
           <Box
             style={{
               width: '100%',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
               alignItems: 'center',
               height: '60%',
               overflow: 'auto',
               marginTop: "15px",
               //border: '1px solid'
             }}
           >
               <Box sx={{width:'100%',height:'10%',display:'flex',flexDirection:'row',alignItems:'center'}} >
               
               <Typography sx={{fontSize: "20px"}}>Bank&nbsp;Name&nbsp;-</Typography>          

               <TextField
                  sx={{
                    //width: MediaQueryOrgCard ? '100%' : '30%',
                    marginLeft: "34px"
                  }}
                    id="outlined-basic"
                    value={data?.bank_details}
                    onChange={(e) => handleChanges('bank_details', e.target.value)}
                    variant="standard"
                    fullWidth
                  />
               </Box>
               <Box sx={{width:'100%',height:'10%',display:'flex',flexDirection:'row',alignItems:'center', marginTop: '5%'}} >
               
              <Typography sx={{fontSize: "20px"}}>Bank&nbsp;Number&nbsp;-</Typography>   

              <TextField
                  sx={{
                    //width: MediaQueryOrgCard ? '100%' : '30%',
                    marginLeft: "14px"
                  }}
                    id="outlined-basic"
                    value={data?.account_number}
                    onChange={(e) => handleChanges('account_number', e.target.value)}
                    variant="standard"
                    fullWidth
                  />       
              </Box>
              <Box sx={{width:'100%',height:'10%',display:'flex',flexDirection:'row',alignItems:'center', marginTop: '5%'}} >
              
              <Typography sx={{fontSize: "20px"}}>IFSC&nbsp;Code&nbsp;-</Typography>          

              <TextField
                  sx={{
                    //width: MediaQueryOrgCard ? '100%' : '30%',
                    marginLeft: "36px"
                  }}
                    id="outlined-basic"
                    value={data?.ifsccode}
                    onChange={(e) => handleChanges('ifsccode', e.target.value)}
                    variant="standard"
                    fullWidth
                  />          
           </Box>
           </Box>
           <Box>
           <Button
                variant="contained"
                style={{...Styles.statusnotifysave_changestat,top: '306px', left: '31px',}}
                onClick={handleupdatenext}
                >
                Save
              </Button>
           </Box>

 
          
         </Box>
          :
          <Box style={{...Styles.statusnotify_changestat,width:502, height: 300,}}>
            <Box style={{ ...Styles.statusnotifyTitlebutton_changestat, display: 'flex', justifyContent: 'space-between' }}>
                <Box style={{display: "flex"}}>
                  <Typography style={{
                    color: '#1282A2',
                    marginBottom: '10px',
                    fontWeight: 700,
                    fontSize: '28px',
                    lineHeight: '34.13px',
                  }}>
                    Bank Details
                  </Typography>
                  <img
                    style={{ cursor: "pointer", width: "20px", height: "20px", marginLeft: "10px", marginTop: "6px" }}
                    onClick={() => openedit()}
                    src={require("../../Images/Iconedit.png")}
                    alt="Edit Icon"
                  />
                </Box>
                <Typography
                  onClick={handleClose}
                  style={{ ...Styles.statusnotifyXbutton_changestat, cursor: 'pointer' }}
                >
                  X
                </Typography>
              </Box>

            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60%',
                overflow: 'auto',
                marginTop: "15px" 
              }}
            >
                <Box sx={{width:'100%',height:'10%',display:'flex',flexDirection:'row',alignItems:'center'}} >
               <Box sx={{height:10,width:10,backgroundColor:'#1282A2',borderRadius:10,marginLeft:5,marginRight:5}}></Box>
                
                <Typography sx={{fontSize: "20px"}}>Bank Name -</Typography>          

<Typography sx={{fontSize: "20px"}}>{data?.bank_details}</Typography> 
                </Box>
                <Box sx={{width:'100%',height:'10%',display:'flex',flexDirection:'row',alignItems:'center', marginTop: '5%'}} >
           
                <Box sx={{height:10,width:10,backgroundColor:'#1282A2',borderRadius:10,marginLeft:5,marginRight:5}}></Box>
                
           <Typography sx={{fontSize: "20px"}}>Bank Number -</Typography>          

<Typography sx={{fontSize: "20px"}}>{data?.account_number}</Typography> 
</Box>
<Box sx={{width:'100%',height:'10%',display:'flex',flexDirection:'row',alignItems:'center', marginTop: '5%'}} >
           
           <Box sx={{height:10,width:10,backgroundColor:'#1282A2',borderRadius:10,marginLeft:5,marginRight:5}}></Box>
           
            <Typography sx={{fontSize: "20px"}}>IFSC Code -</Typography>          

           <Typography sx={{fontSize: "20px"}}>{data?.ifsccode}</Typography>          
            </Box>
            </Box>

  
           
          </Box>}
        </Modal>
      </Box>
    );
  }
  