// src/Connect.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Connect = () => {
  const [uniqueID, setUniqueID] = useState('');

  useEffect(() => {
    const fetchUniqueID = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/get`);
        setUniqueID(response.data.uniqueID);
      } catch (error) {
        console.error('Error fetching the UniqueID:', error);
      }
    };

    fetchUniqueID();
  }, []);

  const connect_userid = localStorage.getItem('connect_userid');

  const iframeSrc =  `https://chatweb.convenevc.com/#/ChatList/4/${connect_userid}`;

  return (
    <div style={{ height: '90vh', width: '90vw' }}>
      {iframeSrc ? (
        <iframe
          src={iframeSrc}
          title="Dynamic Iframe"
          style={{ border: 'none', height: '90%', width: '93%' }}
        ></iframe>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Connect;
