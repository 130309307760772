import { Box, Modal, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import demoImage from "../../Images/docPlaceholder.png"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function LogsAttachment(props) {
  // console.log("props in logs attached", props);
  const [attachments, setAttachments] = useState("");
//   console.log("this is image in logs attachment", attachments);
  useEffect(() => {
  if(props?.checklist){
    getChecklistAttachments();
  }
  else if(props?.turnkey){
    gettaskturnkeydocument();
  }
  else if(props?.task_id){
    gettasklogsAttachments();
    // console.log("it is called")
  }
  else{
    getAttachments();
    // console.log("it is not called")

  }
  }, [props]);

  const getAttachments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getticketlogsattach`,
        { ticket_id: props.id, name: props.name },
        { responseType: "arraybuffer" } // Ensure binary response
      );

      if (response.data) {
      //   console.log("first response", response.data);
        const contentType = response.headers["content-type"];
        const arrayBuffer = response.data;

        // Convert binary data to base64
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        const dataURI = `data:${contentType};base64,${base64}`;
        setAttachments(dataURI);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const gettasklogsAttachments = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/gettasklogsattach`,
        { task_id: props.id, name: props.name },
        { responseType: "arraybuffer" } // Ensure binary response
      );
// console.log("new response",response)
      if (response.data) {
        // console.log("first response", response.data);
        const contentType = response.headers["content-type"];
        const arrayBuffer = response.data;

        // Convert binary data to base64
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        const dataURI = `data:${contentType};base64,${base64}`;
        setAttachments(dataURI);
      }
    } catch (e) {
      console.log(e,"error");
    }
  };

  const getChecklistAttachments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/gettaskdocument`,
        { "checklist_id": props.ticket_id, "name": props.name },
        { responseType: "arraybuffer" } // Ensure binary response
      );

      if (response.data) {
      //   console.log("first response", response.data);
        const contentType = response.headers["content-type"];
        const arrayBuffer = response.data;

        // Convert binary data to base64
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        const dataURI = `data:${contentType};base64,${base64}`;
        setAttachments(dataURI);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const gettaskturnkeydocument = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/gettaskturnkeydocument`,
        { "turnkey_id": props.turnkey, "name": props.name },
        { responseType: "arraybuffer" } // Ensure binary response
      );

      if (response.data) {
      //   console.log("first response", response.data);
        const contentType = response.headers["content-type"];
        const arrayBuffer = response.data;

        // Convert binary data to base64
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        const dataURI = `data:${contentType};base64,${base64}`;
        setAttachments(dataURI);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Box>
        <img
          onClick={handleOpen}
          style={{
            fontFamily: "Montserrat",
            margin: "0",
            padding: "0",
            // border: "1px solid #1282A2",
            borderRadius: "10px",
            padding: "1%",
            color: "#A9A9A9",
            width:props.name?.endsWith("jpeg") || props.name?.endsWith("png") || props.name?.endsWith("jpg")?"100px":"50px",
            // maxWidth:"200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            objectFit:"contain",
          }}
        //   src={attachments}
        src={props.name?.endsWith("jpeg") || props.name?.endsWith("png")  || props.name?.endsWith("jpg") ? attachments : demoImage}

        />
        {/* <img
      onClick={handleOpen}
        style={{
            fontFamily: "Montserrat",
            margin: "0",
          padding: "0",
          border: "1px solid #1282A2",
          borderRadius: "10px",
          padding: "1%",
          color: "#A9A9A9",
          maxWidth:"100px",
        }}
        src={require("../../Images/demouser3.jpg")}
        /> */}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe
            style={{
              width: "100%",
              height: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
            src={attachments}
          />
        </Box>
      </Modal>
    </Box>
  );
}
