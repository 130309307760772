import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import EditprojectDetails from "../Pages/Organisation/EditprojectDetails";
import axios from "axios";
import ProjectSelect from "../Pages/Projects/ProjectSelect";
import ProjectSelectamc from "./Projects/AMC/ProjectSelectamc";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Projects(props) {
  const [selectedProject, setSelectedProject] = useState(null);
  const mediaQueryMyTeamUpper = useMediaQuery('(max-width:873px)');
  // console.log("slec4ed selected project 11: " , props?.taskData);
  const [sort, setsort] = useState("");
  // const [editProjectData1, setEdiProjectData1] = React.useState(null);
   const [open, setOpen] = React.useState(false);
   const [tmp,settmp] = useState(false);
  const [editProjectData1, setEdiProjectData1] = React.useState(null);
  // const [open, setOpen] = React.useState(false);
  const handlesortChange = (event) => {
    setsort(event.target.value);
  };

  useEffect(() => {
    if (props.selectedProject) {
      setSelectedProject(props.selectedProject);
    }
     else if (props.taskData) {
      setSelectedProject(props.taskData);
    }
     else {
      setSelectedProject(null);
      props.setSelectedProject(null);
    }
  }, [props.selectedProject, props.taskData]);
  
  
  useEffect(() => {

    props.setRow(null);
  }, [props.setRow]);
  const [data, setData] = useState([]);
  useEffect(() => {
    
    getsystemdata();
  }, []);
  const getsystemdata = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallprojects`,
        {
          organisation: localStorage.getItem("orgn_id"),
          user_id: localStorage.getItem("user_id"),
        }
      );
      // console.log(response.data)
      if (response.data.status === 1) {
        // console.log("all projects response data ",response.data);

        setData(response.data.data);
        // localStorage.setItem("crtorgid", response.data.data[0].id);
        // props.setcardnumber(3)
        // history.push("/Home");
        // let token = `${response.data.token}`;
        // let user_id = `${response.data.id}`;
        // let mbl_no = `${response.data.mbl_no}`;
        // localStorage.setItem("user_id", user_id);
        // localStorage.setItem("mbl_no", mbl_no);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  // const handleOpen = (row) => {}
  //  console.log("clicked row: ", row);
    const handleOpen = (row) => {
    //console.log("clicked row: " , row)
    //setOpen(true);
    props.setRow(row);
    props.setActive(11);
    //setEdiProjectData1(row);
  };
  const handleDelete = async(row) => {
    //console.log("clicked row: " , row)
    if(settmp){
      const confirmation = window.confirm(
        `Are you sure you want to Delete the ${row.name}`
      );
      if (confirmation) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/deactivateproject`,
            {
              "id":row.project_id
            }
            
          );
          if (response.data.status === 1) {
            getsystemdata()
          // console.log("organisations", id);
          }
        } catch (e) { 
          // console.log(e);
        }
      }
    }

  };
  const handleProjectClick = (row) => {
    //console.log(row,"This is row");
    setSelectedProject(row);
    props.setSelectedProject(row);
  };

  const handleselectedProject = () => {
    if(props?.taskData){
      setSelectedProject(null);
      props.setSelectedProject(null);
      props.setTaskData(null);
      props.setTasknavigate(true);
      props.setActive(1);
    }
    else{
      setSelectedProject(null);
      props.setSelectedProject(null)
    }
  };

  // useEffect(()=>{
  //   setSelectedProject(null);
  //   props.setSelectedProject(null)
  // },[])

  const inputDateString = selectedProject?.start_date;
  const formattedDate = new Date(inputDateString).toLocaleDateString("en-GB");
  return (
    <Box style={Styles.mainBox}>
      <Box style={{...Styles.upperBox,height:"10vh"}}>
        <Box style={{width:"60%",display: 'flex', marginTop: mediaQueryMyTeamUpper ? "24px" : "0px", alignItems: "center", marginLeft: "2%"}}>
        {selectedProject && <img src={require("../Images/Iconbackarrow.png")} alt="plus-icon" style={{ width: "30px", height: "20px", cursor: "pointer" }} onClick={() => { handleselectedProject();
        }}/>
        }
  <Typography style={{ ...Styles.name,marginTop:"0px",fontSize: mediaQueryMyTeamUpper ? "24px" : "30px", marginLeft: selectedProject ? "5px": "30px" }}>
  {selectedProject?.name && selectedProject?.name.length > 60
                ? `${selectedProject?.name.substring(0, 60)}`
                : selectedProject?.name}
              {selectedProject?.name && selectedProject?.name.length > 60 && (
                <>
                  {" "}
                  <span
                    style={{
                      color: "#1282A2",
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                    // onClick={handleOpenPopup}
                  >
                    ...
                  </span>
                </>
              )}
    {/* {selectedProject?.name} */}
  </Typography>
  </Box>
  <Box
    style={{
      width: "85%",
      alignSelf: "center",
      display: "flex",
      flexDirection: mediaQueryMyTeamUpper ? "column" : "row",
      justifyContent: mediaQueryMyTeamUpper ? "center" : "end",
      alignItems: mediaQueryMyTeamUpper ? "center" : "flex-end",
      //border: '1px solid green',
    }}
  >
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: mediaQueryMyTeamUpper ? "1%" : "0",
        marginRight: localStorage.getItem("role") === "admin" ? "0" : "335px",
        marginTop: localStorage.getItem("role") === "admin" ? "0" : "10px",
      }}
    >
      {!selectedProject?.type &&
      <>
        <>
      <Box
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: "#85A1EE",
          marginRight: "1%",
        }}
        ></Box>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "24px",
          lineHeight: "29.26px",
          color: "#85A1EE",
        }}
        >
        AMC
      </Typography>
        </>
        <>
      <Box
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: "#FEB7B1",
          marginLeft: "3%",
          marginRight: "1%",
        }}
        ></Box>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "24px",
          lineHeight: "29.26px",
          color: "#FEB7B1",
        }}
        >
        Turnkey
      </Typography>
        </>
      </>
      }
    </Box>

    {selectedProject?.type && 
    <Box
    style={{
      width: "70%",
      color: "#082A49",
      fontSize: "14px",
      fontWeight: "bold",
      // gap: "30px",
      margin: "auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }}
  >
    <Typography
      style={{
        color: "#082A49",
        fontSize: "18px",
        fontWeight: "bold",
        flexDirection: "row",
        fontFamily: "Montserrat",
        // marginLeft: "3%",
      }}
    >
      Project type :{" "}
      <span style={{ color: "#1282A2" }}>{selectedProject?.type}</span>
    </Typography>
    <Typography
      style={{
        color: "#082A49",
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontWeight: "bold",
      }}
    >
      Date : <span style={{ color: "#1282A2" }}>{formattedDate}</span>
    </Typography>
   
  </Box>
    }

    {localStorage.getItem("role") === "admin" && (
      <>
      {!selectedProject && 
        <Box
        onClick={() => {
          props.setActive(11);
        }}
        style={{
          ...Styles.reqButton,
          marginTop: mediaQueryMyTeamUpper ? "2.4%" : "0%",
          marginLeft: mediaQueryMyTeamUpper ? "0%" : "9%",
        }}
        >
        <Typography style={Styles.text3}>New</Typography>
        <img src={require("../Images/plus.png")} alt="plus-icon" />
      </Box>
      }
      </>
    )}
  </Box>
</Box>

      {selectedProject ? (
        <>
          {selectedProject?.type === "Turnkey" ? (
            <Box>
              <ProjectSelect project={selectedProject} setActive={props.setActive} settaskSelected={props.settaskSelected} setSelectedProject={props.setSelectedProject} setcheckLists={props.setcheckLists}/>
            </Box>
          ) : (
            <Box>
              <ProjectSelectamc taskDatastatus={props.taskDatastatus} project={selectedProject} setActive={props.setActive} settaskSelected={props.settaskSelected} setSelectedProject={props.setSelectedProject} setcheckListsamc={props.setcheckListsamc}/>
            </Box>
          )}
        </>
      ) : (
        <Box style={{ width: "90%", margin: "auto", height: "85%" }}>
        <Box style={{...Styles.tableBox2,height:"100%"}}>
            <TableContainer
              component={Paper}
              elevation={0}
              style={Styles.tableContainer}
            >
              <Table style={Styles.table} aria-label="simple table">
                <TableHead style={Styles.tableHead}>
                  <TableRow>
                    <TableCell style={Styles.tableCell} align="left">
                      No
                    </TableCell>
                    <TableCell style={Styles.tableCell} align="left">
                      Name
                    </TableCell>
                    <TableCell style={Styles.tableCell} align="center">
                      Status
                    </TableCell>
                    <TableCell style={Styles.tableCell} align="center">
                      Start date
                    </TableCell>
                    <TableCell style={Styles.tableCell} align="center">
                      Spoc
                    </TableCell>
                    <TableCell style={Styles.tableCell} align="center">
                      Owner
                    </TableCell>
                    {localStorage.getItem("role") === "admin" && (
                      <TableCell
                        style={Styles.tableCell}
                        align="center"
                      ></TableCell>
                    )}
                    {localStorage.getItem("role") === "admin" && (
                      <TableCell
                        style={Styles.tableCell}
                        align="center"
                      ></TableCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data?.map((row, index) => (
                    <TableRow style={{ height: 80 }} key={row.id}>
                      <TableCell
                        style={{
                          ...Styles.tableText,
                          color:
                            row?.type === "AMC"
                              ? "#F8F8F8"
                              : row?.type === "Turnkey"
                              ? "#F8F8F8"
                              : "#1282A2",
                          //border: '1px solid #E3F2F7',
                          backgroundColor:
                            row?.type === "AMC"
                              ? "#85A1EE"
                              : row?.type === "Turnkey"
                              ? "#FEB7B1"
                              : "#FFFFFF",
                        }}
                        align="center"
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        style={{
                          ...Styles.tableText,
                          color: "#1282A2",
                          border: "1px solid #E3F2F7",
                          cursor:"pointer",
                        }}
                        onClick={() => handleProjectClick(row)}
                        align="left"
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        style={{
                          ...Styles.tableText,
                          color: "#1282A2",
                          border: "1px solid #E3F2F7",
                        }}
                        align="center"
                      >
                        {row?.status}
                      </TableCell>

                      <TableCell
                        style={{
                          ...Styles.tableText,
                          color: "#1282A2",
                          border: "1px solid #E3F2F7",
                        }}
                        align="center"
                      >
                        {new Date(row?.start_date).toLocaleDateString()}
                      </TableCell>

                      <TableCell
                        style={{
                          ...Styles.tableText,
                          color: "#1282A2",
                          border: "1px solid #E3F2F7",
                        }}
                        align="center"
                      >
                        {row?.spoc_name}
                      </TableCell>
                      <TableCell
                        style={{
                          ...Styles.tableText,
                          color: "#1282A2",
                          border: "1px solid #E3F2F7",
                          borderRight: "none",
                        }}
                        align="center"
                      >
                        {row?.owner_name}
                      </TableCell>
                      {localStorage.getItem("role") === "admin" && (
                        <TableCell
                          style={{ ...Styles.tableText, color: "#1282A2" }}
                          align="center"
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={require("../Images/Iconedit.png")}
                            onClick={() => handleOpen(row)}
                          />
                        </TableCell>
                      )}
                      {localStorage.getItem("role") === "admin" && (
                        <TableCell
                          style={{ ...Styles.tableText, color: "#1282A2" }}
                          align="center"
                        >
                          <img
                          onClick={()=> handleDelete(row)}
                            style={{ cursor: "pointer" }}
                            src={require("../Images/Icondelete.png")}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
      {/*open && <EditprojectDetails open={open} setOpen={setOpen} data={editProjectData1}   />*/}
    </Box>
  );
}
