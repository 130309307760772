import {
  Box,
  Typography,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import axios from "axios";

export default function ChangeStatusPopup(props) {
  const [status, setStatus] = React.useState("");
  console.log("props in change assignee",props)
  const MediaQuerypopup = useMediaQuery('(max-width: 900px)')
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const [newStatusArray,setNewstatusArray] = useState([])
  const [statusClosed,setStatusClosed] = useState(true);
  const statusArray1 = [
    {
      id:1,
      name:"Closed",
      value:"Closed"
    },
    {
      id:2,
      name:"Reopened",
      value:"Reopened"
    },
  ]

  const statusArray2 = [
    {
      id:1,
      name:"In Progress",
      value:"In Progress"
    },
    {
      id:2,
      name:"Opened",
      value:"Opened"
    },
    {
      id:3,
      name:"Assigned",
      value:"Assigned"
    },
    {
      id:3,
      name:"Pending",
      value:"Pending"
    },
    {
      id:4,
      name:"Resolved",
      value:"Resolved"
    },
  ]

  useEffect(()=>{
    if(props.currentstatus === "Resolved"){
      setNewstatusArray(statusArray1)
    }
    else if(props.currentstatus === "Closed"){
      setStatusClosed(false);
    }
    else{
      setNewstatusArray(statusArray2)
    }
  },[])

  useEffect(()=>{
    if(props.currentstatus === "Resolved"){
      projectMembers();
    }
    else{
      allMembers();
    }
  },[])

    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getalluser`,
          { "organisation": localStorage.getItem("orgn_id")
        ,"organisation_admin": localStorage.getItem("platorgn")
      }
        );
        // console.log(response.data.data,"project api oooooooo");
        if (response.data.status === 1) {
          setAssignto(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };

    const projectMembers = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallprojectteam`,
        {
          "project_id":props.project_id
        }
      );
      // console.log(response.data.data, "shshshshshh");
      if (response.data.status === 1) {
        // console.log('Edit data has ', response.data.data);
        setAssignto(response.data.data);
      } else {
        // console.error("Failed to update user data:", response.data.data);
      }
    } catch (error) {
      // console.error("Error updating user data:", error);
    }
  };


  const handleSave = async()=>{
    console.log("I am here",status);
    if(status){
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/changeticketstatus`,
        {
          id: props.id,
          status: status,
          user_id: localStorage.getItem("user_id"),
          ticket_status: status,
          orgn_id: localStorage.getItem("orgn_id"),
          project_id: props.project_id,
        }
      );
      // console.log(response.data.data, "project api");
      if (response.data.status === 1) {
        console.log('Status changed successfully');
        props.setstatus(status);
        // console.log("ticket status changed i", response.data.data);
        // props.setHandleRefresh(true);
        // handleClose();
      }
    } catch (e) {
      // console.log(e);
    }
    }
    if(assignstatus){
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/assignticketstouser`,
        {
          id: props.id,
          assigned_to: assignstatus,
          assigned_by: localStorage.getItem("user_id"),
          organisation: localStorage.getItem("orgn_id"),
          ticket_id: props.id,
        }
      );
  
      if (response.data.status === 1) {
        console.log('assignee changed successfully');
        await props.setassigned_to(assignstatus);
        // Update state using the function form of setAllLogs
        // props.setAllLogs((prevData) => {
        //   // Assuming the response.data.data is an array to merge with the previous data
        //   return [...prevData, response.data.data];
        // });
        // props.setHandleRefresh(true);
        // handleClose();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
    }
  }
  props.setHandleRefresh(true);
  handleClose();
  }
 
  const [assignstatus, setassignStatus] = React.useState("");
  
  const handleassignChange = (event) => {
    setassignStatus(event.target.value);
  };
  const [assignTo,setAssignto] = useState([]);
  return (
    <Box>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box style={{...Styles.statusnotify_changestat,width:MediaQuerypopup?"350px":"703px"}}>
          <Box style={Styles.statusnotifyTitlebutton_changestat}>
            <h2 id="parent-modal-title" style={Styles.statusTitle_changestat}>
              Change Status
            </h2>
            <p
              onClick={handleClose}
              style={{...Styles.statusnotifyXbutton_changestat,display:'flex',justifyContent: 'right',marginLeft:"auto",marginTop:"3.5%"}}
            >
              X
            </p>
          </Box>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridTemplateRows: "1fr 1fr",
            }}
          >
            {/* First row */}
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "1",
                gridRow: "1",
                ...Styles.statusText_changestat,
              }}
            >
              Current Status
            </p>
            <div style={{ gridColumn: "2", gridRow: "1" }}>
              {/* You can add any content or leave it empty */}
            </div>
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "3",
                gridRow: "1",
                textAlign: "left",
                ...Styles.statusText_changestat,
              }}
            >
              Change Status
            </p>

            {/* Second row */}
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "1",
                gridRow: "2",
                textAlign: "left",
                ...Styles.statusCurrent_changestat,
              }}
            >
              {props.currentstatus}
            </p>
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "2",
                gridRow: "2",
                textAlign: "center",
                color: "#A9A9A9",
              }}
            >
              to
            </p>
            <FormControl style={{ gridColumn: "3", gridRow: "2" }}>
              <InputLabel id="simple-select-label">Select Status</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={status}
                label="Status"
                InputProps={{ style: { border: "none" } }}
                style={Styles.statusselectlabel_changestat}
                onChange={handleChange}
              >
                {newStatusArray.map((item)=>(
                     <MenuItem key={item.value} value={item.value}>
                     {item.name}
                   </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          {/* <Box>
            <p
              id="status-notify-text-Reason"
              style={{...Styles.statusnotifyreason_changestat,marginTop:MediaQuerypopup?"1px":"425px"}}
            >
              Reason/ Comment
            </p>
            <TextField
              id="outlined-multiline-flexible"
              label="Multiline"
              multiline
              maxRows={4}
              InputLabelProps={{ style: { display: "none" } }}
              InputProps={{ style: { border: "none" ,height:"100%",width:"100%"} }}
              sx={{
                "& fieldset": {
                  border: "none",
                },
                ...Styles.customtextfield_changestat,width:MediaQuerypopup?"350px":"695px"
              }}
            />
          </Box> */}
          <Box style={Styles.statusnotifyTitlebutton_changestat}>
            <h2 id="parent-modal-title" style={Styles.statusTitle_changestat}>
              Change assignee
            </h2>
          </Box>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridTemplateRows: "1fr 1fr",
            }}
          >
            {/* First row */}
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "1",
                gridRow: "1",
                ...Styles.statusText_changestat,
              }}
            >
              Current assignee
            </p>
            <div style={{ gridColumn: "2", gridRow: "1" }}>
              {/* You can add any content or leave it empty */}
            </div>
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "3",
                gridRow: "1",
                textAlign: "right",
                ...Styles.statusText_changestat,
              }}
            >
              Change assignee
            </p>

            {/* Second row */}
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "1",
                gridRow: "2",
                textAlign: "left",
                ...Styles.statusCurrent_changeassign,
              }}
            >
              {props.assigned_name}
            </p>
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "2",
                gridRow: "2",
                textAlign: "center",
                color: "#A9A9A9",
              }}
            >
              to
            </p>
            <FormControl style={{ gridColumn: "3", gridRow: "2" }}>
              <InputLabel id="simple-select-label">Select assignee</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={assignstatus}
                label="Status"
                InputProps={{ style: { border: "none" } }}
                style={Styles.statusselectlabel_changestat}
                onChange={handleassignChange}
              >
                 {assignTo?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          </Box>

          <Box id="status-notify-entry">
            <Button
              variant="contained"
              onClick={()=>{handleSave()}}
              style={Styles.statusnotifysave_changestat}
            >
              Save
            </Button>
            <Button
              variant="contained"
              style={Styles.statusnotifycancel_changestat}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
