import "./App.css";
import Dashboard from "./Admin/Pages/Dashboard";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./Admin/Pages/Login";
import { useState, useEffect } from "react";

function NotFound() {
  return <h1>404 Not Found</h1>;
}

function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated")
  );

  // Define a function to handle authentication status changes
  const handleAuthentication = (isAuthenticated) => {
    setAuthenticated(isAuthenticated);
  };

  useEffect(() => {
    // Check authentication status on component mount
    const isAuthenticated = localStorage.getItem("authenticated");
    setAuthenticated(isAuthenticated);
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route
          path="/login"
          element={<Login onAuthentication={handleAuthentication} />}
        />
        <Route
          path="/admin-dashboard"
          element={authenticated ? <Dashboard /> : <Navigate to="/login" replace />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
