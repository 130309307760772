import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, MenuItem, Select, Button, TextField, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import Styles from '../../Styles/AdminDashboard';
import axios from 'axios';
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ProjectTeam(props) {
    const handlenext = async () => {
        if(difforn.length === 0 && !props?.row){
          alert("Please select Atleast one Organisation Member");
        }
        else{
          if(usersarray.length === 0){
            alert("Please select Atleast one CBS Member");
          }
          else{
            props.setusersarray(usersarray);
            props.setradiovalue(radiovalue);
            props.setDifforn(difforn);
            props.setcardnumber(3);
          }
        }
  };
  // const [data, setData] = useState({});
  const [data, setData] = useState({ user_id: 75 });

const [usersarray, setusersarray] = useState([]);


  const [orgId,setOrgId] = useState("");
  const [organisation , setOrganisation]=useState([]);
  const [projectData, setProjectData] = useState([]);
  const [orgusers, setorgUsers] = useState([]);
  const [editSPOC,setEditSPOC] = useState("");
  const [teammemberid1,setTeamMemberId1] = useState ("");
  const mediaQueryProject = useMediaQuery('(max-width:873px)');
  //console.log('props of row is ', props.row);
  // console.log('projectData is ', projectData);
  // console.log('OrgUsers is ', orgusers);
  // console.log('THis i sspoc data we required',props.row?.spoc);
  // useEffect(() => {
  //   // console.log('I am in');
  // //console.log('THis i sspoc data we required',props.row?.spoc);
  useEffect(() => {
    //console.log('I am in');
    if (props.row?.project_id !== null) {
        handleupdateUserData(props.row?.project_id);
    }
  }, [props.row?.project_id]);
  

  const handleupdateUserData = async (id) => {
    // console.log('I am the function');
    //console.log('I am the function');
    let datas = {
      organisation: localStorage.getItem("orgn_id"),
      project_id: id,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getprojectteam`,
        datas
      );
      //console.log(response, "shshshshshh");
      if (response.data.status === 1) {
        // console.log('Edit data has ', response.data.data);
        //console.log('Edit data has ', response.data.data);
        response.data.data.forEach(({ id, username, teammemberid }) => {
          usersarrayfun(username, id, true);
          setTeamMemberId1(teammemberid);
        });
      } else {
        // console.error("Failed to update user data:", response.data.data);
      }
    } catch (error) {
      // console.error("Error updating user data:", error);
    }
  };


  useEffect(()=>{
    const getsystemdata = async()=>{
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getallorganisations`,
          data
        );
        // console.log("organisations",response)
        if (response.data.status===1) {
          setOrganisation(response?.data?.data)
          
        }
      } catch (e) {
        //console.log(e);
      }
    }
    getsystemdata()
      },[])
      const [cbsuser,setCbsuser] = useState([])
      useEffect(()=>{
        const getcbsusers = async()=>{
          let organisation={
            "organisation":localStorage.getItem("orgn_id"),
            "organisation_admin":localStorage.getItem("orgn_id"),
          }
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/getalluser`,
              organisation
            );
            // console.log("organisations",response)
            if (response.data.status===1) {
              setCbsuser(response?.data?.data)
            }
          } catch (e) {
            //console.log(e);
          }
        }
        getcbsusers()
          },[])
          const [customeruser , setcustomeruser]=useState([])
          useEffect(()=>{
            const getcbsusers = async()=>{
              let organisation={
                "organisation": props.organizationData,
                "organisation_admin":localStorage.getItem("orgn_id"),
              }
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_API_URL}/getalluseraddedorganisation`,
                  organisation
                );
                //console.log("organisations",response)
                if (response.data.status===1) {
                  setcustomeruser(response?.data?.data)
                }
              } catch (e) {
                //console.log(e);
              }
            }
            getcbsusers()
              },[props.organizationData])
// const [ usersarray,setusersarray]=useState([]);

// org admin fetch

const [assignTo,setAssignto] = useState([]);

useEffect(() => {
  const allMembers = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getorganisationadmin`,
        { "organisation":localStorage.getItem("platorgn")}
      );
      // console.log(response.data.data,"project api oooooooo");
      if (response.data.status === 1) {
        // setAssignto(response.data.data);
        console.log(response.data.data[0],'admin fetc')
        setusersarray([...usersarray, response.data.data[0]])
      }
    } catch (e) {
      // console.log(e);
    }
  };
  allMembers();
}, []);


//console.log('spoc is ',data.SPOC_name);
  useEffect(()=>{
    handleChange("SPOC_name",props.row?.spoc);
    setEditSPOC(props.row?.spoc_name);
  },[props.row?.spoc_name])

  const handleChange = (key, value) => {
   // console.log("data is:",key, value);
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    //setSelectedValues([...selectedValues, value]);
  };
  const [dropdownValues, setdropdownValues] = useState([]);
  const [dropdownValues2, setdropdownValues2] = useState([]);
  const [showradio,setshowradio]=useState(props.row ? true : false);
  const [radiovalue, setradiovalue] = React.useState('');
  const handleRadioChange = (event, index) => {
    const selectedValue = event.target.value;
    // If the selected value is "none", set SPOC_name to 0; otherwise, use the corresponding ID
    const spocName = selectedValue === "none" ? 0 : difforn[selectedValue]?.id;
  
    setradiovalue(selectedValue);
    handleChange('SPOC_name', selectedValue);
  };
  
  const handleCancel = (source) => {
    //console.log('handleCancel triggered');
    let deletedUserId = null;

  if (source === 'cbs') {
    const deletedUser = usersarray.pop(); // Remove the last user
    deletedUserId = deletedUser ? deletedUser.id : null;
    setusersarray([...usersarray]); // Update the state
    if(props.row){
   // console.log('Deleted id is ',deletedUserId);
    handleDeleteTeam(deletedUserId);
    }
  } else {
    const deletedUser = difforn.pop(); // Remove the last user
    deletedUserId = deletedUser ? deletedUser.id : null;
    setDifforn([...difforn]); // Update the state
    if (data.SPOC_name && data.SPOC_name === deletedUserId) {
      // We check if the deleted 
      handleChange("SPOC_name", undefined  );
      setradiovalue("none");
    }
  }

  return deletedUserId;
  };
//  console.log("values",usersarray)
// console.log("id ofd therfda",usersarray)
const usersarrayfun = async (name, id, term) => {
  setusersarray((prevArray) => {
    // Check if the id already exists in the array
    const isDuplicate = prevArray.some((user) => user.id === id);

    if (!isDuplicate) {
      // Use push to add a new user object to the array
      prevArray.push({ name, id, uniqueKey: `${name}_${id}` });

      // If you want to keep the array unique based on id, you can use Set
      const uniqueArray = Array.from(new Set(prevArray.map((user) => user.id))).map((id) =>
        prevArray.find((user) => user.id === id)
      );
      if(!term && props.row){
        //console.log("I have been called to set add user");
      // setTeamName(name);
      // setTeam(id);
      // console.log("team name",name,"Id is",id);
      handleAddTeam(name,id);
      }
      return uniqueArray;
    }

    return prevArray;
  });
};


 const[difforn , setDifforn]=useState([])
//  console.log("data ppp",difforn)
 const differentorgnfun = async(name,id)=>{
  setDifforn((prevArray) => {
    // Check if the id already exists in the array
    const isDuplicate = prevArray.some((user) => user.id === id);

    if (!isDuplicate) {
      // Use push to add a new user object to the array
      prevArray.push({ name, id, uniqueKey: `${name}_${id}` });

      // If you want to keep the array unique based on id, you can use Set
      const uniqueArray = Array.from(new Set(prevArray.map((user) => user.id))).map((id) =>
        prevArray.find((user) => user.id === id)
      );

      return uniqueArray;
    }

    return prevArray;
  });
  // setDifforn((prevArray) => {
  //   // Use Set to ensure unique values
  //   const uniqueArray = new Set([...prevArray, {name, id}]);
  //   // Convert Set back to an array
  //   return Array.from(uniqueArray);
  // });
 }
  const [sort, setsort] = useState("");
 
  const [drop1, setdrop1] = useState("");

  const [drop2, setdrop2] = useState("");
  
  
const [errorprojname , setErrorProjName]=useState(false)
const [errorprojdescription , setErrorProjDesignation]=useState(false)
const handleAddTeam = async (username,user_id) => {
  let userData = {
    ...props.row,
    "username": username,
    "organisation": localStorage.getItem("orgn_id"),
    "user_id": user_id,
    "project_id": props.row.project_id,
    "project_name": props.row.name,
    "type": props.row.type
  };
  //console.log("DataShared is",userData );
  try {
    const userResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/addusertoproject`,
      userData
    );

    if (userResponse.data.status !== 1) {
      // console.error("Error inserting user data",userResponse.data);
      // Handle error if needed
    }
    else{
      //console.log("User uploaded successfully inside editting",userResponse.data);
    }
    // console.log("addusertoproject inserted", userResponse);
    handleupdateUserData(props.row.project_id);
    //setAddVisible(false);
   
  } catch (e) {
    //console.log(e);
  }
};

const handleDeleteTeam = async (uid) => {
  let userData = {
    "id": teammemberid1,
    "user_id": uid,
    "project_id": props.row.project_id,
  };
  // console.log("first team deleted",uid)
  try {
    const userResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/removeuserfromproject`,
      userData
    );

    if (userResponse.data.status !== 1) {
      console.error("Error inserting user data",userResponse.data);
      // Handle error if needed
    }
    else{
      //console.log('Removed');
    }
    // console.log("removeuserfromproject remov", userResponse);
    handleupdateUserData(props.row.project_id);
    //setcrossVisible(false);
  } catch (e) {
    // console.log(e);
  }
};
  const handleSPOC = () =>{
    if(data.SPOC_name){
      return true;
    }
    alert("Please Select SPOC")
    return false;
  }
  return (
    <>
    <Box style={{...Styles.formField1, justifyContent: 'space-evenly', width: 'auto', marginTop: '5%'}}>
      <Box style={{display: 'flex', flexDirection: 'row', width: '50%'}}>
            <Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: mediaQueryProject ? "15px" : '22px', lineHeight: '26.82px', marginLeft: '12%'}}>Select&nbsp;your&nbsp;team&nbsp;:</Typography>
            <FormControl size="small"
                style={{
                     ...Styles.FormControl,
                    // backgroundColor: "#144C89",
                    height: "40px",
                    marginLeft: "3.6%",
                    //border: '1px solid'
                }}
          fullWidth >
              <InputLabel
            style={{ fontFamily: "Montserrat", color: "#A9A9A9" }}
            id="cbs-select-label"
          >
            {drop1 ? "" : ""}
          </InputLabel>
              <Select
                labelId="cbs-select-label"
                id="cbs-select"
                value={data.user_id || 75}
                label={drop1 ? "" : "select employee"}
                style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                size="small"
                onChange={(e) => {
                  handleChange("user_id", e.target.value);
                }}
                IconComponent={() => (
                  <img
                    style={{ marginRight: "5%" }}
                    src={require("../../Images/dropdownArrow.png")}
                    alt='dropdown'
                  />
                )}
       >
{
  cbsuser.map((item)=>(

    <MenuItem style={{ fontFamily: "Montserrat" }} value={item?.id} onClick={()=>usersarrayfun(item?.name,item?.id)} >{item?.name}</MenuItem>
  ))
}
    
    </Select>
  </FormControl>
  </Box>
  {editSPOC &&
    <Box style={{display: 'flex', flexDirection: 'row', width: '50%'}}>
        <Box><Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: mediaQueryProject ? "15px" : '22px', lineHeight: '26.82px'}}>
    SPOC&nbsp;for&nbsp;the&nbsp;Project&nbsp;is
  </Typography>
  </Box>
    
    <TextField
    id={`spoc`}
    variant="standard"
    style={{
      width: '50%',
      height: '100%',
      fontFamily: 'Montserrat',
      boxSizing: 'border-box',
      marginLeft: '7%'
    }}
    value={editSPOC}
    readOnly
  />
  </Box>
  }
  {!editSPOC &&
  <Box style={{display: 'flex', flexDirection: 'row', width: '50%'}}>
  <Box>
    <Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: mediaQueryProject ? "15px" : '22px', lineHeight: '26.82px'}}>
  Select&nbsp;organisation&nbsp;team&nbsp;:
  </Typography>
  </Box>
  <Box>

  </Box>
  <Box>
  <FormControl size="small"
                style={{
                     ...Styles.FormControl,
                    // backgroundColor: "#144C89",
                    height: "40px",
                    marginLeft: "3.6%",
                }}
          fullWidth >
              <InputLabel
            style={{ fontFamily: "Montserrat", color: "#A9A9A9" }}
            id="org-select-label"
          >
            {drop2 ? "" : ""}
          </InputLabel>
              <Select
                labelId="org-select-label"
                id="org-select"
                value={data.user_id}
                label={drop2 ? "" : "select employee"}
                style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                size="small"
                // onChange={handledrop2}
                onChange={(e) => {
                  handleChange("user_id", e.target.value);
                  
                }}
                IconComponent={() => (
                  <img
                    style={{ marginRight: "5%" }}
                    src={require("../../Images/dropdownArrow.png")}
                    alt='dropdown'
                  />
                )}
              >
                {
                  customeruser?.map((item)=>(

                    <MenuItem style={{ fontFamily: "Montserrat" }} value={item?.id} onClick={()=>differentorgnfun(item.name,item.id)} >{item?.name}</MenuItem>
                  ))
                }
      
    </Select>
  </FormControl>
  </Box>
  </Box>
  }
  </Box>
  <Box style={{...Styles.formField2, marginLeft: '7%'}}>
  {/* CBS Team */}
  <Box style={{ width: '50%', verticalAlign: 'top' }}>
    {usersarray?.map((item, index) => (
      <Box key={index} width="40%" height="60px">
        <TextField
          id={`dynamic-textfield-${item}`}
          variant="standard"
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'Montserrat',
            boxSizing: 'border-box',
          }}
          value={item.name}
          readOnly
        />
      </Box>
    ))}
    <Box style={{...Styles.formField2}}>  
      {usersarray.length > 0 &&
      <Typography style={{...Styles.addcancelcheck, zIndex: 1, cursor: "pointer"}}>
        <img src={require("../../Images/Iconcancel.png")} alt="Icon Check" onClick={() => {handleCancel('cbs')}}/>
      </Typography>
      }
    </Box>
    <Box>
    {showradio && <Button variant="contained" onClick={() => {if(handleSPOC()){handlenext();}}}style={{
        ...Styles.statusnotifysave_changestat1, marginTop: "70px", zIndex: 1,
      }} >
              Next
            </Button>}
    </Box>
  </Box>
 
  {/* Organization Team */}
  {!editSPOC &&
    <Box style={{ width: '50%', verticalAlign: 'top',marginRight: '6%' }}>
      {
      difforn?.map((item, index) => (
        <Box key={index} width="40%" height="60px" display="flex" alignItems="center">
          {showradio && (
            <RadioGroup
              aria-labelledby={`demo-radio-buttons-group-label-${index}`}
              defaultValue="none"
              name={`radio-buttons-group-${index}`}
              value={radiovalue}
              onChange={(event) => handleRadioChange(event, index)}
            >
              <FormControlLabel control={<Radio />} value={item.id} />
            </RadioGroup>
          )}
          <TextField
            id={`dynamic-textfield-${item.name}`}
            variant="standard"
            style={{
              width: '100%',
              height: '100%',
              fontFamily: 'Montserrat',
              boxSizing: 'border-box',
            }}
            value={item.name}
            readOnly
          />
        </Box>
      ))
    }
    {showradio && (
    <Box>
    {/* <Typography style={{ ...Styles.spocText,width:"100%",alignSelf:"center",marginLeft: '16%'}}>
      Select SPOC for the project
    </Typography> */}
    <Box
      //width="100%"
      //height="60px"
      display="flex"
      alignItems="center"
      //gap="1px"
      style={{  marginTop:'1  px'}}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        // defaultValue={0}
        name="radio-buttons-group"
        value={radiovalue}
        onChange={handleRadioChange}
      >
        <FormControlLabel control={<Radio />} value={0} />
      </RadioGroup>
      <Typography style={{ ...Styles.proceedText }}>
        Proceed without selecting SPOC
      </Typography>
    </Box>
  </Box>
  
          )}
    <Box style={{...Styles.formField2, marginLeft: '5%'}}>
      <Box style={{display: 'flex'}}>
    {!showradio && <Button variant="contained" onClick={() => setshowradio(true)}
    style={{...Styles.statusnotifysave_changestat1,
            width: '110px',
            height: '40px',}} >
              Select SPOC
            </Button>}
      
            {difforn.length > 0 &&
      <Typography style={{...Styles.addcancelcheck, marginLeft: '200px', zIndex: 1, cursor: "pointer"}}>
      <img src={require("../../Images/Iconcancel.png")} alt="Icon Cancel" onClick={() => handleCancel('org')}/>
      </Typography>
}
      </Box>
    </Box>
  </Box>}
  
</Box>

<Box style={{  width: "80%", height: "15vh", position: "fixed", bottom: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }} >
      <Box style={{...Styles.SPOCComplete, marginLeft: '53%'}}>
   {/* {showradio && (
    <Box>
    <Typography style={{ ...Styles.spocText,width:"100%",alignSelf:"center",marginLeft: '16%'}}>
      Select SPOC for the project
    </Typography>
    <Box
      //width="100%"
      //height="60px"
      display="flex"
      alignItems="center"
      gap="1px"
      style={{  marginTop:'1  px',marginLeft:"7px"}}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        // defaultValue={0}
        name="radio-buttons-group"
        value={radiovalue}
        onChange={handleRadioChange}
      >
        <FormControlLabel control={<Radio />} value={0} />
      </RadioGroup>
      <Typography style={{ ...Styles.proceedText }}>
        Proceed without selecting SPOC
      </Typography>
    </Box>
  </Box>
  
          )} */}
</Box>
 
    </Box>
    </>
  )
}
