import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from 'react-router-dom';
import demoimg from "../Images/profileFill.png";

export default function Profile() {
  const mediaQueryProfile = useMediaQuery('(max-width:768px)')
  let org = "Coherent business solutions";
  const [data, setData] = useState({});
  const [profileImgType,setprofileImgType] = useState("");
  // console.log("type of image: ", profileImgType);
  const navigate = useNavigate();
  // console.log(data);
  const handleChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    // console.log("file data :", e.target.files[0]);
    // setNumOfFiles(files.length)
    // setAttachments(files);
    setSelectedFiles(e.target.files[0]);
    uploadCategoryImgFile(e.target.files[0]);
    setprofileImgType(e.target.files[0].type?.split("/")[1])
  };

  const uploadCategoryImgFile = async (file) => {
    // console.log("Hello : " + id);
    try {
      // for (let i = 0; i < numofFiles; i++) {
        const formdata = new FormData();
        // const file = attachments[i];
        formdata.append("user_profile", file);
        formdata.append("user_id", localStorage.getItem("user_id"));
        formdata.append("format", file.type?.split("/")[1]);
        // console.log(formdata);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/uploadprofile?user_id=${localStorage.getItem("user_id")}&format=${file.type.split("/")[1]}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if(response.data.status === 1){
        try {
            // const file = attachments[i];
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/updateprofileimg`,
              {
                "id": localStorage.getItem("user_id"),
              }
            );
            if (response.data.status === 1) {
              // console.log("uploaded successfully", response.data.data);
              getAttachments();
            }
            // console.log(response.data);
        } catch (e) {
          // console.log(e);
        }
      }
      // console.log(response.data);
    // } 
  }
    catch (e) {
      // console.log(e);
    }
  };

  const [attachments, setAttachments] = useState("");
    // console.log("this is image in logs attachment", attachments);
    useEffect(() => {
      getAttachments();
    }, []);

    const getAttachments = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserprofileimg`,
          { "id": localStorage.getItem("user_id"), "name": profileImgType },
          { responseType: "arraybuffer" } // Ensure binary response
        );

        if (response.data) {
        //   console.log("first response", response.data);
          const contentType = response.headers["content-type"];
          const arrayBuffer = response.data;

          // Convert binary data to base64
          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          const dataURI = `data:${contentType};base64,${base64}`;
          setAttachments(dataURI);
        }
      } catch (e) {
        // console.log(e);
      }
    };

  const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserdata`,
          {
            id: localStorage.getItem("user_id"),
          }
        );
        // console.log(response.data);

        if (response.data.status === 1) {
          // console.log(response.data.data);
          setData(response.data.data[0]);
        }
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };
  useEffect(() => {
    fetchUserData();
  }, []);

  const handleupdateUserData = async () => {
    try {
      // const updatedData = {
      //   id: "10",
      //   name: data.name,
      //   email: data.email,
      //   phone: data.phone,
      // };
      // let datas={
      //   ...data,
      //   "id":21
      // }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/updateuser`,
        data
      );

      if (response.data.status === 1) {
        //setData(response.data.data);
        alert("User data updated successfully");
      } else {
        // console.error("Failed to update user data:", response.data.data);
      }
    } catch (error) {
      // console.error("Error updating user data:", error);
    }
  };

  const handleChangepassword = async () => {
    if (!data.newpassword || !data.confirmPassword) {
      alert("Please fill in both the New Password and Confirm New Password fields.");
      return;
    }
    if (data.newpassword === data.confirmPassword) {
      alert("password changed");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/changepassword`,
          {
            id: localStorage.getItem("user_id"),
            password: data.newpassword,
          }
        );
        // console.log(response.data);
        if (response.data.status === 1) {
          handleChange("newpassword", "");
          handleChange("confirmPassword", "");
          fetchUserData();
        }
      } catch (e) {
        // console.log(e);
      }
    } else {
      alert("Incorrect password");
    }
  };
const handleLogout = () => {
  localStorage.clear();
  navigate('/login');
}
  return (
    <Box style={{...Styles.mainBox}}>
      <Box style={{ ...Styles.upperBox, justifyContent: "left",  height: mediaQueryProfile ?"9vh" : "5vh" }}>
        <Typography style={Styles.name}>Profile</Typography>
      </Box>

      <Box style={{...Styles.profileMain, flexDirection: mediaQueryProfile ? "column" : "row", marginTop: "5%"}}>
        <Box style={{...Styles.leftProfileBox }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              height: "10vh",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "100px",height:"100px", borderRadius: "50%" }}
              src={attachments?attachments:demoimg}
            />
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        style={{ display: "none" }}
                        id="fileInput"
                      />
                    <label
                      htmlFor="fileInput"
                      // style={{ cursor: "pointer", marginBottom: "10px" }}
                    >

            <img
              style={{
                width: "30px",
                position: "relative",
                marginTop: "7%",
                marginLeft: "-3%",
                // top: "33.4%",
                // left: "23%",
                cursor: "pointer",
              }}
              src={require("../Images/editProfile.png")}
              />
              </label>
            <Typography style={{ ...Styles.text4, fontSize: "26px" }}>
              Profile photo
            </Typography>
          </Box>
          <Typography
            style={{
              ...Styles.text4,
              fontSize:mediaQueryProfile ?"14px" : "26px",
              marginLeft: "0%",
              marginTop: "3%",
            }}
          >
            Organisation - {org}
          </Typography>
          <Typography style={Styles.profileText1}>Full Name</Typography>
          <TextField
          style={{height: mediaQueryProfile ?"9%":""}}
            value={data.name}
            onChange={(e) => {
              handleChange("name", e.target.value);
            }}
            id="outlined-multiline-flexible"
            label="Multiline"
            //   multiline
            //   maxRows={4}
            InputLabelProps={{ style: { display: "none" } }}
            InputProps={{
              style: {
                border: "none",
                height: "100%",
                width: "100%",
                color: "#A9A9A9",
              },
            }}
            sx={{
              "& fieldset": {
                border: "none",
              },
              ...Styles.inputBox,
              width: mediaQueryProfile ? "100%" : "70%"
            }}
          />
          <Typography style={Styles.profileText1}>Email Address</Typography>
          <TextField   style={{height: mediaQueryProfile ?"9%":""}}
            value={data.email}
            onChange={(e) => {
              handleChange("email", e.target.value);
            }}
            id="outlined-multiline-flexible"
            label="Multiline"
            //   multiline
            //   maxRows={4}
            InputLabelProps={{ style: { display: "none" } }}
            InputProps={{
              style: {
                border: "none",
                height: "100%",
                width: "100%",
                color: "#A9A9A9",
              },
            }}
            sx={{
              "& fieldset": {
                border: "none",
              },
              ...Styles.inputBox,
              width: mediaQueryProfile ? "100%" : "70%"
            }}
          />
          <Typography style={Styles.profileText1}>Number</Typography>
          <TextField   style={{height: mediaQueryProfile ?"9%":""}}
            value={data.phone}
            onChange={(e) => {
              handleChange("phone", e.target.value);
            }}
            id="outlined-multiline-flexible"
            label="Multiline"
            //   multiline
            //   maxRows={4}
            InputLabelProps={{ style: { display: "none" } }}
            InputProps={{
              style: {
                border: "none",
                height: "100%",
                width: "100%",
                color: "#A9A9A9",
              },
            }}
            sx={{
              "& fieldset": {
                border: "none",
              },
              ...Styles.inputBox,
              width: mediaQueryProfile ? "70%" : "30%"
            }}
          />
          <Box style={{ ...Styles.reqButton, marginTop: mediaQueryProfile ?"9%":"2%",    width: mediaQueryProfile ?"130px" : "160px" }}>
            <Typography style={{...Styles.text3,  fontSize:  mediaQueryProfile ?"13px":"15px"}} onClick={() =>{handleupdateUserData()}}>
              update changes
            </Typography>
          </Box>
        </Box>
        <Box style={{...Styles.rightProfileBox, height:  mediaQueryProfile ?'35vh':'100vh',  width: mediaQueryProfile ? "90%" : "40%"}}>
          <Typography style={{ ...Styles.text4, fontSize: "26px" }}>
            Change Password
          </Typography>
          <Box style={{...Styles.rightInnerBox, height: mediaQueryProfile ? "80%" : "50%",}}>
            <Typography style={{ ...Styles.profileText1, marginLeft: "10%" }}>
              Current Password
            </Typography>
            <TextField style={{height: mediaQueryProfile ?"12%":""}}
              value={data.password}
              onChange={(e) => {
                handleChange("password", e.target.value);
              }}
              id="outlined-multiline-flexible"
              label="Multiline"
              type="password"
              //   multiline
              //   maxRows={4}
              InputLabelProps={{ style: { display: "none" } }}
              InputProps={{
                readOnly: true,
                style: {
                  border: "none",
                  height: "100%",
                  width: "100%",
                  color: "#A9A9A9",
                },
              }}
              sx={{
                "& fieldset": {
                  border: "none",
                },
                marginLeft: "10%",
                ...Styles.inputBox,
                borderColor: "#1282A2",
              }}
            />
            <Typography style={{ ...Styles.profileText1, marginLeft: "10%" }}>
              New Password
            </Typography>
            <TextField style={{height: mediaQueryProfile ?"12%":""}}
              value={data.newpassword}
              onChange={(e) => {
                handleChange("newpassword", e.target.value);
              }}
              id="outlined-multiline-flexible"
              label="Multiline"
              type="password"
              //   multiline
              //   maxRows={4}
              InputLabelProps={{ style: { display: "none" } }}
              InputProps={{
                style: {
                  border: "none",
                  height: "100%",
                  width: "100%",
                  color: "#A9A9A9",
                },
              }}
              sx={{
                "& fieldset": {
                  border: "none",
                },
                marginLeft: "10%",
                ...Styles.inputBox,
                borderColor: "#1282A2",
              }}
            />
            <Typography style={{ ...Styles.profileText1, marginLeft: "10%" }}>
              Confirm New Password
            </Typography>
            <TextField style={{height: mediaQueryProfile ?"12%":""}}
              value={data.confirmPassword}
              onChange={(e) => {
                handleChange("confirmPassword", e.target.value);
              }}
              id="outlined-multiline-flexible"
              label="Multiline"
              //   multiline
              type="password"
              //   maxRows={4}
              InputLabelProps={{ style: { display: "none" } }}
              InputProps={{
                style: {
                  border: "none",
                  height: "100%",
                  width: "100%",
                  color: "#A9A9A9",
                },
              }}
              sx={{
                "& fieldset": {
                  border: "none",
                },
                marginLeft: "10%",
                ...Styles.inputBox,
                borderColor: "#1282A2",
              }}
            />
          </Box>
          <Box style={{display :'flex',flexDirection:"row", width:"70%",justifyContent:"center",alignItems:"center",alignSelf:'center',gap:"5px",marginTop:"5% "}}>
          <Box style={{ ...Styles.reqButton,   width: "120px", margin: "auto"}}>
            <Typography style={Styles.text3} onClick={handleChangepassword}>
              Save changes
            </Typography>
           
          </Box>
          <div style={styles.logoutButtonContainer}>
          <div style={styles.loginArrowBox}>
          <img
          style={{ cursor: "pointer" }}
          src={require("../Images/LogoutArrow.png")}  />
         
          <p style={styles.logout} onClick={ handleLogout} > Logout</p>
 
          </div>
        </div>
        </Box>
        </Box>
      </Box>
    </Box>
  );
}
const styles = {
  logoutButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '2%',
    marginTop: '1%',
   
  },
 
  loginArrowBox: {
    width: '90px',
    height: '38px',
    marginRight: '10px',
    border: '2px solid #001E51',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginArrow: {
    width: '20px',
    height: '20px',
  },
  logout: {
    color: "#001E51",
    cursor: "pointer",
    border: "none",
  },
}