import React, { useEffect, useState } from "react";
import { Box, Typography, Modal } from "@mui/material";
import Styles from "../../Styles/AdminDashboard";
import axios from "axios";
import ViewAttachments from "./ViewAttachments";

export default function AttachmentList(props) {
  const [attachments, setAttachments] = useState([]);
  console.log("props in attachment lsize", props);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [FileName,setFileName] = useState("");
  useEffect(() => {
    if (props.checklist) {
      getChecklistAttachments();
    }
    else if (props.docid){
      getProjlistAttachments();
    }
    else {
      getAttachments();
    }
  }, [props]);
  // console.log("this is attachments and", attachments[2]);
  const getAttachments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getticketdocumentslist`,
        { ticket_id: props.id }
      );
      console.log(response.data.data, "project api");
      if (response.data.status === 1) {
        setAttachments(response.data.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const getChecklistAttachments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/gettaskdoclist`,
        { checklist_id: props.id }
      );
      console.log(response.data.data, "task doc list api");
      if (response.data.status === 1) {
        setAttachments(response.data.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const getProjlistAttachments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getprojectdocumentslist`,
        { project_id: props.id }
      );
      console.log(response.data.data, "task doc list api");
      if (response.data.status === 1) {
        setAttachments(response.data.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const handleAttachmentClick = (index,file_name) => {
    setSelectedItemIndex(index);
    setFileName(file_name)
    console.log("nameand in d  m x  ", file_name,index);
  };

  const handleClose = () => {
    props.setOpen(false);
    setSelectedItemIndex(null); // Reset selected item index when closing modal
  };

  return (
    <Box>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          style={{
            ...Styles.statusnotify_changeassign,
            width: "80%",
            height: "80%",
          }}
        >
          <Box
            style={{
              ...Styles.statusnotifyTitlebutton_changestat,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignitem: "center",
            }}
          >
            <h2
              id="parent-modal-title"
              style={{ ...Styles.statusTitle_changestat, fontSize: "20px" }}
            >
              Attachment List
            </h2>
            <Typography
              onClick={handleClose}
              style={{
                ...Styles.statusnotifyXbutton_changestat,
                display: "flex",
                justifyContent: "right",
                marginLeft: "auto",
                marginTop: "1%",
              }}
            >
              X
            </Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ width: "15%", height: "100%" }}>
              {attachments.map((item, index) => (
                <Typography
                  onClick={() => handleAttachmentClick(index,item.file_name)}
                  style={{
                    fontFamily: "Montserrat",
                    color: "#082A49",
                    margin: "3%",
                    cursor: "pointer",
                  }}
                >
                  {item.file_name}
                </Typography>
              ))}
            </Box>
            <Box style={{ width: "80%", height: "100%" }}>
              {/* {attachments.map((item) => ( */}
              {selectedItemIndex !== null && (
                <ViewAttachments
                  projDoc= {props?.projDoc}
                  docId={props?.docid}
                  id={props?.id}
                  FileName={FileName}
                  checklist={props?.checklist}
                  item={attachments[selectedItemIndex]}
                />
              )}{" "}
              {/* ))} */}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
