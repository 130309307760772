import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, MenuItem, Select, Button, TextField, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import Styles from '../../Styles/AdminDashboard';
import axios from 'axios';

export default function ProjectDetails(props) {
  // console.log(props.row)
    const [data, setData] = useState(props.row);
    const [textdata, setTextData] = useState({});
    const [textdataamc, setTextDataAMC] = useState({});
    const [type, settype] = useState("");
    const [organisation , setOrganisation]=useState([])
    const [category, setCategory] = useState(["planned", "on hold", "in progress", "cancelled", "completed"]);
    const [activateAMC, setactivateAMC] = useState(false);
    const [activateturkey, setactivateTurkey] = useState(false);
    const [bucketCountturnkey, setBucketCountturnkey] = useState(0);
    const [bucketCountAMC, setBucketCountAMC] = useState(0);
    const [turnkeysData, setTurnkeysData] = useState([]);
    const [AMCData, setAMCData] = useState([]);
    const [edittargetdate, setedittargetdate] = useState(props.row ? false : true);
    const inputDateString1 = props.row?.end_date;
    const formattedDate1 = new Date(inputDateString1).toLocaleDateString("en-GB");
    //console.log('I am here', textdata)
    const handleCreateBucketturnkey = () => {
      setBucketCountturnkey((prevCount) => prevCount + 1);
    };

    const handleDeleteBucketturnkey = () => {
      const lastBucketId = `bucket-textfield-${bucketCountturnkey}`;

      // Remove the corresponding key from textdata
      setTextData((prevData) => {
        const { [lastBucketId]: deleted, ...newTextData } = prevData;
        return newTextData;
      });
    
      setBucketCountturnkey((prevCount) => Math.max(0, prevCount - 1));
    };

    const handleCreateBucketAMC = () => {
      setBucketCountAMC((prevCount) => prevCount + 1);
    };

    const handleDeleteBucketAMC = () => {
      const lastBucketId = `bucket-textfield-${bucketCountAMC}`;

      // Remove the corresponding key from textdata
      setTextDataAMC((prevData) => {
        const { [lastBucketId]: deleted, ...newTextData } = prevData;
        return newTextData;
      });
      setBucketCountAMC((prevCount) => Math.max(0, prevCount - 1));
    };
    
  useEffect(()=>{
    const getsystemdata = async()=>{
      const datas ={
        ...data,
        "associated_organisationid" : localStorage.getItem("orgn_id")
      }
      console.log("okay",datas)
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallorganisations`,
          datas
        );
        // console.log("organisations",response)
        if (response.data.status===1) {
          setOrganisation(response?.data?.data)
          
        }
      } catch (e) {
        // console.log(e);
      }
    }
    getsystemdata()
      },[])

      useEffect(() => {
          if(data?.type === 'Turnkey'){
            setactivateTurkey(true);
          const getTurnkeys = async () => {
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/getallturnkeys`,
                {
                  role: localStorage.getItem("role"),
                  user_id: localStorage.getItem("user_id"),
                  project_id: props.row?.project_id ? props.row?.project_id : props.row?.id
                }
              );
              console.log(response.data.data, "turnkeys data");
              if (response.data.status === 1) {
                setTurnkeysData(response.data.data);
                //setturnkeysValue(response.data.data);
              }
            } catch (e) {
              //console.log(e);
            }
            //getprganisationdata();
        };
          getTurnkeys();
        }
        if(data?.type === 'AMC'){
          setactivateAMC(true);
          const getAMCkeys = async () => {
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/getallchecklist`,
                {
                  project_id: props.row?.project_id ? props.row?.project_id : props.row?.id
                }
              );
              console.log(response.data.data, "AMC data");
              if (response.data.status === 1) {
                setAMCData(response.data.data);
                //setturnkeysValue(response.data.data);
              }
            } catch (e) {
              //console.log(e);
            }
            //getprganisationdata();
        };
          getAMCkeys();
        }
        }, [data?.type, props.row?.projectid, props.row?.id]);
   
      
const handleOrganizationSelection = (selectedOrganizationData) => {
    // Update the state in the parent component
    props.setOrganizationData(selectedOrganizationData);
    //console.log('I have share the data ',selectedOrganizationData)
  };
  const handleChange = (key, value) => {
    console.log("data is:",key, value);
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    //setSelectedValues([...selectedValues, value]);
    if(key === "type" && value === "AMC")
    {
      setBucketCountturnkey(0);
      setactivateTurkey(false);
      setactivateAMC(true);
    }
    if(key === "type" && value === "Turnkey")
    {
      setBucketCountAMC(0);
      setactivateAMC(false);
      setactivateTurkey(true);
    }
  };
  const handleTxtChange = (key, value) => {
    console.log("Text data is:", key, value);
    setTextData((prevData) => {
      const updatedData = {
        ...prevData,
        [key]: value,
      };
      //console.log("changes are:", textdata);
      props.setTxtTurn(updatedData); // Pass the updated data to the parent component
      return updatedData; // Return the updated data to update the local state
    });
  };

  const handleTxtChangeAMC = (key, textfieldValue) => {
    console.log("Text data is:", key, textfieldValue);
    setTextDataAMC((prevData) => {
      const updatedData = {
        ...prevData,
        [key]: { textfield: textfieldValue },
      };
      //console.log("changes are:", textdataamc);
      props.setTxtAMC(updatedData);
      return updatedData;
    });
  };
  const handleTxtChangeAMCEdit = (index, value, refer) => {
    console.log(index,value);
    if(refer === 'name'){
    setAMCData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].name = value; // Update the name property
  
      // You can also update other properties as needed
  
      return updatedData;
    });
  }
    if(refer === 'frequency'){
      setAMCData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index].frequency = value; // Update the name property
    
        // You can also update other properties as needed
    
        return updatedData;
      });
    }
  };

  const handleTxtChangeTurnkeyEdit = (index, value) => {
    console.log(index,value);
    setTurnkeysData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].name = value; // Update the name property
  
      // You can also update other properties as needed
  
      return updatedData;
    });
  }
//  console.log("values",usersarray)
//  console.log("data ppp",difforn)
  const handleDeleteEditAMC = async(chkid) =>{
    console.log(chkid);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/deletechecklist`,
        {
          "id": chkid,
        }
      );
      console.log(response.data.data, "AMC data");
      if (response.data.status === 1) {
        //setAMCData(response.data.data);
        //setturnkeysValue(response.data.data);
        setAMCData((prevData) => prevData.filter((amc) => amc.chkid !== chkid));
        console.log('deleted successfully');
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleDeleteEditTurnkey = async(id) =>{
    console.log(id);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/deleteturnkey`,
        {
          "id": id,
        }
      );
      console.log(response.data.data, "Turnkey data");
      if (response.data.status === 1) {
        //setAMCData(response.data.data);
        //setturnkeysValue(response.data.data);
        setTurnkeysData((prevData) => prevData.filter((turnkey) => turnkey.id !== id));
        console.log('deleted successfully');
      }
    } catch (e) {
      console.log(e);
    }
  }

  const [errorprojname , setErrorProjName]=useState(false)
  const [errororgname , setErrorOrgName]=useState(false)
  const [errorenddate , setErrorEndDate]=useState(false)
  const [errortype , setErrorType]=useState(false)
 const handlenext = async () => {
    //console.log('I have share the data ',data.organisation)
    if(!data?.name || !data?.organisation || !data?.end_date || !data?.type){
      setErrorProjName(!data?.name);
      setErrorOrgName(!data?.organisation);
      setErrorEndDate(!data?.end_date);
      setErrorType(!data?.type);
    }
    else{
    handleOrganizationSelection(data.organisation)
        props.setData(data);
        props.setAMCData(AMCData);
        props.setTurnkeysData(turnkeysData);
        //console.log('I have share the data ',data)
        props.setcardnumber(2);
  }};

//const [startvalidation , setStartValidation]=useState(false)
// useEffect(() => {
//   if (startvalidation) {
//   setErrorProjName(!data?.name);
//   setErrorOrgName(!data?.organisation);
//   setErrorEndDate(!data?.end_date);
//   setErrorType(!data?.type);
//   }
// }, [startvalidation, data]);

// const validateForm = () => {
//   // console.log('Project Name', errorprojname);
//   // console.log('Org Name', errororgname);
//   // console.log('End Date', errorenddate);
//   // console.log('Type', errortype);
//   if(!startvalidation){
//     return false;
//   }
//   if (
//     errorprojname ||
//     errororgname || errorenddate || errortype
//   ) {
//     //console.log('I have restricted him');
//     return false;
//   }

//   return true;
// };
//console.log('This is sort',sort);
//console.log('This is data.org',data.organisation);
const [selectedDate, setSelectedDate] = useState(null);
// console.log("selceted date: ", selectedDate) 
const handleDateChange = (event) => {
  const selectedDateString = event.target.value;
  const selectedDateObject = new Date(selectedDateString);

  // Format the date as "yyyy-mm-dd"
  const formattedDate = selectedDateObject.toISOString().split('T')[0];

  setSelectedDate(formattedDate);
  handleChange("end_date", formattedDate);
};

  return (
    <>
    <Box style={{ display: 'flex', flexDirection: 'row', width: "100%", marginTop: "6%", marginLeft: "3%", height: "70%", justifyContent: 'space-between' }}>
      <Box style={{  width: "15.5%", height: "70%", justifyContent: 'space-between',display: 'flex', flexDirection: 'column'}}>
    <Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '22px', lineHeight: '26.82px' }}>Organisation&nbsp;name&nbsp;:</Typography>
    <Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '22px', lineHeight: '26.82px' }}>Project&nbsp;name&nbsp;:</Typography>
    <Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '22px', lineHeight: '26.82px' }}>Project&nbsp;description&nbsp;:</Typography>
    <Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '22px', lineHeight: '26.82px' }}>End&nbsp;Date&nbsp;:</Typography>
    <Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '22px', lineHeight: '26.82px' }}>Category:</Typography>
</Box>

<Box style={{  width: "30%", height: "70%", justifyContent: 'space-between',display: 'flex', flexDirection: 'column',marginLeft:'3%'}}>
    <Box style={{display: 'flex'}}>
    <FormControl
    size="small"
    style={{
      ...Styles.FormControl,
      height: "40px",
      //marginLeft: "1.6%",
    }}
    fullWidth
  >
    <InputLabel
      style={{ fontFamily: "Montserrat", color: "#A9A9A9" }}
      id="demo-simple-select-label"
    >
      {data?.organisation ? "" : ""}
    </InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={data?.organisation}
      onChange={(e) => {
        handleChange("organisation", e.target.value);
      }}
      IconComponent={() => (
        <img
          style={{ marginRight: "5%" }}
          src={require("../../Images/dropdownArrow.png")}
          alt='dropdown'
        />
      )}
      style={{ ...Styles.selectBox, color: "#A9A9A9" }}
      size="small"
    >
      {organisation?.map((item) => (
        <MenuItem
          key={item.id}
          style={{ fontFamily: "Montserrat" }}
          value={item.id}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  {errororgname && (
    <Typography sx={{ color: 'red', marginLeft: '3%' }}>Please&nbsp;Select&nbsp;Organisation</Typography>
  )}
    </Box>
    <Box>
    <TextField id="standard-basic-Project-name" label="" variant="standard" value={data?.name}
              onChange={(e) => {
                handleChange("name", e.target.value);
              }}style={{ width: '100%' }}/>
    {errorprojname &&
            <Typography sx={{color:'red',marginleft: "10%"}} >Please&nbsp;Enter&nbsp;Project&nbsp;Name</Typography>
          }
    </Box>
    <Box>
    <TextField id="standard-basic-description" label="" variant="standard" value={data?.description}
              onChange={(e) => {
                handleChange("description", e.target.value);
              }}style={{   width: '100%' }}/>
    </Box>
    <Box style={{display: 'flex'}}>
    <FormControl
                  size="small"
                  style={{
                    ...Styles.FormControl,
                    height: "40px",
                    marginTop: "2.5%",
                    alignItems: "center",
                    justifyContent: "center",
                    //marginLeft: '5%',
                    //   marginLeft: "2.4%",
                  }}
                  fullWidth
                >
                  {!edittargetdate
                  ? (
                      <Typography
                      onClick={()=>{
                        setedittargetdate(true)
                        console.log('i am here',edittargetdate);
                      }}
                      style={{
                        width: "90%",
                        fontSize: "14px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#1282A2 ",
                        fontFamily: "Montserrat",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {formattedDate1}
                      <img
                        style={{ width: "20px", height: "12px" }}
                        src={require("../../Images/downArrow.png")}
                      />
                    </Typography>
                    ) : (
                  <TextField
                    type="date"
                    onChange={handleDateChange}
                    InputLabelProps={{ style: { display: "none" } }}
                    InputProps={{
                      style: {
                        border: "none",
                        height: "100%",
                        // width: "100%",
                        color: "#1282A2 ",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        border: "none",
                      },
                      ...Styles.inputBox,
                      width: "100%",                     
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                    
                    }}
                  />)}
                </FormControl>
                {errorenddate &&
            <Typography sx={{color:'red', marginLeft: '5%', marginTop: '3%'}} >Please Select End Date</Typography>
          }
    </Box>
    <Box style={{display: 'flex'}}>
    <FormControl
    size="small"
    style={{
      ...Styles.FormControl,
      height: "40px",
      //marginLeft: "1.6%",
    }}
    fullWidth
  >
    <InputLabel
      style={{ fontFamily: "Montserrat", color: "#A9A9A9" }}
      id="demo-simple-select-label"
    >
      {data?.category ? "" : ""}
    </InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={data?.category}
      onChange={(e) => {
        handleChange("category", e.target.value);
      }}
      IconComponent={() => (
        <img
          style={{ marginRight: "5%" }}
          src={require("../../Images/dropdownArrow.png")}
          alt='dropdown'
        />
      )}
      style={{ ...Styles.selectBox, color: "#A9A9A9" }}
      size="small"
    >
      {category?.map((item) => (
        <MenuItem
          key={item}
          style={{ fontFamily: "Montserrat" }}
          value={item}
        >
          {item}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  {errororgname && (
    <Typography sx={{ color: 'red', marginLeft: '3%' }}>Please&nbsp;Select&nbsp;Organisation</Typography>
  )}
    </Box>
</Box>

<Box style={{ display: 'flex', flexDirection: 'column', height: "auto", width: "10%" }}>
<Typography style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '22px', lineHeight: '26.82px' }}>Select&nbsp;Type&nbsp;:</Typography>

</Box>

<Box style={{ display: 'flex', flexDirection: 'column', height: "auto", width: "37%" }}>
<Box style={Styles.formField}>
  <FormControl
          size="small"
          style={{
            ...Styles.FormControl,
            // backgroundColor: "#144C89",
            height: "40px",
            //marginLeft: "1.6%",
          }}
          fullWidth
        >
          <InputLabel
            style={{ fontFamily: "Montserrat", color: "#A9A9A9" }}
            id="demo-simple-select-label"
          >
            {type ? "" : ""}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={data?.type}
            label={data?.type ? "" : "select name"}
            style={{ ...Styles.selectBox, color: "#A9A9A9" }}
            size="small"
            onChange={(e) => {
              handleChange("type", e.target.value);
            }}
            IconComponent={() => (
              <img
                style={{ marginRight: "5%" }}
                src={require("../../Images/dropdownArrow.png")}
                alt='dropdown'
              />
            )}
          >
            <MenuItem style={{ fontFamily: "Montserrat" }} value="AMC">
              AMC
            </MenuItem>
            <MenuItem style={{ fontFamily: "Montserrat" }} value="Turnkey">
              Turnkey
            </MenuItem>
            
          </Select>
        </FormControl>
        {errortype && (
    <Typography sx={{ color: 'red', marginLeft: '3%' }}>Please Select Type</Typography>
  )}
</Box>
<Box>
  {activateturkey && (
    <Box
      onClick={() => {
        handleCreateBucketturnkey();
      }}
      style={{
        ...Styles.reqButton,
        cursor: "pointer",
        marginTop: "10px",
        backgroundColor: "#1282A2",
        //marginLeft: "19.6%",
        width: "200px",
        height: "40px",
      }}
    >
      <Typography
        style={{
          fontSize: "18px",
          fontFamily: "Montserrat",
          color: "#F8F8F8",
          fontWeight: 500,
        }}
      >
        Create buckets
      </Typography>
      <img src={require("../../Images/plus.png")} style={{ marginLeft: "10%" }} alt="plus-icon" />
    </Box>
  )}
  
  {!activateAMC && turnkeysData.map((turnkey, index) => (
    <Box >
        <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <Box >
          <TextField
          key={index}
          id={`textfield-${index}`}
          label=""
          variant="standard"
          style={{ width: '80%' }}
          value={turnkey.name}
          onChange={(e) => handleTxtChangeTurnkeyEdit(index, e.target.value)}
          />
          </Box>
        <Typography
            variant="h6"
            style={{ cursor: "pointer", marginLeft: "5%" }}
            onClick={() => handleDeleteEditTurnkey(turnkey.id)}
          >
            X
          </Typography>
        </Box>
        </Box>
      ))}
      
  {bucketCountturnkey > 0 && (
  <Box style={{ marginTop: "60px", display: "flex", flexDirection: "column", cursor: "pointer", }}>
    {props.row && bucketCountturnkey > 0 &&(
      <Typography style={{color: "#1282A2", fontFamily: "Montserrat", fontWeight: "5px", fontSize: "30px", fontWeight:"bold",}}>New Bucket Created</Typography>
    )

    }
    {[...Array(bucketCountturnkey)].map((_, index) => (
      <Box key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <TextField
          id={`bucket-textfield-${index + 1}`}
          label=""
          variant="standard"
          style={{ width: "36%"}}
          onChange={(e) => {
            handleTxtChange( `bucket-textfield-${index + 1}`, e.target.value);
          }}
        />
        {index === bucketCountturnkey - 1 && (
          <Typography
            variant="h6"
            style={{ cursor: "pointer", marginLeft: "5%" }}
            onClick={() => handleDeleteBucketturnkey()}
          >
            X
          </Typography>
        )}
      </Box>
    ))}
  </Box>
)}

</Box>
<Box>
  {activateAMC && (
    <Box
      onClick={() => {
        handleCreateBucketAMC();
      }}
      style={{
        ...Styles.reqButton,
        marginTop: "10px",
        backgroundColor: "#1282A2",
        //marginLeft: "19.6%",
        width: "200px",
        height: "40px",
      }}
    >
      <Typography
        style={{
          fontSize: "18px",
          fontFamily: "Montserrat",
          color: "#F8F8F8",
          fontWeight: 500,
        }}
      >
        CheckList name
      </Typography>
      <img src={require("../../Images/plus.png")} style={{ marginLeft: "10%" }} alt="plus-icon" />
    </Box>
  )}
  {!activateturkey && AMCData.map((amc, index) => (
    <Box style={{ display: "flex", alignItems: "center", justifyContent: "row", marginBottom: "10px"}}>
        <Box >
        <TextField
        key={index}
        id={`textfield-${index}`}
        label=""
        variant="standard"
        style={{ width: '80%' }}
        value={amc.name}
        onChange={(e) => handleTxtChangeAMCEdit(index, e.target.value,'name')}
        />
        </Box>
        <FormControl
  size="small"
  style={{
    ...Styles.FormControl,
    height: "40px",
    marginLeft: "6%",
  }}
  fullWidth
>
  <InputLabel
    style={{ fontFamily: "Montserrat", color: "#A9A9A9" }}
    id={`demo-simple-select-label-${index + 1}`}
  >
    {type ? "" : "select frequency"}
  </InputLabel>
  <Select
    labelId={`demo-simple-select-label-${index + 1}`}
    id={`demo-simple-select-${index + 1}`}
    value={amc.frequency || ""}
    style={{ ...Styles.selectBox, color: "#A9A9A9" }}
    size="small"
    onChange={(e) => handleTxtChangeAMCEdit(index, e.target.value,'frequency')}
    IconComponent={() => (
      <img
        style={{ marginRight: "5%" }}
        src={require("../../Images/dropdownArrow.png")}
        alt='dropdown'
      />
    )}
  >
    {/* Add your MenuItem options here */}
    <MenuItem style={{ fontFamily: "Montserrat" }} value="Daily">
      Daily
    </MenuItem>
    <MenuItem style={{ fontFamily: "Montserrat" }} value="Weekly">
      Weekly
    </MenuItem>
    <MenuItem style={{ fontFamily: "Montserrat" }} value="Monthly">
      Monthly
    </MenuItem>
  </Select>
  </FormControl>
  <Typography
            variant="h6"
            style={{ cursor: "pointer", marginLeft: "5%" }}
            onClick={() => handleDeleteEditAMC(amc.chkid)}
          >
            X
          </Typography>

        </Box>
      ))}
  {bucketCountAMC > 0 && (
  <Box style={{ marginTop: "60px", display: "flex", flexDirection: "column" }}>
    {props.row && bucketCountAMC > 0 &&(
      <Typography style={{color: "#1282A2", fontFamily: "Montserrat", fontWeight: "5px", fontSize: "30px", fontWeight:"bold",}}>New Checklist Created</Typography>
    )

    }
    {[...Array(bucketCountAMC)].map((_, index) => (
      <Box key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <TextField
          id={`bucket-textfield-${index + 1}`}
          label=""
          variant="standard"
          style={{ width: "36%" }}
          onChange={(e) => {
            handleTxtChangeAMC( `bucket-textfield-${index + 1}`, e.target.value);
          }}
        />
        <FormControl
          size="small"
          style={{
            ...Styles.FormControl,
            height: "40px",
            marginLeft: "6%",
          }}
          fullWidth
        >
          <InputLabel
            style={{ fontFamily: "Montserrat", color: "#A9A9A9" }}
            id={`demo-simple-select-label-${index + 1}`} // Assigning a unique ID for each iteration
          >
            {type ? "" : "select frequency"}
          </InputLabel>
          <Select
            labelId={`demo-simple-select-label-${index + 1}`} // Linking the label to the unique ID
            id={`demo-simple-select-${index + 1}`} // Assigning a unique ID for each iteration
            //value={}
            label={type ? "" : "select name"}
            style={{ ...Styles.selectBox, color: "#A9A9A9" }}
            size="small"
            onChange={(e) => {
              handleChange(`checklist_freq_${index + 1}`, e.target.value); // Unique key for each iteration
            }}
            IconComponent={() => (
              <img
                style={{ marginRight: "5%" }}
                src={require("../../Images/dropdownArrow.png")}
                alt='dropdown'
              />
            )}
          >
            <MenuItem style={{ fontFamily: "Montserrat" }} value="Daily">
              Daily
            </MenuItem>
            <MenuItem style={{ fontFamily: "Montserrat" }} value="Weekly">
              Weekly
            </MenuItem>
            <MenuItem style={{ fontFamily: "Montserrat" }} value="Monthly">
              Monthly
            </MenuItem>
            
          </Select>
        </FormControl>
        {index === bucketCountAMC - 1 && (
          <Typography
            variant="h6"
            style={{ cursor: "pointer", marginLeft: "5%" }}
            onClick={() => handleDeleteBucketAMC()}
          >
            X
          </Typography>
        )}
      </Box>
    ))}
  </Box>
)}


</Box>
</Box>
</Box>
<Box>
<Button variant="contained" onClick={() => {handlenext();  }}
      style={{...Styles.statusnotifysave_changestat1, bottom: 100,left: 400,
      }} >
          Next
        </Button>
</Box>
</>
  );
}
