
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import axios from "axios";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRef } from "react";
export default function RaiseNewTicket(props) {
  const MediaQueryTickit = useMediaQuery('(max-width: 900px)')
  const [data, setData] = useState({
    "raised_user_id":11,
    "raised_organisation_id":5,
    "raisedto_organisation":5
  });
  const [datas, setDatas] = useState([]);
  const handleChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachment,setAttachments] = useState([]);
 
  const [selectedProject, setselectedProject] = useState("");
  const [SelectedDepartment, setSelectedDepartment] = useState("");
  const [selectedPriority, setselectedPriority] = useState("");
 
  const handlePriority = (event) => {
    setselectedPriority(event.target.value);
  };
  const handleDepartment = (event) => {
    setSelectedDepartment(event.target.value);
  };
  const handleProject = (event) => {
    setselectedProject(event.target.value);
  };
 
  const [role, setRole] = useState([]);
  const [project, setproject] = useState([]);
  const [department, setDepartment] = useState([]);
  const [assignTo,setAssignto] = useState([]);
  
 useEffect(() => {
  const getsystemdata = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallprojects`,
        { "organisation": localStorage.getItem("orgn_id"), "user_id": localStorage.getItem("user_id") }
      );
      // console.log(response.data.data,"project api");
      if (response.data.status === 1) {
        const filteredProjects = response.data.data.filter(project => project.type === "AMC");
        setproject(filteredProjects);
      }
    } catch (e) {
      // console.log(e);
    }
    getprganisationdata()
  };
  getsystemdata();
}, []);

 
  useEffect(() => {
    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getorganisationadmin`,
          { "organisation":localStorage.getItem("platorgn")}
        );
        // console.log(response.data.data,"project api oooooooo");
        if (response.data.status === 1) {
          setAssignto(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
      getprganisationdata()
    };
    allMembers();
  }, []);
 
  const getprganisationdata = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getorganisationdetails`,
        { "id": localStorage.getItem("orgn_id"), "created_organisation_id": localStorage.getItem("orgn_id") }
      );
      // console.log(response.data.data,"organisation api");
      setDatas(response?.data?.data);
 
      if (response.data.status === 1) {
        setData(response.data.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  // getorganisationdetails
  useEffect(() => {
    const getsystemdata = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getsystemconfiguration`,
          data
        );
        // console.log(response.data);
        if (response.data.status === 1) {
          // setRole(response?.data?.responserole);
          // setDesignation(response?.data?.responsedesignation);
          // console.log("response data department: ", response?.data?.data);
          setDepartment(response?.data?.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getsystemdata();
  }, []);
 
  const [errorproject , setErrorProject]=useState(false)
  const [errorticketname , setErrorTicketName]=useState(false)
  const [errordepartmentvalue , setErrorDepartmentValue]=useState(false)
  const [errorpriority , setErrorPriority]=useState(false)
  const [errorassignto , setErrorAssignTo]=useState(false)
  // const [startvalidation , setStartValidation]=useState(false)
 
  // useEffect(() => {
  //   if (startvalidation) {
  //     // Validation logic for each field
  //     setErrorProject(!data.project_id);
  //     setErrorTicketName(!data.ticket_name);
  //     setErrorDepartmentValue(!data.department);
  //     setErrorPriority(!data.priority);
  //     setErrorAssignTo(!data.assigned_to);
  //   }
  // }, [startvalidation, data]);
 
  // const validateForm = () => {
  //   if(!startvalidation){
  //     return false;
  //   }
  //   if (
  //     errorproject ||
  //     errorticketname ||
  //     errordepartmentvalue ||
  //     errorpriority ||
  //     errorassignto
  //   ) {
  //     // console.log("Assigned To", errorassignto);
  //     // console.log("Priority", errorpriority);
  //     // console.log("Dep", errordepartmentvalue);
  //     // console.log("name", errorticketname);
  //     // console.log("Project", errorproject);
  //     // console.log('I have restricted him');
  //     return false;
  //   }
 
  //   return true;
  // };
  const handleSubmit = async()=>{
    if(!data.project_id || !data.ticket_name || !data.department || !data.priority || !data.assigned_to){
      setErrorProject(!data.project_id);
      setErrorTicketName(!data.ticket_name);
      setErrorDepartmentValue(!data.department);
      setErrorPriority(!data.priority);
      setErrorAssignTo(!data.assigned_to);
    }
    else{
const datas={
  ...data,
  "raised_organisation_id":localStorage.getItem("orgn_id"),
  "raised_user_id":localStorage.getItem("user_id"),
  "platform_admin_orgid":localStorage.getItem("platorgn")
  // "raisedto_organisation":data.assignTo
 
}
// console.log("all datas are saved",data)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/createtickets`,
        datas
      );
      // console.log(response.data.data)
      if (response.data.status===1) {
      // console.log("inserteed ticket: ", response.data?.data[0]?.id)
      uploadCategoryImgFile(response.data?.data[0]?.id)
      alert("Ticket raised")
      props.setActive(1);
      }
    } catch (e) {
      // console.log(e);
    }
  }
  }
  const [numofFiles,setNumOfFiles] = useState("");
  const handleFileChange = (e, file) => {
    let files;
  
    if (e.target && e.target.files) {
      // If called from input file change
      console.log("Input File Change:", e.target.files);
      files = Array.from(e.target.files);
    } else if (file) {
      // If called from clipboard paste
      console.log("Clipboard Paste:", file);
      files = [file];
    }
  
    console.log("These are files", files);
  
    if (files && files.length > 0) {
      // If you want to append the new files to existing Attachments
      setAttachments((prevAttachments) => [...prevAttachments, ...files]);
  
      // If you want to replace the existing Attachments with the new files
      // setAttachments(files);
  
      setNumOfFiles(files.length);
      setSelectedFiles(files);
    }
  };
  
  
  const uploadCategoryImgFile = async (id) => {
    try {
      // Dynamically calculate the number of files based on the length of the attachment array
      const numofFiles = attachment.length;
  
      // Log the calculated numofFiles
      console.log("Calculated numofFiles:", numofFiles);
  
      // Create an array to hold responses
      const uploadResponses = [];
  
      // Iterate over each attached file
      for (let i = 0; i < numofFiles; i++) {
        const formdata = new FormData();
        const file = attachment[i];
  
        // Append file data to FormData
        formdata.append("ticket_documents", file);
        formdata.append("ticket_id", id);
        formdata.append("name", file.name);
  
        // Perform file upload
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/uploadticket?ticket_id=${id}&name=${file.name}`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        // Push the upload response to the array
        uploadResponses.push(uploadResponse);
  
        // Check if the upload was successful
        if (uploadResponse.data.status === 1) {
          // Additional actions after successful upload
          try {
            const addImageResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/addimagetotickets`,
              {
                ticket_id: id,
                file_name: file.name,
                user_id: localStorage.getItem("user_id"),
              }
            );
  
            // Log or handle additional actions response
            console.log("addImageResponse:", addImageResponse.data);
  
          } catch (e) {
            // Handle errors during additional actions
            console.log("addImageResponse error:", e);
          }
        }
      }
  
      // Log or handle responses from file uploads
      console.log("uploadResponses:", uploadResponses);
  
    } catch (e) {
      // Handle errors during file uploads
      console.log("uploadCategoryImgFile error:", e);
    }
  };
// console.log(datas[0]?.name)
const addimagepath = async(id)=>{
  let datas ={
    "id":id
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/addimagetotickets`,
      datas
    );
    // console.log(response.data.data)
    if (response.data.status===1) {
    // console.log("inserteed ticket: ", response.data?.data[0]?.id)
    uploadCategoryImgFile(response.data?.data[0]?.id)
    alert("Ticket raised")
    props.setActive(1);
    }
  } catch (e) {
    // console.log(e);
  }
}
const contentEditableRef = useRef(null);

const handlePaste = (e) => {
  const clipboardData = e.clipboardData || e.originalEvent.clipboardData;
  const items = (clipboardData && clipboardData.items) || [];

  for (let i = 0; i < items.length; i++) {
    if (items[i].type.indexOf('image') !== -1) {
      const blob = items[i].getAsFile();
      const file = new File([blob], `pasted-image-${Date.now()}.png`, { type: 'image/png' });

      // Add the pasted image to the existing files
      handleFileChange(e, file);

      // Display the pasted image
      processPastedImage(file);
    }
  }
};

const processPastedImage = (imageFile) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    const imageSrc = event.target.result;

    // Create an img element and set its attributes
    const imgElement = document.createElement('img');
    imgElement.src = imageSrc;
    imgElement.alt = 'Pasted Image';
    imgElement.style.maxWidth = '100px'; // Set the desired max-width
    imgElement.style.height = '100px';

    // Append the img element to the contentEditableRef
    contentEditableRef.current.appendChild(imgElement);
  };

  reader.readAsDataURL(imageFile);
};

  return (
    <Box style={Styles.mainBox}>
      <Box style={{ ...Styles.upperBox, justifyContent: "left", height:MediaQueryTickit?"10vh": "15vh", }}>
        <Typography style={Styles.name}>Raise ticket</Typography>
      </Box>
      <Box
        style={{
          width: "90%",
          margin: "auto",
          display: "flex",
          flexDirection:MediaQueryTickit? "column":"Row"
        }}
      >
        <Box style={{ width:MediaQueryTickit?"70%":"25%" }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "0%",
              color: "#082A49",
              fontSize: "18px",
              borderRadius:"11px"
            }}
          >
            Customer name
          </Typography>
          <TextField
            value={datas[0]?.name}
            onChange={(e) => {
              handleChange("name", e.target.value);
            }}
            disabled={true}
            InputLabelProps={{ style: { display: "none" } }}
            InputProps={{
              style: {
                border: "none",
                height: "100%",
                width: "100%",
                color: "#082A49 ",
                
                
              },
            }}
            sx={{
              "& fieldset": {
                border: "none",
              },
              ...Styles.inputBox,
              width: "90%",
              marginTop: "1%",
              borderColor: "#1282A2",
              height: "60%",
              borderRadius:"11px"
            }}
          />
        </Box>
 
        <Box style={{ width:MediaQueryTickit?"70%":"25%" }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "0%",
              color: "#082A49",
              fontSize: "18px",
            }}
          >
            Project name
          </Typography>
          <FormControl
            size="small"
            style={{
              ...Styles.tdBox1InnerRight,
              border: "2px solid #1282A2",
              boxShadow: "none",
              backgroundColor: "transparent",
              width: "90%",
              height: "60%",
              marginTop: "1%",
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                border: "1px solid #1282A2",
                borderColor: "#1282A2",
                
              },
            }}
            fullWidth
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.project_id}
              //   label={ticketssort ? "" : "Change status"}
              style={{
                ...Styles.selectBox,
                height: "100%",
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "1px solid white",
                  borderColor: "white",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              size="small"
             
              onChange={(e) => {
                handleChange("project_id", e.target.value);
              }}            >
             {project?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            errorproject &&
            <Typography sx={{color:'red'}} >Please Select Project</Typography>
          }
        </Box>
 
        <Box style={{ width:MediaQueryTickit?"70%":"25%"  }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "0%",
              color: "#082A49",
              fontSize: "18px",
            }}
          >
            Department
          </Typography>
          <FormControl
            size="small"
            style={{
              ...Styles.tdBox1InnerRight,
              border: "2px solid #1282A2",
              boxShadow: "none",
              backgroundColor: "transparent",
              width: "90%",
              height: "60%",
              marginTop: "1%",
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                border: "1px solid #1282A2",
                borderColor: "#1282A2",
              },
            }}
            fullWidth
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.department}
              //   label={ticketssort ? "" : "Change status"}
              style={{
                ...Styles.selectBox,
                height: "100%",
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "1px solid white",
                  borderColor: "white",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              size="small"
              onChange={(e) => {
                handleChange("department", e.target.value);
              }}             >
              {department?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            errordepartmentvalue &&
            <Typography sx={{color:'red'}} >Please Select Department</Typography>
          }
        </Box>
 
        <Box style={{ width:MediaQueryTickit?"70%":"25%"  }}>
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "0%",
              color: "#082A49",
              fontSize: "18px",
            }}
          >
            Set priority
          </Typography>
          <FormControl
            size="small"
            style={{
              ...Styles.tdBox1InnerRight,
              backgroundColor: "transparent",
              width: "90%",
              height: "60%",
              border: "2px solid #1282A2",
              boxShadow: "none",
              marginTop: "1%",
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            fullWidth
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.priority}
              //   label={ticketssort ? "" : "Change status"}
              style={{
                ...Styles.selectBox,
                height: "100%",
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "1px solid white",
                  borderColor: "white",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              size="small"
              onChange={(e) => {
                handleChange("priority", e.target.value);
              }}
              // onChange={handlePriority}
            >
              <MenuItem style={{ fontFamily: "Montserrat" }} value={"Low"}>
                Low
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={"Medium"}>
                Medium
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={"High"}>
                High
              </MenuItem>
            </Select>
          </FormControl>
          {
            errorpriority &&
            <Typography sx={{color:'red'}} >Please Select Priority</Typography>
          }
        </Box>
      </Box>
      <Box style={{ width: "90%",height:"50%", margin: "auto", display:'flex' , flexDirection:'row' }}>
      <Box sx={{width:'40%'}} >
        <Typography
          style={{
            ...Styles.profileText1,
            marginTop: "2%",
            color: "#082A49",
            fontSize: "18px",
          }}
        >
          Ticket Summary
        </Typography>
        <TextField
          value={data.ticket_name}
          onChange={(e) => {
            handleChange("ticket_name", e.target.value);
          }}
          InputLabelProps={{ style: { display: "none" } }}
          InputProps={{
            style: {
              border: "none",
              width: "100%",
              color: "#082A49 ",
              marginTop:"1.5%",
              borderRadius:"11px"
            },
          }}
          sx={{
            "& fieldset": {
              border: "none",
            },
            ...Styles.inputBox,
            width: "80%",
              height: "17%",
            marginTop: "1%",
            borderColor: "#1282A2",
            borderRadius:"11px",
          
          }}
        />{
          errorticketname &&
          <Typography sx={{color:'red'}} >Please Enter Ticket Name</Typography>
        }
 {/* <Box style={{ width: "25%" }}> */}
          <Typography
            style={{
              ...Styles.profileText1,
              marginTop: "0%",
              color: "#082A49",
              fontSize: "18px",
              marginTop:"1%",
              borderRadius:"11px"
            }}
          >
            Assign to
          </Typography>
          <FormControl
            size="small"
            style={{
              ...Styles.tdBox1InnerRight,
              border: "2px solid #1282A2",
              boxShadow: "none",
              backgroundColor: "transparent",
              width: "80%",
              height: "17%",
              marginTop:"1%",
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                border: "1px solid #1282A2",
                borderColor: "#1282A2",
                
              },
            }}
            fullWidth
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.assigned_to}
              //   label={ticketssort ? "" : "Change status"}
              style={{
                ...Styles.selectBox,
                height: "100%",
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "1px solid white",
                  borderColor: "white",
                  borderRadius:"14px"
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              size="small"
              onChange={(e) => {
                handleChange("assigned_to", e.target.value);
              }}             >
              {assignTo?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            errorassignto &&
            <Typography sx={{color:'red'}} >Please Select Assigned To</Typography>
          }
        {/* </Box> */}
        <Typography
          style={{
            ...Styles.profileText1,
            marginTop: "1%",
            color: "#082A49",
            fontSize: "18px",
          }}
        >
          Ticket description
        </Typography>
        <TextField
          value={data.description}
          multiline
          maxRows={4}
          onChange={(e) => {
            handleChange("description", e.target.value);
          }}
          InputLabelProps={{ style: { display: "none" } }}
          InputProps={{
            style: {
              border: "none",
              height: "100%",
              width: "100%",
              color: "#082A49 ",
              borderRadius:"11px"
            },
          }}
          sx={{
            "& fieldset": {
              border: "none",
            },
            ...Styles.inputBox,
            width: "80%",
            height: "27%",
            marginTop: "0.5%",
            borderColor: "#1282A2",
           
          }}
        />
         {/* <div
        ref={contentEditableRef}
        contentEditable
        onPaste={handlePaste}
        style={{ border: '1px solid #ccc', minHeight: '100px', padding: '5px' }}
      ></div> */}
        </Box>
        <Box style={{ width: "60%", display: "flex", flexDirection: "row",justifyContent:"center",alignItem:"center" }}>
          <Box style={{ display: "flex", flexDirection: "column" ,justifyContent:"center",alignItem:"center", height:"105%"}}>
          <div
        ref={contentEditableRef}
        contentEditable
        onPaste={handlePaste}
        style={{ border: '1px solid #ccc', minHeight: '100px', padding: '5px' }}
      ></div>

      {/* Display selected files */}
      {/* {selectedFiles.length > 0 && (
        <div>
          <p>Selected Files:</p>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )} */}
         
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent:MediaQueryTickit? "left":"flex-end",
              marginTop:MediaQueryTickit?"17%":"2%"
            }}
          >
            <Box
              onClick={() => {handleSubmit();}}
              style={{ ...Styles.reqButton, justifyContent: "space-around" }}
            >
              <Typography style={Styles.text3}>Save</Typography>
            </Box>
            <Box
              onClick={() => {
                props.setActive(1);
              }}
              style={{ ...Styles.cancelButton, justifyContent: "space-around" }}
            >
              <Typography
                style={{
                  ...Styles.text3,
                  color: "#144C89",
                  fontWeight: "bolder",
                }}
              >
                Cancel
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      </Box>
    </Box>
  );
}
 