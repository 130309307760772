import {
    Box,
    Typography,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    TextField,
    useMediaQuery,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Styles from "../../Styles/AdminDashboard";
  import axios from "axios";


  export default function AMCselectedchange({ AMCOpen, setAMCOpen, selectedChecklist,projectselected,getCheckList }) {
  const handleClose = () => {
    setAMCOpen(false);
  };

  const [data,setData] = useState();
  const [assignTo,setAssignto] = useState([]);
  const [edittargetdate, setedittargetdate] = useState(false);

  const inputDateString1 = selectedChecklist.performing_date;
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDate1 = new Date(inputDateString1).toLocaleDateString("en-US", options);
  // console.log("date for updating",selectedChecklist,"new dayte",formattedDate1);


  
  const handleInputChange = (key, value) => {
    // console.log("all data: ", key, value);
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const [targetDate, setTargetDate] = useState(null);

  const handleTargetDateChange = (event) => {
    const selectedDateString = event.target.value;
    const selectedDateObject = new Date(selectedDateString);
    setTargetDate(selectedDateObject);
    // console.log(selectedDateObject.toLocaleDateString("en-GB")); // Log in dd/mm/yy format
  };

  useEffect(() => {
    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getprojectteam`,
          {
            organisation: localStorage.getItem("orgn_id"),
            project_id: projectselected.project_id,
          }
        );
        //console.log(response.data.data,"project api oooooooo");
        if (response.data.status === 1) {
          setAssignto(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    allMembers();
  }, []);

  const handleSave = async() => {
    //console.log("data?.assignto",data?.assignto);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/updatechecklisttask`,
        {
          "id":selectedChecklist.id,
          "assigned_user_id":data?.assignto ? data?.assignto:selectedChecklist.assigned_user_id,
          "completed_status":data?.completed_status ? data?.completed_status:selectedChecklist.completed_status,
          "performing_date":targetDate?targetDate: selectedChecklist.performing_date,
          "user_id":localStorage.getItem("user_id")
        }
      );
      //console.log(response.data.data,"cheklist completed",response.data.responseassignee);
      if (response.data.status) {
        // setAssignto(response.data.data);
        // props.setsubCheckLists(response.data.responseassignee);
        // props.setsubCheckLists((prevLogs) => [...prevLogs, ...response.data.responseassignee]);
        // console.log("This is the performing date",selectedChecklist.performing_date);
        // console.log("targetDate",targetDate);
        //alert("Task Updated");
        handleClose();
      }
    } catch (e) {
      console.log(e);
    }
    getCheckList();
    setAMCOpen(false);
  }

  return (
    <Modal
      open={AMCOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box style={{...Styles.statusnotify_changestat,width:"703px",height: "700px",}}>
            <Box style={Styles.statusnotifyTitlebutton_changestat}>
              <h2
                id="parent-modal-title"
                style={{
                  ...Styles.statusTitle_changestat,
                  color: "#082A49",
                  fontSize: "24px",
                  textAlign: "flex-start",
                  width: "80%",
                }}
                //onClick={()=>{setNameEdit(true)}}
              >
                {selectedChecklist.name}
              </h2>
              <p
                onClick={handleClose}
                style={{...Styles.statusnotifyXbutton_changestat,display:'flex',justifyContent: 'right',marginLeft:"auto",marginTop:"3.5%"}}
              >
                X
              </p>
            </Box>


            <Box
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridTemplateRows: "1fr 1fr",
              }}
            >
              {/* First row */}
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "1",
                  gridRow: "1",
                  ...Styles.statusText_changestat,
                }}
              >
                Completion status
              </p>
              <div style={{ gridColumn: "2", gridRow: "1" }}>
                {/* You can add any content or leave it empty */}
              </div>
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "3",
                  gridRow: "1",
                  textAlign: "right",
                  ...Styles.statusText_changestat,
                }}
              >
                Change Status
              </p>
  
              {/* Second row */}
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "1",
                  gridRow: "2",
                  textAlign: "left",
                  ...Styles.statusCurrent_changestat,
                }}
              >
                {selectedChecklist.completed_status}
              </p>
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "2",
                  gridRow: "2",
                  textAlign: "center",
                  color: "#A9A9A9",
                }}
              >
                to
              </p>
              <FormControl
                  size="small"
                  style={{
                    ...Styles.FormControl,
                    height: "40px",
                    marginTop: "1.5%",
                    //   marginLeft: "2.4%",
                  }}
                  fullWidth
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={sort}
                    // label="Select company"
                    style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                    size="small"
                    value={data?.completed_status ? data?.completed_status : "notcompleted"}
                    onChange={(e) =>
                      handleInputChange("completed_status", e.target.value)
                    }
                    // onChange={(event) => setsort(event.target.value)}
                  >
                    {/* ... (other Select menu items) */}
                    <MenuItem value={"notcompleted"}>Not Completed</MenuItem>
                    <MenuItem value={"completed"}>Completed</MenuItem>
                  </Select>
                </FormControl>
            </Box>


            <Box
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridTemplateRows: "1fr 1fr",
              }}
            >
              {/* First row */}
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "1",
                  gridRow: "1",
                  ...Styles.statusText_changestat,
                }}
              >
                Current assignee
              </p>
              <div style={{ gridColumn: "2", gridRow: "1" }}>
                {/* You can add any content or leave it empty */}
              </div>
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "3",
                  gridRow: "1",
                  textAlign: "right",
                  ...Styles.statusText_changestat,
                }}
              >
                Change assignee
              </p>
  
              {/* Second row */}
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "1",
                  gridRow: "2",
                  textAlign: "left",
                  ...Styles.statusCurrent_changeassign,
                }}
              >
                {selectedChecklist.assigned_user_name}
              </p>
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "2",
                  gridRow: "2",
                  textAlign: "center",
                  color: "#A9A9A9",
                }}
              >
                to
              </p>
              <FormControl
                    size="small"
                    style={{
                      ...Styles.FormControl,
                      height: "40px",
                      marginTop: "1.5%",
                      //   marginLeft: "2.4%",
                    }}
                    fullWidth
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={sort}
                      // label="Select company"
                      style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                      size="small"
                      onChange={(e) =>
                        handleInputChange("assignto", e.target.value)
                      }
                      // onChange={(event) => setsort(event.target.value)}
                    >
                      {/* ... (other Select menu items) */}
                      {assignTo?.map((item, index) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
            </Box>


            


              <Box style={{justifyContent: "flex-end" }}>
              <p
                id="parent-modal-description"
                style={{
                  ...Styles.statusText_changestat, lineHeight: '0px',
                }}
              >
                Targeted Date
              </p>
              <Box>
                <FormControl
                  size="small"
                  style={{
                    ...Styles.FormControl,
                    height: "40px",
                    marginTop: "1.5%",
                    alignItems: "center",
                    justifyContent: "center",
                    //   marginLeft: "2.4%",
                  }}
                  fullWidth
                >
                  {!edittargetdate ? (
                    <Typography
                      onClick={() => {
                        setedittargetdate(true);
                      }}
                      style={{
                        width: "90%",
                        fontSize: "14px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#1282A2 ",
                        fontFamily: "Montserrat",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {formattedDate1}
                      <img
                        style={{ width: "20px", height: "12px" }}
                        src={require("../../Images/downArrow.png")}
                      />
                    </Typography>
                  ) : (
                    <TextField
                      type="date"
                      // value={formattedDateString || oldDateObject} // Use formatted string or Date object
                      onChange={handleTargetDateChange}
                      InputLabelProps={{ style: { display: "none" } }}
                      InputProps={{
                        style: {
                          border: "none",
                          height: "100%",
                          // width: "100%",
                          color: "#1282A2 ",
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        ...Styles.inputBox,
                        width: "100%",
                        height: "100%",
                        marginTop: "0%",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        margin: "auto",
                      }}
                    />
                  )}
                </FormControl>
                </Box>
              </Box>

            <Box id="status-notify-entry">
              <Button
                variant="contained"
                onClick={()=>{handleSave()}}
                style={{...Styles.statusnotifysave_changestat, top: '686px', left: '251px',}}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{...Styles.statusnotifycancel_changestat, top: '686px', left: '420px',}}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
            </Box>
    </Modal>
  );
};
