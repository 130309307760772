import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    TextField,
  } from "@mui/material"; 
import Styles from "../Styles/AdminDashboard";
import axios from "axios";
export default function ChangeAsigneePopup(props) {
    const [open, setOpen] = React.useState(false);
    const prevData = props.allLogs;
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      props.setOpen(false);
    };
    const [assignstatus, setassignStatus] = React.useState("");
  
    const handleassignChange = (event) => {
      setassignStatus(event.target.value);
    };
    const [assignTo,setAssignto] = useState([]);

    useEffect(() => {
      const allMembers = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/getalluser`,
            { "organisation": localStorage.getItem("orgn_id")}
          );
          // console.log(response.data.data,"project api oooooooo");
          if (response.data.status === 1) {
            setAssignto(response.data.data);
          }
        } catch (e) {
          // console.log(e);
        }
      };
      allMembers();
    }, []);

    const handleSave = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/assignticketstouser`,
          {
            id: props.id,
            assigned_to: assignstatus,
            assigned_by: localStorage.getItem("user_id"),
            organisation: localStorage.getItem("orgn_id"),
            ticket_id: props.id,
          }
        );
    
        if (response.data.status === 1) {
          // Update state using the function form of setAllLogs
          // props.setAllLogs((prevData) => {
          //   // Assuming the response.data.data is an array to merge with the previous data
          //   return [...prevData, response.data.data];
          // });
          props.setHandleRefresh(true);
          handleClose();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle the error, e.g., show an error message to the user
      }
    };
    
  
  return (
    <Box>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box style={Styles.statusnotify_changeassign}>
          <Box style={Styles.statusnotifyTitlebutton_changestat}>
            <h2 id="parent-modal-title" style={Styles.statusTitle_changestat}>
              Change assignee
            </h2>
            <p
              onClick={handleClose}
              style={{...Styles.statusnotifyXbutton_changestat,display:'flex',justifyContent: 'right',marginLeft:"auto",marginTop:"4.5%"}}
            >
              X
            </p>
          </Box>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridTemplateRows: "1fr 1fr",
            }}
          >
            {/* First row */}
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "1",
                gridRow: "1",
                ...Styles.statusText_changestat,
              }}
            >
              Current assignee
            </p>
            <div style={{ gridColumn: "2", gridRow: "1" }}>
              {/* You can add any content or leave it empty */}
            </div>
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "3",
                gridRow: "1",
                textAlign: "right",
                ...Styles.statusText_changestat,
              }}
            >
              Change assignee
            </p>

            {/* Second row */}
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "1",
                gridRow: "2",
                textAlign: "left",
                ...Styles.statusCurrent_changeassign,
              }}
            >
              {props.assigned_name}
            </p>
            <p
              id="parent-modal-description"
              style={{
                gridColumn: "2",
                gridRow: "2",
                textAlign: "center",
                color: "#A9A9A9",
              }}
            >
              to
            </p>
            <FormControl style={{ gridColumn: "3", gridRow: "2" }}>
              <InputLabel id="simple-select-label">Select assignee</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={assignstatus}
                label="Status"
                InputProps={{ style: { border: "none" } }}
                style={Styles.statusselectlabel_changestat}
                onChange={handleassignChange}
              >
                 {assignTo?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          </Box>

          <Box id="status-notify-entry">
            <Button
            onClick={handleSave}
              variant="contained"
              style={Styles.statusnotifysave_changeassign}
            >
              Save
            </Button>
            <Button
              variant="contained"
              style={Styles.statusnotifycancel_changeassign}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
