import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    TextField,
  } from "@mui/material"; 
import Styles from "../../Styles/AdminDashboard";
import axios from "axios";
export default function SeemoreTd(props) {
    const { cardData } = props;
    console.log(props,"ewdewdewdewd hello");
    const [open, setOpen] = React.useState(false);
    const prevData = props.allLogs;
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      props.setOpen(false);
    };
    const [assignstatus, setassignStatus] = React.useState("");
  
    const handleassignChange = (event) => {
      setassignStatus(event.target.value);
    };
    const [assignTo,setAssignto] = useState([]);

    // useEffect(() => {
    //   const allMembers = async () => {
    //     try {
    //       const response = await axios.post(
    //         `${process.env.REACT_APP_API_URL}/getalluser`,
    //         { "organisation": localStorage.getItem("orgn_id")}
    //       );
    //       // console.log(response.data.data,"project api oooooooo");
    //       if (response.data.status === 1) {
    //         setAssignto(response.data.data);
    //       }
    //     } catch (e) {
    //       // console.log(e);
    //     }
    //   };
    //   allMembers();
    // }, []);

    // const handleSave = async () => {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_API_URL}/assignticketstouser`,
    //       {
    //         id: props.id,
    //         assigned_to: assignstatus,
    //         assigned_by: localStorage.getItem("user_id"),
    //         organisation: localStorage.getItem("orgn_id"),
    //         ticket_id: props.id,
    //       }
    //     );
    
    //     if (response.data.status === 1) {
    //       // Update state using the function form of setAllLogs
    //       // props.setAllLogs((prevData) => {
    //       //   // Assuming the response.data.data is an array to merge with the previous data
    //       //   return [...prevData, response.data.data];
    //       // });
    //       props.setHandleRefresh(true);
    //       handleClose();
    //     }
    //   } catch (error) {
    //     console.error("An error occurred:", error);
    //     // Handle the error, e.g., show an error message to the user
    //   }
    // };
    
  
  return (
    <Box>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box style={{position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', fontFamily: 'Montserrat', padding: 30, width: "40%", height: "50%", backgroundColor: '#FFFFFF', borderRadius: '20px', boxShadow: 'none', pt: 2, px: 4, pb: 3,}}>
          <Box style={{...Styles.statusnotifyTitlebutton_changestat,}}>
     
            <p
              onClick={handleClose}
              style={  {color: '#000000',cursor: 'pointer',marginTop:"0%",display:'flex',justifyContent:"flex-end",alignSelf:"flex-end",marginLeft:"auto"} }
            >
              X
            </p>
          </Box>

       <Typography style={{fontSize:"18px",fontWeight:'bold',color:'#1282A2',margin:1,marginBottom:3}}>{cardData.taskname?"Task Name:":"Ticket Name:"}</Typography>
<Typography style={{ fontSize:"18px",fontWeight:'bold',marginBottom:"10px"}}>{cardData.ticket_name?cardData.ticket_name:cardData.taskname}</Typography>
<Box style={{overflowY:"auto",height:150}}>
<Typography style={{fontSize:"18px",fontWeight:'bold',color:'#1282A2',margin:1,marginBottom:3}}>{cardData.taskname?"Task Description:":"Ticket Description:"}</Typography>
<Typography tyle={{ fontSize:"14px",fontWeight:'bold',marginBottom:3}}>{cardData.description}</Typography>
</Box>
</Box>
      </Modal>
    </Box>
  );
}

