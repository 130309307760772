import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  TextField,
} from "@mui/material";
import Styles from "../../Styles/AdminDashboard";
import axios from "axios";
export default function Notificationpopup(props) {
  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
    props.setOpen(false);
  };
  
  const [notificationinfo, setnotificationinfo] = useState([]);
// console.log("adsavfdvsdfbvdfbdv", notificationinfo)
  useEffect(() => {
    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallnotificationinfo`,
          { user_id: localStorage.getItem("user_id") }
        );
        // console.log(response.data.data, "project api oooooooo");
        if (response.data.status === 1) {
          setnotificationinfo(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    allMembers();
  }, []);

  const handleDetail = (card) => {
    props.setNavigateState(true);
    props.setnavigateData(card);
    handleClose();
    // alert(card.uname,"clicked data");
};

  const removeNotifications = async(event,nId)=>{
    event.stopPropagation(); // Stop the event from propagating
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/deactivatenotification`,
        { "id": nId }
      );
      // console.log(response.data.data, "Notification Removed");
      if (response.data.status) {
        setnotificationinfo((prevNotify) => [...prevNotify, ...response.data.data]);
        handleClose();
        props.setNotificationRemoved(true);
      }
    } catch (e) {
      console.log(e);
    }
    // console.log("Deleterered",nId);
  }

  return (
    <Box>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          style={{
            ...Styles.statusnotify_changeassign,
            width: 800,
            height: 400,
            overflow: "auto",
            border: 5,
          }}
        >
          {notificationinfo.map((item, index) => (
            <Box
            onClick={()=>handleDetail(item)}
              key={index}
              sx={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
                height: 50,
                zIndex:2,
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              {item.type === "ticket" && (
                <img
                  src={require("../../Images/ticketiconnotify.png")}
                  style={{ height: 30 }}
                />
              )}
              {item.type === "task" && (
                <img
                  src={require("../../Images/taskiconnotify.png")}
                  style={{ height: 30 }}
                />
              )}
              {item.type === "project" && (
                <img
                  src={require("../../Images/projectnotification.png")}
                  style={{ height: 30 }}
                />
              )}

              <Box
                sx={{
                  width: "80%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    color: "#082A49",
                    fontWeight: 700,
                    fontSize: 18,
                   
                  }}
                >
                  {item.uname}{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#082A49",
                    fontSize: 18,
                    marginRight: 1,
                    marginLeft: 1,
                  }}
                >
                  {" "}
                  {item.name}{" "}
                </Typography>
                {/* <Typography sx={{color:'#082A49',fontWeight:700,fontSize:18}} >  you </Typography> */}
                {/* <Typography sx={{color:'#082A49',fontSize:18,marginRight:1,marginLeft:1}} >  ticket for</Typography> */}
                <Typography
                  sx={{ color: "#082A49", fontWeight: 700, fontSize: 18 , whiteSpace: "nowrap", // Add this line to prevent line breaks
                  overflow: "hidden", // Add this line to hide overflow if the text is too long
                  textOverflow: "ellipsis",}}
                >
                {item.ticket_name && item.ticket_name.length > 25
                  ? item.ticket_name.substring(0, 25) 
                  : item.ticket_name}
                  <>
                  {" "}
                  <span style={{  color: "#1282A2", cursor: "pointer",fontSize:"13px"  }}  >
                    ....
                  </span>
                </>
              </Typography>

                {/* <Typography sx={{color:'grey',fontWeight:700,fontSize:18,textAlign:'right'}} >X </Typography> */}
              </Box>
              <img onClick={(event)=>{removeNotifications(event,item.nid)}} style={{cursor:"pointer",zIndex:22}} src={require("../../Images/Iconcancel.png")}/>
            </Box>
          ))}
        </Box>
      </Modal>
    </Box>
  );
}

