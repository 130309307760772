import { Box, MenuItem, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function SearchCard(props) {
 
  const handleDetail = (card) => {
    console.log("card details", card);
    if (props.card.table_name === "tickets_master") {
        getsystemdata(props.card.id);
        props.setIsTextFieldSelected(false);
      console.log("handleDetail called with ticket master:");
    } else if (props.card.table_name === "users_master") {
        props.setActive(2);
        props.setIsTextFieldSelected(false);
      console.log("handleDetail called with users master:");
    } else if (props.card.table_name === "project_master") {
        getchecklisttask(props.card.id);
        props.setIsTextFieldSelected(false);
      console.log("handleDetail called with project master:");
    } else {
      getchecklisttask(props.card.project_id);
      if(props.card.turnkey_id){
      gettaskdata((props.card.turnkey_id || props.card.checklist_id),props.card.id)
      }
      else{
        getcheckliststskdata(props.card.checklist_id,props.card.id)
      }
      props.setIsTextFieldSelected(false);
      console.log("handleDetail called with task master:");
    }
  };

//   useEffect(() => {
//     getsystemdata();
//   }, []);

  const getsystemdata = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getuserspecificticketsid`,
        {
          user_id: localStorage.getItem("user_id"),
          raisedto_organisation: localStorage.getItem("user_id"),
          organisation: localStorage.getItem("orgn_id"),
          role: localStorage.getItem("role"),
          id:id
        }
      );
      // console.log(response.data.data,"project api");
      if (response.data.status === 1) {
        // setAlltickets(response.data.data);
        props.setTicketDetails(response.data.data[0]);
        props.setIsTextFieldSelected(false);
        props.setActive(9);
        props.setNavigateState(false);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const gettaskdata = async (chkid,id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallturnkeytaskid`,
        {
          chktskid: chkid,
          id:id,
          user_id:localStorage.getItem("user_id")
        }
      );
      // console.log(response.data.data, "turnkeys  data");
      if (response.data.status === 1) {
        // setsubCheckLists(response.data.data);
        props.settaskSelected(response.data.data[0]);
        // props.setcheckLists(props.checkLists);
        props.setActive(16);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getcheckliststskdata = async (id,tskid) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getalltaskforchecklisttskid`,
        {
            "chktskid": id,
            "id":tskid
        }
      );
      // console.log(response.data.data, "turnkeys  data newwww");
      if (response.data.status === 1) {
        props.settaskSelected(response.data.data[0]);
        props.setActive(16);
      }
    } catch (e) {
      console.log(e);
    }
  };

    const getchecklisttask = async (id) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallprojectsbyid`,
          {
            user_id: localStorage.getItem("user_id"),
            id:id
          }
        );
        if (response.data.status === 1) {
            props.setActive(3);
            props.setTaskData(response?.data?.data[0])
        }
      } catch (e) {
        console.log(e);
      }
    };

  return (
    <Box ref={props.boxRef}>
      <MenuItem
        style={{ display: "flex", justifyContent: "space-between" }}
        onClick={() => {
          handleDetail(props.card);
        }}
      >
        {props.card.name && props.card.name.length > 20
          ? `${props.card.name.substring(0, 20)}`
          : props.card.name}
        {props.card.name && props.card.name.length > 20 && (
          <>
            {" "}
            <span
              style={{
                color: "#1282A2",
                cursor: "pointer",
                fontSize: "13px",
              }}
            >
              ...
            </span>
          </>
        )}
        <Typography style={{ justifyContent: "flex-end", fontSize: "12px" }}>
          {props.card.table_name === "users_master"
            ? "User"
            : props.card.table_name === "tickets_master"
            ? "Ticket"
            : props.card.table_name === "task_master"
            ? "Task"
            : props.card.table_name === "project_master"
            ? "Project"
            : ""}
        </Typography>
      </MenuItem>
    </Box>
  );
}
