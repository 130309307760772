import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";



export default function MyTeam(props) {
  const mediaQueryMyTeam = useMediaQuery('(max-width:1302px)');
  const mediaQueryMyTeamUpper = useMediaQuery('(max-width:873px)');
  const [isEmailInOrgUsers, setIsEmailInOrgUsers] = useState(false);
  const [focused, setFocused] = React.useState(false);
  const [editpopup, setEditPopup] = useState(false);
  const [data, setData] = useState({
    "designation":'-',
    "department":'-',
  });
  const handleChange = (key, value) => {
    // console.log("data is:",key, value);
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const [sort, setsort] = useState("");
  const handlesortChange = (event) => {
    setsort(event.target.value);
  };
  const handleClose = () => {
    setEditPopup(false)
  }
  const [showForm, setshowForm] = useState(false);
  const [orgusers , setorgUsers]=useState([])
  useEffect(() => {
    // Check email in orgusers whenever the email value changes
    checkEmailInOrgUsers();
  }, [data.email, orgusers]);


  const checkEmailInOrgUsers = () => {
    // Check if the filled email matches any email in orgusers
    const emailExists = orgusers.some((user) => user.email === data.email);

    // Set the state based on the result
    setIsEmailInOrgUsers(emailExists);
  };
  useEffect(()=>{
    
    getteam()
  },[sort]);
  useEffect(() => {
    getsystemdata();
  }, []);
  const [role , setRole]=useState([])
  const [designation , setDesignation]=useState([])
  const [department , setDepartment]=useState([])

  let sortoption0="Admin"
  let sortoption1="Finance"
  let sortoption2="Support"
  let sortoption3="Web Development"
  let sortoption4="Android Development"
  let sortoption5="IOS Development"
  let sortoption6="Design"
  let sortoption7="Backend Development"
  let sortoption8="Software Development"

//   const getteam = async()=>{
//     let datas={
//       ...data,
//       "organisation":localStorage.getItem("orgn_id"),
//       // "organisation":'5',
//       "password":"cbs12345"
//     }
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/getalluser`,
//         datas
//       );
//       // console.log("users of this organisation",response.data)
//       if (response?.data?.status===1) {
//         setorgUsers(response?.data?.data)
// getsystemdata()
        
//       }
//     } catch (e) {
//       // console.log(e);
//     }
//   }

  const getteam = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallreportinguser`,
        { "user_id": localStorage.getItem("user_id"),"role":localStorage.getItem("role"), "raised_organisation_id":  localStorage.getItem("orgn_id") ,"organisation_admin": localStorage.getItem("orgn_id")}
      );
      // console.log(response.data.data,"team api for role");
      if (response.data.status === 1) {
        setorgUsers(response.data.data);
        getsystemdata()
      }
    } catch (e) {
      // console.log(e);
    }
  };

const getsystemdata = async()=>{
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/getsystemconfiguration`,
      data
    );
    // console.log(response.data)
    if (response.data.status===1) {
      setRole(response?.data?.responserole)
      setDesignation(response?.data?.responsedesignation)
      setDepartment(response?.data?.data)
      
    }
  } catch (e) {
    // console.log(e);
  }
}


const updateTeammember = async(id,designation,department,reporting_to) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/updateteammember`,
      {
        "id":id,
        "designation":designation,
        "department": department,
        "reporting_to": reporting_to
      }
      
    );
    if (response.data.status === 1) {
    // console.log("Updated Team Member", id);
    getteam();
    setEditPopup(false);
    }
  } catch (e) {
    // console.log(e);
  }
};
  

  const [errorname , setErrorName]=useState(false)
  const [errordesignation , setErrorDesignation]=useState(false)
  const [errordepartmentvalue , setErrorDepartmentValue]=useState(false)
  const [errorreportto , setErrorReportTo]=useState(false)
  const [erroremail , setErrorEmail]=useState(false)
  const [startvalidation , setStartValidation]=useState(false)
  useEffect(() => {
    if (startvalidation) {
    setErrorName(!data.name);
    // setErrorDesignation(!data.designation);
    // setErrorDepartmentValue(!data.department);
    setErrorReportTo(!data.reporting_to);
    setErrorEmail(!data.email);
    }
  }, [startvalidation, data]);
  
  const validateForm = () => {
    if(!startvalidation){
      return false;
    }
    if (
      errorname ||
    
      errorreportto ||
      erroremail
    ) {
      // console.log('I have restricted him');
      return false;
    }
  
    return true;
  };
  const adduserorganisation = async()=>{
  if(!isEmailInOrgUsers){
    let datas={
      ...data,
      "organisation":localStorage.getItem("orgn_id"),
      // "organisation":'5',
      "password":"cbs12345",
      "role":"user",
      "organisation_admin":localStorage.getItem("orgn_id"),// vegali id
    }

    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/adduser`,
        datas
      );
      // console.log("users of this organisation",response.data)
      if (response?.data?.status===1) {
        // setorgUsers(response?.data?.data)
        setshowForm(false)
        getteam()
      }
    } catch (e) {
      // console.log(e);
    }
     }else{
    alert(`${data.email}, This email is already used.`)
  }
  }

  const handleOpenModal = (row) => {
    setData({
      id: row.id,
      name: row.name,
      designation: row.designation,
      department: row.department,
      reporting_to: row.reportingData[0]?.id || '',
      email: row.email,
    });
    setEditPopup(true);
  };
  return (
    <Box style={Styles.mainBox}>
      <Box style={{ ...Styles.upperBox, flexDirection: mediaQueryMyTeamUpper ? "column" : "row" }}>
        <Typography style={Styles.name}>My&nbsp;team</Typography>
        <Box
          style={{
            width: "100%", 
            display: "flex",
            flexDirection: mediaQueryMyTeamUpper ? "column" : "row",
      justifyContent: mediaQueryMyTeamUpper ? "space-between" : "flex-end", 
      alignItems: mediaQueryMyTeamUpper ? "flex-start" : "center",
            marginTop: mediaQueryMyTeamUpper ? "20px":"",
            marginLeft: mediaQueryMyTeamUpper ? "30px":"",
          }}
        >
          <FormControl
            size="small"
            style={{
              ...Styles.FormControl,
              backgroundColor: "#144C89",
              height: "40px",
              marginRight: mediaQueryMyTeamUpper ? "0" : "2%",
              marginBottom: mediaQueryMyTeamUpper ? "5px" : "0", 
            }}
            fullWidth
          >
            <InputLabel
              style={{ fontFamily: "Montserrat", color: "#FFFFFF" }}
              id="demo-simple-select-label"
            >
              {sort ? "" : "Sort by"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sort}
              label={sort ? "" : "Sort by"}
              style={{ ...Styles.selectBox, color: "#FFFFFF" }}
              size="small"
              onChange={handlesortChange}
              IconComponent={() => (
                <img
                  style={{ marginRight: "5%" }}
                  src={require("../Images/filter.png")}
                />
              )}
            >
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption0}>
              {sortoption0}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption1}>
              {sortoption1}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption2}>
              {sortoption2}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption3}>
                {sortoption3}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption4}>
                {sortoption4}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption5}>
                {sortoption5}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption6}>
              {sortoption6}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption7}>
              {sortoption7}
              </MenuItem>
              <MenuItem style={{ fontFamily: "Montserrat" }} value={sortoption8}>
              {sortoption8}
              </MenuItem>
              
            </Select>
          </FormControl>
          <Box
            onClick={() => {
              setData({});
              setshowForm(true);
            }}
            style={{ ...Styles.reqButton, marginTop: mediaQueryMyTeamUpper ? "10px" : "0",}}
          >
            <Typography style={Styles.text3}>New user</Typography>
            <img src={require("../Images/plus.png")} />
          </Box>
        </Box>
      </Box>
      {showForm && (
        <Box
          style={{
            width: "90%",
            margin: "auto",
            height: mediaQueryMyTeamUpper ? "50%" : "25vh",
            backgroundColor: "#F8F8F8",
            borderRadius: "15px",
            marginBottom: "2%",
            display: "flex",
            marginTop: mediaQueryMyTeamUpper ? "16%" : "",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              marginTop: mediaQueryMyTeamUpper ? "10%" : "2%",
              width: "100%",
              margin: "auto",
              height: mediaQueryMyTeamUpper ? "70%" : "40%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: mediaQueryMyTeamUpper ? "flex-start" : "center",
              flexDirection: mediaQueryMyTeamUpper ? "column" : "row",
              flexWrap:mediaQueryMyTeamUpper?"nowrap":"wrap"
              // border: '1px solid red'
            }}
          >
          <Box style={{width:mediaQueryMyTeamUpper?"100%":"50%",display:"flex",height:"60%",alignItems:"center",justifyContent:"center"}}>
              <Typography
                  sx={{
                    width: mediaQueryMyTeamUpper ? '100%' : '20%',
                    color: '#082A49',
                    fontWeight: 500,
                    fontSize: mediaQueryMyTeamUpper ? 15 : 20,
                    marginBottom: mediaQueryMyTeamUpper ? '8px' : 0,
                  }}
                >
                  Name
              </Typography>
              <TextField
                id="outlined-basic"
                value={data.name}
                onChange={(e) => {
                  handleChange("name", e.target.value);
                }}
                // label="Name"
                variant="standard"
                // InputLabelProps={{ shrink: true }}
              />
              {errorname &&
            <Typography sx={{color:'red'}} >Please Enter Name</Typography>
          }
            </Box>
            {
  localStorage.getItem("role")==="admin" &&(   
            <Box style={{width:mediaQueryMyTeamUpper?"100%":"50%",display:"flex",height:"60%",alignItems:"center",justifyContent:"center"}}>
               <Typography
                  sx={{
                    width: mediaQueryMyTeamUpper ? '100%' : '20%',
                    color: '#082A49',
                    fontWeight: 500,
                    fontSize: mediaQueryMyTeamUpper ? 15 : 20,
                    marginBottom: mediaQueryMyTeamUpper ? '8px' : 0,
                  }}
                >
                  Designation
              </Typography>
            <FormControl variant="standard" sx={{  minWidth: 120 ,width:210}}>
        {/* <InputLabel id="demo-simple-select-standard-label" >Designation</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={data.designation}
          onChange={(e) => {
            handleChange("designation", e.target.value);
          }}
          label="designation"
          // InputLabelProps={{ shrink: false }}
        >
         {
          designation?.map((item)=>(
            
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))
         }

          
        </Select>
      </FormControl>
      {errordesignation &&
            <Typography sx={{color:'red'}} >Please Select Designation</Typography>
          }
            </Box>
  )}
   {
  localStorage.getItem("role")==="admin" &&(  
            <Box style={{width:mediaQueryMyTeamUpper?"100%":"50%",display:"flex",height:"60%",alignItems:"center",justifyContent:"center"}}>
           <Typography
                  sx={{
                    width: mediaQueryMyTeamUpper ? '100%' : '20%',
                    color: '#082A49',
                    fontWeight: 500,
                    fontSize: mediaQueryMyTeamUpper ? 15 : 20,
                    marginBottom: mediaQueryMyTeamUpper ? '8px' : 0,
                  }}
                >
                  Department
              </Typography>
            <FormControl variant="standard" sx={{  minWidth: 120 ,width:210}}>
        {/* <InputLabel id="demo-simple-select-standard-label" >Department</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={data.department}
          onChange={(e) => {
            handleChange("department", e.target.value);
          }}
          label="Role"
          // InputLabelProps={{ shrink: false }}
        >
         {
          department?.map((item)=>(
            
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))
          }
          
        </Select>
      </FormControl>
      {errordepartmentvalue &&
            <Typography sx={{color:'red'}} >Please Select Department</Typography>
          }
            </Box>
   )}         
            <Box style={{width:mediaQueryMyTeamUpper?"100%":"50%",display:"flex",height:"60%",alignItems:"center",justifyContent:"center"}} >
            <Typography
                  sx={{
                    width: mediaQueryMyTeamUpper ? '100%' : '20%',
                    color: '#082A49',
                    fontWeight: 500,
                    fontSize: mediaQueryMyTeamUpper ? 15 : 20,
                    marginBottom: mediaQueryMyTeamUpper ? '8px' : 0,
                  }}
                >
                  Reporting To
              </Typography>
            <FormControl variant="standard" sx={{  minWidth: 120 ,width:210}}>
        {/* <InputLabel id="demo-simple-select-standard-label" >Reporting To</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={data.reporting_to}
          onChange={(e) => {
            handleChange("reporting_to", e.target.value);
          }}
          label="Role"
          // InputLabelProps={{ shrink: false }}
        >
         {
          orgusers?.map((item)=>(
            
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))
          }
          
        </Select>
      </FormControl>
      {errorreportto &&
            <Typography sx={{color:'red'}} >Please Select Reporting To</Typography>
          }
            </Box>
            <Box style={{width:mediaQueryMyTeamUpper?"100%":"50%",display:"flex",height:"60%",alignItems:"center",justifyContent:"center"}}  >
            <Typography
                  sx={{
                    width: mediaQueryMyTeamUpper ? '100%' : '20%',
                    color: '#082A49',
                    fontWeight: 500,
                    fontSize: mediaQueryMyTeamUpper ? 15 : 20,
                    marginBottom: mediaQueryMyTeamUpper ? '8px' : 0,
                  }}
                >
                  E-mail
              </Typography>
              <TextField
                id="standard-basic"
                value={data.email}
                onChange={(e) => {
                  handleChange("email", e.target.value);
                }}
                // label="E-mail"
                variant="standard"
                //   color="warning"
                // InputLabelProps={{ shrink: true }}
              />
              {erroremail &&
            <Typography sx={{color:'red'}} >Please Select Email</Typography>
          }
            </Box>
            <Box style={{width:mediaQueryMyTeamUpper?"100%":"50%",display:"flex",height:"60%",alignItems:"center",justifyContent:"center"}}  >
           
            </Box>
          </Box>
          <Box
            style={{ width: mediaQueryMyTeamUpper?"50%":"98%", display: "flex", justifyContent: "flex-end", flexDirection: mediaQueryMyTeamUpper ? "column" : "row" }}
          >
              
            <Box onClick={() => {setStartValidation(true); if (validateForm()) {console.log('.'); adduserorganisation();}}} style={{ ...Styles.reqButton,marginBottom:"1%", width: mediaQueryMyTeamUpper ? "90px" : "160px"}}>
            {/*<Box onClick={() => setStartValidation(true)  if (validateForm()) {adduserorganisation()}} style={{ ...Styles.reqButton,marginBottom:"1%", width: mediaQueryMyTeamUpper ? "90px" : "160px"}}>*/}
              <Typography style={Styles.text3}>Save</Typography>
            </Box>
            <Box onClick={()=>{setshowForm(false)}} style={{ ...Styles.cancelButton,marginBottom:"1%", width: mediaQueryMyTeamUpper ? "90px" : "160px" }}>
              <Typography style={{...Styles.text3,color:"#144C89",fontWeight:"bolder"}}>Cancel</Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box style={{ marginTop: mediaQueryMyTeamUpper ? "29%" : "3%", width: "90%", margin: "auto", height: "37vh" }}>
        <Box style={Styles.tableBox2}>
          <TableContainer
            component={Paper}
            elevation={0}
            style={{height: "calc(100% - 5px)",  overflowY: "scroll",  position: "relative",}}
          >
            <Table style={Styles.table} aria-label="simple table">
              <TableHead style={Styles.tableHead}>
                <TableRow>
                  <TableCell style={mediaQueryMyTeam ? { ...Styles.tableCell, fontSize: "13px" } : Styles.tableCell} align="left">
                    No
                  </TableCell>
                  <TableCell style={mediaQueryMyTeam ? { ...Styles.tableCell, fontSize: "13px" } : Styles.tableCell} align="left">
                    Name
                  </TableCell>
                  {
                    localStorage.getItem("role")==="admin" &&(
                  <TableCell style={mediaQueryMyTeam ? { ...Styles.tableCell, fontSize: "13px" } : Styles.tableCell} align="center">
                    Designation
                  </TableCell>
                  )}
                   {
                    localStorage.getItem("role")==="admin" &&(
                  <TableCell style={mediaQueryMyTeam ? { ...Styles.tableCell, fontSize: "13px" } : Styles.tableCell} align="center">
                    Department
                  </TableCell>
                   )}
                  <TableCell style={mediaQueryMyTeam ? { ...Styles.tableCell, fontSize: "13px" } : Styles.tableCell} align="center">
                    Reporting To
                  </TableCell>
                  <TableCell style={mediaQueryMyTeam ? { ...Styles.tableCell, fontSize: "13px" } : Styles.tableCell} align="center">
                    Email
                  </TableCell>
                  {localStorage.getItem('role') === "admin" &&
                  <TableCell
                    style={mediaQueryMyTeam ? { ...Styles.tableCell, fontSize: "13px" } : Styles.tableCell}
                    align="center"
                  ></TableCell>
                  }
                </TableRow>
              </TableHead>

              <TableBody>
                {orgusers?.filter((row) => !sort || (typeof sort === 'string' && typeof row.department === 'string' && row.department.toLowerCase() === sort.toLowerCase()))?.map((row, index) => (
                  <TableRow style={{ height: 60 }} key={row.id}>
                    <TableCell style={mediaQueryMyTeam ? { ...Styles.tableText, fontSize: "13px" } : Styles.tableText} align="left">
                      {index+1}
                    </TableCell>
                    <TableCell style={mediaQueryMyTeam ? { ...Styles.tableText, fontSize: "13px" } : Styles.tableText} align="left">
                      {row?.name}
                    </TableCell>
                    {
                    localStorage.getItem("role")==="admin" &&(
                    <TableCell style={mediaQueryMyTeam ? { ...Styles.tableText, fontSize: "13px" } : Styles.tableText} align="center">
                      {row?.designation}
                    </TableCell>
                    )}
{
  localStorage.getItem("role")==="admin" &&(
                    <TableCell style={mediaQueryMyTeam ? { ...Styles.tableText, fontSize: "13px" } : Styles.tableText} align="center">
                      {row?.department}
                    </TableCell>
                       )}
                    <TableCell style={mediaQueryMyTeam ? { ...Styles.tableText, fontSize: "13px" } : Styles.tableText} align="center">
                      {row?.reportingData[0]?.name}
                    </TableCell>
                    <TableCell style={mediaQueryMyTeam ? { ...Styles.tableText, fontSize: "13px" } : Styles.tableText} align="center">
                      {row?.email}
                    </TableCell>
                    {localStorage.getItem('role') === "admin" &&
                    <TableCell style={mediaQueryMyTeam ? { ...Styles.tableText, fontSize: "13px" } : Styles.tableText} align="center">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => handleOpenModal(row)}
                        src={require("../Images/Iconedit.png")}
                      />
                    </TableCell>
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {editpopup &&
          <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Modal
          open={editpopup}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box style={{...Styles.statusnotify_changestat, width: 320, height: 420,}}>
              <Box style={Styles.statusnotifyTitlebutton_changestat}>
                <h2
                  id="parent-modal-title"
                  style={{
                    color: '#1282A2',
                    marginBottom: '10px',
                    fontWeight: 700,
                    fontSize: '28px',
                    lineHeight: '34.13px',
                    alignItems: 'center',
                    alignSelf: "center",
                  }}
                >
                  Edit Team Member
                </h2>
                <p
                  onClick={handleClose}
                  style={{...Styles.statusnotifyXbutton_changestat, marginTop: "9.5%",display:"flex",justifyContent: "right",marginLeft:"auto"}}
                >
                  X
                </p>
              </Box>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '4%',
                }}
              >
              <TextField
              id="standard-basic"
              label="Name"
              variant="standard"
              style={{ margin: '10px',marginLeft:"0px", width: "230px" }}
              value={data.name} // Display the data
              InputLabelProps={{ shrink: true }}
              readOnly
            />
              <Box>
            <FormControl variant="standard" sx={{  minWidth: 120 ,width:240, marginTop: '1%'}}>
        <InputLabel id="demo-simple-select-standard-label" >Designation</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={data.designation}
          onChange={(e) => {
            handleChange("designation", e.target.value);
          }}
          label="designation"
          // InputLabelProps={{ shrink: false }}
        >
         {
          designation?.map((item)=>(
            
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))
         }

          
        </Select>
      </FormControl>
            </Box>
            <Box>
            <FormControl variant="standard" sx={{  minWidth: 120 ,width:240, marginTop: '3%'}}>
        <InputLabel id="demo-simple-select-standard-label" >Department</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={data.department}
          onChange={(e) => {
            handleChange("department", e.target.value);
          }}
          label="Role"
          // InputLabelProps={{ shrink: false }}
        >
         {
          department?.map((item)=>(
            
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))
          }
          
        </Select>
      </FormControl>
            </Box>
            <Box>
            <FormControl variant="standard" sx={{  minWidth: 120 ,width:240, marginTop: '3%'}}>
        <InputLabel id="demo-simple-select-standard-label" >Reporting To</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={data.reporting_to}
          onChange={(e) => {
            handleChange("reporting_to", e.target.value);
          }}
          label="Role"
          // InputLabelProps={{ shrink: false }}
        >
         {
          orgusers?.map((item)=>(
            
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))
          }
          
        </Select>
      </FormControl>
            </Box>

                <TextField
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  style={{ margin: '10px', marginTop: '3%' ,marginLeft:"0px", width: "230px"}}
                  value={data.email} // Display the data
                  InputLabelProps={{ shrink: true }}
                  readOnly
                />
              
              </Box>
    
              <Box id="status-notify-entry">
                <Button
                  variant="contained"
                  style={{...Styles.statusnotifysave_changestat, top: '426px', left: '71px',}}
                  onClick={() => updateTeammember(data.id, data.designation, data.department, data.reporting_to)}
                  >
                  Save
                </Button>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  style={{...Styles.statusnotifycancel_changestat, top: '426px', left: '211px',}}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
              }
      </Box>
    </Box>
  );
}
