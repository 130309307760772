import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import ChecklistPopup from "./ChecklistPopup";
import Styles from "../../../Styles/AdminDashboard";
// import ChecklistInnerCard from "../../Components/ChecklistInnerCard";
// import ChecklistNameCard from "../../Components/ChecklistNameCard";
import axios from "axios";
import ChecklistNameCardamc from "../../../Components/AMC/ChecklistNameCardamc";
import Checklistpopupamc from "./Checklistpopupamc";

export default function ProjectSelectamc(props) {
  const { project } = props;
  // console.log("props in project", project.end_date);
  const [createNewVisible, setcreateNewVisible] = useState(false);
  const [isTextFieldVisible, setTextFieldVisible] = useState(false);
  const [newItemText, setNewItemText] = useState("");
  const [checkLists,setcheckLists] = useState([]);
  const handlePlusClick = () => {
    setTextFieldVisible(!isTextFieldVisible);
    // Optionally, you can clear the text field when hiding it
    if (!isTextFieldVisible) {
      setNewItemText("");
    }
  };

  useEffect(() => {
    const getsystemdata = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallchecklist`,
          {
            project_id: project.project_id ? project.project_id : project.id
          }
        );
        // console.log(response.data.data, "checklist data");
        if (response.data.status === 1) {
          setcheckLists(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getsystemdata();
  }, []);

  

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const inputDateString = project?.end_date;
  const formattedDate = new Date(inputDateString).toLocaleDateString("en-GB");

  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState(project.name);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = () => {
    // Perform save logic here
    // For example, update the project name in your state or make an API call

    // After saving, exit edit mode
    setEditMode(false);
  };

  const handleCancelClick = () => {
    // If the user cancels editing, revert to the original project name
    setEditedName(project.name);
    setEditMode(false);
  };

  const handleSave = async() => {

    try {
      const txtturnResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/createchacklist`,
        {
          "user_id":localStorage.getItem("user_id"),
          "name":newItemText,
          "organisation":localStorage.getItem("orgn_id"),
          "project_id": project.project_id ? project.project_id : project.id
        }
      );
      // console.log(txtturnResponse);
      if (txtturnResponse.data.status === 1) {
        setcheckLists((prevLogs) => [...prevLogs, ...txtturnResponse.data.data]);
        setNewItemText("")
      }
    } catch (e) {
      console.error("Error in inserting txtturn ", e);
    }
  };
  
  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Box
        style={{
          width: "100%",
          // marginBottom: "7%",
          display: "flex",
          flexDirection: "row",
          justifyContent:"space-between",
          height: "70px",
          borderBottom: '1px solid #1282A2',
          // alignItems: "right",
          // gap: "300px",
          // backgroundColor:"lightcyan"
        }}
      >
        <Box
          style={{
            width: "47%",
            height: "10vh",
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid transparent",
            marginLeft: "4%",
            // gap: "25px",
            overflowY:"auto",
            position: "relative",
          }}
          // onMouseEnter={(e) => {
          //   e.currentTarget.style.borderBottomColor = "#A9A9A9";
          //   const editIcon = e.currentTarget.querySelector(".edit-icon");
          //   if (editIcon) {
          //     editIcon.style.visibility = "visible";
          //   }
          // }}
          // onMouseLeave={(e) => {
          //   e.currentTarget.style.borderBottomColor = "transparent";
          //   const editIcon = e.currentTarget.querySelector(".edit-icon");
          //   if (editIcon) {
          //     editIcon.style.visibility = "hidden";
          //   }
          // }} 
        >

            <Typography
              style={{
                color: "#082A49",
                fontSize: "20px",
                // fontWeight: "bold",
                marginLeft:"4px"
                // gap: "25px",
              }}
            >
              {/* {project.name} */}
              {project?.description}
            </Typography>
          
          <img
            className="edit-icon"
            style={{
              marginLeft: "10px",
              height: "22px",
              marginTop: "1%",
              visibility: "hidden",
              position: "absolute",
              right: 0,
              cursor: "pointer",
            }}
            src={require("../../../Images/Iconedit.png")}
            width="20px"
            alt="edit-icon"
            onClick={handleEditClick}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            width: "49%",
            height: "40px",
            // border: "2px solid #F8F8F8",
            // backgroundColor: "#F8F8F8",
            // borderRadius: "15px",
            // marginLeft: "4%",
            overflow: "hidden",
            // justifyContent:"flex-end"
            // boxShadow: "5px 5px 4px 0px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Box
            style={{
              width: "87.8%",
              color: "#082A49",
              fontSize: "14px",
              // backgroundColor: "lightgreen",
              fontWeight: "bold",
              // gap: "30px",
              // margin: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
          
            <Typography
              style={{
                color: "#082A49",
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              spoc :{" "}
              <span style={{ color: "#1282A2" }}>{project?.spoc_name}</span>
            </Typography>
            <Typography
              style={{
                color: "#082A49",
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              End date :{" "}
              <span style={{ color: "#1282A2" }}>{formattedDate}</span>
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Box style={{width:"92%",height:"7vh",paddingTop:"1%",paddingLeft:"1%",margin:"auto",display:"flex",flexWrap:"wrap",overflowY:"auto"}}>
        <Typography style={{fontFamily:"Montserrat"}}>
              {project?.description}
        </Typography>
      </Box> */}
      {/* <Typography style={{ ...Styles.name,  marginTop:"1%",marginBottom:"1%"}}> CheckList</Typography> */}

      <Box
        style={{
          width: "92%",
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          overflowX: "auto",
        }}
      >
        {checkLists.map((item) => (
          <ChecklistNameCardamc taskDatastatus={props.taskDatastatus} end_date={project?.end_date} projecttype={project?.type} checkLists={checkLists} item={item} setActive={props.setActive} settaskSelected={props.settaskSelected} setcheckListsamc={props.setcheckListsamc}/>
        ))}

        <Box style={{marginRight:"5%"}}>
          {isTextFieldVisible ? (
                        <Box style={{width:"100%",display:"flex"}}>
              <TextField
                label="Create new"
                value={newItemText}
                variant="standard"
                InputProps={{
                  style: {
                    // gap: "25px",
                    color: "#082A49",
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    // borderBottom: "2px dashed #A9A9A9", // Use dashed line style
                    outline: "none", // Remove the blue outline on focus
                  },
                }}
                style={{
                  color: "#082A49",
                  fontSize: "22px",
                  fontWeight: "bold",
                  minWidth:"150px",
                  // gap: "25px",
                  fontFamily: "Montserrat",
                  cursor: "pointer",
                  height: "10%",
                }}
                onChange={(e) => setNewItemText(e.target.value)}
              />
              <Typography
                onClick={handleSave}
                style={{
                  color: "#082A49",
                  fontSize: "28px",
                  fontWeight: "bold",
                  // gap: "25px",
                  fontFamily: "Montserrat",
                  cursor: "pointer",
                  height: "10%",
                  marginTop: "5%",
                  paddingRight: "15%",
                }}
              >
                +
              </Typography>
            </Box>
          ) : (
            <Box style={{width:"100%",display:"flex",justifyContent:"space-between"}}>

            <Typography
                style={{
                  color: "#082A49",
                  width: "100%",
                  fontSize: "22px",
                  fontWeight: "bold",
                  gap: "25px",
                  fontFamily: "Montserrat",
                  cursor: "pointer",
                  // height: "10%",
                  // marginRight: "10%",
                  borderBottom: "2px dashed #A9A9A9"
                }}
              onClick={handlePlusClick}
            >
              {/* <span style={{ borderBottom: "2px dashed #A9A9A9" }}>
                {" "} */}
                Create new{" "}
              {/* </span>{" "}
              + */}
            </Typography>
            <Typography style={{
                color: "#082A49",
                // width: "100%",
                fontSize: "22px",
                fontWeight: "bold",
                // gap: "25px",
                // fontFamily: "Montserrat",
                cursor: "pointer",
              }}>
            +
            </Typography>
            </Box>
          )}
        </Box>

      </Box>

      {open && (
        <Checklistpopupamc checkLists={checkLists} open={open} setOpen={setOpen} />
      )}
    </Box>
  );
}