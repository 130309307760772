import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import Styles from "../../Styles/AdminDashboard";
import ContactDetails from './ContactDetails';
import BankDetails from './BankDetails';
import EmployeeDetails from './EmployeeDetails';


export default function CreateOrganisation() {
  let colorblue=false
  const [cardnumber , setcardnumber]=useState(1)
  return (
    <Box sx={{width:'100%',height:'95%'}} >
<Box sx={{width:'100%',height:45,marginTop:'2%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
      <Typography style={{...Styles.name,marginTop:0,marginLeft:"5%",}} >Add Organisations</Typography>
      
      </Box>

      {/* 3 dots with the line */}
      <Box sx={{width:'100%',height:90,borderBottom:'2px dashed #D9D9D9',display:'flex',justifyContent:'center',alignItems:'center'}} >
<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'70%'}} >

 {

   cardnumber===1 ?
<Box sx={{height:15,width:15,border:2,borderColor:'#1282A2',borderRadius:20,backgroundColor:colorblue?'#1282A2':'white'}}>
</Box>
:
<img src={require('../../Images/tickcircle.png')}/>
}



<Typography sx={{color:'#1282A2',marginTop:1}} >Contact Details</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'column',alignItems:'center',height:'70%',width:'20%'}} >
<Box sx={{width:'100%',border:1,borderColor:'#1282A2',backgroundColor:colorblue?'#1282A2':'white',marginTop:'4%'}}>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'70%'}} >

{

cardnumber===2 || cardnumber===1 ?
<Box sx={{height:15,width:15,border:2,borderColor:'#1282A2',borderRadius:20,backgroundColor:colorblue?'#1282A2':'white'}}>
</Box>
:
<img src={require('../../Images/tickcircle.png')}/>
}
<Typography sx={{color:'#1282A2',marginTop:1}} >Bank details</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'column',alignItems:'center',height:'70%',width:'20%'}} >
<Box sx={{width:'100%',border:1,borderColor:'#1282A2',backgroundColor:colorblue?'#1282A2':'white',marginTop:'4%'}}>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'70%'}} >

{

cardnumber===2 || cardnumber===1 || cardnumber===3 ?
<Box sx={{height:15,width:15,border:2,borderColor:'#1282A2',borderRadius:20,backgroundColor:colorblue?'#1282A2':'white'}}>
</Box>
:
<img src={require('../../Images/tickcircle.png')}/>
}
<Typography sx={{color:'#1282A2',marginTop:1}} >Employee details</Typography>
</Box>

      </Box>

      <Box sx={{width:'100%',height:'75%',display:'flex',justifyContent:'center',alignItems:'center'}} >
        {
          cardnumber===1 &&
<ContactDetails setcardnumber={setcardnumber} />
        }
          {
          cardnumber===2 &&
<BankDetails setcardnumber={setcardnumber} />
        }
        {
          cardnumber===3 &&
<EmployeeDetails cardnumber={cardnumber}setcardnumber={setcardnumber} />
        }
      </Box>
    </Box>
  )
}




// import { Box, TextField, Typography } from '@mui/material';
// import React, { useState } from 'react';
// import axios from 'axios';
// import useMediaQuery from '@mui/material/useMediaQuery';

// export default function ContactDetails(props) {
//   const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)');
//   const [data, setData] = useState({});

//   const handleChange = (key, value) => {
//     setData((prevData) => ({
//       ...prevData,
//       [key]: value,
//     }));
//   };

//   const handlenext = async () => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/addorganisation`,
//         data
//       );

//       if (response.data.status === 1) {
//         localStorage.setItem('crtorgid', response.data.data[0].id);
//         props.setcardnumber(2);
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   return (
//     <Box
//       sx={{
//         height: '100%',
//         width: '90%',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: MediaQueryOrgCard ? 'center' : 'flex-start',
//         margin: '0 auto',
//       }}
//     >
//       <Box
//         sx={{
//           width: '100%',
//           display: 'flex',
//           flexDirection:MediaQueryOrgCard ? 'column' : 'row',
//           justifyContent: 'left',
//           alignItems: 'flex-start',
//           marginBottom: '15px',
//         }}
//       >
//         <Typography
//           sx={{
//             width: MediaQueryOrgCard ? '100%' : '20%',
//             color: '#082A49',
//             fontWeight: 500,
//             fontSize: MediaQueryOrgCard ? 15 : 20,
//             marginBottom: MediaQueryOrgCard ? '8px' : 0,
//           }}
//         >
//           Organization name
//         </Typography>
//         <TextField
//         sx={{
//           width: MediaQueryOrgCard ? '100%' : '30%',
//         }}
//           id="outlined-basic"
//           value={data.name}
//           onChange={(e) => handleChange('name', e.target.value)}
//           variant="standard"
//           fullWidth
//         />
//       </Box>

//       <Box
//         sx={{
//           width: '100%',
//           display: 'flex',
//           flexDirection:MediaQueryOrgCard ? 'column' : 'row',
//           justifyContent: 'left',
//           alignItems: 'flex-start',
//           marginBottom: '15px',
//         }}
//       >
//         <Typography
//           sx={{
//             width: MediaQueryOrgCard ? '100%' : '20%',
//             color: '#082A49',
//             fontWeight: 500,
//             fontSize: MediaQueryOrgCard ? 15 : 20,
//             marginBottom: MediaQueryOrgCard ? '8px' : 0,
//           }}
//         >
//           Industry type
//         </Typography>
//         <TextField
//         sx={{
//           width: MediaQueryOrgCard ? '100%' : '30%'}}
//           id="outlined-basic"
//           value={data.type}
//           onChange={(e) => handleChange('type', e.target.value)}
//           variant="standard"
//           fullWidth
//         />
//       </Box>

//       <Box
//       sx={{
//         width: '100%',
//         display: 'flex',
//         flexDirection:MediaQueryOrgCard ? 'column' : 'row',
//         justifyContent: 'left',
//         alignItems: 'flex-start',
//         marginBottom: '15px',
//       }}
//     >
//       <Typography
//         sx={{
//           width: MediaQueryOrgCard ? '100%' : '20%',
//           color: '#082A49',
//           fontWeight: 500,
//           fontSize: MediaQueryOrgCard ? 15 : 20,
//           marginBottom: MediaQueryOrgCard ? '8px' : 0,
//         }}
//       >
//       Register Address
//       </Typography>
//       <TextField
//       sx={{
//         width: MediaQueryOrgCard ? '100%' : '30%'}}
//         id="outlined-basic"
//         value={data.location}
//         onChange={(e) => handleChange('location', e.target.value)}
//         variant="standard"
//         fullWidth
//       />
//     </Box>
//     <Box
//     sx={{
//       width: '100%',
//       display: 'flex',
//       flexDirection:MediaQueryOrgCard ? 'column' : 'row',
//       justifyContent: 'left',
//       alignItems: 'flex-start',
//       marginBottom: '15px',
//     }}
//   >
//     <Typography
//       sx={{
//         width: MediaQueryOrgCard ? '100%' : '20%',
//         color: '#082A49',
//         fontWeight: 500,
//         fontSize: MediaQueryOrgCard ? 15 : 20,
//         marginBottom: MediaQueryOrgCard ? '8px' : 0,
//       }}
//     >
// Contact No
//     </Typography>
//     <TextField
//     sx={{
//       width: MediaQueryOrgCard ? '100%' : '30%'}}
//       id="outlined-basic"
//       value={data.number}
//       onChange={(e) => handleChange('number', e.target.value)}
//       variant="standard"
//       fullWidth
//     />
//   </Box>
//   <Box
//   sx={{
//     width: '100%',
//     display: 'flex',
//     flexDirection:MediaQueryOrgCard ? 'column' : 'row',
//     justifyContent: 'left',
//     alignItems: 'flex-start',
//     marginBottom: '15px',
//   }}
// >
//   <Typography
//     sx={{
//       width: MediaQueryOrgCard ? '100%' : '20%',
//       color: '#082A49',
//       fontWeight: 500,
//       fontSize: MediaQueryOrgCard ? 15 : 20,
//       marginBottom: MediaQueryOrgCard ? '8px' : 0,
//     }}
//   >
//   E-mail
//   </Typography>
//   <TextField
//   sx={{
//     width: MediaQueryOrgCard ? '100%' : '30%'}}
//     id="outlined-basic"
//     value={data.email}
//     onChange={(e) => handleChange('email', e.target.value)}
//     variant="standard"
//     fullWidth
//   />
// </Box>
//       <Box
//         sx={{
//           width: '100%',
//           display: 'flex',
//           justifyContent: 'center',
//         }}
//       >
//         <Box
//           sx={{
//             width: '100%',
//             maxWidth: 100,
//             height: '40px',
//             backgroundColor: '#144C89',
//             borderRadius: 2,
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             cursor: 'pointer',
//           }}
//           onClick={handlenext}
//         >
//           <Typography sx={{ color: 'white', cursor: 'pointer' }}>Next</Typography>
//         </Box>
//       </Box>
//     </Box>
//   );
// }
