
import {
    Box,
    Typography,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    TextField,
    useMediaQuery,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Styles from "../../Styles/AdminDashboard";
import axios from "axios";
  
  export default function EditDetails(props) {
    const [open, setOpen] = React.useState(false);
    const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)')
    const [data, setData] = useState(props.data || {}); 
    const handleChanges = (key, value) => {
      //console.log(key, value);
      setData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    };
    
const handleupdateUserData = async () => {

    if(data.name !== "" && data.email !== ""){
    try {
      // const updatedData = {
      //   id: "10", 
      //   name: data.name,
      //   email: data.email,
      //   phone: data.phone,
      // };
  // let datas={
  //   ...data,
  //   "id":21
  // }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/updateorganisation`,
         data
      
      );
  
      if (response.data.status === 1) {
        setData(response.data.data);
        props.updatedataforedit(); 
        handleClose();
        alert(`${data.name} Updated Successfully`);
      
       
      } else {
        // console.error("Failed to update user data:",response.data.data);
      }
    } catch (error) {
      // console.error("Error updating user data:", error);
    }
  }
  else{
    alert("Organisation Name or Email is empty");
  }
  };
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      props.setOpen(false);
    };
   
    const [status, setStatus] = React.useState("");
  
    const handleChange = (event) => {
      setStatus(event.target.value);
    };
    
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box style={{...Styles.statusnotify_changestat, width: 360, height: 450,}}>
            <Box style={Styles.statusnotifyTitlebutton_changestat}>
              <h2
                id="parent-modal-title"
                style={{
                  color: '#1282A2',
                  marginBottom: '10px',
                  fontWeight: 700,
                  fontSize: '28px',
                  lineHeight: '34.13px',
                  alignItems: 'center',
                  alignSelf: "center",
                }}
              >
                Edit Details
              </h2>
              <p
                onClick={handleClose}
                style={{...Styles.statusnotifyXbutton_changestat, marginTop: "4%",display:"flex",justifyContent: "right",marginLeft:"auto"}}
              >
                X
              </p>
            </Box>
            <Box
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'Left',
                alignItems: 'left',
              }}
            >
            <TextField
            id="standard-basic"
            label="Organisation Name"
            variant="standard"
            style={{ margin: '10px' }}
            value={data.name} // Display the data
            onChange={(e) => handleChanges('name', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
              <TextField
                id="standard-basic"
                label="Industry Type"
                variant="standard"
                style={{ margin: '10px' }}          
                value={data.type}
                onChange={(e) => handleChanges('type', e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                id="standard-basic"
                label="Phone no"
                variant="standard"
                style={{ margin: '10px' }}
                value={data.number} // Display the data
                onChange={(e) => handleChanges('number', e.target.value)}
                InputLabelProps={{ shrink: true }}
              />

          
              <TextField
                id="standard-basic"
                label="Email"
                variant="standard"
                style={{ margin: '10px' }}
                value={data.email} // Display the data
                onChange={(e) => handleChanges('email', e.target.value)}
                InputLabelProps={{ shrink: true }}
          
              />
              <TextField
                id="standard-basic"
                label="Location"
                variant="standard"
                style={{ margin: '10px' }}
                value={data.location} // Display the data
                onChange={(e) => handleChanges('location', e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            
            </Box>
  
            <Box id="status-notify-entry">
              <Button
                variant="contained"
                style={{...Styles.statusnotifysave_changestat,top: '456px', left: '31px',}}
           onClick={handleupdateUserData}
                >
                Save
              </Button>
              <Button
                variant="contained"
                style={{...Styles.statusnotifycancel_changestat,top: '456px', left: '161px',}}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }
  