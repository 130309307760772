import React, { useEffect, useState } from "react";
import { Box, Typography, Button, TextField, Modal } from "@mui/material";
import Styles from "../../Styles/AdminDashboard";
import Projects from "../Projects";
import axios from "axios";
import AttachmentList from "../../Components/TicketDetails/AttachmentList";
import ViewAttachments from "../../Components/TicketDetails/ViewAttachments";
export default function ProjectAttachments(props) {
  // console.log("pros in project edit", props);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [projectAttach, setProjectAttach] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [selectedfileName, setSelectedFileName] = useState("");
  const handleOpen2 = (name,id) =>{
    console.log("selected id name",name,id);
    setdocid(id);
    setSelectedFileName(name);
    setOpen2(true);
  }
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachment, setAttachments] = useState([]);
  const [docid,setdocid] = useState("");
  const [numofFiles, setNumOfFiles] = useState("");
  const [data, setData] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [attachedfilename, setAttachmentedFile] = useState([]);
  const [platforms, setPlatforms] = useState([{ id: 1, name: "" }]);

  const addPlatformField = () => {
    const newPlatform = { id: platforms.length + 1, name: "" };
    setPlatforms((prevPlatforms) => [...prevPlatforms, newPlatform]);
  };

  const handlePlatformNameChange = (index, value) => {
    console.log('name',index,value);
    const updatedPlatforms = [...platforms];
    updatedPlatforms[index].name = value;
    console.log(updatedPlatforms);
    setPlatforms(updatedPlatforms);
  };

  const handlePlatformLinkChange = (index, value) => {
    console.log('link',index,value);
    const updatedPlatforms = [...platforms];
    updatedPlatforms[index].link = value;
    console.log(updatedPlatforms);
    setPlatforms(updatedPlatforms);
  };

  useEffect(() => {
    getChecklistAttachments();
    getInsertLinks();
  }, []);

  const getChecklistAttachments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getprojectdocumentslist`,
        { project_id: props.project_id }
      );
      console.log(response.data.data, "task doc list api");
      if (response.data.status === 1) {
        setProjectAttach(response.data.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const getInsertLinks = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallprojectslink`,
        { project_id: props.project_id }
      );
      console.log(response.data.data, "task doc list api");
      if (response.data.status === 1) {
        props.setInsertItems(response.data.data.length);
        if(response.data.data.length > 0){
          setPlatforms(response.data.data);
        }
        else{
          setPlatforms([{ id: 1, name: "" }]);
        }
      }
    } catch (e) {
      // console.log(e);
    }
  }

  const handleImageChange = (event) => {
    const files = event.target.files;
    const files2 = Array.from(event.target.files);
    setNumOfFiles(files2.length);
    setAttachments(files2);
    setSelectedFiles(files2);
    if (files && files.length > 0) {
      const newImages = Array.from(files).map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      });

      Promise.all(newImages).then((results) => {
        setSelectedImages([...selectedImages, ...results]);
      });
    }
  };
  //console.log(selectedImages);
  const handleImageClick = (image,name,docid) => {
    setSelectedImage(image);
    // setSelectedFileName(name);
    console.log("selecetde name ",name)
    // if(docid){
    //   handleOpen2();
    //   setdocid(docid)
    // }
    // else {
      handleOpen();
    // }
  };

  const uploadCategoryImgFile = async () => {
    try {
      const updatedAttachmentFiles = [];

      for (let i = 0; i < numofFiles; i++) {
        const formdata = new FormData();
        const file = attachment[i];
        //console.log(file.name, 'file name');

        formdata.append("project_documents", file);
        formdata.append("file_name", file.name);

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/uploadprojectdocument?file_name=${file.name}`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.status === 1) {
          //console.log('File uploaded successfully', file.name);
          //console.log('response', response.data);

          // Accumulate file names
          updatedAttachmentFiles.push(file.name);
        }
      }

      // Update the state after the loop
      setAttachmentedFile((prevFiles) => [
        ...prevFiles,
        ...updatedAttachmentFiles,
      ]);
      props.setAttachmentedFile((prevFiles) => [
        ...prevFiles,
        ...updatedAttachmentFiles,
      ]);

      // Move this outside the loop to setSave after all files are uploaded
      props.setInsertLink(platforms);
      props.setSave(true);
    } catch (e) {
      //console.log(e);
    }
  };

  //console.log('array getting attached',attachedfilename);
  return (
    <Box style={{ display: "flex", flexDirection: "column", marginTop: "5%" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginLeft: "7%",
          height: "auto",
          //flexDirection: 'row',
        }}
      >
        {/* Left Section */}
        <Box>
          <label
            htmlFor="fileInput"
            style={{ cursor: "pointer", marginBottom: "10px" }}
          >
            <input
              type="file"
              multiple
              onChange={(e) => handleImageChange(e)}
              style={{ display: "none" }}
              id="fileInput"
            />
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                src={require("../../Images/Iconupload.png")}
                alt="Attachment Icon"
              />
              <Typography
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  color: "#082A49",
                  marginLeft: "5%",
                }}
              >
                Upload&nbsp;attachments
              </Typography>
            </Box>
          </label>

          {props.project_id ? (
            <Box
              style={{
                marginTop: "12%",
                display: "flex",
                flexWrap: "wrap",
                overflowX: "auto",
                width: "400px",
                height: "25vh",
                flexDirection: "column",
              }}
            >
              {projectAttach?.map((image, index) => (
                <Box
                  key={index}
                  onClick={() => handleOpen2(image.file_name,image.docid)}
                  style={{
                    display: "inline-flex",
                    width: "30%",
                    margin: "15px",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={require("../../Images/docPlaceholder.png")}
                    alt={`Selected Placeholder ${index + 1}`}
                    style={{
                      width: "30%",
                      height: "50px",
                      cursor: "pointer",
                      // display:"flex"
                    }}
                  />
                  <Typography style={{
                      width: "130px",
                      height: "auto",
                      cursor: "pointer",
                      fontFamily:"Montserrat",
                      marginTop:"4%",
                      marginBottom:"4%",
                      // overflow:"auto"
                    }}>{image.file_name}</Typography>
                  {/* {image?.file_name?.endsWith('png' || 'jpeg') ? (
      <img
        src={image}
        alt={`Selected Preview ${index + 1}`}
        style={{
          width: '30%',
          height: '50px',
          cursor: 'pointer',
        }}
        onClick={() => handleImageClick(image)}
      />
    ) : (
      <img
        src={require('../../Images/docPlaceholder.png')}
        alt={`Selected Placeholder ${index + 1}`}
        style={{
          width: '30%',
          height: '50px',
          cursor: 'pointer',
        }}
        onClick={() => handleImageClick(image)}
      />
    )} */}
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              style={{
                marginTop: "12%",
                display: "flex",
                flexWrap: "wrap",
                overflowX: "auto",
                width: "400px",
                height: "30vh",
              }}
            >
              {selectedImages?.map((image, index) => (
                <Box
                  key={index}
                  style={{
                    display: "inline-flex",
                    width: "30%",
                    margin: "5px",
                    height: "100px",
                  }}
                >
                  {image?.startsWith("data:image/") ? (
                    <img
                      src={image}
                      alt={`Selected Preview ${index + 1}`}
                      style={{
                        width: "30%",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(image)}
                    />
                  ) : (
                    <img
                      src={require("../../Images/docPlaceholder.png")}
                      alt={`Selected Placeholder ${index + 1}`}
                      style={{
                        width: "30%",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(image)}
                    />
                  )}
                </Box>
              ))}
            </Box>
          )}

          {/* Modal for large image preview */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "70%",
                height: "70%",
                bgcolor: "background.paper",
                border: "2px solid",
                boxShadow: 24,
                p: 4,
              }}
            >
              {/* Add your code to display a single large image or implement a gallery */}
              <Typography
                variant="h6"
                style={{
                  position: "absolute",
                  top: 10,
                  right: 20,
                  cursor: "pointer",
                  color: "#082A49",
                }}
                onClick={handleClose}
              >
                X
              </Typography>
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "70%",
                  height: "70%",
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <iframe
                  style={{
                    width: "100%", // specify the desired width
                    height: "100%", // specify the desired height
                    objectFit: "cover",
                    border: "1px solid",
                  }}
                  src={selectedImage}
                />
              </Box>
            </Box>
          </Modal>
        </Box>

        {/* Right Section */}
        <Box style={{ width: "55%" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              src={require("../../Images/Iconlink.png")}
              alt="Attachment Icon"
            />
            <Typography
              style={{
                fontSize: "22px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                color: "#082A49",
                marginLeft: "5%",
              }}
            >
              Insert Links
            </Typography>
          </Box>
          <Box style={{ marginTop: "6%" }}>
            {platforms.map((platform, index) => (
              <Box
                key={platform.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2%",
                }}
              >
                {index === 0 && (
                  <Typography
                    style={{
                      fontSize: "30px",
                      marginTop: "2%",
                      marginRight: "5%",
                      cursor: "pointer",
                    }}
                    onClick={addPlatformField}
                  >
                    +
                  </Typography>
                )}
                <TextField
                  id={`standard-basic-Project-name-${platform.id}`}
                  label="Platform name"
                  variant="standard"
                  value={platform.name}
                  onChange={(e) =>
                    handlePlatformNameChange(index, e.target.value)
                  }
                  style={
                    index !== 0
                      ? { marginLeft: "7%", width: "20%" }
                      : { width: "20%" }
                  }
                />
                <TextField
                  id={`standard-basic-Project-link-${platform.id}`}
                  //label="Link"
                  variant="standard"
                  value={platform.link}
                  onChange={(e) =>
                    handlePlatformLinkChange(index, e.target.value)
                  }
                  style={{ marginLeft: "5%", width: "50%" }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          onClick={() => {
            uploadCategoryImgFile();
          }}
          style={{
            ...Styles.statusnotifysave_changestat1,
            bottom: 100,
            left: 400,
          }}
        >
          Save
        </Button>
      </Box>
      {open2 && <AttachmentList docid={docid} FileName={selectedfileName} projDoc={true} id={props?.project_id} open={open2}  setOpen={setOpen2} />}

    </Box>
  );
}
