import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ContactDetails(props) {
  const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)');
  const [data, setData] = useState({});

  const handleChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const [errororgname , setErrorOrgName]=useState(false)
const [errorprojemail , setErrorProjEmail]=useState(false)
// const [startvalidation , setStartValidation]=useState(false)
// useEffect(() => {
//   if (startvalidation) {
//   setErrorOrgName(!data.name);
//   setErrorProjEmail(!data.email);
//   }
// }, [startvalidation, data]);

// const validateForm = () => {
//   if(!startvalidation){
//     return false;
//   }
//   if (
//     errororgname ||
//     errorprojemail
//   ) {
//     return false;
//   }

//   return true;
// };
  const handlenext = async () => {
    if(!data.name || !data.email){
      setErrorOrgName(!data.name);
      setErrorProjEmail(!data.email);
    }
    else{
      const datas = {
        ...data,
        "associated_organisationid":localStorage.getItem("orgn_id")
      }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addorganisation`,
        datas
      );

      if (response.data.status === 1) {
        localStorage.setItem('crtorgid', response.data.data[0].id);
        props.setcardnumber(2);
      }
    } catch (e) {
      // console.log(e);
    }
  }
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: MediaQueryOrgCard ? 'center' : 'flex-start',
        margin: '0 auto',
       marginTop: 8
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection:MediaQueryOrgCard ? 'column' : 'row',
          justifyContent: 'left',
          alignItems: 'flex-start',
          marginBottom:MediaQueryOrgCard ? '12px' : '18px',
        }}
      >
        <Typography
          sx={{
            width: MediaQueryOrgCard ? '100%' : '20%',
            color: '#082A49',
            fontWeight: 500,
            fontSize: MediaQueryOrgCard ? 15 : 20,
            marginBottom: MediaQueryOrgCard ? '2px' : 0,
          }}
        >
          Organization name
        </Typography>
        <TextField
        sx={{
          width: MediaQueryOrgCard ? '100%' : '30%',
        }}
          id="outlined-basic"
          value={data.name}
          onChange={(e) => handleChange('name', e.target.value)}
          variant="standard"
          fullWidth
        />
        {errororgname &&
            <Typography sx={{color:'red'}} >Please Enter Organisation Name</Typography>
          }
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection:MediaQueryOrgCard ? 'column' : 'row',
          justifyContent: 'left',
          alignItems: 'flex-start',
          marginBottom:MediaQueryOrgCard ? '12px' : '18px',
        }}
      >
        <Typography
          sx={{
            width: MediaQueryOrgCard ? '100%' : '20%',
            color: '#082A49',
            fontWeight: 500,
            fontSize: MediaQueryOrgCard ? 15 : 20,
            marginBottom: MediaQueryOrgCard ? '2px' : 0,
          }}
        >
          Industry type
        </Typography>
        <TextField
        sx={{
          width: MediaQueryOrgCard ? '100%' : '30%'}}
          id="outlined-basic"
          value={data.type}
          onChange={(e) => handleChange('type', e.target.value)}
          variant="standard"
          fullWidth
        />
      </Box>

      <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection:MediaQueryOrgCard ? 'column' : 'row',
        justifyContent: 'left',
        alignItems: 'flex-start',
        marginBottom:MediaQueryOrgCard ? '12px' : '18px',
      }}
    >
      <Typography
        sx={{
          width: MediaQueryOrgCard ? '100%' : '20%',
          color: '#082A49',
          fontWeight: 500,
          fontSize: MediaQueryOrgCard ? 15 : 20,
          marginBottom: MediaQueryOrgCard ? '2px' : 0,
        }}
      >
      Register Address
      </Typography>
      <TextField
      sx={{
        width: MediaQueryOrgCard ? '100%' : '30%'}}
        id="outlined-basic"
        value={data.location}
        onChange={(e) => handleChange('location', e.target.value)}
        variant="standard"
        fullWidth
      />
    </Box>
    <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection:MediaQueryOrgCard ? 'column' : 'row',
      justifyContent: 'left',
      alignItems: 'flex-start',
      marginBottom:MediaQueryOrgCard ? '12px' : '18px',
    }}
  >
    <Typography
      sx={{
        width: MediaQueryOrgCard ? '100%' : '20%',
        color: '#082A49',
        fontWeight: 500,
        fontSize: MediaQueryOrgCard ? 15 : 20,
        marginBottom: MediaQueryOrgCard ? '2px' : 0,
      }}
    >
Contact No
    </Typography>
    <TextField
    sx={{
      width: MediaQueryOrgCard ? '100%' : '30%'}}
      id="outlined-basic"
      value={data.number}
      onChange={(e) => handleChange('number', e.target.value)}
      variant="standard"
      fullWidth
    />
  </Box>
  <Box
  sx={{
    width: '100%',
    display: 'flex',
    flexDirection:MediaQueryOrgCard ? 'column' : 'row',
    justifyContent: 'left',
    alignItems: 'flex-start',
    marginBottom:MediaQueryOrgCard ? '12px' : '18px',
  }}
>
  <Typography
    sx={{
      width: MediaQueryOrgCard ? '100%' : '20%',
      color: '#082A49',
      fontWeight: 500,
      fontSize: MediaQueryOrgCard ? 15 : 20,
      marginBottom: MediaQueryOrgCard ? '2px' : 0,
    }}
  >
  E-mail
  </Typography>
  <TextField
  sx={{
    width: MediaQueryOrgCard ? '100%' : '30%'}}
    id="outlined-basic"
    value={data.email}
    onChange={(e) => handleChange('email', e.target.value)}
    variant="standard"
    fullWidth
  />
  {errorprojemail &&
            <Typography sx={{color:'red'}} >Please Enter Email</Typography>
          }
</Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 100,
            height: '40px',
            backgroundColor: '#144C89',
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => { handlenext(); }}
        >
          <Typography sx={{ color: 'white', cursor: 'pointer' }}>Next</Typography>
        </Box>
      </Box>
    </Box>
  );
}

