import React, { useEffect, useState } from "react";
import Styles from "../../Styles/AdminDashboard";
import { Box, Modal, Typography } from "@mui/material";
import axios from "axios";

export default function ConveneCall(props) {
  const handleClose = async() => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/updatecallnotification`,
        {
          "id":localStorage.getItem("user_id"),
          "call_notification":"false"
        }
      );
      if (response.data.status === 1) {
        // console.log(response.data.data, "project api oooooooo");
        // setnotificationinfo(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
    props.setOpen(false);
  };
//   console.log("props set", props);
  const [ansCall,setansCall] = useState(false);
  const [meetUrl,setMeeturl] = useState("");

  useEffect(()=>{
    createMeet();
    getsystemdata();
  }, [])

    const getsystemdata = async () => {
      try {
        const response = await axios.get(
          `https://portalapi.convenevc.com/api/meetings/organization/121195`,
          { "OrganizationID": 187547 }
        );
        console.log(response.data, "convene vc call res");
         if (response.data) {
      const meetingToSet = response.data.find(meeting => meeting.MeetingName === props.ticket_name);

      console.log("pratik", meetingToSet);
      if (meetingToSet) {
        setMeeturl(meetingToSet.ModeratorUrl);
      } else {
        console.log("No matching meeting found for ticket_name:", props.ticket_name);
      }
    }
      } catch (e) {
        console.error(e);
      }
    };
  
  const createMeet = async() =>{
    try {
        const response = await axios.post(
          `https://portalapi.convenevc.com/api/join/instant`,
          { 
            "MeetingName": props.ticket_name,
            "OrganizationID": 187547,
            "displayName": localStorage.getItem("name"),
            "email": localStorage.getItem("email")
          }
        );
        console.log(response.data.meetingDetails.MemberUrl, "inserted convene vc call");
        if (response) {
        //   const apiUrl = response.data.data[0]?.MemberUrl;
          console.log("meetUrl:", response);
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/updatecallnotification`,
              {
                "id":props.assigned,
                "call_notification":"true",
                "meet_name":props.ticket_name
              }
            );
            if (response.data.status === 1) {
              // console.log(response.data.data, "project api oooooooo");
              // setnotificationinfo(response.data.data);
            }
          } catch (e) {
            console.log(e);
          }
          if(localStorage.getItem("role")==="admin") {
          setMeeturl(response.data.meetingDetails.ModeratorUrl);
          }
          else{
          setMeeturl(response.data.meetingDetails.MemberUrl);
          }
        }
      } catch (e) {
        console.error(e);
      }
  }

  return (
    <Box>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          style={{
            ...Styles.statusnotify_changeassign,
            width: "80%",
            height: "80%",
          }}
        >
          <Box
            style={{
              ...Styles.statusnotifyTitlebutton_changestat,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignitem: "center",
            }}
          >
            <h2
            // onClick={createMeet}
              id="parent-modal-title"
              style={{ ...Styles.statusTitle_changestat, fontSize: "20px" }}
            >
              Convene VC
            </h2>
            <Typography
              onClick={handleClose}
              style={{
                ...Styles.statusnotifyXbutton_changestat,
                display: "flex",
                justifyContent: "right",
                marginLeft: "auto",
                marginTop: "1%",
              }}
            >
              X
            </Typography>
          </Box>
          {props.answerCall ? (
            <>
            <button onClick={()=>{setansCall(true)}}>Answer Call</button>
            <button>Reject Call</button>
            </>
          ) : (
          <Box style={{ width: "95%", height: "90%", margin: "auto" }}>
            <iframe style={{ width: "100%", height: "100%" }} src={meetUrl} allow="camera *;microphone *"></iframe>
          </Box>
           )}

           {ansCall && 
            <Box style={{ width: "95%", height: "90%", margin: "auto" }}>
              <iframe style={{ width: "100%", height: "100%" }} src={meetUrl} allow="camera *;microphone *"></iframe>
            </Box>
           }
          
        </Box>
      </Modal>
    </Box>
  );
}
