import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Styles from "../../Styles/AdminDashboard";

export default function ContactDetails(props) {
  const MediaQueryOrgCard = useMediaQuery("(max-width: 900px)");
  const [data, setData] = useState({name: "",
  email: "",
  role: null,
  department: null,
  designation: null,});
  const [role, setRole] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [department, setDepartment] = useState([]);
  const [orgusers, setOrgUsers] = useState([]);

  useEffect(() => {
    const getSystemData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getsystemconfiguration`,
          data
        );
        // console.log(response.data);
        if (response.data.status === 1) {
          setRole(response?.data?.responserole);
          setDesignation(response?.data?.responsedesignation);
          setDepartment(response?.data?.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getSystemData();
  }, [props.cardnumber === 3]);

  const handleChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleAddUser = async () => {
    if(data.name === "" || data.email === ""){
        alert('Name and Email must not be empty');
    }
    else{
    let datas = {
      ...data,
      organisation: localStorage.getItem("crtorgid"),
      password: "cbs12345",
      reporting_to: 1,
      organisation_admin: localStorage.getItem("orgn_id")
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/adduser`,
        datas
      );
      // console.log(response.data);
      if (response.data.status === 1) {
        getuserorganisation();
      }
    } catch (e) {
      // console.log(e);
    }
  }
  };

  const handleNext = async () => {
    props.setcardnumber(4);
    alert("Organisation Created Successfully!");
    window.location.reload();
  };

// const [orgusers , setorgUsers]=useState([])
  const getuserorganisation = async()=>{
    
    let datas={
      ...data,
      organisation: localStorage.getItem("crtorgid"),
      password: "cbs12345",
      organisation_admin: localStorage.getItem("orgn_id")
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getalluseraddedorganisation`,
        datas
      );
      // console.log("users of this organisation", response.data);
      if (response?.data?.status === 1) {
        setOrgUsers(response?.data?.data);
        setData({
          "name": "",
          "email": "",
          "role": undefined,
          "department": undefined,
          "designation": undefined,
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: MediaQueryOrgCard ? "center" : "space-evenly",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: MediaQueryOrgCard ? "auto" : 100,
          display: MediaQueryOrgCard ? "flex" : "flex",
          flexDirection: MediaQueryOrgCard ? "column" : "row",
          alignItems: "center",
          justifyContent: MediaQueryOrgCard ? "center" : "space-around",
          marginTop: MediaQueryOrgCard ? "" : "35px",
          marginLeft: "3%",
        }}
      >
        <Box sx={{ width: 210, height: "100%", marginRight: 1 }}>
          <TextField
            id="outlined-basic"
            value={data.name}
            onChange={(e) => {
              handleChange("name", e.target.value);
            }}
            label="Name"
            variant="standard"
          />
        </Box>

        <Box sx={{ width: 210, height: "100%", marginRight: 1 }}>
          <TextField
            id="outlined-basic"
            value={data.email}
            onChange={(e) => {
              handleChange("email", e.target.value);
            }}
            label="email"
            variant="standard"
          />
        </Box>

        <Box sx={{ width: 210, height: "100%", marginRight: 1 }}>
          <FormControl variant="standard" sx={{ minWidth: 120, width: 210 }}>
            <InputLabel id="demo-simple-select-standard-label">role</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={data.role}
              onChange={(e) => {
                handleChange("role", e.target.value);
              }}
              label="Department"
              InputLabelProps={{ shrink: false }}
            >
              {role?.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
              {/* <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"user"}>User</MenuItem> */}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ width: 210, height: "100%", marginRight: 1 }}>
          <FormControl variant="standard" sx={{ minWidth: 120, width: 210 }}>
            <InputLabel id="demo-simple-select-standard-label">
              department
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={data.department}
              onChange={(e) => {
                handleChange("department", e.target.value);
              }}
              label="Department"
              InputLabelProps={{ shrink: false }}
            >
              {department?.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ width: 210, height: "100%", marginRight: 1 }}>
          <FormControl variant="standard" sx={{ minWidth: 120, width: 210 }}>
            <InputLabel id="demo-simple-select-standard-label">
              designation
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={data.designation}
              onChange={(e) => {
                handleChange("designation", e.target.value);
              }}
              label="Designation"
              InputLabelProps={{ shrink: false }}
            >
              {designation?.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ width: 100, height: "100%", marginRight: 1 }}>
          <img
            src={require("../../Images/addbutton.png")}
            onClick={() => {handleAddUser()}}
          />
        </Box>
      </Box>

      <Box
        style={{ width: "94%", margin: "auto", height: "35vh" }}
        sx={{
          order: MediaQueryOrgCard ? 2 : 0,
          width: MediaQueryOrgCard ? "100%" : "94%",
        }}
      >
        <Box style={Styles.tableBox2}>
          <TableContainer
            component={Paper}
            elevation={0}
            style={Styles.tableContainer}
          >
            <Table style={Styles.table} aria-label="simple table">
              <TableHead style={Styles.tableHead}>
                <TableRow>
                  <TableCell style={Styles.tableCell} align="left">
                    No
                  </TableCell>
                  <TableCell style={Styles.tableCell} align="left">
                    Name
                  </TableCell>
                  <TableCell style={Styles.tableCell} align="center">
                    Email
                  </TableCell>
                  <TableCell style={Styles.tableCell} align="center">
                    Department
                  </TableCell>
                  <TableCell style={Styles.tableCell} align="center">
                    Designation
                  </TableCell>
                  <TableCell style={Styles.tableCell} align="center">
                    Role
                  </TableCell>
                  {/* <TableCell
                    style={Styles.tableCell}
                    align="center"
                  ></TableCell> */}
                </TableRow>
              </TableHead>

              <TableBody>
                {orgusers?.map((row, index) => (
                  <TableRow style={{ height: 60 }} key={row.id}>
                    <TableCell style={Styles.tableText} align="left">
                      {index + 1}
                    </TableCell>
                    <TableCell style={Styles.tableText} align="left">
                      {row?.name}
                    </TableCell>
                    <TableCell style={Styles.tableText} align="center">
                      {row?.email}
                    </TableCell>
                    <TableCell style={Styles.tableText} align="center">
                      {row?.department}
                    </TableCell>
                    <TableCell style={Styles.tableText} align="center">
                      {row?.designation}
                    </TableCell>
                    <TableCell style={Styles.tableText} align="center">
                      {row?.role}
                    </TableCell>
                    {/* <TableCell style={Styles.tableText} align="center">
                      <img
                        style={{ cursor: "pointer" }}
                        src={require("../../Images/threeDots.png")}
                      />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: 40,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: 100,
            height: "100%",
            backgroundColor: "#144C89",
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => handleNext()}
        >
          <Typography sx={{ color: "white", cursor: "pointer" }}>Save</Typography>
        </Box>
      </Box>
    </Box>
  );
}



// import {
//   Box,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useState } from 'react'
// import Styles from "../../Styles/AdminDashboard";
// import axios from "axios";
// import useMediaQuery from '@mui/material/useMediaQuery';

// const data1 = [
//   {
//     id: 1,
//     jobName: "John Doe",
//     uploadDate: "29-01-2001",
//     owner: "Pratik",
//     uploadedby: "Prasanna",
//     pages: "2",
//   },
//   {
//     id: 2,
//     jobName: "Jane Smith",
//     uploadDate: "01-01-2001",
//     owner: "Kartik",
//     uploadedby: "Atharva",
//     pages: "2",
//   },
//   {
//     id: 1,
//     jobName: "John Doe",
//     uploadDate: "29-01-2001",
//     owner: "Pratik",
//     uploadedby: "Prasanna",
//     pages: "2",
//   },
//   {
//     id: 2,
//     jobName: "Jane Smith",
//     uploadDate: "01-01-2001",
//     owner: "Kartik",
//     uploadedby: "Atharva",
//     pages: "2",
//   },
//   {
//     id: 1,
//     jobName: "John Doe",
//     uploadDate: "29-01-2001",
//     owner: "Pratik",
//     uploadedby: "Prasanna",
//     pages: "2",
//   },
//   {
//     id: 2,
//     jobName: "Jane Smith",
//     uploadDate: "01-01-2001",
//     owner: "Kartik",
//     uploadedby: "Atharva",
//     pages: "2",
//   },
//   {
//     id: 1,
//     jobName: "John Doe",
//     uploadDate: "29-01-2001",
//     owner: "Pratik",
//     uploadedby: "Prasanna",
//     pages: "2",
//   },
//   {
//     id: 2,
//     jobName: "Jane Smith",
//     uploadDate: "01-01-2001",
//     owner: "Kartik",
//     uploadedby: "Atharva",
//     pages: "2",
//   },
//   {
//     id: 1,
//     jobName: "John Doe",
//     uploadDate: "29-01-2001",
//     owner: "Pratik",
//     uploadedby: "Prasanna",
//     pages: "2",
//   },
//   {
//     id: 2,
//     jobName: "Jane Smith",
//     uploadDate: "01-01-2001",
//     owner: "Kartik",
//     uploadedby: "Atharva",
//     pages: "2",
//   },
//   {
//     id: 1,
//     jobName: "John Doe",
//     uploadDate: "29-01-2001",
//     owner: "Pratik",
//     uploadedby: "Prasanna",
//     pages: "2",
//   },
//   {
//     id: 2,
//     jobName: "Jane Smith",
//     uploadDate: "01-01-2001",
//     owner: "Kartik",
//     uploadedby: "Atharva",
//     pages: "2",
//   },
//   // Add more data as needed
// ];
// export default function ContactDetails(props) {
// const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)');
//   const [data, setData] = useState({});
// const handleChange = (key, value) => {
  // console.log("data is:",key, value);
//   setData((prevData) => ({
//     ...prevData,
//     [key]: value,
//   }));
// };
// const [role , setRole]=useState([])
// const [designation , setDesignation]=useState([])
// const [department , setDepartment]=useState([])


// useEffect(()=>{
// const getsystemdata = async()=>{
// try {
//   const response = await axios.get(
//     `${process.env.REACT_APP_API_URL}/getsystemconfiguration`,
//     data
//   );
//   console.log(response.data)
//   if (response.data.status===1) {
//     setRole(response?.data?.responserole)
//     setDesignation(response?.data?.responsedesignation)
//     setDepartment(response?.data?.data)
    
//   }
// } catch (e) {
//   console.log(e);
// }
// }
// getsystemdata()
// },[props.cardnumber===3])

// const handleadduser =async()=>{
//   // console.log("akjdnakjdkjasj")
//   let datas={
//     ...data,
//     "organisation":localStorage.getItem("crtorgid"),
//     "password":"cbs12345"
//   }
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_API_URL}/adduser`,
//       datas
//       );
//       console.log(response.data)
//       if (response.data.status===1) {
//         // setRole(response?.data?.responserole)
//         // setDesignation(response?.data?.responsedesignation)
//         // setDepartment(response?.data?.data)
//         getuserorganisation()
//         // props.setcardnumber(4)

//     }
//   } catch (e) {
//     console.log(e);
//   }
// }

// const handlenext=async()=>{
//   props.setcardnumber(4)
//   alert("Organisation Created Successfully !")
//   window.location.reload()
// }

// const [orgusers , setorgUsers]=useState([])
// const getuserorganisation = async()=>{
//   let datas={
//     ...data,
//     "organisation":localStorage.getItem("crtorgid"),
//     "password":"cbs12345"
//   }
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_API_URL}/getalluser`,
//       datas
//     );
//     console.log("users of this organisation",response.data)
//     if (response?.data?.status===1) {
//       setorgUsers(response?.data?.data)
      
//     }
//   } catch (e) {
//     console.log(e);
//   }
// }

// return (
//   <Box sx={{height:'100%',width:'90%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'space-evenly'}} >

//  <Box sx={{width:'100%',height:100 ,display:MediaQueryOrgCard ? '':'flex',flexDirection:MediaQueryOrgCard ? 'coulmn' : 'row',alignItems:'center',justifyContent:'center'}} >
//     {/* <Typography sx={{color:'#082A49',marginRight:2,marginLeft:2,fontWeight:500,fontSize:20,}} >Account Holder Name </Typography>
//     </Box>     */}
//    <Box sx={{width:210,height:'100%',marginRight:1 ,display:'flex',justifyContent:'center',alignItems:'center'}} >
//       <TextField
    
//     id="outlined-basic"
//     value={data.name}
//     onChange={(e) => {
//       handleChange("name", e.target.value);
//     }}
//     label="Name"
//     variant="standard"
//   //   InputLabelProps={{ shrink: true }}
//     />
//     </Box>
   
    
    
//      {/* <Box sx={{width:200, marginBottom: 1}}> */}
//      {/* <Box sx={{width:220,height:'100%' }} >
//       <Typography sx={{color:'#082A49',marginRight:5,fontWeight:500,fontSize:20}} >Account number </Typography>
//      </Box> */}
//      {/* <Box sx={{width:200, marginBottom: 1}}> */}
//      <Box sx={{width:210,height:'100%',marginRight:1 ,display:'flex',justifyContent:'center',alignItems:'center'}} >

//       <TextField
//               id="outlined-basic"
//               value={data.email}
//               onChange={(e) => {
//                 handleChange("email", e.target.value);
//               }}
//               label="email"
//               variant="standard"
//               // InputLabelProps={{ shrink: true }}
//             />
//             </Box>
    
//               <Box sx={{width:210,height:'100%',marginRight:1 ,display:'flex',justifyContent:'center',alignItems:'center'}} >
//               <FormControl variant="standard" sx={{  minWidth: 120 ,width:210}}>
//       <InputLabel id="demo-simple-select-standard-label" >role</InputLabel>
//       <Select
//         labelId="demo-simple-select-standard-label"
//         id="demo-simple-select-standard"
//         value={data.role}
//         onChange={(e) => {
//           handleChange("role", e.target.value);
//         }}
//         label="Department"
//         InputLabelProps={{ shrink: false }}
//       >
//        {
//         role?.map((item)=>(
          
//           <MenuItem value={item.name}>{item.name}</MenuItem>
//         ))
//        }

        
//       </Select>
//     </FormControl>
//     </Box>
//     <Box sx={{width:210,height:'100%',marginRight:1 ,display:'flex',justifyContent:'center',alignItems:'center'}} >
//               <FormControl variant="standard" sx={{  minWidth: 120 ,width:210}}>
//       <InputLabel id="demo-simple-select-standard-label" >department</InputLabel>
//       <Select
//         labelId="demo-simple-select-standard-label"
//         id="demo-simple-select-standard"
//         value={data.department}
//         onChange={(e) => {
//           handleChange("department", e.target.value);
//         }}
//         label="Department"
//         InputLabelProps={{ shrink: false }}
//       >
//        {
//         department?.map((item)=>(
//           <MenuItem value={item.name}>{item.name}</MenuItem>
//         ))
//        }
//       </Select>
//     </FormControl>
//     </Box>
//     <Box sx={{width:210,height:'100%',marginRight:1 ,display:'flex',justifyContent:'center',alignItems:'center'}} >
//               <FormControl variant="standard" sx={{  minWidth: 120 ,width:210}}>
//       <InputLabel id="demo-simple-select-standard-label" >designation</InputLabel>
//       <Select
//         labelId="demo-simple-select-standard-label"
//         id="demo-simple-select-standard"
//         value={data.designation}
//         onChange={(e) => {
//           handleChange("designation", e.target.value);
//         }}
//         label="Designation"
//         InputLabelProps={{ shrink: false }}
//       >
//        {
//         designation?.map((item)=>(
          
//           <MenuItem value={item.name}>{item.name}</MenuItem>
//         ))
//        }

        
//       </Select>
//     </FormControl>
//     </Box>
//     <Box sx={{width:100,height:'100%',marginRight:1 ,display:'flex',justifyContent:'center',alignItems:'center'}} >

// <img src={require('../../Images/addbutton.png')} onClick={handleadduser} />
//     </Box>
//     </Box>
//     {/* <Box sx={{width:'65%',height:40,display:'flex',justifyContent:'center',alignItems:'center'}} >
// <Box sx={{width:100 , height:'100%',backgroundColor:'#144C89',borderRadius:2,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>handlenext()} >
// <Typography sx={{color:'white',cursor:'pointer'}} >Next</Typography>
// </Box>
//     </Box> */}
//      <Box style={{ width: "94%", margin: "auto", height: "35vh" }}>
//       <Box style={Styles.tableBox2}>
//         <TableContainer
//           component={Paper}
//           elevation={0}
//           style={Styles.tableContainer}
//         >
//           <Table style={Styles.table} aria-label="simple table">
//             <TableHead style={Styles.tableHead}>
//               <TableRow>
//                 <TableCell style={Styles.tableCell} align="left">
//                   No
//                 </TableCell>
//                 <TableCell style={Styles.tableCell} align="left">
//                   Name
//                 </TableCell>
//                 <TableCell style={Styles.tableCell} align="center">
//                   Email
//                 </TableCell>
//                 <TableCell style={Styles.tableCell} align="center">
//                   Department
//                 </TableCell>
//                 <TableCell style={Styles.tableCell} align="center">
//                  Designation
//                 </TableCell>
//                 <TableCell style={Styles.tableCell} align="center">
//                   Role
//                 </TableCell>
//                 <TableCell
//                   style={Styles.tableCell}
//                   align="center"
//                 ></TableCell>
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {orgusers?.map((row,index) => (
//                 <TableRow style={{ height: 60 }} key={row.id}>
//                   <TableCell style={Styles.tableText} align="left">
//                     {index+1}
//                   </TableCell>
//                   <TableCell style={Styles.tableText} align="left">
//                     {row?.name}
//                   </TableCell>
//                   <TableCell style={Styles.tableText} align="center">
//                     {row?.email}
//                   </TableCell>
//                   <TableCell style={Styles.tableText} align="center">
//                     {row?.department}
//                   </TableCell>
//                   <TableCell style={Styles.tableText} align="center">
//                     {row?.designation}
//                   </TableCell>
//                   <TableCell style={Styles.tableText} align="center">
//                     {row?.role}
//                   </TableCell>
//                   <TableCell style={Styles.tableText} align="center">
//                     <img
//                       style={{ cursor: "pointer" }}
//                       src={require("../../Images/threeDots.png")}
//                     />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Box>
//     <Box sx={{width:'100%',height:40,display:'flex',justifyContent:'flex-end',alignItems:'center'}} >
// <Box sx={{width:100 , height:'100%',backgroundColor:'#144C89',borderRadius:2,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>handlenext()} >
// <Typography sx={{color:'white',cursor:'pointer'}} >Next</Typography>
// </Box>
//     </Box>

//   </Box>
// )
// }
