import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import ChangeStatusPopup from "../Components/ChangeStatusPopup";
import ChangeAsigneePopup from "../Components/ChangeAsigneePopup";
import img1 from "../Images/demouser1.png";
import img2 from "../Images/demouser2.png";
import img3 from "../Images/cbs_Logo.png";
import axios from "axios";
import AttachmentList from "../Components/TicketDetails/AttachmentList";
import LogsAttachment from "../Components/TicketDetails/LogsAttachment";
import UserAvatar from "../Components/TicketDetails/UserAvatar";
import SeemoreTd from "../Components/TicketDetails/SeemoreTd";
import ConveneCall from "../Components/TicketDetails/ConveneCall";

export default function TicketDetail(props) {
  const mediaQueryMyTeam = useMediaQuery('(max-width:1302px)');
  const mediaQueryMyTeamUpper = useMediaQuery('(max-width:873px)');
  let {
    ticket_name,
    description,
    id,
    org,
    datetime,
    //status,
    project_id,
    raised_organisation_id,
    //assigned_to,
    raised_name
  } = props.ticketDetails;
  console.log("props in ticketDetails prat?ik", props?.ticketDetails?.connect_userid);
  const [status, setstatus] = useState(props.ticketDetails.status)
  const [assigned_to, setassigned_to] = useState(props.ticketDetails.assigned_to)
  let role = localStorage.getItem("role");
  let bgColor = "#FEB7B1;"; //resolved color
  if (status === "In Progress") {
    bgColor = "#BCDCA4";
  } else if (status === "Opened") {
    bgColor = "#F9C29A";
  } else if (status === "Resolved") {
    bgColor = "#FEB7B1";
  } else if (status === "Closed") {
    bgColor = "#E96973";
  } else if (status === "Assigned") {
    bgColor = "#BEC9F3";
  } else if (status === "Reopened") {
    bgColor = "#FFE4AC";
  } else if (status === "Pending") {
    bgColor = "#86D8FF";
  }
  const imgMap = {
    sameer: img1,
    kartik: img2,
  };
  const [allLogs, setAllLogs] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [handleRefresh, setHandleRefresh] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [numofFiles, setNumOfFiles] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");
  // console.log("this is all logs,", allLogs);
  const [attachments, setAttachments] = useState([]);
  const [newStatusArray, setNewstatusArray] = useState([])
  const [statusClosed, setStatusClosed] = useState(true);
  const [showIframe, setShowIframe] = useState(false);
  const localStorageUserId = localStorage.getItem("user_id")
  const statusArray1 = [
    {
      id: 1,
      name: "Closed",
      value: "Closed"
    },
    {
      id: 2,
      name: "Reopened",
      value: "Reopened"
    },
  ]

  const statusArray2 = [
    {
      id: 1,
      name: "In Progress",
      value: "In Progress"
    },
    {
      id: 2,
      name: "Opened",
      value: "Opened"
    },
    {
      id: 3,
      name: "Assigned",
      value: "Assigned"
    },
    {
      id: 3,
      name: "Pending",
      value: "Pending"
    },
    {
      id: 4,
      name: "Resolved",
      value: "Resolved"
    },
  ]

  useEffect(() => {
    if (status === "Resolved") {
      setNewstatusArray(statusArray1)
    }
    else if (status === "Closed") {
      setStatusClosed(false);
    }
    else {
      setNewstatusArray(statusArray2)
    }
  }, [])

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [ticketssort, setticketssort] = useState(status);
  const [oldStatus, setoldStatus] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [changeStatusClickedHovered, setChangeStatusClickedHovered] = useState(false);
  const [changeAssigneeHovered, setChangeAssigneeHovered] = useState(false);

  useEffect(() => {
    setoldStatus(status);
  }, []);

  const handleticketChange = async (event) => {
    setstatus(event.target.value);
    setticketssort(event.target.value);
    setNewStatus(event.target.value);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/changeticketstatus`,
        {
          id: id,
          status: event.target.value,
          user_id: localStorage.getItem("user_id"),
          ticket_status: event.target.value,
          orgn_id: localStorage.getItem("orgn_id"),
          project_id: project_id,
        }
      );
      // console.log(response.data.data, "project api");
      if (response.data.status === 1) {
        // console.log("ticket status changed i", response.data.data);
        // setAllLogs((prevLogs) => [...prevLogs, ...response.data.data]);
        scrollToBottom();
        fetchUserData();
        setHandleRefresh(true);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  // addticketlogs

  const [data, setData] = useState({});
  const [assiDetail, setassiDetail] = useState("");

  const handleChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, []); // Scroll to bottom when component mounts

  const [projectDetails, setProjectDetails] = useState([]);
  const [orgDetails, setOrgDetails] = useState([]);
  useEffect(() => {
    const getAttachments = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getticketdocumentslist`,
          { ticket_id: id }
        );
        // console.log(response.data.data, "project api");
        if (response.data.status === 1) {
          setAttachments(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getAttachments();
  }, []);
  const [onlinestatus, setonlinestatus] = useState("");
  console.log("this is status and", onlinestatus)


  useEffect(() => {
    const getOnlineStatus = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getonlinestatus`,
          { "id": assiDetail.id }
        );

        if (response.data.status) {
          setonlinestatus(response.data?.data[0]?.online_status);
        }
      } catch (e) {
        // Handle errors
        console.error(e);
      }
    };

    // Initial call
    getOnlineStatus();

    // Set up interval to check every 2 or 3 seconds
    const intervalId = setInterval(getOnlineStatus, 2000); // 2000 milliseconds = 2 seconds

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [assiDetail.id]);


  useEffect(() => {
    const getsystemdata = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallticketlogs`,
          { ticket_id: id }
        );
        // console.log(response.data.data, "all ticket logs");
        if (response.data.status === 1) {
          setAllLogs(response.data.data);
          scrollToBottom();
          setticketssort(status);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getsystemdata();
  }, []);

  useEffect(() => {
    const getsystemdata = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallticketlogs`,
          { ticket_id: id }
        );
        // console.log(response.data.data, "all ticket logs");
        if (response.data.status === 1) {
          setAllLogs(response.data.data);
          scrollToBottom();
          setticketssort(status);
          setHandleRefresh(false);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    fetchUserData();
    getsystemdata();
  }, [handleRefresh]);

  useEffect(() => {
    const getprojectdetails = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getprojectdetails`,
          { project_id: project_id }
        );
        if (response.data.status === 1) {
          // console.log("project logs api", response.data.data);
          setProjectDetails(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getprojectdetails();
  }, []);

  useEffect(() => {
    const getorganisationdetails = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getorganisationdetails`,
          { id: raised_organisation_id }
        );
        if (response.data.status === 1) {
          // console.log("org details", response.data.data);
          setOrgDetails(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getorganisationdetails();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getuserdata`,
        {
          id: assigned_to,
        }
      );
      // console.log(response.data);

      if (response.data.status === 1) {
        console.log(response.data.data, "apsopsopppp ppppp");
        setassiDetail(response.data.data[0]);
      }
    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    //console.log("I am been called");
    fetchUserData();
  }, []);

  const handlemessageend = async () => {
    if (data.comments !== "" || selectedFiles) {
      let datas = {
        ...data,
        project_id: project_id,
        orgn_id: localStorage.getItem("orgn_id"),
        assigned_to: localStorage.getItem("user_id"),
        ticket_id: id,
        ticket_status: ticketssort,
      };
      // console.log("updated data",datas)
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/addticketlogs`,
          datas
        );
        // console.log(response.data.data[0]?.id);
        if (response.data.status === 1) {
          // uploadCategoryImgFile(response.data.data[0]?.id)
          setAllLogs((prevLogs) => [...prevLogs, ...response.data.data]);
          scrollToBottom();
          data.comments = "";
        }
      } catch (e) {
        // console.log(e);
      }
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const sortedArray = allLogs.sort((a, b) => {
    const dateTimeA = new Date(a.datetime);
    const dateTimeB = new Date(b.datetime);
    return dateTimeA - dateTimeB;
  });

  // console.log("pppppp", selectedFiles.type.split("/")[1]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    // console.log("file data :", e.target.files[0]);
    // setNumOfFiles(files.length)
    // setAttachments(files);
    setSelectedFiles(e.target.files[0]);
    // handlemessageend()
    attachmentInsertFun(e.target.files[0]);
  };

  const attachmentInsertFun = async (file) => {
    // console.log('extension name',file?.type?.split("/")[1]);
    try {
      // const file = attachments[i];
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addticketlogsimage`,
        {
          ticket_id: id,
          project_id: project_id,
          orgn_id: localStorage.getItem("orgn_id"),
          assigned_to: localStorage.getItem("user_id"),
          file_extension: file?.type?.split("/")[1],
        }
      );
      if (response.data.status === 1) {
        // console.log("uploaded successfully (addticketlogsimage endpoint)", response.data.data[0].id);
        setAllLogs((prevLogs) => [...prevLogs, ...response.data.data]);
        scrollToBottom();
        let newId = response.data.data[0].id;
        try {
          // for (let i = 0; i < numofFiles; i++) {
          const formdata = new FormData();
          // const file = attachments[i];
          formdata.append("ticket_logs", file);
          formdata.append("ticket_id", newId);
          formdata.append("format", file?.type?.split("/")[1]);
          // console.log(formdata);
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL
            }/uploadticketlogs?ticket_id=${newId}&format=${file?.type?.split("/")[1]
            }`,
            formdata,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data.status === 1) {
            // console.log("the file was successfully uploaded (Upload ticket endpoint)",response.data)
          }
          // console.log(response.data);
        } catch (e) {
          // console.log(e);
        }
      }
      // console.log(response.data);
    } catch (e) {
      // console.log(e);
    }
  };

  const uploadCategoryImgFile = async (ids) => {
    // console.log("Hello : " + ids);
    if (true) {
      try {
        // for (let i = 0; i < numofFiles; i++) {
        const formdata = new FormData();
        // const file = attachments[i];
        formdata.append("ticket_logs", selectedFiles);
        formdata.append("ticket_id", ids);
        formdata.append("format", selectedFiles?.type?.split("/")[1]);
        // console.log(formdata);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL
          }/uploadticketlogs?ticket_id=${ids}&format=${selectedFiles?.type?.split("/")[1]
          }`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.status === 1) {
          // console.log("the file was successfully uploaded (Upload ticket endpoint)",response.data)
          try {
            // const file = attachments[i];
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/addticketlogsimage`,
              {
                ticket_id: id,
                project_id: project_id,
                orgn_id: localStorage.getItem("orgn_id"),
                assigned_to: localStorage.getItem("user_id"),
                file_extension: `${ids}.${selectedFiles?.type?.split("/")[1]}`,
              }
            );
            if (response.data.status === 1) {
              // console.log("uploaded successfully (addticketlogsimage endpoint)", response.data.data);
            }
            // console.log(response.data);
          } catch (e) {
            // console.log(e);
          }
        }
        // console.log(response.data);
        // }
      } catch (e) {
        // console.log(e);
      }
    }
  };
  const handleBack = () => {
    props.setActive(8);
  }

  const [tdBoxHeight, setTdBoxHeight] = useState("11%"); // Initial height
  const [viewMoreClicked, setViewMoreClicked] = useState(false);
  const [seeMoreClicked, setseeMoreClicked] = useState(false);

  const handleViewMore = () => {
    // Set the height to 35% when "view more" is clicked
    setTdBoxHeight("35%");
    setViewMoreClicked(true);
  };

  const handleSeeMore = () => {
    // Set the height to 35% when "view more" is clicked
    setseeMoreClicked(true);
  };
  // Chat iframe
  const handleClick = () => {
    setShowIframe(true);
  };
  const handleClose = () => {
    setShowIframe(false);
  };

  const sender_id = localStorage.getItem("connect_userid");

  const receiver_id = props?.ticketDetails?.connect_userid

  // console.log("url", `https://chatweb.convenevc.com/#/chatscreen/${sender_id}/${receiver_id}`);

  return (
    <Box style={Styles.mainBox}>
      <Box style={Styles.tdMainBox}>
        <Box style={Styles.tdBox1}>
          <Box style={{ ...Styles.tdBox1Inner, height: mediaQueryMyTeamUpper ? "15%" : "10%", flexDirection: mediaQueryMyTeamUpper ? "column" : "row", alignItems: "center" }}>
            <img src={require("../Images/Iconbackarrow.png")} alt="plus-icon" style={{ width: "30px", height: "20px", cursor: "pointer" }} onClick={() => { handleBack(); }}
            />
            <Box style={{ ...Styles.tdBox1InnerLeft, width: mediaQueryMyTeamUpper ? "90%" : "60%", flexWrap: mediaQueryMyTeamUpper ? "wrap" : "nowrap", justifyContent: mediaQueryMyTeamUpper ? "space-between" : "space-around" }}>
              <Typography style={{ ...Styles.tdText1, marginTop: "0px", fontSize: mediaQueryMyTeamUpper ? "10px" : "16px" }}>
                Ticket id :{" "}
                <span
                  style={{
                    ...Styles.tdText1,
                    marginTop: "0px",
                    color: "#1282A2",
                    fontWeight: "light",
                    // marginTop: "25px",
                    marginLeft: "2px",
                    // fontSize: "14px",
                    fontSize: mediaQueryMyTeamUpper ? "10px" : "14px"
                  }}
                >
                  #{id}
                </span>
              </Typography>

              <Typography style={{ ...Styles.tdText1, display: "flex", marginTop: "0px", fontSize: mediaQueryMyTeamUpper ? "10px" : "16px" }}>
                Date :{" "}
                <span
                  style={{
                    ...Styles.tdText1,
                    marginTop: "3px",
                    color: "#1282A2",
                    fontWeight: "light",
                    // marginTop: "25px",
                    marginLeft: "2px",
                    // fontSize: "14px",
                    fontSize: mediaQueryMyTeamUpper ? "10px" : "14px"
                  }}
                >
                  {new Date(datetime)?.toISOString()?.split("T")[0]}
                </span>
              </Typography>

              <Typography style={{ ...Styles.tdText1, marginTop: "0px", fontSize: mediaQueryMyTeamUpper ? "10px" : "16px" }}>
                Current status :{" "}
                <span
                  style={{
                    ...Styles.tdText1,
                    marginTop: "0px",
                    color: "#1282A2",
                    fontWeight: "light",
                    // marginTop: "25px",
                    marginLeft: "2px",
                    // fontSize: "14px",
                    fontSize: mediaQueryMyTeamUpper ? "10px" : "14px"
                  }}
                >
                  {status}
                </span>
              </Typography>
            </Box>
            {statusClosed &&
              <Box style={{ ...Styles.tdBox1InnerRight, width: mediaQueryMyTeamUpper ? "50%" : "15%", height: mediaQueryMyTeamUpper ? "4vh" : "6vh" }}>
                <FormControl
                  size="small"
                  style={{
                    ...Styles.tdBox1InnerRight,
                    width: "100%",
                    height: "100%",
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                  fullWidth
                >
                  <InputLabel
                    style={{
                      fontFamily: "Montserrat",
                      color: "#1282A2",
                      fontWeight: "bold",
                    }}
                    id="demo-simple-select-label"
                  >
                    {ticketssort ? "" : "Change status"}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={ticketssort}
                    label={ticketssort ? "" : "Change status"}
                    style={{
                      ...Styles.selectBox,
                      height: "100%",
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        border: "1px solid white",
                        borderColor: "white",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                    size="small"
                    onChange={handleticketChange}
                  >
                    {newStatusArray.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            }
          </Box>

          <Box style={{ ...Styles.tdBox1Middle, height: tdBoxHeight, overflowY: 'auto', alignItems: 'stretch' }}>
            <Box
              style={{
                width: "3%",
                height: "100%",
                backgroundColor: bgColor,
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            ></Box>
            <Box
              style={{
                width: "80%",
                overflow: "hidden",
                flexWrap: "nowrap",
                overflowY: 'auto',
                boxSizing: 'border-box'
              }}
            >
              <Typography
                // onClick={scrollToBottom}
                style={{
                  textAlign: "justify",
                  color: "#082A49",
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  fontSize: "18px",
                  margin: "1%",
                  display: "-webkit-box",
                  alignItems: "center",
                  justifyContent: "center",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: viewMoreClicked ? "unset" : 2, // Adjusted WebkitLineClamp value
                  width: "97%",
                }}
              >
                {viewMoreClicked
                  ? ticket_name
                  : ticket_name && ticket_name.length > 80
                    ? `${ticket_name.substring(0, 80)}`
                    : ticket_name}
                {ticket_name && ticket_name.length > 80 && !viewMoreClicked && (
                  <>
                    {" "}
                    <span
                      style={{
                        color: "#1282A2",
                        cursor: "pointer",
                        fontSize: "18px",
                      }}
                      onClick={handleViewMore}
                    >
                      ...view more
                    </span>
                  </>
                )}
              </Typography>

              <Typography
                // onClick={scrollToBottom}
                style={{
                  textAlign: "justify",
                  color: "#082A49",
                  fontFamily: "Montserrat",
                  maxHeight: "20px",
                  fontSize: "14px",
                  margin: "1%",
                  display: "-webkit-box",
                  alignItems: "center",
                  justifyContent: "center",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: viewMoreClicked ? "unset" : 2, // Adjusted WebkitLineClamp value
                  width: "97%",
                }}
              >
                {seeMoreClicked
                  ? description
                  : description && description.length > 80
                    ? `${description.substring(0, 80)}`
                    : description}
                {description && description.length > 80 && !seeMoreClicked && (
                  <>
                    {" "}
                    <span
                      style={{
                        color: "#1282A2",
                        cursor: "pointer",
                        fontSize: "13px",
                      }}
                      onClick={handleSeeMore}
                    >
                      See more
                    </span>
                  </>
                )}
              </Typography>
            </Box>

            <Box
              style={{
                width: "15%",
                display: "flex",
                height: "100%",
                alignItems: "center",
                marginLeft: "auto",  // This will push the box to the right
              }}
            >
              <Typography
                onClick={handleOpen2}
                style={{
                  cursor: "pointer",
                  marginLeft: "2%",
                  fontSize: "18px",
                  color: "#1282A2",
                  fontFamily: "Montserrat",
                }}
              >
                <img
                  style={{ width: "20px", marginRight: "1px" }}
                  src={require("../Images/attachment.png")}
                />
                {attachments.length}
              </Typography>
            </Box>
          </Box>


          <Box
            ref={messagesContainerRef}
            style={{
              ...Styles.chatBox,
              height: "50vh",
              width: "95%",
              display: "flex",
              //   margin:"auto",
              // borderLeft: "2px dashed #A9A9A9",

              marginLeft: "10%",
              flexDirection: "column",
            }}
          >
            {sortedArray.map((message) => (
              <Box
                key={message.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems:
                    message.user_id === localStorage.getItem("user_id")
                      ? "flex-end"
                      : "flex-start",
                  margin: "10px",
                  padding: "10px",
                  marginLeft: "4%",
                  borderLeft:
                    message.comments === "changed ticket status" ||
                      "Assigned ticket to"
                      ? "none"
                      : "2px dashed #A9A9A9",
                  // border: "1px solid #1282A2",
                  borderRadius: "0px",
                  maxWidth: "88%",
                }}
              >
                {/* changed status,etc msgs */}

                {/* {update && (
                  <>
                    <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "1%",
                      }}
                    >
                      {message.name} {message.comment} {oldStatus} to {newStatus}
                      .
                    </Typography>
                    <img src={require("../Images/plus.png")} />
                  </>
                )} */}

                {/* changed status,etc msgs */}

                {message.user_id !== localStorage.getItem("user_id") ||
                  (message.comments === "Assigned ticket to" && (
                    <Box
                      style={{
                        width: "30%",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      {/* <UserAvatar uid={message.user_id} /> */}

                      <Typography
                        style={{
                          textAlign: "justify",
                          color: "#082A49",
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          fontSize: "18px",
                          margin: "1%",
                        }}
                      >
                        {message.name}
                      </Typography>
                    </Box>
                  ))}

                {message.comments === "changed ticket status" ? (
                  <Box style={{ width: "100%", height: "30px", display: "flex", alignItems: "center" }} >
                    <img
                      style={{
                        alignSelf: "flex-start",
                        position: "relative",
                        top: "34%",
                        left: "-1.5%",
                      }}
                      src={require("../Images/circleBlue.png")}
                    />
                    <UserAvatar uid={message.user_id} />

                    <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "1%",
                        marginLeft: "3%",
                        alignSelf: "flex-start",
                      }}
                    >
                      {message.user_name}{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {message.comments}
                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Montserrat",
                          color: "#BCDCA4",
                        }}
                      >
                        {message.ticket_status}
                      </span>{" "}
                      .
                    </Typography>
                    <Typography
                      style={{
                        margin: "5px 0",
                        fontSize: "12px",
                        color: "#A9A9A9",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {`${new Date(message.datetime).toLocaleDateString()} `}
                    </Typography>
                  </Box>

                ) : message.comments === "Assigned ticket to" ? (

                  <Box style={{ height: "30px", width: "100%", display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        alignSelf: "flex-start",
                        position: "relative",
                        top: "34%",
                        left: "-1.5%",
                      }}
                      src={require("../Images/circleBlue.png")}
                    />
                    <UserAvatar uid={message.user_id} />

                    <Typography
                      style={{
                        textAlign: "justify",
                        color: "#082A49",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "18px",
                        // margin: "1%",
                        marginLeft: "3%",
                        alignSelf: "flex-start",
                      }}
                    >
                      {message?.assigned_by_name}{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {message.comments}
                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Montserrat",
                          color: "#BCDCA4",
                        }}
                      >
                        {message?.user_name} <span style={{ color: "#082A49", fontWeight: "bold" }}>{message.name}</span>
                      </span>{" "}
                      . {" "} {" "}
                    </Typography>
                    <Typography
                      style={{
                        margin: "5px 0",
                        fontSize: "12px",
                        color: "#A9A9A9",
                        fontFamily: "Montserrat",
                        alignSelf: "flex-end"
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${new Date(message.datetime).toLocaleDateString()} `}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    {message.attachments ? (
                      <>
                        {message.user_id !== localStorage.getItem("user_id") && (
                          <Box style={{ width: "100%", display: "flex" }}>
                            <UserAvatar uid={message.user_id} />

                            <Typography
                              style={{
                                textAlign: "justify",
                                color: "#082A49",
                                fontFamily: "Montserrat",
                                fontWeight: "bold",
                                fontSize: "18px",
                                margin: "1%",
                              }}
                            >
                              {message.user_name}
                            </Typography>
                          </Box>
                        )}
                        <Box>
                          <LogsAttachment
                            ticket_id={message.ticket_id}
                            id={message.id}
                            name={message.file_extension}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        {message.user_id !== localStorage.getItem("user_id") && (
                          <Box style={{ width: "100%", display: "flex" }}>
                            <UserAvatar uid={message.user_id} />

                            <Typography
                              style={{
                                textAlign: "justify",
                                color: "#082A49",
                                fontFamily: "Montserrat",
                                fontWeight: "bold",
                                fontSize: "18px",
                                margin: "1%",
                              }}
                            >
                              {message.user_name}
                            </Typography>
                          </Box>
                        )}
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            margin: "0",
                            padding: "0",
                            border: message.attachments
                              ? "none"
                              : "1px solid #1282A2",
                            borderRadius: "10px",
                            padding: "1%",
                            color: "#A9A9A9",
                            ...(message.comments === "changed ticket status" ||
                              (message.comments === "Assigned ticket to" && {
                                alignSelf: "flex-start",
                                border: "none",
                              })),
                          }}
                        >
                          {message.comments}
                        </Typography>
                        <Typography
                          style={{
                            margin: "5px 0",
                            fontSize: "12px",
                            color: "#A9A9A9",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {" "}
                          {`${new Date(
                            message.datetime
                          ).toLocaleDateString()} `}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </Box>
            ))}
          </Box>



          {/*  Umesh Hargane */}
{/* <Box
              ref={messagesContainerRef}
              style={{
                ...Styles.chatBox,
                height: "50vh",
                width: "95%",
                display: "flex",
                marginLeft: "10%",
                flexDirection: "column",
              }}
            >
              {sortedArray.map((message) => (
                <Box
                  // onMouseOver={(e) => handleMouseOver(e, message)}
                  // onMouseOut={handleMouseOut}
                 
                  key={message.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems:
                      message.user_id === localStorage.getItem("user_id")
                        ? "flex-end"
                        : "flex-start",
                    margin: "5px",
                    padding: "5px",
                    marginLeft: "4%",
                    borderRadius: "0px",
                    maxWidth: "88%",
                  }}
                >
                  {hoveredMessage?.id === message.id && (
                    <Box
                    onMouseOver={(e) => handleMouseOver(e, message)}
                          onMouseOut={handleMouseOut}
                      style={{
                        position: "absolute",
                        top: popupPosition.top,
                        left: popupPosition.left,
                        cursor: "pointer",
                        width: "200px", // Adjust the width as needed
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        border: "2px solid #144C89",
                        borderRadius: "10px",
                        padding: "5px",
                        zIndex: 1000,
                      }}
                
                  >
                      <Typography
                        style={{
                          ...Styles.tdText1,
                          fontSize: "14px",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {onlinestatus === "Online" ? (
                          <img
                            src={require("../Images/onlineStatus.png")}
                            style={{ width: "15px", height: "15px" }}
                          />
                        ) : (
                          <img
                            src={require("../Images/offlineStatus.png")}
                            style={{ width: "15px", height: "15px" }}
                          />
                        )}
                        Call To {hoveredMessage.user_name}
                      </Typography>
                      <Box
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "white",
                          position: "absolute",
                          bottom: "-12px",
                          left: "20%",
                          transform: "translateX(-50%) rotate(45deg)", // Rotate by 45 degrees
                          borderBottom: "2px solid #144C89",
                          borderRight: "2px solid #144C89",
                          borderBottomRightRadius: "5px",
                        }}
                      ></Box>
                    </Box>
                  )}
                  {message.comments === "changed ticket status" ? (
                    <Box
                      style={{
                        width: "100%",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        jusifyContent:"center",
                       
                      }}
                    >
                      <img
                        style={{
                          alignSelf: "flex-start",
                          position: "relative",
                          top: "34%",
                          left: "-1.5%",
                        }}
                        src={require("../Images/circleBlue.png")}
                        alt="status"
                      />
                      <UserAvatar uid={message.user_id} />
                      <Typography
                          onMouseOver={(e) => handleMouseOver(e, message)}
                          onMouseOut={handleMouseOut}
                        style={{
                          // textAlign: "justify",
                          display:"flex",
                          color: "#082A49",
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          fontSize: "16px",
                          margin: "0%",
                          marginLeft: "3%",
                          alignSelf: "flex-start",
                        height: "100%",
        
                           alignItems: "center",
                        jusifyContent:"center",
                        }}
                      >
                        <div
                          style={{ display: "inline"}}
                          onMouseOver={(e) => handleMouseOver(e, message)}
                          onMouseOut={handleMouseOut}
                        >
                          {message.user_name}
                        </div>{" "}
                        <div
                          style={{
                            display: "inline",
                            fontWeight: "lighter",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {message.comments}
                        </div>{" "}
                        <div
                          style={{
                            display: "inline",
                            fontWeight: "normal",
                            fontFamily: "Montserrat",
                            color: "#BCDCA4",
                          }}
                        >
                          {message.ticket_status}
                        </div>{" "}
                        .
                      </Typography>
                      <Typography
                        style={{
                          margin: "5px 0",
                          fontSize: "12px",
                          color: "#A9A9A9",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {`${new Date(message.datetime).toLocaleDateString()} `}
                      </Typography>
                    </Box>
                  ) : message.comments === "Assigned ticket to" ? (
                    <Box
                      style={{
                        height: "30px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          alignSelf: "flex-start",
                          position: "relative",
                          top: "34%",
                          left: "-1.5%",
                        }}
                        src={require("../Images/circleBlue.png")}
                        alt="status"
                      />
                      <UserAvatar uid={message.user_id} />
                      <Typography
                        style={{
                          textAlign: "justify",
                          color: "#082A49",
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          fontSize: "16px",
                          margin: "0.5%",
                          marginLeft: "3.3%",
                          alignSelf: "flex-start",
                        }}
                      >
                        {message?.assigned_by_name}{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {message.comments}
                        </span>{" "}
                        <span
                          style={{
                            fontWeight: "normal",
                            fontFamily: "Montserrat",
                            color: "#BCDCA4",
                            alignItems: "center",
                          }}
                        >
                          {message?.user_name}{" "}
                          <span style={{ color: "#082A49", fontWeight: "bold" }}>
                            {message.name}
                          </span>
                        </span>{" "}
                        .{" "}
                      </Typography>
                      <Typography
                        style={{
                          margin: "5px 0",
                          fontSize: "12px",
                          color: "#A9A9A9",
                          fontFamily: "Montserrat",
                          alignSelf: "flex-end",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {`${new Date(message.datetime).toLocaleDateString()} `}
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      {message.attachments ? (
                        <>
                          {message.user_id !==
                            localStorage.getItem("user_id") && (
                            <Box style={{ width: "100%", display: "flex" }}>
                              <UserAvatar uid={message.user_id} />
                              <Typography
                                style={{
                                  textAlign: "justify",
                                  color: "#082A49",
                                  fontFamily: "Montserrat",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  margin: "1%",
                                }}
                              >
                                {message.user_name}
                              </Typography>
                            </Box>
                          )}
                          <Box>
                            <LogsAttachment
                              ticket_id={message.ticket_id}
                              id={message.id}
                              name={message.file_extension}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          {message.user_id !==
                            localStorage.getItem("user_id") && (
                            <Box style={{ width: "100%", display: "flex" }}>
                              <UserAvatar uid={message.user_id} />
                              <Typography
                                style={{
                                  textAlign: "justify",
                                  color: "#082A49",
                                  fontFamily: "Montserrat",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  margin: "1%",
                                }}
                              >
                                {message.user_name}
                              </Typography>
                            </Box>
                          )}
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              margin: "0",
                              padding: "0",
                              border: message.attachments
                                ? "none"
                                : "1px solid #1282A2",
                              borderRadius: "10px",
                              padding: "1.5%",
                              color: "#A9A9A9",
                              ...(message.comments === "changed ticket status" ||
                                (message.comments === "Assigned ticket to" && {
                                  alignSelf: "flex-start",
                                  border: "none",
                                })),
                            }}
                          >
                            {message.comments}
                          </Typography>
                          <Typography
                            style={{
                              margin: "5px 0",
                              fontSize: "12px",
                              color: "#A9A9A9",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {`${new Date(
                              message.datetime
                            ).toLocaleDateString()} `}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </Box>
              ))}
            </Box> */}

          <Box style={Styles.textfieldSender}>
            <TextField
              // size="small"
              // InputProps={{
              //   style: { border: "none" },
              // }}
              value={data.comments}
              onChange={(e) => {
                handleChange("comments", e.target.value);
              }}
              style={{
                width: "100%",
                height: "6vh",
                borderRadius: "10px",
                "& fieldset": {
                  border: "none",
                },
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handlemessageend();
                }
              }}
              placeholder="Type here ..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        style={{ display: "none" }}
                        id="fileInput"
                      />
                    </Box>
                    <label
                      htmlFor="fileInput"
                      style={{ cursor: "pointer", marginBottom: "10px" }}
                    >
                      <img
                        style={{
                          marginRight: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginTop: "14px"
                        }}
                        src={require("../Images/attachment.png")}
                      />
                    </label>
                    <img
                      style={{ cursor: "pointer" }}
                      src={require("../Images/sendIcon.png")}
                      onClick={() => handlemessageend()}
                    />{" "}
                  </InputAdornment>
                ),
              }}
            >
              {/* <img src={require("../Images/attachment.png")}/> */}
            </TextField>
          </Box>
        </Box>

        <Box style={Styles.tdBox2}>
          <Typography style={Styles.tdText1}>Client details</Typography>
          <Typography style={Styles.tdText2}>{orgDetails[0]?.name}</Typography>
          <Typography style={Styles.tdText3}>Manager</Typography>
          <Typography style={Styles.tdText3}>
            {" "}
            <img
              style={{
                marginRight: "2%",
                marginLeft: "3%",
                marginTop: "3%",
                width: "15px",
              }}
              src={require("../Images/phone.png")}
            />
            {orgDetails[0]?.number}
          </Typography>
          <Typography style={Styles.tdText3}>
            {" "}
            <img
              style={{ marginRight: "2%", marginLeft: "3%", width: "15px" }}
              src={require("../Images/email.png")}
            />
            {orgDetails[0]?.email}
          </Typography>
          <Box
            style={{
              width: "90%",
              height: "2px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "5%",
              borderBottom: "2px dashed #A9A9A9",
            }}
          />
          <Typography style={Styles.tdText1}>Project details</Typography>
          <Typography
            style={{ ...Styles.tdText3, fontWeight: "bolder", marginTop: "2%" }}
          >
            Project name :
          </Typography>
          <Typography style={{ ...Styles.tdText3, width: "70%" }}>
            {projectDetails[0]?.name}
          </Typography>
          <Typography
            style={{ ...Styles.tdText3, fontWeight: "bolder", marginTop: "2%" }}
          >
            Ticket Created By :
          </Typography>
          <Typography style={{ ...Styles.tdText3, width: "70%" }}>
            {raised_name}
          </Typography>
          {role === "admin" && (
            <Box style={{ ...Styles.reqButton, margin: "3%" }} onClick={() => { props.setActive(11) }}>
              <Typography style={Styles.text3}>Show details</Typography>
            </Box>
          )}
          <Box
            style={{
              borderBottom: "2px dashed #A9A9A9",
              width: "90%",
              height: "2px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />

          {/* <Button  onMouseOver={() => setIsHovered(true)}  onMouseOut={() => setIsHovered(false)}
            variant="contained" color="primary" onClick={handleClick}>
            Chat
          </Button>
 */}
          <Box style={{ width: "100%", display: "flex" }}>
            <Typography style={Styles.tdText1}>Assigned to</Typography>
            {isHovered &&
                assiDetail &&
                assiDetail.id !== localStorageUserId && (

              <Box
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                style={{
                  cursor: "pointer",
                  width: "50%",
                  height: "90%",
                  backgroundColor: "White",
                  display: "flex",
                  jusifyContent: "center",
                  border: "2px solid #144C89",
                  borderRadius: "10px",
                  margin: "2.5%",
                  marginBottom: "0px",
                  alignItems: "center"
                  // overflow: "hidden"
                }}
              >
                <Typography

                  onClick={handleOpen3}
                  style={{
                    ...Styles.tdText1,
                    fontSize: "14px",
                    margin: "auto",
                    zIndex: 100,
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    // backgroundColor:"green",
                    width: "80%",
                  }}
                >
                  {onlinestatus === "Online" ? (
                    <img src={require("../Images/onlineStatus.png")} style={{ width: "15px", height: "15px" }} />
                  ) : (
                    <img src={require("../Images/offlineStatus.png")} style={{ width: "15px", height: "15px" }} />
                  )}
                  {/* <Box style={{width:"10px",height:"10px",borderRadius:"50%",backgroundColor:onlinestatus === "Online"?"lightgreen":"red"}}/> */}
                  Call
                </Typography>

                <Box
                  style={{
                    width: "30px",
                    height: "20px",
                    backgroundColor: "white",
                    position: "relative",
                    top: "55%",
                    right: "12%",
                    borderBottom: "2px solid #144C89",
                    borderRight: "2px solid #144C89",
                    borderBottomRightRadius: "5px",
                    transform: "rotate(45deg)", // Rotate by 90 degrees
                  }}
                ></Box>

                  <Button style={{backgroundColor:"white" , color:"Black", marginRight:"1rem"}} 
                          onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
                          variant="contained" onClick={handleClick} > CHAT 
                  </Button>

              </Box>


            )}

            {/* {isHovered && (

              <Button onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
                variant="contained" onClick={handleClick}>CHAT</Button>

            )} */}
          </Box>
          <Box
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
          >
            <UserAvatar uid={assiDetail.id} assigned={true} />
            {/* <img
              style={{
                width: "55px",
                height: "50px",
                borderRadius: "50%",
                marginLeft: "4%",
                marginTop: "4%",
              }}
              src={require("../Images/profile.png")}
            /> */}
            <Box>
              <Typography style={{ ...Styles.tdText2, marginTop: "15px" }}>
                {assiDetail ? assiDetail?.name : "null"}
              </Typography>
              <Typography style={Styles.tdText3}>{assiDetail?.designation} </Typography>
            </Box>
          </Box>
          <Box
            style={{
              borderBottom: "2px dashed #A9A9A9",
              width: "90%",
              height: "2px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "5%",
            }}
          />

          {/* {!isHovered2 && ( */}
          {statusClosed &&
            <Box
              onMouseOver={() => setIsHovered2(true)}
              onMouseOut={() => setIsHovered2(false)}
              onClick={() => { handleOpen() }}
              style={{
                ...Styles.reqButton,
                marginTop: "5%",
                marginRight: "0%",
                marginLeft: "5%",
              }}
            >
              <Typography style={Styles.text3}>Action</Typography>
            </Box>
          }
          {/* )} */}
          {isHovered2 && (
            <Box
              onMouseOver={() => setIsHovered2(true)}
              onMouseOut={() => setIsHovered2(false)}
              style={{
                width: "55%",
                //   height: "4vh",
                backgroundColor: "white",
                display: "flex",
                // jusifyContent: "center",
                border: "2px solid #144C89",
                borderRadius: "10px",
                margin: "auto",
                marginBottom: "0px",
                marginTop: "1%",
                flexDirection: "column",
                // alignItems: "center",
                // textAlign: "center",
              }}
            >
              <Typography
                style={{
                  textAlign: "end",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsHovered2(false);
                }}
              >
                X
              </Typography>

              <Typography
                onClick={handleOpen}
                onMouseOver={() => setChangeStatusClickedHovered(true)}
                onMouseLeave={() => setChangeStatusClickedHovered(false)}
                style={{
                  fontFamily: "Montserrat",
                  color: "#082A49",
                  fontWeight: "bold",
                  marginTop: "2%",
                  marginLeft: "8px",
                  fontSize: "18px",
                  fontSize: "14px",
                  cursor: "pointer",
                  textAlign: "justify",
                  color: changeStatusClickedHovered ? "#144C89" : "#B2AFAF",
                  //   margin: "auto",
                  //   marginTop: "15px",
                }}
              >
                Change status
              </Typography>
              {/* {role === "admin" && */}

              <Typography
                onClick={handleOpen1}
                onMouseOver={() => setChangeAssigneeHovered(true)}
                onMouseLeave={() => setChangeAssigneeHovered(false)}
                style={{
                  fontFamily: "Montserrat",
                  color: changeAssigneeHovered ? " #144C89" : "#B2AFAF",
                  fontWeight: "bold",
                  marginTop: "2%",
                  marginLeft: "8px",
                  fontSize: "18px",
                  fontSize: "14px",
                  cursor: "pointer",

                  //   margin: "auto",
                  //   marginTop: "20px",
                }}
              >
                Change asignee
              </Typography>
              {/* 
              {role === "admin" && (
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "#082A49",
                    fontWeight: "bold",
                    marginTop: "2%",
                    marginBottom: "18px",
                    fontSize: "18px",
                    fontSize: "14px",
                    cursor: "pointer",
                    marginLeft: "8px",
                  }}
                >
                  Resolve ticket
                </Typography>
              )}
            */}
            </Box>
          )}

          {/* iframe  */}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="20vh" // Adjust the height as necessary
          >
            {/* <Typography variant="h6" style={{ marginBottom: '20px' }}>
              Convene Connect
            </Typography>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Chat
            </Button> */}
            {showIframe && (
              <Box
                position="fixed"
                top="63.5%"
                left="89.7%"
                width="375px" // Mobile device width
                height="667px" // Mobile device height
                style={{ transform: 'translate(-50%, -50%)', marginTop: '20px' }}
              >
                <Box position="relative" width="100%" height="100%">
                  <IconButton
                    onClick={handleClose}
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      height: "5px"
                    }}
                  >
                    X
                  </IconButton>

                  <iframe
                    src={`https://chatweb.convenevc.com/#/chatscreen/${sender_id}/${receiver_id}`}
                    width="100%"
                    height="98%"
                    style={{ border: '1px' }}
                    title="Chat Screen"
                  ></iframe>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

      </Box>
      {open && (
        <ChangeStatusPopup
          open={open}
          currentstatus={status}
          project_id={project_id}
          id={id}
          assigned_to={assigned_to}
          assigned_name={assiDetail?.name}
          setstatus={setstatus}
          setassigned_to={setassigned_to}
          fetchUserData={fetchUserData}
          setOpen={setOpen}
          setHandleRefresh={setHandleRefresh}
        />
      )}
      {open1 && (
        <ChangeAsigneePopup
          open={open1}
          id={id}
          assigned_to={assigned_to}
          assigned_name={assiDetail?.name}
          setOpen={setOpen1}
          setHandleRefresh={setHandleRefresh}
        // allLogs={allLogs}
        // setAllLogs={setAllLogs}

        />
      )}
      {openPopup && (
        <SeemoreTd
          open={openPopup}
          setOpen={setOpenPopup}
          cardData={props.ticketDetails}

        />
      )}
      {open2 && <AttachmentList open={open2} id={id} setOpen={setOpen2} />}
      {open3 && <ConveneCall ticket_name={ticket_name} open={open3} assigned={assiDetail.id} setOpen={setOpen3} />}

    </Box>

  );
}
