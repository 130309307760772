import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { useNavigate } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import img1 from "../Images/eye_icon_open.png";
import img2 from "../Images/eye_icon_close.png";

export default function Login(props) {

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      event.stopPropagation();
      window.history.pushState(null, null, window.location.href);
    };

    // Lock back navigation when the component mounts
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleBackButton);

    // Clean up event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const MediaQueryOrgCard = useMediaQuery("(max-width: 900px)");
  const [isExpanded, setIsExpanded] = useState(false);
  const [Password, setDatabasePassword] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const handleChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showinvalidemail, setInvalidEmail] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleadduser = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getuserorganisations`,
        data
      );
      if (response.data.status === 1) {
        setIsExpanded(!isExpanded);
        setOrgList(response.data.data);
        setInvalidEmail(false);
      } else {
        setInvalidEmail(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [locatdata, setlocaldata] = useState({});

  const handleLogin = () => {
    console.log(locatdata);
    // associated_organisationid
// 
    if(data.Orgnisation !== undefined){
    if (data.password === Password) {
      localStorage.setItem("orgn_id", locatdata?.organisation);
      localStorage.setItem("user_id", locatdata?.id);
      localStorage.setItem("role", locatdata?.role);
      localStorage.setItem("platorgn", locatdata?.organisation_admin);

      localStorage.setItem("authenticated", true);
      localStorage.setItem("connect_userid", locatdata?.connect_userid);
      // localStorage.setItem("connect_sender_id")
      
      navigate("/admin-dashboard");
      props.onAuthentication(true);
    } else {
      alert('Incorrect Password, Please try again');
    }
  }
  else{
    alert('Please Select Organisation');
  }
  };

  const handlepasswords = async (password, org) => {
    setlocaldata(org);
    setDatabasePassword(password);
  };

  return (
    <Box style={Styles.mainContainer}>
      <Box style={Styles.backgroundImage} />

      <Box style={Styles.mainBox} className={isExpanded ? "expanded" : ""}>
        <Box style={Styles.logoAndLoginBox}>
          <Box style={Styles.logoBox}>
            <img
              style={{ width: "100px", marginTop: "15px" }}
              src={require("../Images/cbs_Logo.png")}
              alt="Logo"
            />
            <img
              style={{ width: "140px", marginLeft: "10px", marginTop: "15px" }}
              src={require("../Images/cbs.png")}
              alt="CBS"
            />
          </Box>

          <Box style={Styles.loginText}>
            <h1 style={{ marginRight: "46%",marginTop:"40%" }}>LOG IN</h1>
            <div style={{ width: "70%", justifyContent: "center", alignItems: "center", alignSelf: "center" }}>
              <TextField
                id="standard-basic"
                label="Email"
                variant="standard"
                value={data.email}
                required
                onChange={(e) => {
                  handleChange("email", e.target.value);
                }}
                style={{
                  borderColor: "#144C89",
                  width: "80%",
                }}
              />
              <img
                style={{...Styles.arrowImage,marginLeft:"5%"}}
                src={require("../Images/LoginArrow.png")}
                alt="Arrow"
                onClick={handleadduser}
              />
              {showinvalidemail && (
                <Typography sx={{ color: "red" }}>Invalid Email</Typography>
              )}
            </div>
            {isExpanded && (
              <>
                <div style={{ width: "70%", justifyContent: "center", alignItems: "center", alignSelf: "center" }}>
                  <FormControl variant="standard" sx={{ m: 0, minWidth: "80%", marginBottom: "15px" }}>
                    <InputLabel id="demo-simple-select-standard-label">Organisation</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={data.Orgnisation}
                      onChange={(e) => handleChange("Orgnisation", e.target.value)}
                      label="Search Orgnisation"
                    >
                      {orgList?.map((org) => (
                        <MenuItem
                          key={org?.id}
                          value={org?.id}
                          onClick={() => handlepasswords(org?.password, org)}
                        >
                          {org?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ width: "70%", justifyContent: "center", alignItems: "center", alignSelf: "center" }}>
                  <TextField
                    id="standard-basic"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={data.password}
                    required
                    onChange={(e) => {
                      handleChange("password", e.target.value);
                    }}
                    variant="standard"
                    style={{
                      borderColor: "#144C89",
                      width: "80%",
                    }}
                  />
                  <img
                    src={showPassword ? img2 : img1}
                    alt="Toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{...Styles.arrowImage,marginLeft:"5%"}}
                  />
                </div>

                <Box style={Styles.reqButton} onClick={handleLogin}>
                  <Typography style={Styles.text3}>Login</Typography>
                </Box>

                <Typography variant="body2" color="error" style={{ marginTop: "10px" }}>
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const Styles = {
    mainContainer: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
  backgroundImage: {
    width: "40%",
  height:'auto',
    position: "fixed",
    top: "0%",
    right: 0,
    bottom: "0",
    left: "auto",
    backgroundImage: `url(${require("../Images/Loginbg.jpg")})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    zIndex: -1,
  },
  mainBox: {
    display: "flex",
 
    alignItems: "center",
    position: "relative",
    zIndex: 1,
  },
  logoAndLoginBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    padding: "1px", // Adjust as needed
    boxSizing: "border-box",
  },
  logoBox: {
    width: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  
  },
  loginText: {
    width: "30%",
    color: "#144C89",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    
  },
  arrowImage: {
    marginTop: "20px",
    marginLeft: "1px",
    cursor: "pointer",
  },
  reqButton: {
    width: "160px",
    height: "40px",
    borderRadius: "10px",
    backgroundColor: "#144C89",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "68px",
    cursor: "pointer",
    marginTop: "5%",
    alignSelf: "center",
  },
  text3: {
    fontSize: "15px",
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
};
