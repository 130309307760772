import React, { useEffect, useState } from "react";
import Styles from "../../Styles/AdminDashboard";
import { Box, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";

export default function ChecklistCard2(props) {
  const MediaQueryOrgCard = useMediaQuery("(max-width: 900px)");
  // console.log("props in checklist card 2",props.item.end_date)

  const inputDateString = props.item.end_date;
  const formattedDate = new Date(inputDateString).toLocaleDateString("en-GB");

  const inputDateString1 = props.item.start_date;
  const formattedDate1 = new Date(inputDateString1).toLocaleDateString("en-GB");
  const [checklists, setchecklists] = useState([]);
  const [arrName, setarrName] = useState([]);
  const letterColors = {
    a: "#81b29a",
    b: "#81b29a",
    c: "#81b29a",
    d: "#f2cc8f",
    e: "#f2cc8f",
    f: "#f2cc8f",
    g: "#3d405b",
    h: "#3d405b",
    i: "#3d405b",
    j: "#e07a5f",
    k: "#e07a5f",
    l: "#e07a5f",
    m: "#eaac8b",
    n: "#eaac8b",
    o: "#eaac8b",
    p: "#eaac8b",
    q: "#e4c1f9",
    r: "#e4c1f9",
    s: "#a1c181",
    t: "#a1c181",
    u: "#a1c181",
    v: "#fe7f2d",
    w: "#fe7f2d",
    x: "#fe7f2d",
    y: "#bf0603",
    z: "#bf0603",
  };

  useEffect(() => {
    const getchecklisttask = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallprojectschecklisttask`,
          {
            projectId: props?.item?.project_id,
            type: props?.item?.type,
          }
        );
        if (response.data.status === 1) {
          // console.log("response data checklist task new : ", response?.data?.data);
          setchecklists(response?.data?.data);

          // // Count status-wise tasks
          // const statusCounts = countStatusTasks(response?.data?.data);
          // console.log("Status counts:", statusCounts);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getchecklisttask();
  }, []);

  useEffect(() => {
    const getchecklisttask = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallassigneduserstotask`,
          {
            projectId: props?.item?.project_id,
          }
        );
        if (response.data.status === 1) {
          // console.log("response data all assigned members : ", response?.data?.data);
          setarrName(response?.data?.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getchecklisttask();
  }, []);

  const navigateTeamsStatus = (item) => {
    props.setActive(3);
    props.setTaskDatastatus(item);
  };

  const getRandomColor = (name) => {
    const firstLetter = name?.charAt(0).toLowerCase();
    return letterColors[firstLetter] || "#808080"; // Default color is gray (#808080)
  };

  const isDarkColor = (color) => {
    const hex = color.substring(1); // Remove the '#' character
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Use the relative luminance formula to determine if the color is dark
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance < 128;
  };

  const renderCircles = () => {
    const maxCirclesToShow = 4;

    return arrName.slice(0, maxCirclesToShow).map(({ id, username }, index) => {
      const backgroundColor = getRandomColor(username);
      const textColor = isDarkColor(backgroundColor) ? "white" : "black";

      return (
        <Box
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Box
            key={id}
            style={{
              marginLeft: index > 0 ? "-10px" : "0", // Adjust spacing between circles
              width: "39px",
              height: "39px",
              borderRadius: "50px",
              backgroundColor,
              color: textColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              style={{ fontStyle: "bolder", fontFamily: "Montserrat" }}
            >
              {username?.charAt(0).toUpperCase()}
            </Typography>
          </Box>
        </Box>
      );
    });
  };

  return (
    <div>
      <Box
        sx={{
          width: MediaQueryOrgCard ? "99%" : "90%",
          marginTop: "1.7%",
          backgroundColor: "#F8F8F8",
          borderRadius: 5,
          boxShadow: 5,
          display: "flex",
          flexDirection: "row",
          height: 260,
          cursor: "pointer",
          marginBottom: 1,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box
          sx={{
            width: "70%",
            borderRight: "2px dashed #A9A9A9",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "95%",
              height: "35%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "#082A49",
                fontWeight: 700,
                fontSize: "32px",
                marginLeft: "5%",
                marginTop: "4%",
                fontFamily: "Montserrat",
              }}
            >
              {props.item.project_name && props.item.project_name.length > 20
                ? `${props.item.project_name.substring(0, 20)}`
                : props.item.project_name}
              {props.item.project_name && props.item.project_name.length > 20 && (
                <>
                  {" "}
                  <span
                    style={{
                      color: "#1282A2",
                      cursor: "pointer",
                      fontSize: "22px",
                    }}
                    // onClick={handleOpenPopup}
                  >
                    ...
                  </span>
                </>
              )}
              {/* {props.item.project_name} */}
            </Typography>
            <Typography
              style={{
                marginLeft: "5%",
                marginTop: "2%",
                color: "#A9A9A9",
                fontFamily: "Montserrat",
              }}
            >
              {formattedDate1} to {formattedDate}
            </Typography>
            <Typography
              sx={{
                color: "#A9A9A9",
                fontWeight: 500,
                fontSize: MediaQueryOrgCard ? 13 : 18,
                marginLeft: "5%",
              }}
            >
              {props.item.industry}
            </Typography>
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <Typography
              sx={{
                color: "#1282A2",
                fontWeight: 500,
                fontSize: "24px",
                marginLeft: "5%",
                fontFamily: "Montserrat",
              }}
            >
              Owner - {props.item.owner_name}
            </Typography>
            <Typography
              sx={{
                color: "#1282A2",
                fontWeight: 500,
                fontSize: "24px",
                marginLeft: "5%",
                fontFamily: "Montserrat",
              }}
            >
              Spoc - {props.item.spoc_name}
            </Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              height: "12%",
              display: "flex",
              marginLeft: "30px",
              marginTop: "30px",
            }}
          >
            {/* {props.item?.Members?.map(({ id, name }) => {
              const backgroundColor = getRandomColor(name);
              const textColor = isDarkColor(backgroundColor) ? "white" : "black";
          
              return (
                <Box
                  key={id}
                  style={{
                    width: "53px",
                    height: "53px",
                    borderRadius: "50px",
                    backgroundColor,
                    color: textColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Typography style={{ fontWeight: "bold", fontSize: "32px" }}>
                    {name.charAt(0).toUpperCase()}
                  </Typography>
                </Box>
              );
            })} */}
            {renderCircles()}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexWrap: "wrap",
            marginTop: "15px",
          }}
        >
          <Box
            onClick={() => {
              navigateTeamsStatus("all");
            }}
            style={{ ...Styles.card, width: "290px", height: "5vh", zIndex: 4 }}
          >
            <Box style={Styles.dottedLine}></Box>
            <Box
              style={{
                ...Styles.innerTicket,
                height: "4vh",
                width: "50%",
                marginLeft: "-38px",
              }}
            >
              <Box style={Styles.circle1}></Box>
              <Box style={{ ...Styles.circleMainBox, marginLeft: "16.2%" }}>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
              </Box>
              <Typography
                style={{ ...Styles.text4, marginLeft: "2%", fontSize: "10px" }}
              >
                Total Tasks
              </Typography>
            </Box>
            <Box style={Styles.circle3}></Box>
            <Typography style={Styles.text5}>
              {checklists[0]?.total_count}
            </Typography>
          </Box>

          <Box
            onClick={() => {
              navigateTeamsStatus("today");
            }}
            style={{ ...Styles.card, width: "290px", height: "5vh" }}
          >
            <Box style={Styles.dottedLine}></Box>
            <Box
              style={{
                ...Styles.innerTicket,
                backgroundColor: "#FFE4AC",
                height: "4vh",
                width: "50%",
                marginLeft: "-38px",
              }}
            >
              <Box style={Styles.circle1}></Box>
              <Box style={{ ...Styles.circleMainBox, marginLeft: "16.2%" }}>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
              </Box>
              <Typography
                style={{ ...Styles.text4, marginLeft: "2%", fontSize: "10px" }}
              >
                Today's Tasks
              </Typography>
            </Box>
            <Box style={Styles.circle3}></Box>
            <Typography style={Styles.text5}>
              {checklists[0]?.todays_count}
            </Typography>
          </Box>

          <Box
            onClick={() => {
              navigateTeamsStatus("notcompleted");
            }}
            style={{ ...Styles.card, width: "290px", height: "5vh" }}
          >
            <Box style={Styles.dottedLine}></Box>
            <Box
              style={{
                ...Styles.innerTicket,
                backgroundColor: "#BEC9F3",
                height: "4vh",
                width: "50%",
                marginLeft: "-33px",
              }}
            >
              <Box style={Styles.circle1}></Box>
              <Box style={{ ...Styles.circleMainBox, marginLeft: "13.5%" }}>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
              </Box>
              <Typography
                style={{ ...Styles.text4, marginLeft: "2%", fontSize: "10px" }}
              >
                Not Completed Tasks
              </Typography>
            </Box>
            <Box style={Styles.circle3}></Box>
            <Typography style={Styles.text5}>
              {checklists[0]?.notcompleted_count}
            </Typography>
          </Box>

          <Box
            onClick={() => {
              navigateTeamsStatus("completed");
            }}
            style={{ ...Styles.card, width: "290px", height: "5vh", zIndex: 4 }}
          >
            <Box style={Styles.dottedLine}></Box>
            <Box
              style={{
                ...Styles.innerTicket,
                backgroundColor: "#BCDCA4",
                height: "4vh",
                width: "50%",
                marginLeft: "-38px",
              }}
            >
              <Box style={Styles.circle1}></Box>
              <Box style={{ ...Styles.circleMainBox, marginLeft: "16.2%" }}>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
              </Box>
              <Typography
                style={{ ...Styles.text4, marginLeft: "2%", fontSize: "10px" }}
              >
                Completed Tasks
              </Typography>
            </Box>
            <Box style={Styles.circle3}></Box>
            <Typography style={Styles.text5}>
              {checklists[0]?.completed_count}
            </Typography>
          </Box>

          <Box
            onClick={() => {
              navigateTeamsStatus("inprogress");
            }}
            style={{ ...Styles.card, width: "290px", height: "5vh", zIndex: 4 }}
          >
            <Box style={Styles.dottedLine}></Box>
            <Box
              style={{
                ...Styles.innerTicket,
                backgroundColor: "#FEB7B1",
                height: "4vh",
                width: "50%",
                marginLeft: "-38px",
              }}
            >
              <Box style={Styles.circle1}></Box>
              <Box style={{ ...Styles.circleMainBox, marginLeft: "16.2%" }}>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
                <Box
                  style={{ ...Styles.circle2, width: "8px", height: "8px" }}
                ></Box>
              </Box>
              <Typography
                style={{ ...Styles.text4, marginLeft: "2%", fontSize: "10px" }}
              >
                In Progress Tasks
              </Typography>
            </Box>
            <Box style={Styles.circle3}></Box>
            <Typography style={Styles.text5}>
              {checklists[0]?.inprogress_count}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
