import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Styles from "../Styles/AdminDashboard";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import Checklist from './CheckList';

export default function AdminDashboard(props) {
  const [status, setStatus] = useState({
    isOnline: navigator.onLine,
    statusMessage: navigator.onLine ? 'Online' : 'Offline',
  });

  // console.log("online status", status.statusMessage);

  useEffect(() => {
    const handleOnline = () => {
      setStatus({
        isOnline: true,
        statusMessage: 'Online',
      });
    };

    const handleOffline = () => {
      setStatus({
        isOnline: false,
        statusMessage: 'Offline',
      });
    };

    const handleBeforeUnload = () => {
      axios.post(`${process.env.REACT_APP_API_URL}/updateonlinestatus`, {
        id: localStorage.getItem("user_id"),
        online_status: 'Offline',
      });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/updateonlinestatus`,
          {
            id: localStorage.getItem("user_id"),
            online_status: status.isOnline ? 'Online' : 'Offline',
          }
        );
      } catch (error) {

      }
    };

    fetchUserData();
  }, [status.isOnline]);
  const mediaQueryMyTeamUpper = useMediaQuery('(max-width:807px)');
  const [allTickets,setAlltickets] = useState([]);
  const [data,setData] = useState("");
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserdata`,
          {
            id: localStorage.getItem("user_id"),
          }
        );
        // console.log(response.data);

        if (response.data.status === 1) {
          // console.log(response.data.data);
          setData(response.data.data[0]);
        }
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const getsystemdata = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserspecifictickets`,
          { "user_id": localStorage.getItem("user_id"), "organisation":  localStorage.getItem("orgn_id"),"role":localStorage.getItem("role") }
        );
        // console.log(response.data.data,"project api");
        if (response.data.status === 1) {
          setAlltickets(response.data.data);
        }
        getteam()
        getallcustomerforadmin()
      } catch (e) {
        // console.log(e);
      }
    };
    getsystemdata();
  }, []);
const [getteams,setgetteams]=useState([])
  const getteam = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getallreportinguser`,
        { "user_id": localStorage.getItem("user_id"), "raised_organisation_id":  localStorage.getItem("orgn_id") }
      );
      console.log(response.data.data,"team api for role");
      if (response.data.status === 1) {
        setgetteams(response.data.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  // getalluserforadmin
const [allcustomer,setallcustomer]=useState([])

  const getallcustomerforadmin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getalluserforadmin`,
        { "user_id": localStorage.getItem("user_id"), "organisation":  Number(localStorage.getItem("orgn_id" )),"platorgn": Number(localStorage.getItem("platorgn")) }
      );
      // console.log(response.data.data,"team api");
      if (response.data.status === 1) {
        setallcustomer(response.data.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const navigateInnerDash =(user_id,role)=>{
    props.setActive(14);
    props.setinnerId(user_id);
    props.setTicketId(user_id);
    props.setinnerRole(role);
  }

  const ViewTicketPage = (status,length)=>{
    props.setActive(8);
    props.setTicketSortings(status);
    props.setTicketLength(length)
    props.setTicketId(null);
  }
  const resolvedTickets = allTickets?.filter(ticket => ticket.status === 'Resolved');
  const closedTickets = allTickets?.filter(ticket => ticket.status === 'Closed');
  const openedTickets = allTickets?.filter(ticket => ticket.status === 'Opened');
  const inprogressTickets = allTickets?.filter(ticket => ticket.status === 'In Progress');
  const assignedTickets = allTickets?.filter(ticket => ticket.status === "Assigned");
  const reopenedTickets = allTickets?.filter(ticket => ticket.status === "Reopened");
  const pendingTickets = allTickets?.filter(ticket => ticket.status === "Pending");

  const [mode, setMode] = useState('Ticket');
  const [circlePosition, setCirclePosition] = useState(0);

  const handleCircleClick = () => {
    if (mediaQueryMyTeamUpper) {
      setCirclePosition((prevPosition) => (prevPosition === 0 ? 40 : 0));
    } else {
      setCirclePosition((prevPosition) => (prevPosition === 0 ? 70 : 0));
    }
    setMode((prevMode) => (prevMode === 'Ticket' ? 'Task' : 'Ticket'));
  };

  useEffect(()=>{
    if(props.taskNavigate){
      // setMode('Task');
      setMode((prevMode) => (prevMode === 'Ticket' ? 'Task' : 'Task'));
      setCirclePosition(70);
      // handleCircleClick();
    }
  },[props.taskNavigate])

  const circleBackgroundColor = circlePosition === 0 ? '#144C89' : '#E7E7E7';
  const boxBackgroundColor = circlePosition === 0 ? '#E7E7E7' : '#144C89';
  const [showForm, setshowForm] = useState(false);
 
  return (
    <Box style={{...Styles.mainBox}}>
       <Box style={Styles.upperBox}>
          <Typography style={{...Styles.name, flexDirection: mediaQueryMyTeamUpper ? 'column' : 'row',fontSize:mediaQueryMyTeamUpper?'18px':'30px'}}>Hello, {data.name}</Typography>
          <Box onClick={handleCircleClick}  style={{marginLeft:"auto", border: "3px solid #144C89", backgroundColor: boxBackgroundColor, borderRadius: "20px", width: '110px', height: "38px", marginTop: '2.23%', position: 'relative', display: "flex", alignItems: "center", flexDirection: "row",}}>
          <Box onClick={handleCircleClick} >
          <Box  onClick={handleCircleClick} style={{ marginLeft: '3px', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: circleBackgroundColor, transform: `translateX(${circlePosition}px)`, transition: 'transform 0.3s ease-in-out',   }}></Box> </Box>
          {circlePosition === 0 ? (
            <Typography style={{ marginLeft: '15px', display: 'flex', justifyContent: "center", alignItems: "center", color: '#144C89', fontWeight: "bold",fontSize:mediaQueryMyTeamUpper?'14px':'',marginLeft:mediaQueryMyTeamUpper?"1px":" " }}>Ticket</Typography>
          ) : (
            <Typography style={{  display: 'flex', justifyContent: "left", alignItems: "left", color: '#E7E7E7', fontWeight: "bold",alignSelf:"left",fontSize:mediaQueryMyTeamUpper?'14px':'',marginLeft:mediaQueryMyTeamUpper?"-20px":" " }}>Task</Typography>
          )}
        </Box>
       
        {mode === 'Ticket' &&
          <Box onClick={()=>{props.setActive(12)}}style={{...Styles.reqButton,marginLeft:"5px"}}>
         
            <Typography style={{...Styles.text3,fontSize:mediaQueryMyTeamUpper?'12px':''}}>New ticket</Typography>
            <img src={require("../Images/plus.png")} />
          </Box>
        
        }
        {mode !== 'Ticket' &&
        <Box onClick={()=>{props.setActive(15)}}style={{...Styles.reqButton,marginLeft:"5px"  }}>
         
        <Typography style={{...Styles.text3,fontSize:mediaQueryMyTeamUpper?'12px':''  }}>Create Task</Typography>
        <img  src={require("../Images/plus.png")} />
      </Box>
        
    } 
          </Box>

          {mode === 'Ticket' &&
        <>
      <Box style={{...Styles.mainMiddleBox, marginTop: mediaQueryMyTeamUpper ? '1%' : ''}}> 
        <Box onClick={()=>ViewTicketPage("",allTickets?.length)} style={{ ...Styles.card }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={Styles.innerTicket}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Total tickets</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{allTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Resolved",resolvedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#FEB7B1" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Resolved</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{resolvedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Closed",closedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#E96973" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Closed</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{closedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Opened",openedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#F9C29A" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Opened</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{openedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("In Progress",inprogressTickets?.length)} style={{ ...Styles.card }}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#BCDCA4" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>In Progress</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{inprogressTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Assigned",assignedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#BEC9F3" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Assigned</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{assignedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Reopened",reopenedTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#FFE4AC" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Reopened</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{reopenedTickets?.length}</Typography>
        </Box>

        <Box onClick={()=>ViewTicketPage("Pending",pendingTickets?.length)} style={Styles.card}>
          <Box style={Styles.dottedLine}></Box>
          <Box style={{ ...Styles.innerTicket, backgroundColor: "#86D8FF" }}>
            <Box style={Styles.circle1}></Box>
            <Box style={Styles.circleMainBox}>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
              <Box style={Styles.circle2}></Box>
            </Box>
            <Typography style={Styles.text4}>Pending</Typography>
          </Box>
          <Box style={Styles.circle3}></Box>
          <Typography style={Styles.text5}>{pendingTickets?.length}</Typography>
        </Box>
      </Box>
      {/* {role === "admin" && */}

      <Box style={{...Styles.upperBox, height:"10%",marginTop:'0%'}}>
        <Typography
          style={{ ...Styles.name, fontSize: "22px", marginTop:"2%" }}
        >
          Team performance
        </Typography>
      </Box>
{/* } */}
{/* {role === "user" && */}

      <Box style={Styles.mainBottomBox}>
      <Box style={Styles.tableBox2}>
  <TableContainer component={Paper} elevation={0} style={Styles.tableContainer}>
    <Table style={Styles.table} aria-label="simple table">
      <TableHead style={Styles.tableHead}>
        <TableRow>
          <TableCell style={Styles.tableCell} align="left">
            Name
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Assigned
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Pending
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Opened
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            AMC Pending
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Turnkey Pending
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {getteams?.map((row) => (
          <TableRow onClick={()=>{navigateInnerDash(row.id,row?.role)}} style={{ height: 60,cursor:"pointer" }} key={row.id}>
            <TableCell style={Styles.tableText} align="left">
              {row?.name}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.assigned_count}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.pending_count}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.opened_count}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.amc_notcompleted_count}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.turnkey_notstarted_count}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</Box>

      </Box>
{/* } */}
{localStorage.getItem("role")==="admin" &&
<>
<Box style={{...Styles.upperBox, height:mediaQueryMyTeamUpper ?"60%":"auto"}}>
        <Typography
          style={{ ...Styles.name, fontSize: "22px", marginTop:mediaQueryMyTeamUpper ? "auto":"7%" }}
        >
          Customer performance
        </Typography>
      </Box>
<Box style={Styles.mainBottomBox}>
      <Box style={Styles.tableBox2}>
  <TableContainer component={Paper} elevation={0} style={Styles.tableContainer}>
    <Table style={Styles.table} aria-label="simple table">
      <TableHead style={Styles.tableHead}>
        <TableRow>
          <TableCell style={Styles.tableCell} align="left">
            Name
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Created
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Resolved
          </TableCell>
          <TableCell style={Styles.tableCell} align="center">
            Opened
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allcustomer?.map((row) => (
          <TableRow onClick={()=>{navigateInnerDash(row.id,row?.role)}} style={{ height: 60,cursor:"pointer" }} key={row.id}>
            <TableCell style={Styles.tableText} align="left">
              {row?.name}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.pending_count}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.resolved_count}
            </TableCell>
            <TableCell style={Styles.tableText} align="center">
              {row?.opened_count}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</Box>

      </Box>
      </>
}
</>
    }
      {mode !== 'Ticket' &&
      <Box style={{width:"100%",height:"86%",marginTop:"-2%",overflowY:"auto"}}>
        <Checklist setTaskDatastatus={props.setTaskDatastatus} setTaskData={props.setTaskData} setActive={props.setActive}/>
      </Box>
  }
    </Box>
  );
}
