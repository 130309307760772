import axios from "axios";
import React, { useEffect, useState } from "react";
import demoimg from "../../Images/profile.png";

const UserAvatar = (props) => {
    const [attachments, setAttachments] = useState("");
    // console.log("props in user avatar", props.uid);
    useEffect(() => {
      getAttachments();
    }, [props]);

    const getAttachments = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserprofileimg`,
          { "id": props.uid},
          { responseType: "arraybuffer" } // Ensure binary response
        );
        if (response.data) {
          const contentType = response.headers["content-type"];
          const arrayBuffer = response.data;
          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte),"")
          );
          const dataURI = `data:${contentType};base64,${base64}`;
          setAttachments(dataURI);
        }
      } catch (e) {
        // console.log(e);
      }
    };

  return (
    <img
      src={attachments?attachments:demoimg}
      alt="user avatar"
      style={props.assigned?styles.img2:styles.img1}
    />
  );
};

const styles = {
    img1:{
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
    },
    img2:{
        width: "55px",
        height: "50px",
        borderRadius: "50%",
        marginLeft: "4%",
        marginTop: "4%",
    }
}
export default UserAvatar;
