import { Box, Radio, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function ChecklistInneramc(props) {
  // console.log("props in checklistcard",props)
  const [selectedValue, setSelectedValue] = useState("a");
  const arrName = [
    {
      id: props.item?.created_user_id,
      name: props.item?.createdname,
    },
    {
      id: props.item?.assigned_user_id,
      name: props.item?.username,
    },
  ];
  // console.log("custom array for",userIdsArray);
  // const arrName = [
  //   {
  //     id: "1",
  //     name: "Mratik",
  //   },
  //   {
  //     id: "2",
  //     name: "Umesh",
  //   },
  //   {
  //     id: "3",
  //     name: "Vaidehi",
  //   },
  //   {
  //     id: "2",
  //     name: "Umesh",
  //   },
  // ];
  const letterColors = {
    a: "#81b29a",
    b: "#81b29a",
    c: "#81b29a",
    d: "#f2cc8f",
    e: "#f2cc8f",
    f: "#f2cc8f",
    g: "#3d405b",
    h: "#3d405b",
    i: "#3d405b",
    j: "#e07a5f",
    k: "#e07a5f",
    l: "#e07a5f",
    m: "#eaac8b",
    n: "#eaac8b",
    o: "#eaac8b",
    p: "#fe7f2d",
    q: "#e4c1f9",
    r: "#e4c1f9",
    s: "#a1c181",
    t: "#a1c181",
    u: "#a1c181",
    v: "#fe7f2d",
    w: "#fe7f2d",
    x: "#fe7f2d",
    y: "#bf0603",
    z: "#bf0603",
  };

  const getRandomColor = (name) => {
    const firstLetter = name?.charAt(0).toLowerCase();
    return letterColors[firstLetter] || "#540d6e"; // Default color is gray (#808080)
  };

  const isDarkColor = (color) => {
    const hex = color.substring(1); // Remove the '#' character
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Use the relative luminance formula to determine if the color is dark
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance < 128;
  };
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  useEffect(() => {
    if (props.item.completed_status === "completed") {
      setSelectedValue("true");
    }
  }, []);

  const inputDateString = props.item.performing_date;
  const formattedDate = new Date(inputDateString).toLocaleDateString("en-GB");

  const renderCircles = () => {
    const maxCirclesToShow = 2;

    return arrName.slice(0, maxCirclesToShow).map(({ id, name }, index) => {
      const backgroundColor = getRandomColor(name);
      const textColor = isDarkColor(backgroundColor) ? "white" : "black";

      return (
        <Box
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Box
            key={id}
            style={{
              marginLeft: index > 0 ? "2%" : "0", // Adjust spacing between circles
              width: "33px",
              height: "33px",
              borderRadius: "50px",
              backgroundColor,
              color: textColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography style={{ fontStyle: "bolder" }}>
              {name.charAt(0).toUpperCase()}
            </Typography>
          </Box>
        </Box>
      );
    });
  };

  return (
    <div>
      <Box
        style={{
          width: "90%",
          display: "flex",
          //   width: "15%",
          height: "120px",
          flexDirection: "column",
          border: "2px solid #F8F8F8",
          backgroundColor:
            props.item.completed_status === "completed" ? "#C3C0C0" : "#F8F8F8",
          borderRadius: "10px",
          marginTop: "5%",
          overflow: "hidden",
          boxShadow:
            props.item.completed_status === "completed"
              ? ""
              : "5px 5px 4px 0px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            borderBottom: "2px dashed #A9A9A9",
            height: "80px",
            flexDirection: "column",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Radio
              {...controlProps("true")}
              sx={{
                color: "#082A49",
                "&.Mui-checked": {
                  color: "#082A49",
                },
              }}
            />
            <Typography
              style={{
                color: "#082A49",
                fontSize: "14px",
                marginLeft: "2%",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                // height:"50%",
                // overflow:"hidden"
              }}
            >
              {props.item.taskname && props.item.taskname.length > 50
                ? `${props.item.taskname.substring(0, 50)}`
                : props.item.taskname}
              {props.item.taskname && props.item.taskname.length > 50 && (
                <>
                  {" "}
                  <span
                    style={{
                      color: "#1282A2",
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                    // onClick={handleOpenPopup}
                  >
                    ...
                  </span>
                </>
              )}

              {/* {props.item.taskname} */}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            width: "90%",
            marginLeft: "2%",
            height: "90%",
            display: "flex",
          }}
        >
          <Box
            style={{
              width: "60%",
              height: "90%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#A3CEF1",
                borderRadius: "5px",
                justifyContent: "space-evenly",
                // flexWrap:"inherit",
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  height: "15%",
                  width: "15%",
                  marginTop: "1%",
                  marginLeft: "1px",
                  marginRight: "1px",
                }}
                src={require("../../Images/DateIcon.png")}
                width="20px"
                alt="edit-icon"
              />
              <Typography style={{ fontSize: 11, color: "#082A49" }}>
                {formattedDate}
              </Typography>
            </Box>
          </Box>
          {renderCircles()}
          {arrName.length > 2 && (
            <Box
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  marginLeft: "2%", // Adjust spacing between circles and plus symbol
                  width: "33px",
                  height: "33px",
                  borderRadius: "50px",
                  backgroundColor: "#CCCCCC", // Adjust the color as needed
                  color: "black", // Adjust the color as needed
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Typography style={{ fontStyle: "bolder" }}>+</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
}
