import {
    Box,
    Typography,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    TextField,
    useMediaQuery,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Styles from "../../Styles/AdminDashboard";
  import axios from "axios";
import LogsAttachment from "../../Components/TicketDetails/LogsAttachment";
  
  export default function ChangeTaskPopupamc(props) {
    const [status, setStatus] = React.useState("");
    console.log("props in change assignee",props.data.performing_date)
    const MediaQuerypopup = useMediaQuery('(max-width: 900px)')
    const [attachments, setAttachments] = useState([]);
    const [editedName, setEditedName] = useState(props?.data?.taskname);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [nameEdit, setNameEdit] = useState(false);
    const [data,setData] = useState();
    const [selectedAssignee, setSelectedAssignee] = useState("");
    const [selectedBucket, setselectedBucket] = useState("");
    const handleClose = () => {
      props.setOpen1(false);
    };
  
    const handleChange = (event) => {
      setStatus(event.target.value);
    };
  
    const [newStatusArray,setNewstatusArray] = useState([])
    const [statusClosed,setStatusClosed] = useState(true);
    const statusArray2 = [
        {
          id:1,
          name:"In Progress",
          value:"In Progress"
        },
        {
          id:2,
          name:"Completed",
          value:"Completed"
        },
        {
          id:3,
          name:"Not Started",
          value:"notstarted"
        },
      ]

    useEffect(()=>{
        setNewstatusArray(statusArray2)
    },[])
  
    // useEffect(()=>{
    //   if(props.currentstatus === "Resolved"){
    //     projectMembers();
    //   }
    //   else{
    //     allMembers();
    //   }
    // },[])
  
      const allMembers = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/getalluser`,
            { "organisation": localStorage.getItem("orgn_id")}
          );
          // console.log(response.data.data,"project api oooooooo");
          if (response.data.status === 1) {
            setAssignto(response.data.data);
          }
        } catch (e) {
          // console.log(e);
        }
      };
  
      const projectMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallprojectteam`,
          {
            "project_id":props.project_id
          }
        );
        console.log(response.data.data, "shshshshshh");
        if (response.data.status === 1) {
          // console.log('Edit data has ', response.data.data);
          setAssignto(response.data.data);
        } else {
          // console.error("Failed to update user data:", response.data.data);
        }
      } catch (error) {
        // console.error("Error updating user data:", error);
      }
    };
  

   
    const [assignstatus, setassignStatus] = React.useState("");
    
    const handleassignChange = (event) => {
      setassignStatus(event.target.value);
    };
    const [assignTo,setAssignto] = useState([]);

    const uploadCategoryImgFile = async (len, allfiles) => {
        console.log("Hello : uploadCategoryImgFile ");
        try {
          for (let i = 0; i < len; i++) {
            const formdata = new FormData();
            const file = allfiles[i];
            console.log("file name : ", file, "ith value", allfiles[i]);
            formdata.append("tasks_documents", file);
            // formdata.append("task_id", id);
            // formdata.append("name", file?.name);
            console.log(formdata, "uploaded formdata");
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/uploadtask?name=${file.name}`,
              formdata,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response.data.status === 1) {
              console.log("uploaded successfully in second attempt");
    
              try {
                const file = allfiles[i];
                const response = await axios.post(
                  `${process.env.REACT_APP_API_URL}/addtaskturneydocpath`,
                  {
                    turnkey_id: props.data?.turnkey_id,
                    file_name: file.name,
                    user_id: localStorage.getItem("user_id"),
                    task_id:props?.data?.taskid
                  }
                );
                if (response.data.status === 1) {
                  console.log("uploaded successfully", response.data.data);
                  // setAttachments()
                  setAttachments((prevLogs) => [
                    ...prevLogs,
                    ...response.data.data,
                  ]);
                  setSelectedFiles((prevLogs) => [
                    ...prevLogs,
                    ...response.data.data,
                  ]);
                }
                console.log(response.data);
              } catch (e) {
                console.log(e);
              }
            }
            console.log(response.data);
          }
        } catch (e) {
          console.log(e);
        }
      };

      const [targetDate, setTargetDate] = useState(null);

    const handleTargetDateChange = (event) => {
        const selectedDateString = event.target.value;
        const selectedDateObject = new Date(selectedDateString);
        setTargetDate(selectedDateObject);
        // console.log(selectedDateObject.toLocaleDateString("en-GB")); // Log in dd/mm/yy format
      };

      const [edittargetdate, setedittargetdate] = useState(false);
      const inputDateString1 = props.data?.performing_date;
  const formattedDate1 = new Date(inputDateString1).toLocaleDateString("en-GB");

  const [numofFiles, setNumOfFiles] = useState("");
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setNumOfFiles(files.length);
    // setAttachments(files);
    setSelectedFiles(files);
    uploadCategoryImgFile(files.length, files);
  };


  useEffect(() => {
    const allMembers = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getprojectteam`,
          {
            organisation: localStorage.getItem("orgn_id"),
            project_id: props.projectselected.project_id,
          }
        );
        //console.log(response.data.data,"project api oooooooo");
        if (response.data.status === 1) {
          setAssignto(response.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    allMembers();
  }, []);

  const handleInputChange = (key, value) => {
    console.log("all data: ", key, value);
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

    if (key === 'assignto') {
        const selectedAssignee = assignTo.find(item => item.id === value);
        if (selectedAssignee) {
          setSelectedAssignee(selectedAssignee.name);
        }
      }
  };

  const handleSave1 = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/updateturnkeytask`,
        {
          id: props.data.taskid,
          turnkey_id: data.bucket ? data.bucket : props.data.turnkey_id,
          assigned_user_id: data.assignto ? data.assignto : props.data.assigned_user_id,
          progress: data.progress ? data.progress : props.data.progress,
          performing_date: targetDate ? targetDate : props.data.performing_date,
          name: data.name ? data.name : editedName
        }
      );
      console.log(response.data.data,"task updated successfully");
      if(data.progress){
        props.setstatus(data.progress);
      }
      if(data.assignto){
        props.setassigned_to(selectedAssignee);
      }

      if(data.bucket){
        props.setCurrentBucketamc(selectedBucket);
      }
      //props.setData(data);
      if (response.data.status) {
        // setAssignto(response.data.data);
        props.setsubCheckListsamc((prevLogs) => [
          ...prevLogs,
          ...response.data.responseassignee,
        ]);
        // alert("Task Updated");
        props.getCheckList();
        handleClose();
      }
    } catch (e) {
      console.log(e);
    }
    props.setOpen(false);
  };

  const handleSave = async() => {
    console.log("data?.assignto",data?.assignto);
    let uuid = localStorage.getItem("user_id");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/updatechecklisttask`,
        {
          "id":props.data.checklist_id,
          "user_id":uuid?uuid:localStorage.getItem("user_id"),
          "assigned_user_id":data?.assignto ? data?.assignto:props.data.assigned_user_id,
          "completed_status":data?.progress ? data?.progress:props.data.progress,
          "performing_date":targetDate?targetDate: props.data.performing_date,
          "description": generateDescription(data, props?.data,assignTo)
        }
        
      );
      function generateDescription(newData, oldData, assignTo) {
        const assignedUserName = assignTo.find(item => item.id === newData?.assignto)?.name;
      
        if (assignedUserName && newData?.assignto !== oldData?.assigned_user_id) {
          return `Changed assigned user ${assignedUserName}`;
        } else if (newData?.targetDate && newData?.targetDate !== oldData?.performing_date) {
          return 'Changed performing date';
        } else if (newData?.progress && newData?.progress !== oldData?.progress) {
          return `Changed status to ${newData?.progress}`;
        }
      
        // If no specific change, you can return a default message or an empty string
        return '.';
      }
      console.log(response.data.data,"cheklist completed",response.data.responseassignee);
      if (response.data.status) {
        // setAssignto(response.data.data);
        // props.setsubCheckLists(response.data.responseassignee);
        // props.setsubCheckLists((prevLogs) => [...prevLogs, ...response.data.responseassignee]);
        if(data.progress){
            props.setstatus(data.progress);
          }
        if(data.assignto){
            props.setassigned_to(selectedAssignee);
          }
         alert("Task Updated");
        handleClose();
      }
    } catch (e) {
      console.log(e,"eeore");
    }
    props.setOpen1(false);
  }
    return (
      <Box>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
            {/* <Box>
                <Typography
                  style={{ fontFamily: "Montserrat", color: "#082A49" }}
                >
                  Targeted date
                </Typography>
                <FormControl
                  size="small"
                  style={{
                    ...Styles.FormControl,
                    height: "40px",
                    marginTop: "1.5%",
                    alignItems: "center",
                    justifyContent: "center",
                    //   marginLeft: "2.4%",
                  }}
                  fullWidth
                >
                  {!edittargetdate ? (
                    <Typography
                      onClick={() => {
                        setedittargetdate(true);
                      }}
                      style={{
                        width: "90%",
                        fontSize: "14px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#1282A2 ",
                        fontFamily: "Montserrat",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {formattedDate1}
                      <img
                        style={{ width: "20px", height: "12px" }}
                        src={require("../../Images/downArrow.png")}
                      />
                    </Typography>
                  ) : (
                    <TextField
                      type="date"
                      // value={formattedDateString || oldDateObject} // Use formatted string or Date object
                      onChange={handleTargetDateChange}
                      InputLabelProps={{ style: { display: "none" } }}
                      InputProps={{
                        style: {
                          border: "none",
                          height: "100%",
                          // width: "100%",
                          color: "#1282A2 ",
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        ...Styles.inputBox,
                        width: "100%",
                        height: "100%",
                        marginTop: "0%",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        margin: "auto",
                      }}
                    />
                  )}
                </FormControl>
              </Box> */}
          <Box style={{...Styles.statusnotify_changestat,width:MediaQuerypopup?"350px":"703px",height: "700px",}}>
            <Box style={Styles.statusnotifyTitlebutton_changestat}>
            {nameEdit ? (
              <>
                <TextField
                  value={editedName}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  autoFocus
                  variant="standard"
                  // onBlur={handleCancelClick} // Cancel editing on blur
                />
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "2%",
                    cursor: "pointer",
                  }}
                onClick={()=>{setNameEdit(false)}}

                  src={require("../../Images/Iconcheck.png")}
                />
              </>
            ) : (
              <h2
                id="parent-modal-title"
                style={{
                  ...Styles.statusTitle_changestat,
                  color: "#082A49",
                  fontSize: "24px",
                  textAlign: "flex-start",
                  width: "80%",
                }}
                onClick={()=>{setNameEdit(true)}}
              >
                 {editedName && editedName.length > 70
                ? `${editedName.substring(0, 70)}`
                : editedName}
              {editedName && editedName.length > 70 && (
                <>
                  {" "}
                  <span
                    style={{
                      color: "#1282A2",
                      cursor: "pointer",
                      fontSize: "22px",
                    }}
                    // onClick={handleOpenPopup}
                  >
                    ...
                  </span>
                </>
              )}
                {/* {editedName} */}
              </h2>
            )}
              <p
                onClick={handleClose}
                style={{...Styles.statusnotifyXbutton_changestat,display:'flex',justifyContent: 'right',marginLeft:"auto",marginTop:"3.5%"}}
              >
                X
              </p>
            </Box>
            <Box
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridTemplateRows: "1fr 1fr",
              }}
            >
              {/* First row */}
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "1",
                  gridRow: "1",
                  ...Styles.statusText_changestat,
                }}
              >
                Current Status
              </p>
              <div style={{ gridColumn: "2", gridRow: "1" }}>
                {/* You can add any content or leave it empty */}
              </div>
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "3",
                  gridRow: "1",
                  textAlign: "right",
                  ...Styles.statusText_changestat,
                }}
              >
                Change Status
              </p>
  
              {/* Second row */}
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "1",
                  gridRow: "2",
                  textAlign: "left",
                  ...Styles.statusCurrent_changestat,
                }}
              >
                {props.currentstatus}
              </p>
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "2",
                  gridRow: "2",
                  textAlign: "center",
                  color: "#A9A9A9",
                }}
              >
                to
              </p>
              <FormControl
                  size="small"
                  style={{
                    ...Styles.FormControl,
                    height: "40px",
                    marginTop: "1.5%",
                    //   marginLeft: "2.4%",
                  }}
                  fullWidth
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={sort}
                    // label="Select company"
                    style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                    size="small"
                    value={data?.progress ? data?.progress : "notstarted"}
                    onChange={(e) =>
                      handleInputChange("progress", e.target.value)
                    }
                    // onChange={(event) => setsort(event.target.value)}
                  >
                    {/* ... (other Select menu items) */}
                    <MenuItem value={"notstarted"}>Not started</MenuItem>
                    <MenuItem value={"inprogress"}>In Progress</MenuItem>
                    <MenuItem value={"completed"}>Completed</MenuItem>
                  </Select>
                </FormControl>
            </Box>
  
            {/* <Box>
              <p
                id="status-notify-text-Reason"
                style={{...Styles.statusnotifyreason_changestat,marginTop:MediaQuerypopup?"1px":"425px"}}
              >
                Reason/ Comment
              </p>
              <TextField
                id="outlined-multiline-flexible"
                label="Multiline"
                multiline
                maxRows={4}
                InputLabelProps={{ style: { display: "none" } }}
                InputProps={{ style: { border: "none" ,height:"100%",width:"100%"} }}
                sx={{
                  "& fieldset": {
                    border: "none",
                  },
                  ...Styles.customtextfield_changestat,width:MediaQuerypopup?"350px":"695px"
                }}
              />
            </Box> */}
            {/* <Box style={Styles.statusnotifyTitlebutton_changestat}>
              <h2 id="parent-modal-title" style={Styles.statusTitle_changestat}>
                Change assignee
              </h2>
            </Box> */}
            <Box
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridTemplateRows: "1fr 1fr",
              }}
            >
              {/* First row */}
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "1",
                  gridRow: "1",
                  ...Styles.statusText_changestat,
                }}
              >
                Current assignee
              </p>
              <div style={{ gridColumn: "2", gridRow: "1" }}>
                {/* You can add any content or leave it empty */}
              </div>
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "3",
                  gridRow: "1",
                  textAlign: "right",
                  ...Styles.statusText_changestat,
                }}
              >
                Change assignee
              </p>
  
              {/* Second row */}
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "1",
                  gridRow: "2",
                  textAlign: "left",
                  ...Styles.statusCurrent_changeassign,
                }}
              >
                {props.data.username}
              </p>
              <p
                id="parent-modal-description"
                style={{
                  gridColumn: "2",
                  gridRow: "2",
                  textAlign: "center",
                  color: "#A9A9A9",
                }}
              >
                to
              </p>
              <FormControl
                    size="small"
                    style={{
                      ...Styles.FormControl,
                      height: "40px",
                      marginTop: "1.5%",
                      //   marginLeft: "2.4%",
                    }}
                    fullWidth
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={sort}
                      // label="Select company"
                      style={{ ...Styles.selectBox, color: "#A9A9A9" }}
                      size="small"
                      onChange={(e) =>
                        handleInputChange("assignto", e.target.value)
                      }
                      // onChange={(event) => setsort(event.target.value)}
                    >
                      {/* ... (other Select menu items) */}
                      {assignTo?.map((item, index) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
            </Box>

            {/* <Box style={Styles.statusnotifyTitlebutton_changestat}>
              <h2 id="parent-modal-title" style={Styles.statusTitle_changestat}>
                Change Bucket
              </h2>
            </Box> */}
            
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box style={{ }}>
            <p
                id="parent-modal-description"
                style={{
                  ...Styles.statusText_changestat, lineHeight: '0px',
                }}
              >
                Attachments
              </p>
                <Box
                  style={{
                    width: "90%",
                    marginTop: "2%",
                    height: "30%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      width: "130%",
                      height: "95%",
                      display: "flex",
                      flexDirection: "row",
                      overflowY: "auto",
                      justifyContent: "space-between",
                    }}
                  >
                    {selectedFiles?.map((file, index) => (
                      <Box
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#F8F8F8",
                          border: "1px solid #1282A2",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* {getFileExtension(file.name) === "pdf" ? (
                          <img
                            src={imageDemo}
                            alt={`Demo Image for PDF`}
                            style={{
                              width: "70%",
                              height: "70%",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`Selected Image`}
                            style={{
                              width: "80%",
                              height: "80%",
                              objectFit: "contain",
                            }}
                          />
                        )} */}
                        <LogsAttachment
                          turnkey={props.data.turnkey_id}
                          ticket_id={file.ticket_id}
                          id={file.id}
                          name={file.file_name}
                        />
                        {/* <img style={{width:"80%",height:"80%"}} src={URL.createObjectURL(file)} /> */}
                      </Box>
                    ))}
                  </Box>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => handleFileChange(e)}
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <label
                    htmlFor="fileInput"
                    style={{ cursor: "pointer", marginBottom: "10px" }}
                  >
                    <img
                      style={{ width: "25px", cursor: "pointer" }}
                      src={require("../../Images/Iconplusblue.png")}
                    />
                  </label>
                </Box>
              </Box>
              {localStorage.getItem("role")==='admin' &&
              
              <Box style={{justifyContent: "flex-end" }}>
              <p
                id="parent-modal-description"
                style={{
                  ...Styles.statusText_changestat, lineHeight: '0px',
                }}
              >
                Targeted Date
              </p>
              <Box>
                <FormControl
                  size="small"
                  style={{
                    ...Styles.FormControl,
                    height: "40px",
                    marginTop: "1.5%",
                    alignItems: "center",
                    justifyContent: "center",
                    //   marginLeft: "2.4%",
                  }}
                  fullWidth
                >
                  {!edittargetdate ? (
                    <Typography
                      onClick={() => {
                        setedittargetdate(true);
                      }}
                      style={{
                        width: "90%",
                        fontSize: "14px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#1282A2 ",
                        fontFamily: "Montserrat",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {formattedDate1}
                      <img
                        style={{ width: "20px", height: "12px" }}
                        src={require("../../Images/downArrow.png")}
                      />
                    </Typography>
                  ) : (
                    <TextField
                      type="date"
                      // value={formattedDateString || oldDateObject} // Use formatted string or Date object
                      onChange={handleTargetDateChange}
                      InputLabelProps={{ style: { display: "none" } }}
                      InputProps={{
                        style: {
                          border: "none",
                          height: "100%",
                          // width: "100%",
                          color: "#1282A2 ",
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        ...Styles.inputBox,
                        width: "100%",
                        height: "100%",
                        marginTop: "0%",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        margin: "auto",
                      }}
                    />
                  )}
                </FormControl>
                </Box>
              </Box>
              }
              </Box>
  
            <Box id="status-notify-entry">
              <Button
                variant="contained"
                onClick={()=>{handleSave()}}
                style={{...Styles.statusnotifysave_changestat, top: '686px', left: '251px',}}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{...Styles.statusnotifycancel_changestat, top: '686px', left: '420px',}}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }
  