
import { Box,Typography, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState ,useRef } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import demoimg from "../Images/profile.png";
import notification from "../Images/notification.png";
import Notificationpopup from './notification/Notificationpopup';
import ConveneCall from './TicketDetails/ConveneCall';
import NotificationSound from "../Images/call.mp3";
import SearchCard from './Dashboard/SearchCard';


export default function Navbar(props) {
  const MediaQueryOrgCard = useMediaQuery('(max-width: 900px)');
  const [allTickets, setAllTickets] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCards, setFilteredCards] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [navigateData,setnavigateData] = useState([]);
  const [navigateState, setNavigateState] = useState(false);
  const [notificationRemoved, setNotificationRemoved] = useState(false);
  const [isTextFieldSelected, setIsTextFieldSelected] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const ticket_name = "prasanna joshi";
  const [playedNotificationSound, setPlayedNotificationSound] = useState(false);
  const [soundCall,setsoundCall] = useState(0);
  const [attachments, setAttachments] = useState("");
    // console.log("this is image in logs attachment", attachments);
    useEffect(() => {
      getAttachments();
    }, []);

    const audioRef = useRef(null);
    const audioRef1 = React.useRef(null);

    const [notificationcnt , setNotificationcnt] = useState([])
    const [callNotify,setCallNotify] = useState(false);
    const [meetName,setMeetName] = useState("");
    const [oldCount, setOldCount] = useState('')
    useEffect(() => {
    const allnotifications = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getallnotifications`,
          {
            "user_id": localStorage.getItem("user_id"),
          }
        );
        if (response.data.status === 1) {
          setNotificationcnt(response.data.data);
          // console.log("notificationcnt test",response.data.data[0]?.notopened_count,"ccc",notificationcnt[0]?.notopened_count,"ccc",oldCount)
          if (oldCount !== response.data.data[0]?.notopened_count) {
            // playNotificationSound();
            if (playedNotificationSound === false) {
              // playNotificationSound();
              // setPlayedNotificationSound(true);
            }
          }
      else {
            // pauseNotificationSound();
          }
        }
      } catch (e) {
        // console.log(e);
      }
    };
    allnotifications();
    const intervalId = setInterval(allnotifications, 2000); // 2000 milliseconds = 2 seconds

  // Clean up interval on component unmount
  return () => clearInterval(intervalId);
  },[])

  
  useEffect(() => {
    const callnotifications = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getcallnotification`,
          {
            "id": localStorage.getItem("user_id"),
          }
        );
        if (response.data.data[0]?.call_notification === "true") {
          //console.log("call notification",response.data.data[0]?.call_notification)
          setMeetName(response.data.data[0]?.meet_name);
          setCallNotify(true)
          if (soundCall === 0 && !playedNotificationSound) {
            // playNotificationSound();
            setPlayedNotificationSound(true);
            setsoundCall(1);
          }
      
        }
      } catch (e) {
        //console.log(e);
      }
    };
    // callnotifications();
  //   const intervalId = setInterval(callnotifications, 2000); // 2000 milliseconds = 2 seconds

  // // Clean up interval on component unmount
  // return () => clearInterval(intervalId);
  },[])
  
  useEffect(() => {
    setOldCount(notificationcnt[0]?.notopened_count)
  },[notificationcnt])

  useEffect(() => {
    if(callNotify=== true){
      handleOpen3()
      // playNotificationSound()

    }
  },[callNotify])

  // useEffect(() => {
  //   if(callNotify === true){
  //     playNotificationSound()
  //   }
  // },[])

    // useEffect(() => {
    //   const intervalId = setInterval((len) => {
    //     allnotifications();
    //     if (notificationcnt.length !== len) {
    //       // Assuming notificationcnt is an array, you might need to adjust the condition
    //       playNotificationSound();
    //     }
    //   }, 2000); // 2000 milliseconds = 2 seconds
  
    //   // Clean up interval on component unmount
    //   return () => clearInterval(intervalId);
    // }, [notificationcnt]); // Run when notificationcnt changes
  
    const playNotificationSound = () => {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        setPlayedNotificationSound(true);
      }
    };

    

    // const pauseNotificationSound = () => {
    //   if (audioRef.current) {
    //     // audioRef.current.currentTime = 0;
    //     audioRef.current.pause();
    //   }
    // };

    useEffect(() => {
      const allnotifications = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/getallnotifications`,
                { 
                  "user_id":localStorage.getItem("user_id")
              }
              );
          // console.log(response.data.data,"notifications");
          if (response.data.status === 1) {
            setNotificationcnt(response.data.data);
            
          }
        } catch (e) {
          console.log(e);
        }
      };
      allnotifications();
    }, [notificationRemoved]);
    const getAttachments = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getuserprofileimg`,
          { "id": localStorage.getItem("user_id") },
          { responseType: "arraybuffer" } // Ensure binary response
        );

        if (response.data) {
        //   console.log("first response", response.data);
          const contentType = response.headers["content-type"];
          const arrayBuffer = response.data;

          // Convert binary data to base64
          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          const dataURI = `data:${contentType};base64,${base64}`;
          setAttachments(dataURI);
        }
      } catch (e) {
        // console.log(e);
      }
    };
  useEffect(() => {
    // console.log("searched value",searchValue)
    const getUserSpecificTickets = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/globalSearch`,
          { query: searchValue, "user_id":localStorage.getItem("user_id"),"orgn_id":localStorage.getItem("orgn_id"),"role":localStorage.getItem("role") }
        );
        console.log(response.data, 'project search api'); 
        if (response.data.status === 1) {
          setAllTickets(response.data.data);
          // setNavigateState(false);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getUserSpecificTickets();
  }, [searchValue]);

  useEffect(() => {
    // Update filteredCards when searchValue changes
    // console.log('Effect is called');
    if (searchValue.length > 0) {
      const newFilteredCards = allTickets
        .filter((card) => card?.name?.toLowerCase().startsWith(searchValue.toLowerCase()))
        .sort((a, b) => a?.name?.localeCompare(b.name));
  
      setFilteredCards(newFilteredCards);
  
      // Clear the dropdown if no matches are found
      if (newFilteredCards.length === 0) {
        setIsTextFieldSelected(false);
      } else {
        setIsTextFieldSelected(true);
      }
    } else {
      // If searchValue is empty, hide the dropdown
      setFilteredCards([]);
      setIsTextFieldSelected(false);
    }
  }, [searchValue, allTickets]);

  useEffect(()=>{
    if(navigateState){
      handleDetail(navigateData);
    }
  },[navigateState])

const handleDetail = (card) => {
  console.log('handleDetail called with card:', card);
    props.setTicketDetails(card);
    setIsTextFieldSelected(false);
    props.setActive(9);
    setNavigateState(false);
    // console.log("vlicked card data", card);
};
const navbarRef = useRef(null);

const boxRef = useRef(null);

const handleClickOutside = (event) => {
  if (boxRef.current && !boxRef.current.contains(event.target)) {
    setIsTextFieldSelected(false);
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

// const playCallSound = () => {
//   if (audioRef1.current) {
//     audioRef1.current.currentTime = 0;
//     audioRef1.current.play();
//   }
// };
return (
  <Box style={{ ...Styles.mainBox, display: 'flex', justifyContent: 'space-between' }}>
    <Box onClick={()=>props.setActive(1)} style={{ ...Styles.logoBox, width: MediaQueryOrgCard ? '55%' : '20%',justifyContent:MediaQueryOrgCard ? 'left' : '' }}>
      <img style={{ marginTop: '3px',width: MediaQueryOrgCard ? '35%' : '' }} src={require('../Images/cbs_Logo.png')} alt="Logo 1" />
    </Box>
    {/* <text onClick={()=>{playNotificationSound()}} >sdcscfdsv</text> */}
    <Box style={{ ...Styles.rightSection, display: 'flex', position: 'relative', }}>
    {!MediaQueryOrgCard && (
    <TextField
        id="outlined-basic"
        onChange={(event) => setSearchValue(event.target.value)}
        variant="outlined"
        autoComplete='off'
        onFocus={() => setIsTextFieldSelected(true  )}
        //onBlur={() =>  setIsTextFieldSelected(false)}
        fullWidth
        style = { {border: '3px solid #144C89', borderRadius: '8px', marginRight: '5%',width:'50%' }}
        InputProps={{
          placeholder: 'Search',
          autoComplete: 'off'

        }}        //label="Search"
      />
      )}
      {isTextFieldSelected && searchValue.length > 0 && (
         <Box
         //   ref={boxRef}
         style={{
           backgroundColor: "#F8F8F8",
           position: "absolute",
           zIndex: 1000,
           width: "270px",
           height: `${filteredCards.length * 40 + 10}px`,
           marginRight: "25%",
           top: 80,
           boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
           maxHeight: "200px",
           overflowY: "auto",
         }}
       >
         {filteredCards.map((card) => (
             <SearchCard settaskSelected={props.settaskSelected} setTaskData={props.setTaskData} setIsTextFieldSelected={setIsTextFieldSelected} setNavigateState={setNavigateState} setTicketDetails={props.setTicketDetails} setActive={props.setActive} boxRef={boxRef} card={card} filteredCards={filteredCards}/>
         ))}
       </Box>
       
        // <Box
        //   ref={boxRef}
        //   style={{
        //     backgroundColor: "#F8F8F8",
        //     position: "absolute",
        //     zIndex: 1000,
        //     width: "270px",
        //     height: `${filteredCards.length * 40 + 10}px`,
        //     marginRight: "25%",
        //     top: 80,
        //     boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        //     maxHeight: "200px",
        //     overflowY: "auto",
        //   }}
        // >
        //   {filteredCards.map((card) => (
        //     <MenuItem 
        //       style={{display:"flex",justifyContent:"space-between"}}
        //       onClick={() => { handleDetail(card) }}>
        //       {card.name && card.name.length > 20
        //         ? `${card.name.substring(0, 20)}`
        //         : card.name}
        //       {card.name && card.name.length > 20 && (
        //         <>
        //           {" "}
        //           <span
        //             style={{
        //               color: "#1282A2",
        //               cursor: "pointer",
        //               fontSize: "13px",
        //             }}
        //           >
        //             ...
        //           </span>
        //         </>
        //       )}
        //        <Typography style={{justifyContent:"flex-end",fontSize:"12px"}}>
        //         {card.table_name === "users_master" ? "User" :
        //         card.table_name === "tickets_master" ? "Ticket" :
        //         card.table_name === "task_master" ? "Task" :
        //         card.table_name === "project_master" ? "Project" : ""}
        //       </Typography>
        //     </MenuItem>
        //   ))}
        // </Box>
      )}
      
      <Box
        sx={{
          ...Styles.item,
          position: "relative",
          color: props.active === 5 ? "#082A49" : "#082A49",
          marginRight:2,
          
          
        }}
        ref={navbarRef}
        
        onClick={() =>handleOpen()}
        >
       
        {/* <Typography>{notificationcnt[0]?.notopened_count}</Typography> */}
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%",cursor:"pointer" }}>
    {/* Your count */}
    <audio ref={audioRef} onEnded={() => setPlayedNotificationSound(false)}>
      <source src={require("../Images/call.mp3")} />
      <source src={require("../Images/call.ogg")} />
      Your browser does not support the audio element.
    </audio> 
    {/* <audio ref={audioRef1} src={require("../Images/call.mp3")} /> */}

    {/* <button onClick={handleNotificationClick}>Trigger Notification Sound</button> */}
{/* <text}>Pause now</text> */}
    <Typography style={{ textAlign: "center", paddingTop: "5px",fontWeight:'bold',color:'#144C89' }}>{notificationcnt[0]?.notopened_count}</Typography>
  </div>
          <img
          // ref={navbarRef}
          style={{ height: "40px", width: "40px",cursor:"pointer"}}
          src={notification}
          />
      </Box>
          {open && <Notificationpopup setNotificationRemoved={setNotificationRemoved} setNavigateState={setNavigateState} open={open} setOpen={setOpen} setnavigateData={setnavigateData} anchorEl={navbarRef.current} />}
      <Box
        sx={{
          ...Styles.item,
          // justifyContent: "flex-end",
          color: props.active === 5 ? "#082A49" : "#082A49"
        }}
        onClick={() => {
          props.setActive(5);
          props.handleClick(5);
        }}
      >
        {props.active === 5 ? (
          <img
          style={{ marginRight: "0px", height: "45px", width: "45px",borderRadius:"50px",position:'relative' }}
          src={attachments?attachments:demoimg}
          />
          ) : (
            <img
            style={{ marginRight: "0px", height: "45px", width: "45px",borderRadius:"50px" ,cursor:"pointer",position:'relative'}}
            src={attachments?attachments:demoimg}
            />
        )}
      </Box>
    </Box>
    {open3 && <ConveneCall answerCall={true} ticket_name={meetName} open={open3}  setOpen={setOpen3} />}

  </Box>
);

}
const Styles = {
  mainBox: {
    width: '100%',
    height: '10vh',
    borderBottom: '4px solid #144C89',
  },
  logoBox: {
    height: '10vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightSection: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
    //marginRight: '60px',
  },
  searchImg: {
    width: '20px',
    height: '20px',
    marginTop: '9px',
  },
  searchTextField: {
    width: '20%',
    height: '40px',
    border: '3px solid #144C89',
    borderRadius: '8px',
  },
  txt: {
    fontSize: "14px",
  },
};

